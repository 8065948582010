import React from "react";
import { connect } from "react-redux";
import { Row, Col, Alert } from "reactstrap";
import Scorecard from "./Scorecard";
import BrokerDropdown from "./BrokerDropdown";
import s from "./Profile.module.scss";
// import axios from 'axios';
import ErrorPage from "../error/ErrorPage";
import Loading from "./components/loading";
// var moment = require('moment');

class BrokerScorecardV2 extends React.Component {
  constructor(props) {
    super(props);
    this.selectBrokerforCard = this.selectBrokerforCard.bind(this);
    this.chooseBrokerforCard = this.chooseBrokerforCard.bind(this);
    this.state = {
      relevantProjects: [],
      subjectName: "",
      marketDataReady: false,
      fetchingBrokerData: false,
      brokerDropdownOptions: [],
      brokerRetrieveError: "",
      dropdownSelected: "",
      marketdata: [],
      alerts: [
        {
          id: "al-4",
          type: "danger",
          msg: '<span class="fw-semi-bold">Notice:</span> To use this tool, you must be subscribed to at least one local market or to the Master National Dashboard.',
          visible: [true, true, true],
        },
      ],
      alerterror: [
        {
          id: "al-5",
          type: "danger",
          msg: '<span class="fw-semi-bold">Error:</span> Error retrieving broker info. Please refresh the page and try again.',
          visible: [true, true, true],
        },
      ],
      subjectInfo: {
        serviceline: "",
        phonenumber: "",
        email: "",
        market: "",
        title: "",
      },
    };
  }

  cleanPersonList(person) {
    let list = [];
    person.forEach((item) => list.push(item.fullname));
    let theperson = list.join(", ");
    return theperson;
  }

  cleanServiceList(serviceline) {
    let list = [];
    serviceline.forEach((item) => list.push(item.servicelinename));
    let theservice = list.join(", ");
    return theservice;
  }

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  closeAlert(index, alertGroup) {
    const newAlerts = [...this.state.alerts];
    newAlerts[index].visible[alertGroup] = false;

    this.setState({ alerts: newAlerts });
  }

  selectBrokerforCard(event) {
    this.setState({
      dropdownSelected: event.value,
    });
  }

  chooseBrokerforCard() {
    if (!(this.state.dropdownSelected === "")) {
      this.props.history.push(
        `/app/reporting/brokerscorecard/${this.state.dropdownSelected}`
      );
    }
  }

  redirectToDashboard() {
    this.props.history.push("/app/dashboard");
  }

  getMemberInfo() {
    const params = this.props.match.params;
    if (!(params.subjectName === undefined)) {
      let subjectName = params.subjectName;

      this.setState(
        {
          subjectName: subjectName
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" "),
        },
        this.retreiveMemberInfo(subjectName)
      );
    }
  }

  getMarketsOppData() {
    // for(var x in this.props.user.groups) {
    //   markets.push(this.props.user.groups[x]);
    // }

    // axios.defaults.headers ={
    //   "Content-Type": "application/json",
    //   "Authorization": `Token ${this.props.token}`
    // }

    let opps = this.props.opportunities;
    let brokerlist = [];

    brokerlist = this.props.blist;
    if (typeof brokerlist[0] === "object") {
      brokerlist = brokerlist.map((x) => ({ value: x.name, label: x.name }));
    } else {
      brokerlist = brokerlist.map((x) => ({ value: x, label: x }));
    }

    this.setState({
      brokerDropdownOptions: brokerlist,
      marketdata: opps,
      marketDataReady: true,
      fetchingBrokerData: false,
    });
  }
  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params.brokername !== prevProps.match.params.brokername
    ) {
      let broker = "";
      if (this.props.match.params.brokername) {
        broker = this.props.match.params.brokername;
      }
      this.setState({
        subjectName: broker,
      });
    }
    if (this.props.blist !== prevProps.blist) {
      this.getMarketsOppData();
    }
  };

  componentDidMount() {
    this.getMarketsOppData();
    if (this.props.match.params.brokername) {
      let brokername = this.props.match.params.brokername;
      this.setState({
        subjectName: brokername,
      });
    }
  }

  render() {
    const { user } = this.props;
    const { alerts } = this.state;

    let markets = [];

    for (var o in user.groups) {
      markets.push(user.groups[o].name);
    }

    return (
      <div className={s.root}>
        <ErrorPage>
          {this.state.subjectName === "" ? (
            <Row>
              <Col xs={12} md={8} lg={8} xl={8}>
                <h1 className="page-title">
                  Broker <span className="fw-semi-bold">Scorecard</span>
                </h1>
              </Col>
            </Row>
          ) : null}

          {markets.length === 1 && markets.includes("National") ? (
            <div>
              {alerts.map((alert, index) => (
                <Alert
                  className="alert-transparent"
                  key={alert.id}
                  isOpen={alert.visible[1]}
                  toggle={() => this.closeAlert(index, 1)}
                  color={alert.type}
                >
                  <span dangerouslySetInnerHTML={{ __html: alert.msg }} />
                </Alert>
              ))}
            </div>
          ) : null}

          {this.state.brokerRetrieveError !== "" ? (
            <div>
              {this.state.alerterror.map((alert, index) => (
                <Alert
                  className="alert-transparent"
                  key={alert.id}
                  isOpen={alert.visible[1]}
                  toggle={() => this.closeAlert(index, 1)}
                  color={alert.type}
                >
                  <span dangerouslySetInnerHTML={{ __html: alert.msg }} />
                </Alert>
              ))}
            </div>
          ) : null}
          {this.props.receivedUserMarketOpps ? (
            <>
              {!this.props.match.params.brokername ? (
                <>
                  {!this.state.fetchingBrokerData &&
                  this.state.brokerDropdownOptions.length > 1 ? (
                    <BrokerDropdown
                      selectBrokerforCard={this.selectBrokerforCard}
                      chooseBrokerforCard={this.chooseBrokerforCard}
                      brokeroptions={this.state.brokerDropdownOptions}
                    />
                  ) : (
                    <Loading />
                  )}
                </>
              ) : null}
              {this.state.marketdata.length > 0 &&
              this.state.subjectName !== "" ? (
                <Scorecard
                  brokername={this.state.subjectName}
                  opps={this.state.marketdata}
                />
              ) : null}
            </>
          ) : (
            <div className="loader animated fadeIn handle initialLoader">
              <span className="spinner">
                <i className="fa fa-spinner fa-spin" />
              </span>
              <Col xs={12} md={12} lg={12} xl={12}>
                Loading Data...
              </Col>
            </div>
          )}
        </ErrorPage>
      </div>
    );
  }
}

// selectBrokerforCard(){
//   let name = this.refs["brokerSelector"].state.value.value
//   console,log()
//   this.props.history.push("/app/reporting/brokerscorecard/"+name)
//   this.retreiveMemberInfo()
// }

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    user: state.authentication.userdata,
    opportunities: state.projects.opportunities,
    cleandata: state.projects.cleandata,
    projects: state.projects.projects,
    blist: state.projects.blist,
    marketdata: state.projects.marketdata,
    receivedUserMarketOpps: state.projects.receivedUserMarketOpps,
  };
}

export default connect(mapStateToProps)(BrokerScorecardV2);
