import React, { useEffect, useState } from "react";
import Chip from "@material-ui/core/Chip";

const style = {
  margin: 10,
  fontFamily: "Montesserat, sans-serif",
  fontSize: "11px",
  maxWidth: "100%",
};

export default function FilterItem(props) {
  useEffect(() => {}, [props]);

  const handleDelete = (data) => () => {
    props.update(data);
  };

  let icon;

  return (
    <>
      <Chip
        icon={icon}
        label={props.data.value}
        onDelete={handleDelete(props.data.type)}
        style={style}
      />
    </>
  );
}
