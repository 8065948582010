import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-dates/initialize";
import cx from "classnames";
import axios from "axios";
import { withRouter } from "react-router";
import Transition from "react-transition-group/Transition";
import { Col } from "reactstrap";
import marketregions from "./reference/marketregions";

import NoAccessPage from "./components/error/noAccessPage";
import DashboardTemplate from "./markets/dashboardTemplate";
import YearEndReport from "./specializedDashboards/yearEndReport";
import GoalsReport from "./specializedDashboards/goalsReport";
import BuyerEnablementReport from "./specializedDashboards/buyerEnablementReport";
import RegionalReport from "./specializedDashboards/regionalreport";
import NationalLimitedDashboard from "./markets/NationalLimitedDashboard";
import ServicesReport from "./specializedDashboards/servicesReport";
import BLCReport from "./specializedDashboards/blcReport";
import ErrorPage from "../error/ErrorPage";

var moment = require("moment");
// import { getProjectsRequest } from '../../actions/projects';

function cleanPersonList(person) {
  let list = [];
  person.forEach((item) => list.push(item.fullname));
  let theperson = list.join(", ");
  return theperson;
}
function cleanServiceList(serviceline) {
  let list = [];
  serviceline.forEach((item) => list.push(item.servicelinename));
  let theservice = list.join(", ");
  return theservice;
}

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usermarkets: [],
      marketarray: [],
      updates: [],
      opportunitydata: [],
      marketinfo: "",
      marketname: "",
      currentmarketid: "",
      currentdashboard: null,
      targetmarket: {},
      jtedashboard: false,
      errormessage: null,
      opperror: "",
      nationaldashboardata: [],
      specialreport: false,
      nationaldatareceived: false,
      oppsreceived: false,
    };
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  filterOppsForCustomDashboard(marketinfoobject) {
    let markets = marketinfoobject.market;
    let marketdata = this.props.marketdata;
    let marketinfo = "";
    let marketopps = [];
    let marketsettings = JSON.parse(marketinfoobject.settings);
    if (marketdata.length > 0) {
      let missingpermissions = [];
      markets.forEach((item) => {
        let founditem = marketdata.find(
          (marketditem) => marketditem.market_id === item.market_id
        );
        if (!founditem) {
          missingpermissions.push(item);
        }
      });
      if (!missingpermissions.length > 0) {
        marketinfo = marketdata.filter((x) =>
          markets.some((obj) => obj.market_id === x.market_id)
        );
        let marketarray = marketinfo;
        marketopps = this.props.opportunities;
        if (
          !(
            markets.some((item) => item.market_id === "125") ||
            markets.some((item) => item.market_id === "1000")
          )
        ) {
          marketopps = marketopps.filter((item) =>
            markets.some((obj) => obj.market_id === item.market[0].market_id)
          );
        }
        this.setState({
          specialreport: false,
          marketname: marketsettings.dashboardname,
          marketinfo: marketinfo,
          marketarray: marketarray,
          currentdashboard: marketinfoobject,
          opportunitydata: marketopps,
          marketsettings: marketsettings,
          oppsreceived: true,
        });
      } else {
        let names = missingpermissions.map(function (item) {
          return item["marketname"];
        });
        names = names.join(", ");
        let newerror = `You do not currently have access to the ${names} dashboard(s) which makes up this custom dashboard. Please reach out if you believe this is an error.`;
        this.setState({
          specialreport: false,
          errormessage: newerror,
          oppsreceived: true,
        });
      }
    }
  }

  getCustomMarketInformation() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let customdashboard = this.props.match.params.customdashboardid;
    let foundsettings = this.props.userdashboards.find(
      (item) => item.dashboardid === customdashboard
    );
    if (foundsettings) {
      this.filterOppsForCustomDashboard(foundsettings);
    } else {
      axios.get(`/api/v1/userdashboard/${customdashboard}/`).then((res) => {
        this.filterOppsForCustomDashboard(res.data);
      });
    }
  }

  getSpecializedReport() {
    let marketid = this.props.match.params.marketreportid;
    let reporttype = this.props.match.params.reporttype;
    let marketdata = this.props.marketdata;

    let marketarray = [];
    let regionmarkets = null;
    let currentregion = "";
    let marketinfo = "";
    let marketname = "";
    let marketopps = [];
    // let updates = []
    if (!Number.isNaN(marketid)) {
      currentregion = marketregions.find((item) => item.urlparam === marketid);
      if (currentregion) {
        regionmarkets = currentregion.markets;
      }
    }
    if (marketdata.length > 0) {
      marketopps = this.props.opportunities;

      marketarray = [marketinfo];
      if (regionmarkets) {
        let finalmarketopps = [];
        // let finalupdates = []
        regionmarkets.forEach((regionmarket) => {
          finalmarketopps.push(
            marketopps.filter(
              (item) => item.market[0].market_id == regionmarket.id
            )
          );
          // finalupdates.push(updates.filter(item => item.market[0].market_id == regionmarket.id))
        });
        marketname = currentregion.name;
        marketopps = [].concat.apply([], finalmarketopps);
        // updates = [].concat.apply([], finalupdates);
      } else {
        marketinfo = marketdata.find((x) => x.market_id === marketid);
        marketname = marketinfo.marketname;
        if (marketid !== "1000" && marketid !== "125") {
          marketopps = marketopps.filter(
            (item) => item.market[0].market_id === marketid
          );
          // updates = updates.filter(item => item.market[0].market_id === marketid)
        }
      }

      this.setState({
        specialreport: true,
        reporttype: reporttype,
        currentmarketid: marketid,
        marketinfo: marketinfo,
        marketarray: marketarray,
        marketname: marketname,
        opportunitydata: marketopps,
        // updates: updates,
        oppsreceived: true,
      });
    }
  }

  getMarketInformation() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };

    let marketid = this.props.match.params.market;
    let marketdata = this.props.marketdata;

    let marketinfo = "";
    let marketopps = [];

    if (marketid.toLowerCase() === "national") {
      axios.get("/api/v1/nationalchartdata/").then((res) => {
        this.setState({
          marketinfo: "National",
          nationaldashboardata: res.data[0],
          nationaldatareceived: true,
        });
      });
    } else if (marketid.toLowerCase() === "125") {
      if (marketdata.length > 0) {
        marketinfo = marketdata.find(
          (x) => x.marketname.toLowerCase() === "jte"
        );
        let marketarray = [marketinfo];
        marketopps = this.props.opportunities;

        if (marketid !== "1000") {
          marketopps = marketopps.filter((item) => item.techused.length > 0);
          let jlltech =
            // || item.techused.length > 2
            (marketopps = marketopps.filter(
              (item) =>
                item.techused.some(
                  (obj) => obj.techname === "JLL Tenant Experience"
                ) ||
                item.techused.length >= 2 ||
                item.techused.some((obj) => obj.techname === "JLL Journeys")
            ));
          let beginningofyear = moment("01-01-2020", "MM-DD-YYYY");
          let enddate = moment();

          marketopps = marketopps.filter(
            (item) =>
              moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(
                beginningofyear
              ) &&
              moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(enddate)
          );
          marketopps = marketopps.filter(
            (item) => item.type !== "BRO Agency Leasing"
          );
          // marketopps = marketopps.filter(item => item.techused.length >= 2 )
          // updates = updates.filter(item1 => marketopps.some(item2 => (item1.opportunityid === item2.opportunityid)  ))
        }
        this.setState({
          marketinfo: marketinfo,
          marketname: marketinfo.marketname,
          marketarray: marketarray,
          opportunitydata: marketopps,
          jtedashboard: true,
          // updates: updates,
          oppsreceived: true,
        });
      }
    } else {
      if (this.props.opportunities.length > 0) {
        if (marketdata.length > 0) {
          marketinfo = marketdata.find((x) => x.market_id === marketid);
          let marketarray = [marketinfo];
          marketopps = this.props.opportunities;
          // let updates = this.props.updates.OppStatusUpdates
          if (marketid !== "1000" && marketid !== "125") {
            marketopps = marketopps.filter(
              (item) => item.market[0].market_id === marketid
            );
            // updates = updates.filter(item => item.market[0].market_id === marketid)
          }
          this.setState({
            specialreport: false,
            currentmarketid: marketid,
            marketinfo: marketinfo,
            marketarray: marketarray,
            marketname: marketinfo.marketname,
            opportunitydata: marketopps,
            // updates: updates,
            oppsreceived: true,
          });
        }
      }
    }
  }

  componentDidMount() {
    let usermarkets = [];
    for (var o in this.props.user.groups) {
      usermarkets.push(this.props.user.groups[o].name.toLowerCase());
    }
    if (this.props.match.params.market) {
      this.getMarketInformation();
    } else if (this.props.match.params.customdashboardid) {
      this.getCustomMarketInformation();
    } else if (this.props.match.params.marketreportid) {
      this.getSpecializedReport();
    }

    this.setState({
      usermarkets: usermarkets,
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match !== prevProps.match) {
      if (this.props.match.params.market) {
        this.getMarketInformation();
      } else if (this.props.match.params.customdashboardid) {
        this.getCustomMarketInformation();
      } else if (this.props.match.params.marketreportid) {
        this.getSpecializedReport();
      }
    }
    if (this.props.opportunities.length !== prevProps.opportunities.length) {
      if (this.props.match.params.market) {
        this.getMarketInformation();
      } else if (this.props.match.params.customdashboardid) {
        this.getCustomMarketInformation();
      } else if (this.props.match.params.marketreportid) {
        this.getSpecializedReport();
      }
    }
  };

  render() {
    const { marketdataready } = this.props;
    const { nationaldashboardata } = this.state;

    const market = this.props.match.params;

    return (
      <ErrorPage>
        {this.state.oppsreceived && this.state.opportunitydata.length > 0 ? (
          <>
            {this.state.reporttype === "yearend" && this.state.specialreport ? (
              <YearEndReport
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            ) : this.state.reporttype === "yearoveryear" &&
              this.state.specialreport ? (
              <BuyerEnablementReport
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            ) : this.state.reporttype === "goalsreport" &&
              this.state.specialreport ? (
              <GoalsReport
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            ) : this.state.reporttype === "monthlyregional" &&
              this.state.specialreport ? (
              <RegionalReport
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            ) : this.state.reporttype === "crosssell" &&
              this.state.specialreport ? (
              <ServicesReport
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            ) : this.state.reporttype === "blc" && this.state.specialreport ? (
              <BLCReport
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            ) : (
              <DashboardTemplate
                currentmarketid={this.state.currentmarketid}
                marketarray={this.state.marketarray}
                opps={this.state.opportunitydata}
                updates={this.state.updates}
                marketname={this.state.marketname}
                currentdashboardsettings={this.state.currentdashboard}
              />
            )}
          </>
        ) : this.state.oppsreceived &&
          this.state.opportunitydata.length == 0 &&
          this.props.receivedUserMarketOpps ? (
          <NoAccessPage errormessage={this.state.errormessage} />
        ) : this.state.nationaldatareceived && marketdataready ? (
          <NationalLimitedDashboard
            nationallinechartdata={nationaldashboardata.oppsenteredbydate}
            nationalbrokerdata={nationaldashboardata.oppsenteredbybroker}
            nationalstatusdata={nationaldashboardata.oppsbystatuschart}
          />
        ) : (
          <div className="loader animated fadeIn handle initialLoader">
            <span className="spinner">
              <i className="fa fa-spinner fa-spin" />
            </span>
            <Col xs={12} md={12} lg={12} xl={12}>
              Loading Data...
            </Col>
          </div>
        )}
      </ErrorPage>
    );
  }
}

function mapStateToProps(state) {
  return {
    updates: state.projects.alloppupdates,
    userdashboards: state.authentication.userprofile.dashboards,
    receivingNationalDashboardready:
      state.projects.receivingNationalDashboardready,
    cleandataready: state.projects.cleandataready,
    marketdataready: state.projects.marketdataready,
    token: state.authentication.token,
    filters: state.dashboardfilters,
    filterbox: state.filterbox,
    marketdata: state.projects.marketdata,
    filteredprojects: state.projects.filteredprojects,
    activefilters: state.dashboardfilters.activefilters,
    projects: state.projects.projects,
    opportunities: state.projects.opportunities,
    cleandata: state.projects.cleandata,
    oppnamelist: state.projects.oppnamelist,
    blist: state.projects.blist,
    user: state.authentication.userdata,
    receivedUserMarketOpps: state.projects.receivedUserMarketOpps,
  };
}

export default withRouter(connect(mapStateToProps)(Dashboard));
