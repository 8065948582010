import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";

exporting(Highcharts);
exportData(Highcharts);

class SalesStage extends React.Component {
  chartOptions = () => {
    let opps = this.props.data;

    let series = [
      {
        name: "# Opportunities",
        data: [],
        keys: ["name", "y"],
        type: "pie",
      },
    ];

    let query = [
      "Awarded Awaiting Contract",
      "Decision Pending",
      "Presentation",
      "Proposal Done",
      "RFP Received",
      "RFP Pending",
      "Info Exchanged",
      "Meeting Held",
      "Attempting Contact",
      "New Lead",
    ];

    query.forEach((item) => {
      if (item) {
        let newitem = {
          name: item,
          y: opps.filter((object) => object.sales_stage == item).length,
        };
        series[0].data.push(newitem);
      }
    });
    return {
      title: {
        text: "",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          borderWidth: 0,
          showInLegend: true,
          innerSize: 0,
          size: 170,
          states: {
            hover: {
              halo: {
                size: 1,
              },
            },
          },
        },
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        itemStyle: {
          color: "#788898",
          fontWeight: 400,
        },
        itemHoverStyle: {
          color: "#cccccc",
        },
        itemMarginBottom: 5,
        symbolRadius: 0,
      },
      exporting: {
        chartOptions: {
          title: {
            text: null,
          },
        },
      },
      chart: {
        type: "pie",
        height: 246,
        backgroundColor: "rgba(0,0,0,0)",
      },
      credits: {
        enabled: false,
      },

      // colors: ["#68ca66", "#FD5F00", "#005792", "#1A86D0", "#1A86D0"],
      series: series,
    };
  };

  render() {
    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Opportunity <span className="fw-semi-bold">Sales Stage</span>
              </h5>
            </Col>
          </Row>
        }
      >
        {this.props.data.length > 0 ? (
          <HighchartsReact
            highcharts={Highcharts}
            constructorType={"chart"}
            options={this.chartOptions()}
            ref={"pieChart"}
          />
        ) : null}
      </Widget>
    );
  }
}

export default SalesStage;
