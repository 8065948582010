import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "react-dates/initialize";
import { createFilterSnapshot } from "../../../actions/projects";
import {
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { Link } from "react-router-dom";
import Widget from "../../../components/Widget/Widget";
import "react-dates/lib/css/_datepicker.css";
import MainChart from "../components/Charts/MainChart";
import NationalMainChartMaster from "../components/MarketCharts/NationalMainChartMaster";
import MarketPerformance from "../components/Charts/marketperformance";
import DataTable from "../components/datatablev2/DataTable";
import ServiceLineAndPropertyType from "../components/Charts/MainChart withbar";
import FilterPanel from "../components/FilterPanel/filterpanel";
import ExporReport from "../components/ExportReportPanel/exportreportpanel";
import BrokerPerformance from "../components/Charts/BrokerPerformance";
import StatusChart from "../components/Charts/Donut";
import SalesStage from "../components/Charts/SalesStage";
import SalesStageRev from "../components/Charts/SalesStageRev";
import BrokerEstRev from "../components/Charts/BrokerEstRev";
import EstRevenueQuarter from "../components/Charts/EstRevenueQuarter";
import {
  updateAllFilters,
  updateServiceLineFilter,
  updateSizeFilter,
  updateStatusFilter,
  updateActiveFilters,
  updateEndDate,
  updateStartDate,
  updateShortcut,
} from "../../../actions/dashboardfilters";
import s from "../MainDashboard.module.scss";
import ErrorPage from "../../error/ErrorPage";
import Loading from "../components/loading";
import SaveCustomDashboard from "../components/SaveCustomDashboard/savecustomdashboard";
import WeekOverWeek from "../components/Charts/WeekoverWeek";
import DayChart from "../components/MarketCharts/DayChartNational";
import DayChartSL from "../components/FilterRelevant/DayChartSL";
import TimerangeStats from "../components/FilterRelevant/TimerangeStats";
import Geocode from "react-geocode";
import CurrentDashboardSettings from "../components/CurrentSettingsPanel/currentsettings";
import ToggleCharts from "../components/ToggleChartsPanel/togglecharts";
import IndustryChart from "../components/Charts/Industrychart";
import OtherServicesChart from "../components/Charts/OtherServicesChart";
import YearOverYearLineChart from "../components/Charts/YearOverYearLineChart";
import YearOverYearWinRateLineChart from "../components/Charts/YearOverYearWinRateLineChart";
import OpportunityMap from "../components/vector/OpportunityMap";
import SourcingMethodChart from "../components/Charts/SourcingMethodChart";
import TechToolUsed from "../components/Charts/TechToolUsed";
import MonthOverMonth from "../components/Charts/MonthoverMonth";
import MonthOverMonthMeetingDate from "../components/Charts/MonthoverMonthMeetingDate";
import WeekOverWeekMeetingDate from "../components/Charts/WeekoverWeekMeetingDate";
import TechUsedStacked from "../components/Charts/techusedaveragestacked";
import PercentIncrease from "../components/PercentIncrease/PercentIncreaseModule";
import FilterItemButton from "../components/FilterItemButton";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
// const SliderWithTooltip = createSliderWithTooltip(Slider);
var moment = require("moment");
// import { getProjectsRequest } from '../../actions/projects';

function cleanPersonList(person) {
  let list = [];
  person.forEach((item) => list.push(item.fullname));
  let theperson = list.join(", ");
  return theperson;
}
function cleanServiceList(serviceline) {
  let list = [];
  serviceline.forEach((item) => list.push(item.servicelinename));
  let theservice = list.join(", ");
  return theservice;
}

class DashboardTemplate extends Component {
  constructor(props) {
    super(props);
    this.filterboxchild = React.createRef();
    this.oneJLLExportShortcut = this.oneJLLExportShortcut.bind(this);
    this.techAdoptionShortcut = this.techAdoptionShortcut.bind(this);
    this.filterOpportunities = this.filterOpportunities.bind(this);
    this.filterUpdates = this.filterUpdates.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.startFiltering = this.startFiltering.bind(this);
    this.findSectionsToHide = this.findSectionsToHide.bind(this);
    this.toggleCharts = this.toggleCharts.bind(this);
    this.hideCustomDashboardSaveModal =
      this.hideCustomDashboardSaveModal.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.orFilterClick = this.orFilterClick.bind(this);
    this.state = {
      presetFilters: false,
      customDashboardFilters: null,
      filterbox: false,
      orFilterBox: false,
      orFilters: [],
      exportbox: false,
      toggleChartsPanel: false,
      toggleCurrentSettings: false,
      saveCustomDashboardToggle: false,
      key: "selection",
      sBrokerFilter: "",
      brokerFilter: "",
      dropdownOpenOne: false,
      dropdownOpenTwo: false,
      dropdownOpenThree: false,
      dropdownOpenFour: false,
      filteringopps: false,
      slineFilter: "",
      oppNameFilter: "",
      statusFilter: "",
      DateRangeFiltered: [],
      dateFilter: "",
      cleandata: "",
      oppnamelist: [],
      blist: [],
      renderMonthStats: false,
      updates: [],
      newopps: [],
      statusWins: [],
      statusLosses: [],
      locations: [],
      clientlist: [],
      currentfiltersnapshot: {},
      statusOpps: [],
      opps: [],
      thismonthstatusupdates: [],
      thismonthsstatusopps: [],
      slist: [],
      RenderPercentIncreaseChart: false,
      RenderDonut: true,
      RenderDefaultStats: true,
      RenderWinsTable: false,
      RenderLossesTable: false,
      RenderNewOpps: false,
      RenderDayChart: false,
      RenderYearOverYearWinRate: false,
      RenderYearOverYearOpps: false,
      RenderDayChartSL: false,
      RenderBigStats: true,
      RenderColumnOneStats: true,
      RenderLocationsMap: false,
      RenderMonthOverMonthMeeting: false,
      RenderWeekOverWeekMeeting: false,
      RenderMonthOverMonth: false,
      RenderTechTools: false,
      RenderSLandPropType: false,
      RenderMarketPerformance: false,
      RenderWeekOverWeek: false,
      RenderSourcingMethodChart: false,
      RenderIndustryChart: false,
      RenderColumnTwoStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: true,
      RenderBrokerPerf: true,
      RenderAgencyScorecards: false,
      RenderCalendar: true,
      RenderTableData: true,
      RenderTechUsedAverage: false,
      RenderOtherServices: false,
      RenderBrokerEstRev: false,
      RenderStageEstRev: false,
      RenderSalesStage: false,
      RenderEstRevenueQuarter: false,
      pdf: false,
    };
  }

  toggleCharts(e, id) {
    this.setState({
      [id]: e.target.checked,
    });
  }

  printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      // canvas.width = 600
      // canvas.height = 1200
      const imgData = canvas.toDataURL("image/png");

      // var w=window.open('about:blank','image from canvas');
      // w.document.write("<img src='"+imgData+"' alt='from canvas'/>");
      const pdf = new jsPDF("p", "mm", "tabloid");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      pdf.setFillColor(237, 240, 242);
      pdf.rect(0, 0, pageWidth, pageHeight, "F");
      pdf.text(
        25,
        10,
        `${this.props.marketname} Sales Enablement Dashboard | ${moment(
          new Date()
        ).format("DD-MM-YYYY H:MM")}`
      );
      pdf.addImage(
        imgData,
        "JPEG",
        marginX,
        marginY + 15,
        canvasWidth,
        canvasHeight - 25
      );

      // var ratio = canvas.width/canvas.height;
      // var width = pdf.internal.pageSize.getWidth();
      // var height = width / ratio;
      // pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width*0.2,canvas.height*0.2);
      // pdf.output('dataurlnewwindow');
      pdf.save(
        `${this.props.marketname}-SEdashboard_${moment(new Date()).format(
          "DD-MM-YYYY_H:MM"
        )}_.pdf`
      );
    });
  }

  orFilterClick() {
    this.setState({
      filterbox: true,
      orFilterBox: true,
    });
  }

  hideCustomDashboardSaveModal() {
    this.setState({
      saveCustomDashboardToggle: false,
    });
  }
  getHistoricalData() {
    this.clearFilters();
  }
  wonOppsShortcut() {
    this.clearFilters();
    this.setState({
      RenderDonut: false,
      renderMonthStats: false,
      RenderAgencyScorecards: false,
      RenderColumnOneStats: false,
      RenderColumnTwoStats: false,
    });
    this.props.dispatch(updateStatusFilter([{ name: "Won" }]));
    this.props.dispatch(updateShortcut(true));
  }

  twoMoreBusinessLinesShortcut() {
    this.clearFilters();
    this.props.dispatch(updateAllFilters({ crossSellingTwo: true }));
    this.props.dispatch(updateShortcut(true));
  }

  fiftyThousandShortcut() {
    // this.setState({
    //   RenderDonut: false
    // })
    this.clearFilters();
    this.props.dispatch(updateSizeFilter("50000"));
    this.props.dispatch(updateShortcut(true));
  }

  yearEndReportShortcut() {
    this.props.history.push(
      "/app/dashboard/report/yearend/" + this.props.currentmarketid
    );
  }

  thisMonthShortcut() {
    this.startFiltering();
    this.clearFilters();
    this.props.dispatch(updateStartDate(moment().startOf("month")));
    this.props.dispatch(updateEndDate(moment().endOf("month")));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTimeRangeStats: true,
      RenderTableData: false,
      RenderDayChart: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
    });
  }

  startFiltering() {
    this.setState({
      filteringopps: true,
    });
  }

  jteShortcut() {
    this.clearFilters();
    this.props.dispatch(updateAllFilters({ jteFilter: true }));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderIndustryChart: true,
      RenderSourcingMethodChart: true,
      RenderTechTools: true,
      RenderLocationsMap: true,
      renderMonthStats: false,
    });
  }

  returnDateRangeFromFilter(date) {
    date = date.replace("Between ", "");
    let str = date.split(" and ");
    let fromdate = moment(str[0], "MMMM Do, YYYY");
    let todate = moment(str[1], "MMMM Do, YYYY");
    let array = [fromdate, todate];
    return array;
  }

  findSectionsToHide() {
    let activefilters = this.props.activefilters;
    let RenderDonut = this.state.RenderDonut;
    let RenderDefaultStats = this.state.RenderDefaultStats;
    let RenderWinsTable = this.state.RenderWinsTable;
    let RenderLossesTable = this.state.RenderLossesTable;
    let RenderNewOpps = this.state.RenderNewOpps;
    let RenderDayChart = this.state.RenderDayChart;
    let RenderBigStats = this.state.RenderBigStats;
    let RenderColumnOneStats = this.state.RenderColumnOneStats;
    let RenderColumnTwoStats = this.state.RenderColumnTwoStats;
    let RenderLineChart = this.state.RenderLineChart;
    let RenderServiceLineChart = this.state.RenderServiceLineChart;
    let RenderBrokerPerf = this.state.RenderBrokerPerf;
    let RenderTableData = this.state.RenderTableData;
    let RenderDayChartSL = this.state.RenderDayChartSL;
    let RenderTimeRangeStats = this.state.RenderTimeRangeStats;
    let therange = [];

    if (activefilters.length > 0) {
      activefilters.forEach((item) => {
        if (item.type === "serviceLineFilter") {
          RenderBigStats = false;
        } else if (
          item.type === "brokerTeamFilter" ||
          item.type === "sourcingBrokerFilter"
        ) {
          RenderBigStats = false;
        } else if (
          item.type === "endDate" &&
          item.value !== "" &&
          item.value !== null
        ) {
          if (item.value.toLowerCase().includes("between")) {
            therange = this.returnDateRangeFromFilter(item.value);
            RenderTimeRangeStats = true;
            RenderNewOpps = true;
            RenderWinsTable = true;
            RenderDefaultStats = false;
            RenderTableData = false;
            RenderLossesTable = true;

            if (therange[0] !== "" && therange[1] !== "") {
              if (therange[1].diff(therange[0], "days") <= 60) {
                RenderDayChart = true;
                RenderLineChart = false;
                RenderDayChartSL = true;
              }
            }
          }
          RenderBigStats = false;
        } else if (item.type === "statusFilter") {
          RenderDonut = false;
          RenderBigStats = false;
        }
      });
    }

    this.setState({
      RenderDonut: RenderDonut,
      RenderDefaultStats: RenderDefaultStats,
      DateRangeFiltered: therange,
      RenderTimeRangeStats: RenderTimeRangeStats,
      RenderWinsTable: RenderWinsTable,
      RenderLossesTable: RenderLossesTable,
      RenderNewOpps: RenderNewOpps,
      RenderDayChart: RenderDayChart,
      RenderDayChartSL: RenderDayChartSL,
      RenderBigStats: RenderBigStats,
      RenderColumnOneStats: RenderColumnOneStats,
      RenderColumnTwoStats: RenderColumnTwoStats,
      RenderLineChart: RenderLineChart,
      RenderServiceLineChart: RenderServiceLineChart,
      RenderBrokerPerf: RenderBrokerPerf,
      RenderTableData: RenderTableData,
    });
  }

  filterOpportunities(opps) {
    console.log("");
  }

  filterUpdates(updates, statusWins, statusLosses, newopps, opps) {
    this.setState({
      statusUpdates: updates,
      statusWins: statusWins,
      statusLosses: statusLosses,
      newopps: newopps,
      opps: opps,
      filteringopps: false,
    });
  }

  thisWeekShortcut() {
    this.clearFilters();
    this.props.dispatch(updateStartDate(moment().startOf("week")));
    this.props.dispatch(updateEndDate(moment().endOf("week")));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTableData: false,
      RenderDayChart: true,
      RenderTimeRangeStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
      RenderWeekOverWeek: false,
    });
  }

  lastWeekShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateStartDate(moment().subtract(1, "week").startOf("week"))
    );
    this.props.dispatch(
      updateEndDate(moment().subtract(1, "week").endOf("week"))
    );
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTableData: false,
      RenderDayChart: true,
      RenderTimeRangeStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
      RenderWeekOverWeek: false,
    });
  }

  lastMonthShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateStartDate(moment().subtract(1, "months").startOf("month"))
    );
    this.props.dispatch(
      updateEndDate(moment().subtract(1, "months").endOf("month"))
    );
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTableData: false,
      RenderDayChart: true,
      RenderTimeRangeStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
    });
  }

  lastThreeMonthsShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateStartDate(moment().subtract(2, "months").startOf("month"))
    );
    this.props.dispatch(updateEndDate(moment().endOf("month")));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTableData: false,
      RenderDayChart: true,
      RenderTimeRangeStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
    });
  }

  thisYearShortcut() {
    this.clearFilters();
    this.props.dispatch(updateStartDate(moment().startOf("year")));
    this.props.dispatch(updateEndDate(moment().endOf("year")));
    this.props.dispatch(updateShortcut(true));
  }

  lastYearShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateStartDate(moment().subtract(1, "year").startOf("year"))
    );
    this.props.dispatch(
      updateEndDate(moment().subtract(1, "year").endOf("year"))
    );
    this.props.dispatch(updateShortcut(true));
  }

  PDSWinsTRShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateAllFilters({
        serviceLineFilter: [{ name: "Tenant Rep", id: 1 }],
        sizeFilter: "100000",
        statusFilter: [{ name: "Won" }],
      })
    );
    this.props.dispatch(updateShortcut(true));
  }

  industrialOppsShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter("Industrial"));
    this.props.dispatch(updateShortcut(true));
  }

  matchBusinessLine(sl, type, pt) {
    if (sl.includes("Industrial")) {
      return "Industrial";
    } else if (
      (type.includes("Tenant Rep") || type.includes("IPS")) &&
      pt.includes("Office")
    ) {
      return "Tenant Rep";
    } else if (type.includes("Agency Leasing") && pt.includes("Office")) {
      return "Agency Leasing";
    } else {
      return `${sl} - ${type} - ${pt}`;
    }
  }

  techAdoptionShortcut() {
    let export_opps = this.state.opps;
    let hashmap = new Object();
    export_opps.forEach((opp) => {
      let brokerTeam = [...opp.sourcingbroker, ...opp.broker];
      let techused = false;
      if (opp.techused.length > 0) {
        techused = true;
      }
      // hashmap def [market, techopps, alloppsCount, email]
      brokerTeam.forEach((broker) => {
        if (hashmap[broker.fullname]) {
          hashmap[broker.fullname] = [
            hashmap[broker.fullname][0],
            techused
              ? hashmap[broker.fullname][1] + 1
              : hashmap[broker.fullname][1],
            hashmap[broker.fullname][2] + 1,
          ];
        } else {
          hashmap[broker.fullname] = [
            opp.market[0]?.marketname,
            techused ? 1 : 0,
            1,
            broker.email || " ",
          ];
        }
      });
    });
    let finalArray = [];

    Object.keys(hashmap).forEach((broker) => {
      console.log(hashmap[broker][3]);
      finalArray.push({
        name: broker,
        email: hashmap[broker][3] || " ",
        tech_opps: hashmap[broker][1],
        total_opps: hashmap[broker][2],
        tech_adoption:
          hashmap[broker][1] > 0
            ? `${(
                (Math.round((hashmap[broker][1] / hashmap[broker][2]) * 100) /
                  100) *
                100
              ).toFixed(2)}%`
            : 0 + "%",
        market: hashmap[broker][0],
      });
    });
    let csvArray = JSON.stringify(finalArray);
    csvArray = JSON.parse(csvArray);
    let json = csvArray;
    let fields = Object.keys(json[0]);
    let replacer = function (key, value) {
      return value === null ? "" : value;
    };
    let csv = json.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",");
    });
    csv.unshift(fields.join(",")); // add header column
    csv = csv.join("\r\n");
    let link = document.createElement("a");
    link.id = "download-csv";
    link.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute(
      "download",
      `tech-adoption-${this.props.marketname}-${moment().format(
        "MM-DD-YYYY_HH:MM:SS"
      )}.csv`
    );
    document.body.appendChild(link);
    document.querySelector("#download-csv").click();
  }

  oneJLLExportShortcut() {
    let export_opps = this.state.opps;
    export_opps = export_opps.filter(
      (item) =>
        (item.winloss === "Won" || item.winloss === "Lost") &&
        item.otherJllServices.length > 0
    );
    let csvArray = export_opps?.map((item) => ({
      "Market/Office": item.market[0]?.marketname,
      Client: item.clientcompany[0]?.clientconame,
      "Sourcing Business Line": this.matchBusinessLine(
        item.servicelines[0]?.servicelinename,
        item.type,
        item.propertytype
      ),
      "Cross-selling Business Line(s)": item.otherJllServices
        ?.map((service) => service.servicename)
        .join(", "),
      "Pursuit Lead": item.sourcingbroker[0]?.fullname,
      "Pursuit Team": item.broker?.map((broker) => broker.fullname).join(", "),
      "Size (Sq. ft)": item.pursuitsize,
      "Potential Gross Fee": `$${(item.pursuitsize * 30 * 0.04 * 7).toFixed(
        2
      )}`,
      "Won or Lost": item.winloss,
      Comments: "",
      "Date Created": moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").format(
        "LL"
      ),
      "Date Closed": moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").format(
        "LL"
      ),
    }));

    csvArray = JSON.stringify(csvArray);
    csvArray = JSON.parse(csvArray);
    let json = csvArray;
    let fields = Object.keys(json[0]);
    let replacer = function (key, value) {
      return value === null ? "" : value;
    };
    let csv = json.map(function (row) {
      return fields
        .map(function (fieldName) {
          return JSON.stringify(row[fieldName], replacer);
        })
        .join(",");
    });
    csv.unshift(fields.join(",")); // add header column
    csv = csv.join("\r\n");
    let link = document.createElement("a");
    link.id = "download-csv-onejll";
    link.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
    );
    link.setAttribute(
      "download",
      `one-jll-opps-${this.props.marketname}-${moment().format(
        "MM-DD-YYYY_HH:MM:SS"
      )}.csv`
    );
    document.body.appendChild(link);
    document.querySelector("#download-csv-onejll").click();
  }

  tenantrepOppsShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter("Tenant Rep"));
    this.props.dispatch(updateShortcut(true));
  }

  retailOppsShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter("Retail"));
    this.props.dispatch(updateShortcut(true));
  }

  clearFilters() {
    this.filterboxchild?.current?.clearFilterInputs();

    if (this.state.customDashboardFilters) {
      this.props.dispatch(updateAllFilters(this.state.customDashboardFilters));
      this.props.dispatch(updateShortcut(true));
      this.setState({
        opps: this.props.opps,
      });
    } else {
      this.props.dispatch(
        updateAllFilters({
          seLeadFilter: "",
          digitalAFilter: "",
          startDate: "",
          endDate: "",
          startCloseDate: "",
          endCloseDate: "",
          startMeetingDate: "",
          endMeetingDate: "",
          marketsFilter: "",
          sourcingBrokerFilter: "",
          typeFilter: "",
          addBrokerFilter: "",
          serviceLineFilter: "",
          brokerTeamFilter: "",
          techUsedFilter: "",
          jteFilter: false,
          crossSellingTwo: false,
          sourcingMethodFilter: "",
          otherServicesFilter: "",
          practiceGroupFilter: "",
          industryFilter: "",
          oppNameFilter: "",
          teamIncludeorExact: "includes",
          toolsIncludeorExact: "includes",
          marketIncludeExclude: "includes",
          sizeFilter: "",
          greaterorlessFilter: "greater",
          statusFilter: "",
          activefilters: [],
          inboundfilter: "",
          propertytypefilter: "",
          meetingtypefilter: "",
          shortcutBoolean: false,
          estStartDateStart: "",
          estStartDateEnd: "",
          dealTypeFilter: "",
          dealSubTypeFilter: "",
          salesStageFilter: "",
          estRevGreaterLess: "greater",
          estRevFilter: "",
        })
      );

      this.props.dispatch(
        createFilterSnapshot({
          seLeadFilter: "",
          digitalAFilter: "",
          startDate: "",
          endDate: "",
          startCloseDate: "",
          endCloseDate: "",
          startMeetingDate: "",
          endMeetingDate: "",
          marketsFilter: "",
          sourcingBrokerFilter: "",
          typeFilter: "",
          addBrokerFilter: "",
          serviceLineFilter: "",
          brokerTeamFilter: "",
          jteFilter: false,
          crossSellingTwo: false,
          techUsedFilter: "",
          sourcingMethodFilter: "",
          otherServicesFilter: "",
          industryFilter: "",
          practiceGroupFilter: "",
          oppNameFilter: "",
          teamIncludeorExact: "includes",
          toolsIncludeorExact: "includes",
          marketIncludeExclude: "includes",
          sizeFilter: "",
          greaterorlessFilter: "greater",
          statusFilter: "",
          activefilters: [],
          inboundfilter: "",
          propertytypefilter: "",
          meetingtypefilter: "",
          shortcutBoolean: false,
          estStartDateStart: "",
          estStartDateEnd: "",
          dealTypeFilter: "",
          dealSubTypeFilter: "",
          salesStageFilter: "",
          estRevGreaterLess: "greater",
          estRevFilter: "",
        })
      );

      this.setState({
        RenderDonut: true,
        RenderDefaultStats: true,
        renderMonthStats: false,
        RenderColumnOneStats: true,
        RenderTimeRangeStats: false,
        RenderWinsTable: false,
        RenderLossesTable: false,
        RenderNewOpps: false,
        RenderColumnTwoStats: true,
        RenderServiceLineChart: true,
        RenderLineChart: true,
        RenderBrokerPerf: true,
        RenderAgencyScorecards: true,
        RenderCalendar: true,
        RenderTableData: true,
        RenderDayChart: false,
        RenderDayChartSL: false,
        opps: this.props.opps,
      });
    }
  }

  getLocations(opps) {
    // const sortedprops = [].concat(clientdata.property).sort((a,b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0));

    let marketobjects = [];
    let finalmarketobjects = [];
    let currentmarket = "";
    let promises = [];
    let clientlist = [];

    opps.forEach((item, index) => {
      currentmarket = "Unknown";
      if (item.market.length > 0) {
        currentmarket = item.market[0].marketname;
      }
      if (item.clientcompany.length > 0) {
        clientlist.push(item.clientcompany[0]);
      }
      let foundmarket = marketobjects.find(
        (element) => element.marketname === currentmarket
      );
      if (!(foundmarket === undefined)) {
        foundmarket.count += 1;
      } else {
        if (!(currentmarket === "Unknown")) {
          let newobj = {
            marketname: currentmarket,
            count: 1,
          };
          marketobjects.push(newobj);
        }
      }
    });

    Geocode.setApiKey("AIzaSyC9pPjr83c5Ml_WIs8Us6SbmAPqfpPDTy0");
    marketobjects.forEach((item) => {
      promises.push(
        Geocode.fromAddress(item.marketname).then(
          (response) => {
            const coords = response.results[0].geometry.location;
            let newobj = {
              z: item.count,
              keyword: item.marketname,
              lat: coords.lat,
              lon: coords.lng,
            };
            finalmarketobjects.push(newobj);
            setTimeout(function () {}, 3000);
          },
          (error) => {
            console.log("ERROR");
            console.error(error);
          }
        )
      );
    });
    Promise.all(promises).then(() => {
      this.setState({
        locations: finalmarketobjects,
        clientlist: clientlist,
      });
    });
  }

  checkfilters(filters) {
    let filterarray = [];
    Object.keys(filters).forEach(function (key) {
      if (
        key !== "greaterorlessFilter" &&
        key !== "sizeFilter" &&
        key !== "startDate" &&
        key !== "endDate" &&
        key !== "startCloseDate" &&
        key !== "endCloseDate"
      ) {
        if (filters[key] !== "") {
          filterarray.push(filters[key]);
        }
      } else if (key === "sizeFilter" && filters[key] !== "") {
        filterarray.push(
          filters["greaterorlessFilter"].toUpperCase() +
            " than " +
            filters["sizeFilter"] +
            " sf"
        );
      } else if (key === "endDate" && filters[key] !== "") {
        filterarray.push(
          "Between " +
            moment(filters["startDate"]).format('MMMM Do, YYYY"') +
            " and " +
            moment(filters["endDate"]).format("MMMM Do, YYYY")
        );
      } else if (key === "endCloseDate" && filters[key] !== "") {
        filterarray.push(
          "Between " +
            moment(filters["startCloseDate"]).format('MMMM Do, YYYY"') +
            " and " +
            moment(filters["endCloseDate"]).format("MMMM Do, YYYY")
        );
      }
    });

    let joined = this.props.activefilters.concat(filterarray);
    this.props.dispatch(updateActiveFilters(joined));
  }

  static defaultProps = {
    projects: [],
    visits: {},
    performance: {},
    server: {},
    revenue: [],
    mainChart: [],
    isReceiving: false,
  };

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
      orFilterBox: false,
    }));
  }

  updateDashboard(filterRemoved = undefined) {
    if (this.state.RenderLocationsMap) {
      this.getLocations(this.state.opps);
    }
    let theupdates = [];
    if (this.props.updates) {
      theupdates = this.props.updates;
    }

    if (this.props.currentdashboardsettings) {
      let parsedsettings = JSON.parse(
        this.props.currentdashboardsettings.settings
      );
      let currentfilters = parsedsettings.dashboardFilters;
      let currenttoggles = parsedsettings.dashboardCharts;

      if (
        currentfilters.endDate !== null &&
        currentfilters.endDate !== "" &&
        currentfilters.endDate !== undefined
      ) {
        let enddate = moment(currentfilters.endDate);
        let startdate = moment(currentfilters.startDate);
        currentfilters.endDate = enddate;
        currentfilters.startDate = startdate;
      }
      if (
        currentfilters.endCloseDate !== null &&
        currentfilters.endCloseDate !== "" &&
        currentfilters.endCloseDate !== undefined
      ) {
        let endCloseDate = moment(currentfilters.endCloseDate);
        let startClosedate = moment(currentfilters.startCloseDate);
        currentfilters.endCloseDate = endCloseDate;
        currentfilters.startCloseDate = startClosedate;
      }
      if (
        currentfilters.estStartDateEnd !== null &&
        currentfilters.estStartDateEnd !== "" &&
        currentfilters.estStartDateEnd !== undefined
      ) {
        let estStartDateEnd = moment(currentfilters.estStartDateEnd);
        let estStartDateStart = moment(currentfilters.estStartDateStart);
        currentfilters.estStartDateEnd = estStartDateEnd;
        currentfilters.estStartDateStart = estStartDateStart;
      }

      this.props.dispatch(updateAllFilters(currentfilters));
      this.props.dispatch(updateShortcut(true));
      this.setState({
        ...this.state,
        opps: this.props.opps,
        customDashboardFilters: currentfilters,
        statusUpdates: theupdates,
        ...currenttoggles,
      });
    } else if (filterRemoved) {
      let newFilters = this.props.filters;

      if (filterRemoved == "endDate" || filterRemoved == "startDate") {
        newFilters.endDate = "";
        newFilters.startDate = "";
      } else if (filterRemoved == "marketcodeFilter") {
        newFilters.marketcodeFilter = [];
      } else if (
        filterRemoved == "endCloseDate" ||
        filterRemoved == "startCloseDate"
      ) {
        newFilters.endCloseDate = "";
        newFilters.startCloseDate = "";
      } else {
        newFilters[filterRemoved] = "";
      }

      let deletedActiveFilterIndex = newFilters.activefilters.findIndex(
        (filter) => filter.type === filterRemoved
      );
      if (deletedActiveFilterIndex > -1) {
        newFilters.activefilters.splice(deletedActiveFilterIndex, 1);
      }
      this.props.dispatch(updateAllFilters(newFilters));
      this.props.dispatch(updateShortcut(true));
      this.setState({
        ...this.state,
        opps: this.props.opps,
      });
    } else {
      let statusUpdates = [];
      let marketname = this.props.marketname;
      let renderMap = this.state.RenderLocationsMap;
      let renderTech = this.state.RenderTechTools;
      let renderSourcing = this.state.RenderSourcingMethodChart;
      let renderIndustries = this.state.RenderIndustryChart;
      let renderWoW = this.state.RenderWeekOverWeek;
      let renderMPerformance = this.state.RenderMarketPerformance;
      let RenderTechUsedAverage = this.state.RenderTechUsedAverage;
      let renderLineChart = this.state.RenderLineChart;

      if (marketname === "JTE") {
        // this.thisMonthShortcut()
        renderMPerformance = true;
        renderLineChart = true;
        renderMap = true;
        renderTech = true;
        renderSourcing = true;
        renderIndustries = true;
      }
      if (marketname === "National") {
        renderLineChart = true;
      }

      this.setState({
        opps: this.props.opps,
        statusUpdates: theupdates,
        RenderLineChart: renderLineChart,
        RenderLocationsMap: renderMap,
        RenderWeekOverWeek: renderWoW,
        RenderTechUsedAverage: RenderTechUsedAverage,
        RenderMarketPerformance: renderMPerformance,
        RenderTechTools: renderTech,
        RenderSourcingMethodChart: renderSourcing,
        RenderIndustryChart: renderIndustries,
      });
    }
  }

  removeFilter(data) {
    this.updateDashboard(data);
  }

  componentDidMount() {
    // let daterangestatusupdates= this.props.updates.OppStatusUpdates.filter(item => moment(item.updateTime, 'MM-DD-YYYY hh:mm:ss').isSame(moment(), 'month'))
    // let daterangestatusopps = this.props.opps.filter(item => thismonthstatusupdates.some(item2 => item2.opportunityid === item.opportunityid))
    if (this.props.opps.length > 0) {
      this.updateDashboard();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
    this.clearFilters();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.activefilters !== prevProps.activefilters) {
      this.findSectionsToHide();
    }

    if (
      this.state.RenderLocationsMap !== prevState.RenderLocationsMap &&
      this.state.RenderLocationsMap
    ) {
      this.getLocations(this.state.opps);
    }

    if (
      this.state.RenderIndustryChart !== prevState.RenderIndustryChart &&
      this.state.RenderIndustryChart
    ) {
      this.getLocations(this.state.opps);
    }
    if (
      prevProps.currentdashboardsettings !== this.props.currentdashboardsettings
    ) {
      this.clearFilters();
      this.updateDashboard();
    }
  };

  render() {
    const { activefilters, marketname } = this.props;
    const {
      filterbox,
      exportbox,
      toggleChartsPanel,
      RenderDonut,
      RenderLineChart,
      RenderServiceLineChart,
      RenderBrokerPerf,
      RenderTableData,
      opps,
    } = this.state;

    let winrate = (
      (opps.filter((object) => object.winloss === "Won").length /
        opps.filter(
          (object) => object.winloss === "Won" || object.winloss === "Lost"
        ).length) *
      100
    ).toFixed(1);
    let lossrate = (
      (opps.filter((object) => object.winloss === "Lost").length /
        opps.filter(
          (object) => object.winloss === "Won" || object.winloss === "Lost"
        ).length) *
      100
    ).toFixed(1);
    return (
      <div>
        <ErrorPage opps={opps}>
          <div className={s.analyticsSide}>
            <Row>
              <Col className="title-block-wnotice" xs={12} md={5} lg={5} xl={5}>
                <h1 className="page-title">
                  <span className="fw-semi-bold">{marketname}</span> Dashboard
                </h1>
              </Col>
              <Col xs={12} md={7} lg={7} xl={7}>
                <div className="pb-md h-100 mb-0 float-right exportAndBack">
                  <ul>
                    <li>
                      <Link to={"/app/reporting/calendar"}>
                        <Button
                          className="mb-xs mr-xs btn-block btn-primary float-right"
                          color="primary"
                        >
                          Calendar
                        </Button>
                      </Link>
                    </li>
                    <li>
                      <Button
                        className="mb-xs mr-xs btn-block btn-primary float-right"
                        onClick={() => this.toggle("filterbox")}
                        color="primary"
                      >
                        Filter
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="mb-xs mr-xs btn-block btn-primary float-right"
                        onClick={() => this.toggle("exportbox")}
                        color="primary"
                      >
                        Export Report
                      </Button>
                    </li>
                    <li>
                      <Button
                        className="mb-xs mr-xs btn-block btn-primary float-right"
                        onClick={() => this.toggle("toggleChartsPanel")}
                        color="primary"
                      >
                        Toggle Charts
                      </Button>
                    </li>
                    {marketname !== "JTE" ? (
                      <li>
                        <Button
                          className="mb-xs mr-xs btn-block btn-primary float-right"
                          onClick={() =>
                            this.toggle("saveCustomDashboardToggle")
                          }
                          color="primary"
                        >
                          {this.props.activefilters.length > 0
                            ? "Save Dashboard"
                            : "Create Custom Dashboard"}
                        </Button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              </Col>
            </Row>

            {this.state.filteringopps ? (
              <Row>
                <Loading />
              </Row>
            ) : null}
            {this.props.currentdashboardsettings ? (
              <Row className="mb-5">
                <Col xs={12} md={12} lg={12} xl={12}>
                  <div>
                    This is a custom dashboard.{" "}
                    <Button
                      className="btn-link btn-primary"
                      color="primary"
                      onClick={() => this.toggle("toggleCurrentSettings")}
                    >
                      View this dashboard's default settings.
                    </Button>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Row className={activefilters.length > 0 ? "show" : "d-none"}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="pb-xlg h-100">
                  <Widget className="mb-0 h-100" collapse bodyClass="mt-lg">
                    <div>
                      <span className="fw-semi-bold">Filtering by:</span>
                      {activefilters.length > 0 ? (
                        activefilters.map((filter, key) => {
                          return (
                            <FilterItemButton
                              update={this.removeFilter}
                              key={key}
                              data={filter}
                            />
                          );
                        })
                      ) : (
                        <div>No filters</div>
                      )}

                      <Button
                        className="float-right m-4"
                        style={{ color: "#fff" }}
                        onClick={this.clearFilters}
                      >
                        Clear filters
                      </Button>
                    </div>
                  </Widget>
                </div>
              </Col>
              {/* <Col xs={12} md={12} lg={12} xl={12}>
                <div className="pb-xlg ">
                  <Button onClick={this.orFilterClick} className="btn-link">
                    Add an "or" filter.
                  </Button>
                </div>
              </Col> */}
            </Row>
          </div>
          <div className={s.sidesWrapper}>
            <div
              className={s.analyticsSide}
              style={{ backgroundColor: "#EDF0F2" }}
              id="divToPrint"
            >
              {/* Default Overhead Stats*/}
              {this.state.RenderDefaultStats ? (
                <Row>
                  <Col xs={12} xl={3} md={6}>
                    <div className="pb-xlg h-100">
                      <Widget
                        className="mb-0 h-100"
                        close
                        bodyClass="mt-lg"
                        title={
                          <h5>
                            <span className="fw-semi-bold">Total</span> Pursuits
                          </h5>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center mb h3">
                          <h2 style={{ fontSize: "2.1rem" }}>
                            {opps.length
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </h2>
                        </div>
                      </Widget>
                    </div>
                  </Col>
                  <Col xs={12} xl={3} md={6}>
                    <div className="pb-xlg h-100">
                      <Widget
                        bodyClass="mt"
                        close
                        className="mb-0 h-100"
                        title={
                          <h5>
                            Current{" "}
                            <span className="fw-semi-bold">Win Rate</span>
                          </h5>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center mb h3">
                          <h2 style={{ fontSize: "2.1rem" }}>{winrate} %</h2>
                          <i className="la la-arrow-right text-success rotate-315" />
                        </div>
                      </Widget>
                    </div>
                  </Col>

                  <Col xs={12} xl={3} md={6}>
                    <div className="pb-xlg h-100">
                      <Widget
                        bodyClass="mt"
                        close
                        className="mb-0 h-100"
                        title={
                          <h5>
                            Current{" "}
                            <span className="fw-semi-bold">Loss Rate</span>
                          </h5>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center mb h3">
                          <h2 style={{ fontSize: "2.1rem" }}>{lossrate} %</h2>
                          <i className="la la-arrow-right text-danger rotate-45" />
                        </div>
                      </Widget>
                    </div>
                  </Col>
                  <Col xs={12} xl={3} md={6}>
                    <div className="pb-xlg h-100">
                      <Widget
                        className="mb-0 h-100"
                        close
                        bodyClass="mt-lg"
                        title={
                          <h5>
                            Average Pursuit{" "}
                            <span className="fw-semi-bold">Size</span>
                          </h5>
                        }
                      >
                        <div className="d-flex justify-content-between align-items-center mb h3">
                          <h2 style={{ fontSize: "2.1rem" }}>
                            {(
                              opps.reduce(
                                (a, { pursuitsize }) => a + pursuitsize,
                                0
                              ) / opps.length
                            )
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                            sf
                          </h2>
                          {/*<i className="la la-arrow-right text-success rotate-315"/>*/}
                        </div>
                      </Widget>
                    </div>
                  </Col>
                </Row>
              ) : null}
              {/*End default overhead stats*/}

              {/* Begin Timerange stats */}
              {this.state.RenderTimeRangeStats ? (
                <TimerangeStats
                  opps={opps}
                  dateRange={this.state.DateRangeFiltered}
                  newopps={this.state.newopps}
                  rawopps={this.props.opps}
                  wonopps={this.state.statusWins}
                  lostopps={this.state.statusLosses}
                  updates={this.state.statusUpdates}
                />
              ) : null}
              {/* End Timerange stats */}

              <Row>
                {RenderBrokerPerf ? (
                  <Col lg={7}>
                    <BrokerPerformance
                      dateRange={this.state.DateRangeFiltered}
                      data={opps}
                    />
                  </Col>
                ) : null}
                {RenderDonut ? (
                  <Col>
                    <StatusChart data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderLocationsMap &&
                this.state.locations.length > 0 ? (
                  <Col lg={6} xs={12}>
                    <OpportunityMap locations={this.state.locations} />
                  </Col>
                ) : null}
                {this.state.RenderTechTools ? (
                  <Col lg={6} xs={12}>
                    <TechToolUsed data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderSalesStage && (
                  <Col lg={6} xs={12}>
                    <SalesStage data={opps} />
                  </Col>
                )}

                {this.state.RenderBrokerEstRev && (
                  <Col lg={6} xs={12}>
                    <BrokerEstRev data={opps} />
                  </Col>
                )}
                {this.state.RenderEstRevenueQuarter && (
                  <Col lg={6} xs={12}>
                    <EstRevenueQuarter data={opps} />
                  </Col>
                )}

                {this.state.RenderStageEstRev && (
                  <Col lg={6} xs={12}>
                    <SalesStageRev data={opps} />
                  </Col>
                )}
                {this.state.RenderOtherServices ? (
                  <Col lg={12} xs={12}>
                    <OtherServicesChart data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderSourcingMethodChart ? (
                  <Col lg={6} xs={12}>
                    <SourcingMethodChart data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderIndustryChart ? (
                  <Col lg={6} xs={12}>
                    <IndustryChart
                      companydata={this.state.clientlist}
                      data={opps}
                    />
                  </Col>
                ) : null}
                {this.state.RenderTechUsedAverage ? (
                  <Col lg={12} xs={12}>
                    <TechUsedStacked data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderMarketPerformance ? (
                  <Col lg={12} xs={12}>
                    <MarketPerformance data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderYearOverYearOpps ? (
                  <Col lg={12} xs={12}>
                    <YearOverYearLineChart data={this.props.opps} />
                  </Col>
                ) : null}
                {this.state.RenderYearOverYearWinRate ? (
                  <Col lg={12} xs={12}>
                    <YearOverYearWinRateLineChart data={this.props.opps} />
                  </Col>
                ) : null}
                {this.state.RenderWeekOverWeek ? (
                  <Col lg={12} xs={12}>
                    <WeekOverWeek
                      data={opps}
                      updates={this.state.statusUpdates}
                    />
                  </Col>
                ) : null}
                {this.state.RenderMonthOverMonthMeeting ? (
                  <Col lg={12} xs={12}>
                    <MonthOverMonthMeetingDate
                      data={opps}
                      updates={this.state.statusUpdates}
                    />
                  </Col>
                ) : null}
                {this.state.RenderWeekOverWeekMeeting ? (
                  <Col lg={12} xs={12}>
                    <WeekOverWeekMeetingDate
                      data={opps}
                      updates={this.state.statusUpdates}
                    />
                  </Col>
                ) : null}
                {this.state.RenderMonthOverMonth ? (
                  <Col lg={12} xs={12}>
                    <MonthOverMonth
                      data={opps}
                      updates={this.state.statusUpdates}
                    />
                  </Col>
                ) : null}
                {this.state.RenderSLandPropType ? (
                  <Col lg={12} xs={12}>
                    <ServiceLineAndPropertyType data={opps} />
                  </Col>
                ) : null}
                {this.state.RenderPercentIncreaseChart ? (
                  <Col lg={12} xs={12}>
                    <PercentIncrease data={opps} />
                  </Col>
                ) : null}
                {RenderServiceLineChart && opps.length > 0 ? (
                  <Col lg={12} xs={12}>
                    <MainChart
                      dateRange={this.state.DateRangeFiltered}
                      data={opps}
                    />
                  </Col>
                ) : null}
                {RenderLineChart && opps.length > 0 ? (
                  <Col lg={12} xs={12}>
                    <NationalMainChartMaster
                      dateRange={this.state.DateRangeFiltered}
                      data={opps}
                    />
                  </Col>
                ) : null}
                {this.state.RenderDayChart ? (
                  <Col lg={12} xs={12}>
                    <DayChart
                      dateRange={this.state.DateRangeFiltered}
                      data={opps}
                    />
                  </Col>
                ) : null}
                {this.state.RenderDayChartSL ? (
                  <Col lg={12} xs={12}>
                    <DayChartSL
                      dateRange={this.state.DateRangeFiltered}
                      data={opps}
                    />
                  </Col>
                ) : null}
                {this.state.RenderNewOpps ? (
                  <Col xs={12} className="mb-lg">
                    <Widget
                      className="mb-0 h-100"
                      close
                      bodyClass="mt-lg"
                      title={
                        <h4>
                          {" "}
                          <strong>New Opportunities</strong> Reported
                        </h4>
                      }
                    >
                      <DataTable
                        data={this.state.newopps}
                        marketname={marketname}
                      />
                    </Widget>
                  </Col>
                ) : null}
                {this.state.RenderWinsTable &&
                this.state.statusWins.length > 0 ? (
                  <Col xs={12} className="mb-lg">
                    <Widget
                      className="mb-0 h-100"
                      close
                      bodyClass="mt-lg"
                      title={
                        <h4>
                          <strong>Wins</strong> Reported
                        </h4>
                      }
                    >
                      <DataTable
                        data={this.state.statusWins}
                        marketname={marketname}
                      />
                    </Widget>
                  </Col>
                ) : null}
                {this.state.RenderLossesTable &&
                this.state.statusLosses.length > 0 ? (
                  <Col xs={12} className="mb-lg">
                    <Widget
                      className="mb-0 h-100"
                      close
                      bodyClass="mt-lg"
                      title={
                        <h4>
                          {" "}
                          <strong>Losses</strong> Reported
                        </h4>
                      }
                    >
                      <DataTable
                        data={this.state.statusLosses}
                        marketname={marketname}
                      />
                    </Widget>
                  </Col>
                ) : null}
                {RenderTableData ? (
                  <Col xs={12} className="mb-lg">
                    <Widget
                      className="mb-0 h-100"
                      close
                      bodyClass="mt-lg"
                      title={
                        <h4>
                          {" "}
                          Opportunity <strong>Table</strong>
                        </h4>
                      }
                    >
                      <DataTable data={opps} marketname={marketname} />
                    </Widget>
                  </Col>
                ) : null}
              </Row>
            </div>

            <div className={s.analyticsSide}>
              <Row>
                <Col xs={12} md={6} xl={12} className={s.lastSideElement}>
                  <div className="pb-xlg h-100">
                    <Widget
                      bodyClass="mt"
                      close
                      className="mb-0 h-100"
                      title={<h5>Shortcuts</h5>}
                    >
                      <legend className="w-100 m-1">Pursuit Status</legend>
                      <Button
                        onClick={(e) => this.wonOppsShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Won Opportunities
                      </Button>

                      {/* <legend className="w-100 m-1">Service Line</legend>
                <Button onClick={(e) => this.tenantrepOppsShortcut()} className="w-100 m-1" color="primary">Tenant Rep</Button>

                <Button onClick={(e) => this.industrialOppsShortcut()} className="w-100 m-1" color="primary">Industrial</Button> */}

                      <legend className="w-100 m-1">Timeframe</legend>

                      <Button
                        onClick={(e) => this.thisWeekShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        This Week
                      </Button>
                      <Button
                        onClick={(e) => this.lastWeekShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Last Week
                      </Button>
                      <Button
                        onClick={(e) => this.thisMonthShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        This Month
                      </Button>
                      <Button
                        onClick={(e) => this.lastMonthShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Last Month
                      </Button>
                      <Button
                        onClick={(e) => this.lastThreeMonthsShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Last 3 Months
                      </Button>
                      <Button
                        onClick={(e) => this.thisYearShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        This Year
                      </Button>
                      <Button
                        onClick={(e) => this.lastYearShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Last Year
                      </Button>

                      {marketname !== "JTE" ? (
                        <>
                          <legend className="w-100 m-1">Reports</legend>
                          <Button
                            onClick={(e) => this.yearEndReportShortcut()}
                            className="w-100 m-1"
                            color="primary"
                          >
                            Year End Report
                          </Button>
                        </>
                      ) : null}

                      {/* {marketname !== "JTE" ? (
                        <>
                          <Button
                            onClick={(e) => this.twoMoreBusinessLinesShortcut()}
                            className="w-100 m-1"
                            color="primary"
                          >
                            Two+ Business Lines
                          </Button>
                        </>
                      ) : null} */}
                      <legend className="w-100 m-1">Other</legend>

                      <Button
                        onClick={(e) => this.PDSWinsTRShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        TR Wins Over 100,000 SF
                      </Button>
                      <Button
                        onClick={(e) => this.oneJLLExportShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        OneJLL Export
                      </Button>
                      <Button
                        onClick={(e) => this.techAdoptionShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Broker Tech Adoption Export
                      </Button>

                      {/* <legend className="w-100 m-1">Historical Data</legend>
                  <Button onClick={(e) => this.getHistoricalData()} id="getalldata" className="w-100 m-1" color="primary">Get Historical Data</Button>
       */}

                      {/*
                <Button className="w-100 m-1" color="primary" disabled>Suburban Agency</Button>
                <Button className="w-100 m-1" color="primary" disabled>Downtown Agency</Button>

                <Button onClick={(e) => this.retailOppsShortcut()} className="w-100 m-1" color="primary">Retail</Button>
                <Button  className="w-100 m-1" color="primary" disabled>Property Management</Button>
                <Button className="w-100 m-1" color="primary" disabled>Minneapolis</Button> */}

                      {/*  <Button onClick={(e) => this.fiftyThousandShortcut()} className=" m-1 w-100" color="primary">Greater than 50,000 SF</Button> */}
                    </Widget>
                  </div>
                </Col>
                <Col xs={12} md={6} xl={12} className={s.lastSideElement}>
                  <div className="pb-xlg h-100">
                    <Widget
                      bodyClass="mt-lg"
                      close
                      className="mb-0 h-100"
                      title={<h5>Filters</h5>}
                    >
                      <FilterPanel
                        orFilterBox={this.state.orFilterBox}
                        filterSnapshot={this.props.filterSnapshot}
                        findSectionsToHide={this.findSectionsToHide}
                        ref={this.filterboxchild}
                        opps={opps}
                        updates={this.state.statusUpdates}
                        rawupdates={this.props.updates}
                        market={marketname}
                        startFiltering={this.startFiltering}
                        filterUpdates={this.filterUpdates}
                        filterOpportunities={this.filterOpportunities}
                      />
                    </Widget>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/* Begin filter box */}

          <Modal
            size="lg"
            isOpen={filterbox}
            toggle={() => this.toggle("filterbox")}
          >
            <ModalHeader toggle={() => this.toggle("filterbox")}>
              Dashboard Filter
            </ModalHeader>
            <ModalBody className="bg-white">
              <FilterPanel
                toggleFilterBox={this.toggle.bind(this)}
                orFilterBox={this.state.orFilterBox}
                filterSnapshot={this.state.currentfiltersnapshot}
                findSectionsToHide={this.findSectionsToHide}
                market={marketname}
                opps={opps}
                updates={this.state.statusUpdates}
                rawupdates={this.props.updates}
                filterUpdates={this.filterUpdates}
                startFiltering={this.startFiltering}
                filterOpportunities={this.filterOpportunities}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
          {/* Begin Export box */}
          <Modal
            size="lg"
            isOpen={exportbox}
            toggle={() => this.toggle("exportbox")}
          >
            <ModalHeader toggle={() => this.toggle("exportbox")}>
              Export PDF Report
            </ModalHeader>
            <ModalBody className="bg-white">
              <div
                className="mb-4"
                style={{ borderBottom: "1px solid #e9ecef" }}
              >
                <Col>
                  <h6 className="mb-4">
                    Export the dashboard as it currenlty appears on your screen:
                  </h6>
                  <Button
                    className="mb-4 mr-xs btn-block btn-primary"
                    onClick={() => this.printDocument()}
                    color="primary"
                  >
                    Export Dashboard
                  </Button>
                </Col>
              </div>

              <Col>
                <h6>Or export a monthly report in JLL branding:</h6>
              </Col>
              <ExporReport />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
          {/* Begin Toggle box */}
          <Modal
            size="lg"
            isOpen={toggleChartsPanel}
            toggle={() => this.toggle("toggleChartsPanel")}
          >
            <ModalHeader toggle={() => this.toggle("toggleChartsPanel")}>
              Toggle Charts
            </ModalHeader>
            <ModalBody className="bg-white">
              <ToggleCharts
                dashboardState={this.state}
                market={marketname}
                toggleCharts={this.toggleCharts}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
          {/* Begin Current Custom Settings box */}
          <Modal
            size="lg"
            isOpen={this.state.toggleCurrentSettings}
            toggle={() => this.toggle("toggleCurrentSettings")}
          >
            <ModalHeader toggle={() => this.toggle("toggleCurrentSettings")}>
              Dashboard settings
            </ModalHeader>
            <ModalBody className="bg-white">
              <CurrentDashboardSettings
                settings={this.props.currentdashboardsettings}
                userdata={this.props.userdata}
                token={this.props.token}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

          {/* Begin Current Custom Settings box */}
          <Modal
            size="lg"
            isOpen={this.state.saveCustomDashboardToggle}
            toggle={() => this.toggle("saveCustomDashboardToggle")}
          >
            <ModalHeader
              toggle={() => this.toggle("saveCustomDashboardToggle")}
            >
              Save Custom Dashboard
            </ModalHeader>
            <ModalBody className="bg-white">
              <SaveCustomDashboard
                filterSnapshot={this.props.filterSnapshot}
                togglePanel={this.hideCustomDashboardSaveModal}
                token={this.props.token}
                settings={this.props.currentdashboardsettings}
                currentmarkets={this.props.marketarray}
                markets={this.props.marketdata}
                dashboardState={this.state}
                filters={this.props.filters}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </ErrorPage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    filterSnapshot: state.projects.filterSnapshot,
    cleandataready: state.projects.cleandataready,
    userdata: state.authentication.userprofile,
    filters: state.dashboardfilters,
    filterbox: state.filterbox,
    filteredprojects: state.projects.filteredprojects,
    activefilters: state.dashboardfilters.activefilters,
    oppnamelist: state.projects.oppnamelist,
    marketdata: state.projects.marketdata,
    blist: state.projects.blist,
    sllist: state.projects.servicelinelist,
  };
}

export default withRouter(connect(mapStateToProps)(DashboardTemplate));
