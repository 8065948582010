import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts";

import { connect } from 'react-redux';


class NationalDonutChart extends PureComponent {



  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false,
  };

  chartData = () => {
    const { nationalstatusdata } = this.props;
    let data = nationalstatusdata

    let series = [{
      name: "# Opportunities",
      data: []
    }]

    let query = ["Won", "Lost", "Pending", "Open"]

    query.forEach(item =>{
      let key = item.toLowerCase()
      let newitem = {
        name: item,
        y: data[key]
      }
      series[0].data.push(newitem)
      })


    // let series = [
    //   {
    //     name: 'Revenue',
    //     data: this.props.revenue.map(s => {
    //       return {
    //         name: s.label,
    //         y: s.data
    //       }
    //     })
    //   }
    // ]


    return {
      series,
      ...this.chartOptions
    }};

  chartOptions ={
      chart: {
        type: 'pie',
        height: 220,
        backgroundColor: 'rgba(0,0,0,0)',
      },
      credits: {
        enabled: false
      },
      title: false,
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false
          },
          borderWidth: 0,
          showInLegend: true,
          innerSize: 0,
          size: 170,
          states: {
            hover: {
              halo: {
                size: 1
              }
            }
          }
        }
      },
      colors: ['#68ca66', '#FD5F00','#005792', '#1A86D0','#1A86D0'],
      legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemStyle: {
          color: '#788898',
          fontWeight: 400,
        },
        itemHoverStyle: {
          color: "#cccccc"
        },
        itemMarginBottom: 5,
        symbolRadius: 0
      },
      exporting: {
        enabled: true
      }
    };

  render() {

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Opportunity <span className="fw-semi-bold">Status</span>
              </h5>
            </Col>
          </Row>
        }
      >
       <HighchartsReact  highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}


export default NationalDonutChart
