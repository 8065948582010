import axios from "axios";
import { getNotificationsandUpdates, getMarketData } from "./projects";
import LogRocket from "logrocket";

export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_HISTORICAL_TOGGLE = "SET_HISTORICAL_TOGGLE";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const RECEIVED_PROFILE_DATA_SUCCESS = "RECEIVED_PROFILE_DATA_SUCCESS";

export function toggleHistoricalData() {
  return {
    type: SET_HISTORICAL_TOGGLE,
  };
}

export function updateUserProfile(payload) {
  return {
    type: UPDATE_USER_PROFILE,
    payload,
  };
}

export function receiveProfileDataSuccess(payload) {
  return {
    type: RECEIVED_PROFILE_DATA_SUCCESS,
    payload,
  };
}

export function authStart() {
  return {
    type: AUTH_START,
  };
}

export function authSuccess(token) {
  return {
    type: AUTH_SUCCESS,
    token: token,
  };
}

export function authFail(error) {
  return {
    type: AUTH_FAIL,
    error,
  };
}

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  return {
    type: AUTH_LOGOUT,
  };
};
function logRocketUser(user) {
  if (user) {
    LogRocket.identify(user.email, {
      name: user.first_name + " " + user.last_name,
      email: user.email,
      username: user.username,
      first_name: user.first_name,
      last_name: user.last_name,
      groups: user.groups,
    });
  }
}

export function getUserData(token) {
  return (dispatch) => {
    // dispatch(receivingUserProfile());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    axios.get("/api/v1/userprofile/").then(
      (res) => {
        dispatch(receiveProfileDataSuccess(res.data));
        logRocketUser(res.data[0].useraccount);
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function checkAuthTimeout(expirationTime) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
}

export function checkLSforToken() {
  try {
    const serializedState = localStorage.getItem("token");
    if (serializedState === null) {
      return logout();
    }
    let token = JSON.parse(serializedState);
    return authSuccess(token);
  } catch (e) {
    return logout();
  }
}

export function authLogin(username, password) {
  return (dispatch) => {
    dispatch(authStart());
    axios.defaults.headers = {};
    axios
      .post("/rest-auth/login/", {
        username: username,
        password: password,
      })
      .then((res) => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 5600 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(3600));
        let redirectTo = localStorage.getItem("redirectTo");
        // dispatch(getUserData(token));
        // window.location.href = "/"
        dispatch(authCheckState());
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
}

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token === undefined) {
      dispatch(logout());
    } else {
      localStorage.removeItem("redirectTrue");
      dispatch(getUserData(token));
      dispatch(getMarketData(token));
      dispatch(getNotificationsandUpdates(token));
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};
