import React from 'react';
import {
  ButtonGroup,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import OppNotifications from './notifications/Notifications';
import ProgressDemo from './notifications/Progress';
import { connect } from 'react-redux';
import s from './Notifications.module.scss';
import {
  Link
} from "react-router-dom";

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notificationsTabSelected: 1,
      newNotifications: null,
      isLoad: false,
    };
  }

  changeNotificationsTab(tab) {
    this.setState({
      notificationsTabSelected: tab,
      newNotifications: null,
    });
  }


  render() {
    const { notifications } = this.props;

    let notificationsTab;

    switch (this.state.notificationsTabSelected) {
      case 1:
        notificationsTab = (<OppNotifications />);
        break;
      case 3:
        notificationsTab = (<ProgressDemo />);
        break;
      default:
        notificationsTab = (<OppNotifications />);
        break;
    }
    return (
      <section className={`${s.notifications} card navbar-notifications`}>
        <header className={[s.cardHeader, 'card-header'].join(' ')}>
          <div className="text-center mb-sm">
            <strong>You have {notifications.length} notifications</strong>
          </div>
          <ButtonGroup id="notification-buttons">
            <Button color="secondary" onClick={() => this.changeNotificationsTab(1)} active={this.state.notificationsTabSelected === 1}>Notifications</Button>
            {/*
            <Button color="secondary" onClick={() => this.changeNotificationsTab(3)} active={this.state.notificationsTabSelected === 3}>Progress</Button> */}
          </ButtonGroup>
        </header>
        {this.state.newNotifications || notificationsTab}
        {/* <footer className={[s.cardFooter, 'text-sm', 'card-footer'].join(' ')}>
           <Link to={'/app/updates'} ><div className="text-center mb-sm">View all updates</div></Link>
        </footer> */}
      </section>
    );
  }
}


// <Button
//   color="link"
//   className={classnames({ disabled: this.state.isLoad }, s.btnNotificationsReload, 'btn-xs', 'float-right', 'py-0')}
//   onClick={() => this.loadNotifications()}
//   id="load-notifications-btn"
// >
//   {this.state.isLoad ? <span><i className="la la-refresh la-spin" /> Loading...</span> : <i className="la la-refresh" />}
// </Button>
// <span className="fs-mini">Synced at: 21 Apr 2014 18:36</span>
function mapStateToProps(state) {
  return{
    notifications: state.projects.notifications
  }
}

export default connect(mapStateToProps)(Notifications);
