import React from 'react';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import s from './ErrorPage.module.scss';

class NoAccessPage extends React.Component {
  constructor(props) {
      super(props)
      this.state = { errorOccurred: false }
    }

  render() {

 
    return (
      <div className={s.errorPage}>
        <Container>
          <div className={`${s.errorContainer} mx-auto`}>
            <h1 className={s.errorCode}>Error</h1>
            <p className={s.errorInfo}>
              {this.props.error}
              {
                this.props.errormessage
                ?
                `${this.props.errormessage}`
                :
                "Either this dashboard does not exist, or you do not have access."
              }
              
            </p>
            <p className={[s.errorHelp, 'mb-3'].join(' ')}>
              Click below to return to the market selector.
            </p>
            <Form method="get">
              <Link to="/app/">
                <Button className={s.errorBtn} color="success">
                   Return
                </Button>
              </Link>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}


export default NoAccessPage;
