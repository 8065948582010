import React from 'react';
import {
  Container,
  Form,
  FormGroup,
  Input,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import s from './ErrorPage.module.scss';

class FourohFour extends React.Component {
  constructor(props) {
      super(props)
      this.state = { errorOccurred: false }
    }
    
  render() {
    return (
      <div className={s.errorPage}>
        <Container>
          <div className={`${s.errorContainer} mx-auto`}>
            <h1 className={s.errorCode}>404</h1>
            <p className={s.errorInfo}>
              Opps, it seems that this page does not exist.
            </p>
            <p className={[s.errorHelp, 'mb-3'].join(' ')}>
              Click below to return to the main Dashboard.
            </p>
            <Form method="get">
              <Link to="/app/">
                <Button className={s.errorBtn} color="success">
                   Return Home
                </Button>
              </Link>
            </Form>
          </div>
        </Container>
      </div>
    );
  }
}


export default FourohFour;
