import React from "react";
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import DateRangePickerWrapper from "../DateRange/DateRange";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import s from "../Invoice.module.scss";
import DataTable from "../logstable/DataTable";
import Widget from "../../../components/Widget";
var moment = require("moment");

class EmailDetails extends React.Component {
  constructor(props) {
    super(props);
    this.updateLocalStartDate = this.updateLocalStartDate.bind(this);
    this.updateLocalEndDate = this.updateLocalEndDate.bind(this);
    this.state = {
      success: false,
      fetching: false,
      error: false,
      complete: false,
      successCustomDate: false,
      fetchingCustomDate: false,
      errorCustomDate: false,
      completeCustomDate: false,
      errortextCustomDate: "",
      missingDateError: false,
      startdate: null,
      enddate: null,
      errortext: "",
      customRangeModal: false,
      fetchingError: false,
      errorData: "",
      currentReportDetails: null,
      emailreports: [],
      emaillogs: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  getSingleEmailReport() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/emailreport/${this.props.currentemailid}/`).then(
      (res) => {
        this.setState({
          currentReportDetails: res.data,
          fetching: false,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          fetchingError: false,
        });
      }
    );
  }

  updateLocalStartDate(val) {
    this.setState({
      startdate: val,
    });
  }

  updateLocalEndDate(val) {
    this.setState({
      enddate: val,
    });
  }

  getReportLogs() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/emailreportlogs/?pk=${this.props.currentemailid}`).then(
      (res) => {
        this.setState({
          emaillogs: res.data,
          fetching: false,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          fetchingError: false,
        });
      }
    );
  }

  handleClick(cell) {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/sendtestemail/${cell}/`).then(
      (res) => {
        this.setState({
          complete: true,
          success: true,
          fetching: false,
        });
      },
      (error) => {
        let erro = JSON.stringify(error.response.data);
        erro = JSON.parse(erro);
        let errormessage = "Some Error Occured";
        if (erro.Error) {
          errormessage = erro.Error;
        }

        this.setState({
          error: true,
          errortext: errormessage,
          fetching: false,
          complete: true,
        });
      }
    );
  }

  handleCustomTest(cell) {
    if (this.state.startdate && this.state.enddate) {
      this.setState({
        fetchingCustomDate: true,
        customRangeModal: false,
      });
      let startdate = moment(this.state.startdate).format("MM DD YYYY");
      let enddate = moment(this.state.enddate).format("MM DD YYYY");

      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`,
      };
      axios
        .get(
          `/api/v1/sendcustomdatetest/${cell}/?startDate=${startdate}&endDate=${enddate}`
        )
        .then(
          (res) => {
            this.setState({
              completeCustomDate: true,
              successCustomDate: true,
              fetchingCustomDate: false,
            });
          },
          (error) => {
            let erro = JSON.stringify(error.response.data);
            erro = JSON.parse(erro);
            let errormessage = "Some Error Occured";
            if (erro.Error) {
              errormessage = erro.Error;
            }

            this.setState({
              errorCustomDate: true,
              errortextCustomDate: errormessage,
              fetchingCustomDate: false,
              completeCustomDate: true,
            });
          }
        );
    } else {
      this.setState({
        missingDateError: true,
      });
    }
  }

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  componentDidMount() {
    if (!this.props.noReports) {
      this.getSingleEmailReport();
      this.getReportLogs();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentemailid !== this.props.currentemailid) {
      this.getSingleEmailReport();
      this.getReportLogs();
      this.setState({
        success: false,
        fetching: false,
        error: false,
        complete: false,
        errortext: "",
        successCustomDate: false,
        fetchingCustomDate: false,
        errorCustomDate: false,
        completeCustomDate: false,
        errortextCustomDate: "",
        missingDateError: false,
        startdate: null,
        enddate: null,
      });
    }
  }

  render() {
    const { currentReportDetails, emaillogs, customRangeModal } = this.state;

    let marketsincluded = [];
    let filtersincluded = [];

    if (currentReportDetails) {
      if (currentReportDetails.markets.length >= 15) {
        marketsincluded.push("All Markets");
      } else {
        currentReportDetails.markets.forEach((item) => {
          marketsincluded.push(item.marketname);
        });
      }
      if (currentReportDetails.serviceline.length > 0) {
        let sl = [];
        currentReportDetails.serviceline.forEach((item) => {
          sl.push(item.servicelinename);
        });
        sl = sl.join(", ");
        filtersincluded.push(`Service Line(s): ${sl}`);
      }
      if (currentReportDetails.propertytype.length > 0) {
        filtersincluded.push(
          `Property Type: ${currentReportDetails.propertytype}`
        );
      }
      if (currentReportDetails.sfthreshold > 0) {
        filtersincluded.push(
          `Pursuit size: ${currentReportDetails.sfthreshold
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
        );
      }
      if (currentReportDetails.timerangefilter.length > 0) {
        filtersincluded.push(
          `Time Range Query: ${currentReportDetails.timerangefilter}`
        );
      }
      if (currentReportDetails.opportunitytype.length > 0) {
        filtersincluded.push(
          `Opp Type: ${currentReportDetails.opportunitytype}`
        );
      }
      if (currentReportDetails.otherserviceline.length > 0) {
        filtersincluded.push(`Other: ${currentReportDetails.otherserviceline}`);
      }
    }

    return (
      <>
        <Row className="mb-2">
          <Col lg={8}>
            <h2 className="text-muted no-margin">Campaign details:</h2>
          </Col>
          <Col lg={3}>
            <Button
              color="primary"
              className="float-right"
              onClick={() => this.props.toggleCreateorEdit("createReportModal")}
            >
              Create Report
            </Button>
          </Col>
        </Row>

        {currentReportDetails ? (
          <Row>
            <Col lg={11}>
              <Row className={s.root}>
                <Col xs={12}>
                  <Widget bodyClass={"p-0"}>
                    <div className="widget">
                      <header>
                        <Row className="mb-lg">
                          <Col sm={6} lg={8} className="col-print-6">
                            <h2 className="text-muted no-margin">
                              {currentReportDetails.name}
                            </h2>

                            <div>
                              <Button
                                onClick={() =>
                                  this.props.toggleCreateorEdit(
                                    "editReportModal"
                                  )
                                }
                                color="primary"
                                className="float-left btn-link"
                              >
                                Edit Report
                              </Button>
                            </div>
                          </Col>
                          <Col lg={4}>
                            <span
                              className={`ml-auto ${s.timestamp}createdByText float-right`}
                            >
                              <Tooltip
                                title={
                                  <div style={{ fontSize: "12px" }}>
                                    {currentReportDetails.active
                                      ? "Active"
                                      : "Not Active"}
                                  </div>
                                }
                                placement="top"
                              >
                                <span
                                  className={`emailStatus ${
                                    currentReportDetails.active
                                      ? "bg-success"
                                      : "bg-warning"
                                  }`}
                                ></span>
                              </Tooltip>
                            </span>
                            <br></br>
                            <p className="float-right">
                              Created on:{" "}
                              {moment(
                                currentReportDetails.datecreated,
                                "YYYY-MM-DD hh:mm:ss"
                              ).format("MMM DD, YYYY")}
                            </p>
                            <br></br>
                            {currentReportDetails.createdby ? (
                              <div className="createdByText">
                                <p className="float-right">
                                  Created by:{" "}
                                  {currentReportDetails.createdby.fullname}
                                </p>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </header>
                      <section>
                        <Row className="">
                          <Col lg={6} className="border-right border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Markets included:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {marketsincluded.map((item) => {
                                    return <li>{item}</li>;
                                  })}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} className="border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Currently subscribed:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.recipients.map(
                                    (item) => {
                                      return <li>{item.fullname}</li>;
                                    }
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} className="border-right border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Alt. Market Desc.:
                              </Col>
                              <Col lg={6}>
                                {currentReportDetails.altname
                                  ? `${currentReportDetails.altname}`
                                  : "None"}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} className="border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Report Frequency:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.frequency}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} className="border-right border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                CC Members:
                              </Col>
                              <Col lg={6}>
                                {currentReportDetails.cc_members.map((item) => {
                                  return <li>{item.fullname}</li>;
                                })}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} className="border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Show win/loss details:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.includewinloss
                                    ? "Yes"
                                    : "No"}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6} className="border-right border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Filters Included:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {filtersincluded.length > 0
                                    ? filtersincluded.map((item) => {
                                        return <li>{item}</li>;
                                      })
                                    : "None"}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} className="border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Report Type:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.template}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} className="border-right ">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Include CSV:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.includecsv
                                    ? "True"
                                    : "False"}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Send If No Updates:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.sendreportifnoopps
                                    ? "Yes"
                                    : "No"}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </section>
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <NoAccess
            header={"Email Report"}
            message={"No report details to display."}
          />
        )}
        <h2 className="text-muted no-margin">Report logs:</h2>
        {emaillogs.length > 0 ? (
          <DataTable data={emaillogs} token={this.props.token} />
        ) : (
          <NoAccess
            message={"No logs for this email report currently exist."}
          />
        )}
        <h2 className="text-muted no-margin">Tools:</h2>

        {currentReportDetails ? (
          <Row>
            <Col lg={11}>
              <Row className={s.root}>
                <Col xs={12}>
                  <Widget bodyClass={"p-0"}>
                    <div className="widget">
                      <Row>
                        <Col lg={6}>
                          {!this.state.complete && !this.state.fetching ? (
                            <Tooltip
                              title={
                                <div style={{ fontSize: "12px" }}>
                                  A test will be sent to your email address.
                                </div>
                              }
                              placement="top"
                            >
                              <Button
                                block
                                color="primary"
                                className="px-2"
                                onClick={() =>
                                  this.handleClick(currentReportDetails.id)
                                }
                              >
                                Send Test
                              </Button>
                            </Tooltip>
                          ) : this.state.complete && this.state.success ? (
                            <Button
                              block
                              color="success"
                              className="px-2"
                              disabled
                            >
                              Test was sent!
                            </Button>
                          ) : this.state.fetching && !this.state.complete ? (
                            <Button
                              block
                              color="primary"
                              className="px-2"
                              disabled
                            >
                              <i
                                className="la la-spinner la-spin"
                                style={{ fontSize: this.props.size }}
                              />
                            </Button>
                          ) : (
                            <Button
                              block
                              color="danger"
                              className="px-2"
                              disabled
                            >
                              {this.state.errortext}
                            </Button>
                          )}
                        </Col>
                        <Col lg={6}>
                          {!this.state.completeCustomDate &&
                          !this.state.fetchingCustomDate ? (
                            <Tooltip
                              title={
                                <div style={{ fontSize: "12px" }}>
                                  A test will be sent to your email address.
                                </div>
                              }
                              placement="top"
                            >
                              <Button
                                block
                                color="primary"
                                className="px-2"
                                onClick={() => this.toggle("customRangeModal")}
                              >
                                Send Custom Date Test
                              </Button>
                            </Tooltip>
                          ) : this.state.completeCustomDate &&
                            this.state.successCustomDate ? (
                            <Button
                              block
                              color="success"
                              className="px-2"
                              disabled
                            >
                              Test was sent!
                            </Button>
                          ) : this.state.completeCustomDate &&
                            this.state.errorCustomDate ? (
                            <Button
                              block
                              color="danger"
                              className="px-2"
                              disabled
                            >
                              {this.state.errortextCustomDate}
                            </Button>
                          ) : !this.state.completeCustomDate &&
                            this.state.fetchingCustomDate ? (
                            <Button
                              block
                              color="primary"
                              className="px-2"
                              disabled
                            >
                              <i
                                className="la la-spinner la-spin"
                                style={{ fontSize: this.props.size }}
                              />
                            </Button>
                          ) : null}
                        </Col>
                      </Row>
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        <Modal
          size="lg"
          isOpen={customRangeModal}
          toggle={() => this.toggle("customRangeModal")}
        >
          <ModalHeader toggle={() => this.toggle("customRangeModal")}>
            Send custom{" "}
          </ModalHeader>
          <ModalBody className="bg-white">
            <p>Select a custom date range to test the email report:</p>
            <div className="mb-4">
              <DateRangePickerWrapper
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                updateLocalEndDate={this.updateLocalEndDate}
                updateLocalStartDate={this.updateLocalStartDate}
              />
            </div>
            {this.state.missingDateError ? (
              <p className="text-danger">Please choose a date range.</p>
            ) : null}
            <Button
              block
              color="primary"
              className="px-2"
              onClick={() => this.handleCustomTest(currentReportDetails.id)}
            >
              Send Test
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

class NoAccess extends React.Component {
  render() {
    const { message, header } = this.props;
    return (
      <>
        <Row>
          <Col lg={11}>
            <Row className={s.root}>
              <Col xs={12}>
                <Widget bodyClass={"p-0"}>
                  <div className="widget">
                    <header>
                      <Row className="mb-lg">
                        <Col sm={6} className="col-print-6">
                          <h2 className="text-muted no-margin">{header}</h2>
                        </Col>
                      </Row>
                    </header>
                    <section>
                      <div className="text-center">{message}</div>
                    </section>
                  </div>
                </Widget>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

class SendButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      complete: false,
      error: false,
      success: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(cell, row, rowIndex) {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/sendtestemail/${cell}/`).then(
      (res) => {
        this.setState({
          complete: true,
          success: true,
          fetching: false,
        });
      },
      (error) => {
        this.setState({
          error: true,
          fetching: false,
          complete: true,
        });
      }
    );
  }

  render() {
    const { cell, row, rowIndex } = this.props;
    return (
      <>
        {!this.state.fetching && !this.state.complete ? (
          <React.Fragment>
            <Tooltip
              title={
                <div style={{ fontSize: "12px" }}>
                  A test will be sent to your email only.
                </div>
              }
              placement="top"
            >
              <Button
                color="primary"
                size="xs"
                className="px-2"
                onClick={() => this.handleClick(cell, row, rowIndex)}
              >
                Send Test
              </Button>
            </Tooltip>
            {/* <Dialog ref={(el) => { this.dialog = el }} /> */}
          </React.Fragment>
        ) : this.state.fetching ? (
          <div>
            <i className="la la-spinner la-spin" />
          </div>
        ) : this.state.success ? (
          <div>Test Sent!</div>
        ) : this.state.error ? (
          <div>Error.</div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    userprofile: state.authentication.userprofile,
  };
}

export default connect(mapStateToProps)(EmailDetails);
