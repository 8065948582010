import React from 'react';
import { Col, Row,  UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu} from 'reactstrap';
import PercentileIncrease from './PercentileIncrease'
import VolumeIncrease from './VolumeIncrease'
import TechUsedPercent from './TechUsedIncreasePercent'
import TechUsedVolume from './TechUsedIncreaseVolume'
import Section from '../../../../components/Section/Section';

class PercentileIncreaseModule extends React.Component {

  constructor() {
    super();
    this.state={
        timerange: 'Quarter',
    };
  }

  changeSelectDropdownSimple = (e) => {
    this.setState({ timerange: e.currentTarget.textContent});
  }

  render() {
   
    return (
     <>
 
    <Row className="mb-3">
        <Col lg={6}>
        <h2>Adoption Goals</h2>
        </Col>
        <Col>
        <UncontrolledButtonDropdown outline color="secondary" className="float-right goalsdropdown">
           <DropdownToggle
             caret color="default"
             className="dropdown-toggle-split mr-xs"
           >
             {this.state.timerange}
           </DropdownToggle>
           <DropdownMenu>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
              Month
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
              Quarter
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               Year
             </DropdownItem>
           </DropdownMenu>
         </UncontrolledButtonDropdown>
        </Col>
        
    </Row>
     <Row>
         <Col lg={6}>
         <PercentileIncrease data={this.props.data} timerange={this.state.timerange} />
         </Col>
         <Col lg={6}>
        <VolumeIncrease data={this.props.data} timerange={this.state.timerange} />  
         </Col>
     </Row>
     <Row>
         <Col lg={6}>
         <TechUsedPercent data={this.props.data} timerange={this.state.timerange} />
         </Col>
         <Col lg={6}>
         <TechUsedVolume data={this.props.data} timerange={this.state.timerange} />
         </Col>
     </Row>
     </>
    );
  }
}

export default PercentileIncreaseModule;
