import React, { Component } from 'react';
import { connect } from 'react-redux';
import Wizard from './wizard/Wizard';
import axios from 'axios';
import {
  Row,
  Col,
  Table,
  ButtonToolbar,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';

class CreateEditRefreshCampaign extends Component {
  
  constructor(props) {
    super(props)
    this.state={
      createReportModal: false,
      editReportModal: false,
      currentReportDetails: null
    }
    
    }


    getCurrentReportDetails(){
      let foundReport = this.props.refreshemails.find(item=> this.props.currentRefreshEmailID === item.id)
      this.setState({
        currentReportDetails: foundReport
      })
  
    }


    componentDidMount() {
      if(this.props.refreshemails.length > 0){
        this.getCurrentReportDetails()
      }
      this.setState({
        createReportModal: this.props.createReportModal,
        editReportModal: this.props.editReportModal
      })
    }

    componentDidUpdate(prevProps) {
      if(prevProps.createReportModal !== this.props.createReportModal){
        this.setState({
          createReportModal: this.props.createReportModal
        })
      }
      if(prevProps.editReportModal !== this.props.editReportModal){
        this.setState({
          editReportModal: this.props.editReportModal
        })
      }
      if(prevProps.currentRefreshEmailID !== this.props.currentRefreshEmailID){
        this.getCurrentReportDetails()
      }
      if(prevProps.refreshemails.length !== this.props.refreshemails.length){
        this.getCurrentReportDetails()
      }
    }

 
  render() {
  return (
    <>
    <Modal size="lg" isOpen={this.state.createReportModal} toggle={() => this.props.toggleCreateorEdit('createReportModal')}>
    <ModalHeader toggle={() => this.props.toggleCreateorEdit('createReportModal')}>Create a Refresh Campaign</ModalHeader>
      <ModalBody className="bg-white">
      <div className="mb-4">
        <Wizard token={this.props.token} receiveNewlyCreatedCampaign={this.props.receiveNewlyCreatedCampaign} toggleCreateorEdit={this.props.toggleCreateorEdit}  />
      </div>
      </ModalBody>
      </Modal>
    <Modal size="lg" isOpen={this.state.editReportModal} toggle={() => this.props.toggleCreateorEdit('editReportModal')}>
        <ModalHeader toggle={() => this.props.toggleCreateorEdit('editReportModal')}>Edit a Refresh Campaign </ModalHeader>
          <ModalBody className="bg-white">
          <div className="mb-4">
            { 
              this.state.currentReportDetails
              ?
              <Wizard token={this.props.token} receiveNewlyCreatedCampaign={this.props.receiveNewlyCreatedCampaign} currentReportDetails={this.state.currentReportDetails} toggleCreateorEdit={this.props.toggleCreateorEdit}  />
              : null
            }
          </div>
          </ModalBody>
    </Modal>
</>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps)(CreateEditRefreshCampaign);