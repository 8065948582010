import {
  RECEIVED_PROJECTS,
  RECEIVED_PROJECT,
  RECEIVING_PROJECTS,
  RECEIVING_PROJECT,
  RECEIVED_DATA_SUCCESS,
  RECEIVING_DATA,
  SET_FILTERED_PROJECTS,
  RECEIVING_PROFILE,
  SET_CLEAN_DATA,
  RECEIVING_NOTIFICATIONS,
  RECEIVED_NOTIFICATION_SUCCESS,
  RECEIVING_MEETING_DATE_UPDATES,
  RECEIVED_MEETING_DATE_UPDATES_SUCCESS,
  RECEIVING_NEW_OPP_UPDATES,
  RECEIVED_NEW_OPP_UPDATES_SUCCESS,
  RECEIVING_OPP_STATUS_UPDATES,
  RECEIVED_OPP_STATUS_UPDATES_SUCCESS,
  GET_MARKET_SPECIFIC_DATA,
  RECEIVING_MARKETS,
  RECEIVED_MARKET_DATA_SUCCESS,
  WARNING_OCCURED,
  MARK_NOTICE_AS_READ,
  RECEIVED_MARKET_OPTIONS_SUCCESS,
  MARK_NOTICE_AS_READ_SIMPLE,
  CREATE_FILTER_SNAPSHOT,
  FAILED_USER_MARKET_OPPS,
  RECEIVING_NATIONAL_DASHBOARD_DATA,
  RECEIVED_NATIONAL_DASHBOARD_DATA_SUCCESS,
  RECEIVED_USER_MARKET_OPPS,
} from "../actions/projects";
import tempprojectdata from "./tempprojectdata";
import tempnationalprojectdata from "./tempnationalprojectdata";
import tempsurveydata from "./tempsurveydata";

//
// function checkForLocalData(){
//   console.log("localStorage")
//   const serializedState = localStorage.getItem('state')
//   const parsedJSON = JSON.parse(serializedState)
//   console.log(parsedJSON)
//   if(!(parsedJSON.projects.projects.length > 0)){
//
//   }
//
// }

// projects: tempnationalprojectdata,
// surveys: tempsurveydata,

const initialData = {
  projects: [],
  marketcodes: [],
  opportunities: [],
  receivedUserMarketOpps: false,
  failedUserMarketopps: false,
  surveys: [],
  data: [],
  blist: [],
  selist: [],
  dalist: [],
  filterSnapshot: {},
  oppnamelist: [],
  oppobjectlist: [],
  techlist: [],
  opptypelist: [],
  marketoptions: [],
  nationallinechartdata: [],
  nationalbrokerdata: [],
  nationalstatusdata: [],
  marketdataready: false,
  receivingNationalDashboard: false,
  receivingNationalDashboardready: false,
  servicelinelist: [],
  sourcingmethodologylist: [],
  industrylist: [],
  mainChart: [],
  isReceiving: false,
  cleandataready: false,

  isReceivingProfile: false,
  alloppupdates: [],
  isReceivingNotifications: false,
  NewOppUpdatesReady: false,
  OppMeetingUpdateReady: false,
  OppStatusUpdatesReady: false,
  isReceivingMarketData: false,
  notifications: [],
  meetingDateUpdates: [],
  otherserviceslist: [],
  propertytypelist: [],
  meetingtypelist: [],
  newOppUpdates: [],
  oppStatusUpdates: [],
  marketspecificdata: [],
  practicegrouplist: [],
  marketdata: [],
  dataLastupdated: null,
  notificationsRead: false,
  finaldalist: [],
  finalselist: [],
  finalmarketcodelist: [],
  finalindustries: [],
  finalsourcingmethods: [],
  finalservicelinelist: [],
  finalotherservices: [],
  finaltechlist: [],
  finalbrokerlist: [],
  finalpracticegrouplist: [],
  finalstatuslist: [],
  finalopptypelist: [],
  finalpropertytypelist: [],
  finalDealTypeList: [],
  finalDealSubtypeList: [],
  finalSalesStageList: [],
};

export default function projectsReducer(state = initialData, action) {
  switch (action.type) {
    case RECEIVED_DATA_SUCCESS:
      return {
        ...state,
        cleandata: action.payload.cleandata,
        blist: action.payload.blist,
        oppnamelist: action.payload.oppnamelist,
        oppobjects: action.payload.oppobjectlist,
        servicelinelist: action.payload.servicelinelist,
        cleandataready: true,
        projects: action.payload.projects,
        isReceiving: false,
        dataLastupdated: action.payload.dataLastupdated,
      };
    case RECEIVED_NATIONAL_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        receivingNationalDashboard: false,
        nationallinechartdata: action.payload[0].oppsenteredbydate,
        nationalbrokerdata: action.payload[0].oppsenteredbybroker,
        nationalstatusdata: action.payload[0].oppsbystatuschart,
        receivingNationalDashboardready: true,
      };
    case RECEIVED_MARKET_OPTIONS_SUCCESS:
      return {
        ...state,
        marketoptions: action.payload,
      };
    case RECEIVING_NATIONAL_DASHBOARD_DATA:
      return {
        ...state,
        receivingNationalDashboard: true,
      };
    case CREATE_FILTER_SNAPSHOT:
      return {
        ...state,
        filterSnapshot: action.payload,
      };
    case MARK_NOTICE_AS_READ:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notice) => notice !== action.payload
        ),
      };
    case RECEIVED_USER_MARKET_OPPS:
      return {
        ...state,
        opportunities: action.payload.opportunities,
        blist: action.payload.blist,
        alloppupdates: action.payload.oppupdates,
        opptypelist: action.payload.opptypelist,
        techlist: action.payload.techlist,
        industrylist: action.payload.industrylist,
        oppnamelist: action.payload.oppnamelist,
        dalist: action.payload.dalist,
        selist: action.payload.selist,
        marketcodes: action.payload.marketcodes,
        otherserviceslist: action.payload.otherserviceslist,
        propertytypelist: action.payload.propertytypelist,
        meetingtypelist: action.payload.meetingtypelist,
        sourcingmethodologylist: action.payload.sourcingmethodology,
        servicelinelist: action.payload.servicelinelist,
        practicegrouplist: action.payload.practicegrouplist,
        finaldalist: action.payload.finaldalist,
        finalselist: action.payload.finalselist,
        finalmarketcodelist: action.payload.finalmarketcodelist,
        finalindustries: action.payload.finalindustries,
        finalsourcingmethods: action.payload.finalsourcingmethods,
        finalservicelinelist: action.payload.finalservicelinelist,
        finalotherservices: action.payload.finalotherservices,
        finaltechlist: action.payload.finaltechlist,
        finalbrokerlist: action.payload.finalbrokerlist,
        finalpracticegrouplist: action.payload.finalpracticegrouplist,
        finalstatuslist: action.payload.finalstatuslist,
        finalopptypelist: action.payload.finalopptypelist,
        finalpropertytypelist: action.payload.finalpropertytypelist,
        finalDealTypeList: action.payload.finalDealTypeList,
        finalDealSubtypeList: action.payload.finalDealSubtypeList,
        finalSalesStageList: action.payload.finalSalesStageList,
        receivedUserMarketOpps: true,
      };
    case MARK_NOTICE_AS_READ_SIMPLE:
      return {
        ...state,
        notificationsRead: true,
      };
    case WARNING_OCCURED:
      return {
        ...state,
        warning: action.payload.warning,
      };
    case RECEIVING_PROJECTS:
      return {
        ...state,
        isReceiving: true,
      };
    case RECEIVING_MARKETS:
      return {
        ...state,
        isReceivingMarketData: true,
      };
    case RECEIVING_NOTIFICATIONS:
      return {
        ...state,
        isReceivingNotifications: true,
      };
    case FAILED_USER_MARKET_OPPS:
      return {
        ...state,
        failedUserMarketopps: true,
      };

    case RECEIVED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isReceivingNotifications: false,
        notifications: action.payload,
      };
    case RECEIVING_MEETING_DATE_UPDATES:
      return {
        ...state,
        isReceivingMeetingDateUpdates: true,
      };

    case RECEIVED_MARKET_DATA_SUCCESS:
      return {
        ...state,
        isReceivingMarketData: false,
        marketdataready: true,
        marketdata: action.payload,
      };
    case RECEIVED_MEETING_DATE_UPDATES_SUCCESS:
      return {
        ...state,
        OppMeetingUpdateReady: true,
        meetingDateUpdates: action.payload,
      };
    case RECEIVING_NEW_OPP_UPDATES:
      return {
        ...state,
        isReceivingNewOppUpdates: true,
      };
    case RECEIVED_NEW_OPP_UPDATES_SUCCESS:
      return {
        ...state,
        NewOppUpdatesReady: true,
        newOppUpdates: action.payload,
      };
    case RECEIVING_OPP_STATUS_UPDATES:
      return {
        ...state,
        isReceivingOppStatusUpdates: true,
      };
    case RECEIVED_OPP_STATUS_UPDATES_SUCCESS:
      return {
        ...state,
        OppStatusUpdatesReady: true,
        oppStatusUpdates: action.payload,
      };

    case RECEIVING_DATA:
      return Object.assign({}, state, {
        ...state,
        isReceiving: true,
      });
    case RECEIVING_PROFILE:
      return Object.assign({}, state, {
        ...state,
        isReceivingProfile: true,
      });
    case SET_FILTERED_PROJECTS:
      return {
        ...state,
        marketspecificdata: action.payload,
      };
    case GET_MARKET_SPECIFIC_DATA:
      return {
        ...state,
        marketspecificdata: action.payload.marketdata,
      };
    case SET_CLEAN_DATA:
      return {
        ...state,
        cleandata: action.payload.cleandata,
        marketspecificdata: action.payload.marketdata,
        blist: action.payload.blist,
        oppobjects: action.payload.oppobjectlist,
        oppnamelist: action.payload.oppnamelist,
        cleandataready: true,
      };
    default:
      return state;
  }
}
