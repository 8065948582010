import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Table, Button } from 'reactstrap';

import s from './TableContainer.module.scss';

const states = {
  won: 'success',
  lost: 'danger',
  open: 'info',
  pending: 'warning'
};

function cleanPersonList(person){
  let list =[]
  person.forEach(item => list.push(item.fullname))
  let theperson = list.join(", ")
  return theperson
}
function cleanServiceList(serviceline){
  let list =[]
  serviceline.forEach(item => list.push(item.servicelinename))
  let theservice = list.join(", ")
  return theservice
}

class TableContainer extends PureComponent {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        pursuitname: PropTypes.string,
        sourcingbroker: PropTypes.string,
        broker: PropTypes.string,
        servicelines: PropTypes.string,
        type: PropTypes.string,
        meetingtype: PropTypes.string,
        meetingdate: PropTypes.string,
        pursuitsize: PropTypes.string,
        bdmember: PropTypes.string,
        winloss: PropTypes.string
      }),
    ).isRequired,
  }

  render() {
    const { data } = this.props;

    const keys = Object.keys(data[0]).map(i => i.toUpperCase());
    keys.shift(); // delete "id" key
    return (
      <Table className={cx('mb-0', s.table)} borderless responsive>
        <thead>
          <tr className="text-muted">
            {/* {keys.map((key, index) => (
              index === 0
              ? <th key={key} scope="col"><span className="pl-2">{key}</span></th>
              : <th key={key} scope="col">{key}</th>
            ))} */}
          <td>ID</td>
          <td>Pursuit Name</td>
          <td>Sourcing Broker</td>
          <td>Broker</td>
          <td>Service Lines</td>
          <td>Type</td>
          <td>Meeting Type</td>
          <td>Pursuit Size</td>
          <td>SE Member</td>
          <td>Status</td>
          </tr>
        </thead>
        <tbody className="text-dark">
          {
            data.map(({ id, pursuitname, datecreated, type, sourcingbroker, bdmember, meetingtype, broker, meetingdate, winloss, pursuitsize, servicelines }) => (
                <tr key={id}>
                <td className="pl-3 fw-normal">{ id }</td>
                <td>{ pursuitname }</td>
                <td>{ cleanPersonList(sourcingbroker) }</td>
                <td>{ cleanPersonList(broker) }</td>
                <td>{ cleanServiceList(servicelines) }</td>
                <td>{ type }</td>
                <td>{ meetingtype} </td>
                <td>{ pursuitsize }</td>
                <td>{ cleanPersonList(bdmember) }</td>
                <td>
                  <Button
                    color={ states[winloss.toLowerCase()] }
                    size="xs"
                    className="px-2"
                  >
                    { winloss }
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table>
    );
  }
}

export default TableContainer;
