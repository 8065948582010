import React, { Component } from "react";

import RefreshEmailDetails from "./components/RefreshEmailDetails";
import RefreshEmailList from "./components/components/RefreshEmailList/RefreshEmailList";
import RefreshEmailLogList from "./components/components/RefreshEmailLogs/RefreshEmailLogList";
import CreateEditRefreshCampaign from "./components/components/CreateRefreshCampaign/CreateRefreshCampaign";
import { connect } from "react-redux";
import Widget from "../../components/Widget";
import axios from "axios";

import { Row, Col, Button } from "reactstrap";

class RefreshPortal extends Component {
  constructor(props) {
    super(props);
    this.setCurrentRefreshTemplate = this.setCurrentRefreshTemplate.bind(this);
    this.setCurrentRefreshLog = this.setCurrentRefreshLog.bind(this);
    this.state = {
      fetching: false,
      fetchingError: false,
      errorData: "",
      doneFetching: false,
      currentReportDetails: null,
      refreshemails: [],
      refreshlogs: [],
      doneFetchingLogs: false,
      currentRefreshEmailID: null,
      createReportModal: false,
      editReportModal: false,
      currentRefreshLog: null,
    };
    this.setRefreshLogs = this.setRefreshLogs.bind(this);
    this.receiveNewlyCreatedCampaign =
      this.receiveNewlyCreatedCampaign.bind(this);
    this.getRefreshEmailLogs = this.getRefreshEmailLogs.bind(this);
    this.toggleCreateorEdit = this.toggleCreateorEdit.bind(this);
  }

  getRefreshEmails() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/refreshemails/`).then(
      (res) => {
        let refreshemails = res.data;

        this.setState({
          refreshemails: refreshemails,
          fetching: false,
          doneFetching: true,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          doneFetching: true,
          fetchingError: false,
        });
      }
    );
  }

  setCurrentRefreshTemplate(id) {
    this.setState({
      currentRefreshEmailID: id,
      currentRefreshLog: null,
    });
  }

  setCurrentRefreshLog(id) {
    this.setState({
      currentRefreshLog: id,
    });
  }

  receiveNewlyCreatedCampaign(report) {
    let reports = this.state.refreshemails;
    this.setState({
      currentRefreshEmailID: null,
      refreshemails: [],
    });
    let reportExists = reports.findIndex((item) => item.id === report.id);
    if (reportExists >= 0) {
      reports.splice(reportExists, 1);
      reports.unshift(report);
    } else {
      reports.unshift(report);
    }
    this.setState(
      {
        refreshemails: reports,
      },
      this.setCurrentRefreshTemplate(report.id)
    );
  }

  setRefreshLogs(logs) {
    this.setState({
      refreshlogs: logs,
    });
  }

  toggleCreateorEdit(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  getRefreshEmailLogs(id) {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/refreshemaillogs/?pk=${id}`).then(
      (res) => {
        let sortedLogs = res.data.sort(function (a, b) {
          return new Date(b.datesent) - new Date(a.datesent);
        });
        this.setState({
          refreshlogs: sortedLogs,
          doneFetchingLogs: true,
        });
      },
      (error) => {
        this.setState({
          errorLogsData: error,
          doneFetchingLogs: true,
          fetchingError: false,
        });
      }
    );
  }

  componentDidMount() {
    if (this.props.match.params.refreshid) {
      let id = this.props.match.params.refreshid;
      this.setCurrentRefreshTemplate(id);
    }
    this.getRefreshEmails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.currentRefreshEmailID !== this.state.currentRefreshEmailID &&
      this.state.currentRefreshEmailID
    ) {
      this.setState({
        refreshlogs: [],
        currentRefreshLog: null,
      });
      this.getRefreshEmailLogs(this.state.currentRefreshEmailID);
    }
  }

  render() {
    return (
      <>
        {this.state.refreshemails.length > 0 && this.state.doneFetching ? (
          <Row>
            <Col lg={3}>
              <RefreshEmailList
                refreshemails={this.state.refreshemails}
                currentid={this.state.currentRefreshEmailID}
                setCurrentRefreshTemplate={this.setCurrentRefreshTemplate}
              />
            </Col>
            {this.state.currentRefreshEmailID && this.state.doneFetchingLogs ? (
              <Col lg={3}>
                <RefreshEmailLogList
                  token={this.props.token}
                  currentid={this.state.currentRefreshLog}
                  refreshlogs={this.state.refreshlogs}
                  setCurrentRefreshLog={this.setCurrentRefreshLog}
                />
              </Col>
            ) : null}
            {this.state.currentRefreshEmailID ? (
              <Col lg={6}>
                <RefreshEmailDetails
                  toggleCreateorEdit={this.toggleCreateorEdit}
                  setRefreshLogs={this.setRefreshLogs}
                  getRefreshEmailLogs={this.getRefreshEmailLogs}
                  currentRefreshEmailID={this.state.currentRefreshEmailID}
                  refreshlogs={this.state.refreshlogs}
                  currentRefreshLog={this.state.currentRefreshLog}
                  refreshemails={this.state.refreshemails}
                  receiveNewlyCreatedCampaign={this.receiveNewlyCreatedCampaign}
                />
              </Col>
            ) : (
              <>
                <Col lg={6}>
                  <Row>
                    <Col lg={8}>
                      <h2 className="text-muted no-margin">
                        Refresh campaign details:
                      </h2>
                    </Col>
                    <Col lg={4}>
                      <Button
                        color="primary"
                        className="float-right"
                        onClick={() =>
                          this.toggleCreateorEdit("createReportModal")
                        }
                      >
                        Create Campaign
                      </Button>
                    </Col>
                  </Row>
                  <Widget bodyClass={"p-0 extrapadding"}>
                    <div className="widget">
                      <span>
                        Select a campaign from the table found on the left side
                        of this page to see campaign information.
                      </span>
                    </div>
                  </Widget>
                </Col>
              </>
            )}
          </Row>
        ) : (
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={8}>
                  <h2 className="text-muted no-margin">
                    Refresh campaign details:
                  </h2>
                </Col>
                <Col lg={4}>
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={() => this.toggleCreateorEdit("createReportModal")}
                  >
                    Create Campaign
                  </Button>
                </Col>
              </Row>
              <Widget bodyClass={"p-0 extrapadding"}>
                <div className="widget">
                  <span>
                    You are not currently part of any Refresh Campaigns. Please
                    create one now.
                  </span>
                </div>
              </Widget>
            </Col>
          </Row>
        )}
        <CreateEditRefreshCampaign
          token={this.props.token}
          receiveNewlyCreatedCampaign={this.receiveNewlyCreatedCampaign}
          refreshemails={this.state.refreshemails}
          currentRefreshEmailID={this.state.currentRefreshEmailID}
          toggleCreateorEdit={this.toggleCreateorEdit}
          createReportModal={this.state.createReportModal}
          editReportModal={this.state.editReportModal}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    useraccount: state.authentication.userprofile,
  };
}

export default connect(mapStateToProps)(RefreshPortal);
