import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";

export default class BrokerPerformance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
    };
    this.Performance = React.createRef()
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  handleclick(event){
    this.setState({
      test: event
    })
  }


  chartData = () => {
    const { data, brokerName } = this.props;

    let dataobject = {
      wins: 0,
      losses: 0,
      open: 0
    }

    data.forEach(item =>{
      if((item.winloss).toLowerCase() === "won"){
        dataobject.wins += 1
      }else if((item.winloss).toLowerCase() === "lost"){
        dataobject.losses += 1
      }else{
        dataobject.open += 1
      }
    })


    //Begin creation of chart data
    let categories = [brokerName]
    let series = [
      {
      name: 'Wins',
      data:[],
      color: '#21AE8C',
      },
      {
      name: 'Losses',
      data: [],
      color: '#FD5F00',
      },
      {
      name: 'Open',
      data:[],
      color: '#005792',
        }
      ]


    series.forEach(item => {
      if(item.name === 'Wins'){
        item.data.push(dataobject.wins)
      }else if(item.name === 'Losses'){
        item.data.push(dataobject.losses)
      }else if(item.name === 'Open'){
        item.data.push(dataobject.open)
      }
    })


    return {
      ...this.chartOptions,
      series: series,
      xAxis: {
        min: 0,
        max: 0,
        scrollbar: {
            enabled: false
        },
        tickLength: 0,
        categories: categories,
    },
    }
  };

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 200,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'bar'
    },
    title: {
      text: null
    },
    exporting: {
      enabled: false,
      chartOptions:{
               title: {
                   text: null
               }
           }
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },

    annotations: null,
    plotOptions: {
    series: {
    	stacking:'normal',
      point: {
        events: {
          click() {
            console.log(this.series.name)
            console.log(this.category)
          }
        }
      }
    }
  },
  };



  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Opportunity<span className="fw-semi-bold"> Status</span>
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact ref={this.Performance} highcharts={Highcharts} options={this.chartData()} />

      </Widget>
    );
  }
}
