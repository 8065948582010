import React, { useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import Divider from "@material-ui/core/Divider";
import SAChart from "./Components/SAChart";
import { useSelector } from "react-redux";
import { DateRange } from "react-date-range";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateModal from "./Components/DateModal";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const BrokerAnalysis = (props) => {
  const [wrLine, setWRLine] = React.useState(0);
  const [volLine, setVolLine] = React.useState(0);
  const [marketList, setMarketList] = React.useState([]);
  const [brokerData, setBrokerData] = React.useState([]);
  const [typeFilter, setTypeFilter] = React.useState([]);
  const [propertyTypeFilter, setPropertyTypeFilter] = React.useState([]);
  const [brokerFilter, setBrokerFilter] = React.useState([]);
  const [dateModalOpen, setDateModalOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({
    startDate: moment("01-01-2018", "MM-DD-YYYY"),
    endDate: moment().endOf("year"),
    key: "selection",
  });

  const projects = useSelector((state) => state.projects);
  let opportunities = projects.opportunities;
  let marketOptions = projects.marketoptions;
  let brokerOptions = projects.finalbrokerlist;
  let oppTypeList = projects.opptypelist;
  let propertyTypeList = projects.propertytypelist;

  function dateRangeFilter(type) {
    if (type === "All Time") {
      setDateRange({
        startDate: moment("01-01-2018", "MM-DD-YYYY"),
        endDate: moment().endOf("year"),
      });
    } else if (type === "Last Year") {
      setDateRange({
        startDate: moment().subtract(1, "year").startOf("year"),
        endDate: moment().subtract(1, "year").endOf("year"),
      });
    } else if (type === "This Year") {
      setDateRange({
        startDate: moment().startOf("year"),
        endDate: moment().endOf("year"),
      });
    }
    setDateModalOpen(false);
  }

  function filterOpportunities() {
    let brokerList = [];
    let filteredOpps = opportunities.filter(
      (item) =>
        moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(
          dateRange.startDate
        ) &&
        moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(
          dateRange.endDate
        )
    );
    if (marketList.length > 0) {
      filteredOpps = filteredOpps.filter((opp) =>
        opp.market.some((market) =>
          marketList.some((m2) => m2.name === market.marketname)
        )
      );
    }
    if (brokerFilter.length > 0) {
      filteredOpps = filteredOpps.filter((item) =>
        brokerFilter.every(
          (broker) =>
            item.sourcingbroker.every((sb) => sb.fullname !== broker.name) &&
            item.broker.every((b) => b.fullname !== broker.name)
        )
      );
    }
    if (typeFilter.length > 0) {
      filteredOpps = filteredOpps.filter((opp) =>
        typeFilter.some((type) => type == opp.type)
      );
    }
    if (propertyTypeFilter.length > 0) {
      filteredOpps = filteredOpps.filter((opp) =>
        propertyTypeFilter.some((pt) => pt == opp.propertytype)
      );
    }

    filteredOpps.forEach((opp) => {
      let brokerTeam = [...opp.sourcingbroker, ...opp.broker];
      brokerTeam.forEach((broker) => {
        let foundBroker = brokerList.find(
          (existingBroker) => existingBroker.name === broker.fullname
        );
        if (foundBroker) {
          if (opp.winloss === "Won") {
            foundBroker.winCount += 1;
            foundBroker.totalCount += 1;
          } else if (opp.winloss === "Lost") {
            foundBroker.lossCount += 1;
            foundBroker.totalCount += 1;
          } else {
            foundBroker.totalCount += 1;
          }
        } else {
          let brokerObject = {
            name: broker.fullname,
            winCount: opp.winloss === "Won" ? 1 : 0,
            lossCount: opp.winloss === "Lost" ? 1 : 0,
            totalCount: 1,
          };
          brokerList.push(brokerObject);
        }
      });
    });
    brokerList = brokerList?.map((broker) => ({
      name: broker.name,
      win_rate:
        broker.winCount / (broker.winCount + broker.lossCount)
          ? broker.winCount / (broker.winCount + broker.lossCount)
          : 0,
      opp_count: broker.totalCount,
    }));
    let averageWR =
      (brokerList.reduce((total, next) => total + next.win_rate, 0) /
        brokerList.length) *
      100;
    let averageOppCount =
      brokerList.reduce((total, next) => total + next.opp_count, 0) /
      brokerList.length;
    setWRLine(averageWR);
    setVolLine(averageOppCount);
    setBrokerData(brokerList);
  }
  const submitInput = () => {
    filterOpportunities();
  };

  return (
    <>
      <div>
        <Row>
          <Col
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "4px",
            }}
            lg={12}
          >
            <h2 className="scorecardHeader">Seller Analysis</h2>
            <Divider />
          </Col>
        </Row>
        <Row>
          {/* Input */}
          <Col
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "4px",
              // maxWidth: "430px",
            }}
            sm={4}
          >
            <h2 className="mb-lg">Input</h2>
            <div className="mb-md">Select date range:</div>
            <div
              className="dateRangeFilter mb-lg"
              onClick={(e) => setDateModalOpen(true)}
            >
              <span>{`${moment(dateRange.startDate).format(
                "MMM Do, YYYY"
              )} - ${moment(dateRange.endDate).format("MMM Do, YYYY")}`}</span>
            </div>
            <div className="mb-md">Select market:</div>
            <Autocomplete
              multiple
              id="tags-outlined"
              className="mb-md"
              options={marketOptions}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setMarketList(newValue);
              }}
              value={marketList}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="All markets"
                />
              )}
            />
            <div className="mb-md">Select brokers to exclude:</div>
            <Autocomplete
              multiple
              id="tags-outlined"
              className="mb-md"
              options={brokerOptions}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                setBrokerFilter(newValue);
              }}
              value={brokerFilter}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="All Sellers"
                />
              )}
            />
            <div className="mb-md">Select opportunity types to include:</div>
            <Autocomplete
              multiple
              id="tags-outlined"
              className="mb-md"
              options={oppTypeList}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setTypeFilter(newValue);
              }}
              value={typeFilter}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="All Opportunity Types"
                />
              )}
            />
            <div className="mb-md">Select property types to include:</div>
            <Autocomplete
              multiple
              id="tags-outlined"
              className="mb-md"
              options={propertyTypeList}
              getOptionLabel={(option) => option}
              onChange={(event, newValue) => {
                setPropertyTypeFilter(newValue);
              }}
              value={propertyTypeFilter}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="All Property Types"
                />
              )}
            />
            <Row>
              <Col sm={6}>
                <div className="mb-md">
                  Select Y Axis (default is avg. Win Rate):
                </div>
                <TextField
                  id="outlined-number"
                  type="number"
                  value={wrLine}
                  onChange={(e) => setWRLine(e.target.value)}
                  placeholder="Set Y Axis"
                  variant="outlined"
                />
              </Col>
              <Col sm={6}>
                <div className="mb-md">
                  Select X Axis (default is avg. Volume):
                </div>

                <TextField
                  id="outlined-number"
                  type="number"
                  value={volLine}
                  onChange={(e) => setVolLine(e.target.value)}
                  placeholder="Set X Axis"
                  variant="outlined"
                />
              </Col>
            </Row>
            <Button
              onClick={(e) => submitInput()}
              style={{ color: "#fff", marginTop: "5%" }}
            >
              Submit
            </Button>
          </Col>
          <Col
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "4px",
            }}
            // sm={7}
          >
            <SAChart
              brokerData={brokerData}
              wrLine={wrLine}
              volLine={volLine}
            />
          </Col>
        </Row>
        <DateModal
          dateRangeFilter={dateRangeFilter}
          dateModalOpen={dateModalOpen}
          setDateModalOpen={setDateModalOpen}
          dateRange={dateRange}
          setDateRange={setDateRange}
        />
      </div>
    </>
  );
};

export default BrokerAnalysis;
