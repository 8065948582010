import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment/moment";
import Widget from "../../components/Widget/Widget";
import s from "./Profile.module.scss";
import Geocode from "react-geocode";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Report from "./components/oppreport";
import Loading from "./components/loading";
import { Table } from "reactstrap";
import axios from "axios";

class OpportunityCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunityid: "",
      dataready: false,
      opportunityobject: {},
      sourcingbroker: "",
      brokerteam: "",
      oppclientaddress: "",
      sourcingbrokerarray: [],
      brokerteamarray: [],
      latitude: "",
      longitude: "",
      otherclientprojects: [],
      bdmembers: [],
      relevantSurveys: [],
      debriefResults: [],
      pdf: false,
    };
    this.printComponent = React.createRef();
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  getFormattedDate = (date) => {
    return moment(date, "MM-DD-YYYY hh:mm:ss").format("MMM DD, YYYY");
  };

  cleanPersonList(person) {
    let list = [];
    person.forEach((item) => list.push(item.fullname));
    let theperson = list.join(", ");
    return theperson;
  }

  cleanServiceList(serviceline) {
    let list = [];
    serviceline.forEach((item) => list.push(item.servicelinename));
    let theservice = list.join(", ");
    return theservice;
  }

  cleanPersonArray(person) {
    let list = [];
    person.forEach((item) => list.push(item.fullname));
    return list;
  }

  generateDownload() {
    this.setState({
      pdf: true,
    });
  }

  retreiveOppInfo(opportunityobject) {
    let opportunityid = opportunityobject.opportunityid;
    let oppclientaddress = "";
    let sourcingbroker = "";
    let brokerteam = "";
    let bdmembers = [];
    let sourcingbrokerarray = [];
    let brokerteamarray = [];
    let otherclientprojects = [];
    let relevantSurveys = [];

    if (
      !(
        Object.keys(opportunityobject).length === 0 &&
        opportunityobject.constructor === Object
      )
    ) {
      sourcingbroker = this.cleanPersonList(opportunityobject.sourcingbroker);
      brokerteam = this.cleanPersonList(opportunityobject.broker);
      sourcingbrokerarray = this.cleanPersonArray(
        opportunityobject.sourcingbroker
      );
      brokerteamarray = this.cleanPersonArray(opportunityobject.broker);
      // relevantSurveys = this.props.surveys.filter(item => item.project.opportunityid === opportunityid)
      bdmembers = opportunityobject.bdmember;
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };

    axios.get(`/api/v1/debriefresults/${opportunityid}`).then((res) => {
      console.log(res.data);
      this.setState({
        debriefResults: res.data,
      });
    });

    this.setState({
      oppclientaddress: oppclientaddress,
      sourcingbroker: sourcingbroker,
      sourcingbrokerarray: sourcingbrokerarray,
      brokerteamarray: brokerteamarray,
      otherclientprojects: otherclientprojects,
      bdmembers: bdmembers,
      dataready: true,
      // relevantSurveys: relevantSurveys
    });
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  getIDfromName(oppname) {
    let oppid = "";
    let matchingopp = this.props.projects.find(
      (item) => item.pursuitname === oppname
    );
    if (!(matchingopp === undefined)) {
      oppid = matchingopp.opportunityid;
    }
    return oppid;
  }

  redirectToDashboard() {
    this.props.history.push("/app/dashboard");
  }

  findOpp() {
    if (this.props.keyopportunity) {
      if (!(this.props.keyopportunity === undefined)) {
        this.setState(
          {
            opportunityobject: this.props.keyopportunity,
          },
          this.retreiveOppInfo(this.props.keyopportunity)
        );
      }
    } else {
      let opportunityid = this.state.opportunityid;
      let opportunityobject = this.props.opportunities.find(
        (item) => opportunityid === item.opportunityid
      );
      if (!(opportunityobject === undefined)) {
        this.setState(
          {
            opportunityobject: opportunityobject,
          },
          this.retreiveOppInfo(opportunityobject)
        );
      }
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.opportunityid !== prevState.opportunityid) {
      if (!(this.state.opportunityid === "")) {
        this.findOpp();
      }
    }
  };

  componentWillReceiveProps({ oppid }) {
    this.setState({
      opportunityid: oppid,
    });
  }

  componentDidMount() {
    this.setState({
      opportunityid: this.props.oppid,
    });
  }

  render() {
    const {
      projects,
      surveys,
      cleandata,
      blist,
      oppobjects,
      user,
      marketdata,
    } = this.props;
    const {
      opportunityID,
      opportunityobject,
      sourcingbroker,
      brokerteam,
      oppclientaddress,
      sourcingbrokerarray,
      brokerteamarray,
      opportunityId,
      alerts,
    } = this.state;

    let brokerDropdownOptions = [];

    let markets = [];

    for (var o in user.groups) {
      markets.push(user.groups[o].name);
    }

    blist.forEach((item) => {
      let newitem = {
        value: item,
        label: item,
      };
      brokerDropdownOptions.push(newitem);
    });

    return (
      <div>
        <div className={s.root}>
          {this.state.pdf && (
            <div>
              <PDFDownloadLink
                document={<Report object={opportunityobject} />}
                fileName="somename.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download now!"
                }
              </PDFDownloadLink>
            </div>
          )}
          {Object.keys(opportunityobject).length > 0 &&
          opportunityobject.constructor === Object &&
          this.state.dataready ? (
            <div>
              <Row>
                <Col lg={6} xs={12}>
                  <Widget>
                    <div className="widget-top-overflow text-white profileBorderBottom">
                      <div className="height-150 overflow-hidden bg-primary">
                        {opportunityobject.confidential ? (
                          <div className="confidentialBadge">Confidential</div>
                        ) : null}
                        {opportunityobject.servicelines.length > 0 ? (
                          <div className="serviceLineBadge">
                            {opportunityobject.servicelines[0].servicelinename}
                          </div>
                        ) : null}

                        <h3 className="fw-normal opp-header">
                          <span>{opportunityobject.pursuitname}</span>
                        </h3>
                      </div>
                    </div>
                    <Row>
                      <Col
                        md={6}
                        xs={12}
                        className="text-left profileBorderRight"
                      >
                        <div className="mt-3">
                          <div className="mt-lg">
                            <h5 className="lead mt-lg">
                              Opportunity{" "}
                              <span className="fw-semi-bold"> Information</span>
                            </h5>
                            <ul className={cx(s.profileContacts, "mt-sm")}>
                              {opportunityobject.opportunityid ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Opportunity ID:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {opportunityobject.opportunityid}
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.datecreated ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Date Created:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {this.getFormattedDate(
                                      opportunityobject.datecreated
                                    )}
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.meetingtype ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Meeting Type:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {opportunityobject.meetingtype}
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.type ? (
                                <li>
                                  <span className="fw-semi-bold">Type: </span>
                                  <span className="opportunityDetailRight">
                                    {opportunityobject.type}
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.propertytype ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Property Type:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {opportunityobject.propertytype}
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.meetingdate ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Meeting Date:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {this.getFormattedDate(
                                      opportunityobject.meetingdate
                                    )}
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.sourcingmethodology.length >
                              0 ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Sourcing Methodology:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {
                                      opportunityobject.sourcingmethodology[0]
                                        .methodname
                                    }
                                  </span>
                                </li>
                              ) : null}
                              {opportunityobject.pursuitsize ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Pursuit Size:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {this.formatNumber(
                                      opportunityobject.pursuitsize
                                    )}{" "}
                                    sf
                                  </span>
                                </li>
                              ) : null}

                              {opportunityobject.numberofmeetings > 0 ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Number of Meetings:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {opportunityobject.numberofmeetings}{" "}
                                  </span>
                                </li>
                              ) : null}

                              <li>
                                <span className="fw-semi-bold">
                                  Inbound Business:{" "}
                                </span>
                                <span className="opportunityDetailRight">
                                  {opportunityobject.inboundbusiness.toString()}
                                </span>
                              </li>

                              {opportunityobject.oppnotes &&
                              opportunityobject.oppnotes !== "0" ? (
                                <li>
                                  <span className="fw-semi-bold">
                                    Description:{" "}
                                  </span>
                                  <span className="opportunityDetailRight">
                                    {opportunityobject.oppnotes}{" "}
                                  </span>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                        </div>
                      </Col>
                      <Col md={6} xs={12}>
                        <div>
                          {opportunityobject.servicelines.length > 0 ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Opportunity </span>
                              Service Line
                            </h5>
                          ) : null}
                          <ul>
                            {opportunityobject.servicelines.length > 0 ? (
                              <li>
                                {
                                  opportunityobject.servicelines[0]
                                    .servicelinename
                                }
                              </li>
                            ) : null}
                          </ul>
                          {opportunityobject.market.length > 0 ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Opportunity </span>
                              Market
                            </h5>
                          ) : null}
                          <ul>
                            {opportunityobject.market.length > 0 ? (
                              <li>{opportunityobject.market[0].marketname}</li>
                            ) : null}
                          </ul>
                          {this.state.sourcingbrokerarray.length ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Primary </span>{" "}
                              Broker
                            </h5>
                          ) : null}
                          <ul>
                            {sourcingbrokerarray.map((member, index) => (
                              <li>
                                <Link
                                  to={
                                    "/app/reporting/brokerscorecard/" + member
                                  }
                                  className="btn-link"
                                >
                                  {member}
                                </Link>
                              </li>
                            ))}
                          </ul>
                          {this.state.brokerteamarray.length ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Broker </span>Team
                            </h5>
                          ) : null}
                          <ul>
                            {brokerteamarray.map((member, index) => (
                              <li>
                                <Link
                                  to={
                                    "/app/reporting/brokerscorecard/" + member
                                  }
                                  className="btn-link"
                                >
                                  {member}
                                </Link>
                              </li>
                            ))}
                          </ul>
                          {this.state.bdmembers.length ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">
                                Sales Enablement{" "}
                              </span>
                              Members
                            </h5>
                          ) : null}
                          <ul>
                            {this.state.bdmembers.map((member, index) => {
                              if (
                                member.role &&
                                member.role[0].rolename ==
                                  "Sales Enablement Member"
                              ) {
                                return (
                                  <li key={index}>
                                    <button className="btn-link">
                                      {member.fullname}
                                    </button>
                                  </li>
                                );
                              } else {
                                return "None set...";
                              }
                            })}
                          </ul>

                          {this.state.bdmembers.length ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Tech </span>Advisor
                            </h5>
                          ) : null}
                          <ul>
                            {this.state.bdmembers.map((member, index) => {
                              if (
                                member.role &&
                                member.role[0].rolename == "Digital Advisor"
                              ) {
                                return (
                                  <li key={index}>
                                    <button className="btn-link">
                                      {member.fullname}
                                    </button>
                                  </li>
                                );
                              }
                            })}
                          </ul>

                          {opportunityobject.incumbent ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Incumbent</span>
                            </h5>
                          ) : null}
                          <ul>
                            {opportunityobject.incumbent.map(
                              (company, index) => (
                                <li>{company.companyname}</li>
                              )
                            )}
                          </ul>

                          {opportunityobject.competitor &&
                          opportunityobject.competitor.length > 0 ? (
                            <h5 className="lead mt-lg">
                              <span className="fw-semi-bold">Competitors</span>
                            </h5>
                          ) : null}
                          {opportunityobject.competitor &&
                          opportunityobject.competitor.length > 0 ? (
                            <ul>
                              {opportunityobject.competitor.map(
                                (company, index) => (
                                  <li>{company.companyname}</li>
                                )
                              )}
                            </ul>
                          ) : null}

                          {opportunityobject.otherJllServices.length > 0 ? (
                            <h5 className="lead mt-lg">
                              Other{" "}
                              <span className="fw-semi-bold">Jll Services</span>
                            </h5>
                          ) : null}
                          <ul>
                            {opportunityobject.otherJllServices.map(
                              (service, index) => (
                                <li>{service.servicename}</li>
                              )
                            )}
                          </ul>

                          {opportunityobject.techused.length > 0 ? (
                            <h5 className="lead mt-lg">
                              Tech{" "}
                              <span className="fw-semi-bold"> Tools Used</span>
                            </h5>
                          ) : null}

                          <ul>
                            {opportunityobject.techused.map((tool, index) => (
                              <li>{tool.techname}</li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Widget>
                </Col>
                <Col lg={6} xs={12}>
                  <Row>
                    <Col xs={12} xl={6} lg={6} md={4}>
                      <div className="pb-xlg h-100">
                        <Widget
                          className={
                            "mb-0 h-100" + opportunityobject.winloss ===
                            "Best Team Review"
                              ? "border-left-danger"
                              : opportunityobject.winloss === "Lost"
                              ? "border-left-danger"
                              : opportunityobject.winloss === "Won"
                              ? "border-left-success"
                              : opportunityobject.winloss === "Open"
                              ? "border-left-primary"
                              : opportunityobject.winloss === "Pending"
                              ? "border-left-primary"
                              : null
                          }
                          close
                          bodyClass="mt-lg"
                          title={
                            <h5>
                              Pursuit
                              <span className="fw-semi-bold"> Status</span>
                            </h5>
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center mb h3">
                            <h2 style={{ fontSize: "1.5rem" }}>
                              {opportunityobject.winloss}
                            </h2>
                          </div>
                        </Widget>
                      </div>
                    </Col>
                    <Col xs={12} xl={6} lg={6} md={4}>
                      <div className="pb-xlg h-100">
                        <Widget
                          className={"mb-0 h-100"}
                          close
                          bodyClass="mt-lg"
                          title={
                            <h5>
                              Pursuit<span className="fw-semi-bold"> Size</span>{" "}
                            </h5>
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center mb h3">
                            <h2 style={{ fontSize: "1.5rem" }}>
                              {this.formatNumber(opportunityobject.pursuitsize)}{" "}
                              sf
                            </h2>
                          </div>
                        </Widget>
                      </div>
                    </Col>

                    <Col xs={12} xl={6} lg={6} md={4}>
                      <div className="pb-xlg h-100">
                        <Widget
                          className={"mb-0 h-100"}
                          close
                          bodyClass="mt-lg"
                          title={
                            <h5>
                              Pursuit<span className="fw-semi-bold"> Type</span>
                            </h5>
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center mb h3">
                            {opportunityobject.type ? (
                              <h2 style={{ fontSize: "1.5rem" }}>
                                {opportunityobject.type}
                              </h2>
                            ) : (
                              <h2 style={{ fontSize: "1.5rem" }}>
                                None set...
                              </h2>
                            )}
                          </div>
                        </Widget>
                      </div>
                    </Col>
                    <Col xs={12} xl={6} lg={6} md={4}>
                      <div className="pb-xlg h-100">
                        <Widget
                          className={"mb-0 h-100"}
                          close
                          bodyClass="mt-lg"
                          title={
                            <h5>
                              Pursuit
                              <span className="fw-semi-bold"> Industry</span>{" "}
                            </h5>
                          }
                        >
                          <div className="d-flex justify-content-between align-items-center mb h3">
                            {opportunityobject.clientcompany.length > 0 &&
                            opportunityobject.clientcompany[0]
                              .clientindustry ? (
                              <h2 style={{ fontSize: "1.5rem" }}>
                                {
                                  opportunityobject.clientcompany[0]
                                    .clientindustry
                                }
                              </h2>
                            ) : (
                              <h2 style={{ fontSize: "1.5rem" }}>
                                None set...
                              </h2>
                            )}
                          </div>
                        </Widget>
                      </div>
                    </Col>
                  </Row>

                  {opportunityobject.clientcompany ? (
                    <Row>
                      <Col xs={12} xl={12} md={12}>
                        <Widget className="mb-0 h-200" close bodyClass="mt-lg">
                          <Row>
                            <Col md={12} xs={12}>
                              <div className="mt-3">
                                <div className="mt-lg">
                                  <h5 className=" mt-lg lead">
                                    Client{" "}
                                    <span className="fw-semi-bold">
                                      {" "}
                                      Information
                                    </span>
                                  </h5>
                                  <ul
                                    className={cx(s.profileContacts, "mt-sm")}
                                  >
                                    {opportunityobject.clientcompany[0]
                                      .clientconame ? (
                                      <li>
                                        <span className="fw-semi-bold">
                                          Client Name:{" "}
                                        </span>{" "}
                                        {
                                          opportunityobject.clientcompany[0]
                                            .clientconame
                                        }
                                      </li>
                                    ) : null}
                                    {opportunityobject.clientcompany[0]
                                      .clientindustry ? (
                                      <li>
                                        <span className="fw-semi-bold">
                                          Industry:{" "}
                                        </span>
                                        {
                                          opportunityobject.clientcompany[0]
                                            .clientindustry
                                        }
                                      </li>
                                    ) : null}
                                    {opportunityobject.clientcompany[0]
                                      .clientcontactname ? (
                                      <li>
                                        <span className="fw-semi-bold">
                                          Client Contact:{" "}
                                        </span>
                                        {
                                          opportunityobject.clientcompany[0]
                                            .clientcontactname
                                        }
                                      </li>
                                    ) : null}
                                    {opportunityobject.clientcompany[0]
                                      .clientcontacttitle ? (
                                      <li>
                                        <span className="fw-semi-bold">
                                          Contact Title:{" "}
                                        </span>
                                        {
                                          opportunityobject.clientcompany[0]
                                            .clientcontacttitle
                                        }
                                      </li>
                                    ) : null}
                                  </ul>
                                  {this.state.otherclientprojects > 0 ? (
                                    <p className="lead mt-lg">
                                      Other projects with client
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Widget>
                      </Col>
                    </Row>
                  ) : null}

                  {this.state.debriefResults?.length > 0 ? (
                    <Row>
                      <Col xs={12} xl={12} md={12}>
                        <Widget className="mb-0 h-200" close bodyClass="mt-lg">
                          <Row>
                            <Col md={12} xs={12}>
                              <div className="mt-3">
                                <div className="mt-lg">
                                  <h5 className=" mt-lg lead">
                                    Opportunity{" "}
                                    <span className="fw-semi-bold">
                                      {" "}
                                      Debrief
                                    </span>
                                  </h5>
                                  <ul
                                    className={cx(s.profileContacts, "mt-sm")}
                                  >
                                    <li>
                                      <span className="fw-semi-bold">
                                        # Debrief surveys sent:{" "}
                                      </span>
                                      {this.state.debriefResults.length}
                                    </li>
                                    <li>
                                      <span className="fw-semi-bold">
                                        # Debrief surveys answered:{" "}
                                      </span>
                                      {
                                        this.state.debriefResults.filter(
                                          (survey) => survey.survey_answered
                                        ).length
                                      }
                                    </li>
                                    <li>
                                      <span className="fw-semi-bold">
                                        Can we share the pursuit?:{" "}
                                      </span>
                                      {this.state.debriefResults.includes(
                                        (survey) =>
                                          survey.survey_recipient_type ===
                                            "Broker" && survey.can_share
                                      )
                                        ? "Yes"
                                        : "No"}
                                    </li>
                                    <li>
                                      <span className="fw-semi-bold">
                                        Date sent:{" "}
                                      </span>
                                      {moment(
                                        this.state.debriefResults[0]
                                          ?.date_created
                                      ).format("MM-DD-YYYY")}
                                    </li>
                                  </ul>
                                  <Table>
                                    <thead>
                                      <tr>
                                        <th>Recipient</th>
                                        <th>What improvements?</th>
                                        <th>Can share pitch?</th>
                                        <th>Reasons why</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.debriefResults.map(
                                        (survey, ind) => {
                                          let reasons = JSON.parse(
                                            survey.raw_answers
                                          )?.reasonsWhy;
                                          reasons = reasons?.sort();

                                          return (
                                            <tr key={"deb-" + ind}>
                                              <th scope="row">
                                                {survey?.recipient?.fullname}
                                              </th>
                                              <td>
                                                {survey.survey_answered ? (
                                                  <>
                                                    {survey.what_improvements}
                                                  </>
                                                ) : (
                                                  "Not answered"
                                                )}
                                              </td>
                                              <td>
                                                {survey.survey_answered ? (
                                                  <>
                                                    {survey.can_share
                                                      ? "Yes"
                                                      : "No"}{" "}
                                                  </>
                                                ) : (
                                                  "Not answered"
                                                )}
                                              </td>
                                              <td>
                                                {survey.survey_answered ? (
                                                  <>
                                                    {reasons
                                                      ? reasons.map(
                                                          (reason) => {
                                                            return (
                                                              <div>
                                                                {reason.name} -{" "}
                                                                {reason.weight}
                                                              </div>
                                                            );
                                                          }
                                                        )
                                                      : "No reasons given..."}{" "}
                                                  </>
                                                ) : (
                                                  "Not answered"
                                                )}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Widget>
                      </Col>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.userdata,
    marketdata: state.projects.marketdata,
    cleandata: state.projects.cleandata,
    oppobjects: state.projects.oppobjects,
    surveys: state.projects.surveys,
    projects: state.projects.projects,
    blist: state.projects.blist,
  };
}

export default connect(mapStateToProps)(OpportunityCard);
