import React, { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";

var moment = require("moment");

class ToggleCharts extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Row className="mb-3 mx-auto">
          <Col xs={12} xl={12} lg={12} md={12}>
            <div>
              Check/Uncheck boxes below to show/hide elements on the dashboard.
            </div>
          </Col>
        </Row>

        <Row className="mx-auto">
          <Col xs={4} xl={4} lg={4} md={4}>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderDefaultStats"
                type="checkbox"
                checked={this.props.dashboardState.RenderDefaultStats}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderDefaultStats")
                }
              />{" "}
              <Label for="RenderDefaultStats" check>
                Top Stats Row
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderBrokerPerf"
                type="checkbox"
                checked={this.props.dashboardState.RenderBrokerPerf}
                onChange={(e) => this.props.toggleCharts(e, "RenderBrokerPerf")}
              />{" "}
              <Label for="RenderBrokerPerf" check>
                Broker Performance Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderDonut"
                type="checkbox"
                checked={this.props.dashboardState.RenderDonut}
                onChange={(e) => this.props.toggleCharts(e, "RenderDonut")}
              />{" "}
              <Label for="RenderDonut" check>
                Opportunity Status Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderLocationsMap"
                type="checkbox"
                checked={this.props.dashboardState.RenderLocationsMap}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderLocationsMap")
                }
              />{" "}
              <Label for="RenderLocationsMap" check>
                Opp. Location Map
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderTableData"
                type="checkbox"
                checked={this.props.dashboardState.RenderTableData}
                onChange={(e) => this.props.toggleCharts(e, "RenderTableData")}
              />{" "}
              <Label for="RenderTableData" check>
                Opportunity Table
              </Label>
            </FormGroup>

            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderMonthOverMonthMeeting"
                type="checkbox"
                checked={this.props.dashboardState.RenderMonthOverMonthMeeting}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderMonthOverMonthMeeting")
                }
              />{" "}
              <Label for="RenderMonthOverMonthMeeting" check>
                Meeting Count Monthly
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderTechUsedAverage"
                type="checkbox"
                checked={this.props.dashboardState.RenderTechUsedAverage}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderTechUsedAverage")
                }
              />{" "}
              <Label for="RenderTechUsedAverage" check>
                Tech Used/Number of Tools
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderYearOverYearOpps"
                type="checkbox"
                checked={this.props.dashboardState.RenderYearOverYearOpps}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderYearOverYearOpps")
                }
              />{" "}
              <Label for="RenderYearOverYearOpps" check>
                YoY Opps Entered
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderYearOverYearWinRate"
                type="checkbox"
                checked={this.props.dashboardState.RenderYearOverYearWinRate}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderYearOverYearWinRate")
                }
              />{" "}
              <Label for="RenderYearOverYearWinRate" check>
                YoY Winrate
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} xl={4} lg={4} md={4}>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderTechTools"
                type="checkbox"
                checked={this.props.dashboardState.RenderTechTools}
                onChange={(e) => this.props.toggleCharts(e, "RenderTechTools")}
              />{" "}
              <Label for="RenderTechTools" check>
                Tech Tools Used
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderSourcingMethodChart"
                type="checkbox"
                checked={this.props.dashboardState.RenderSourcingMethodChart}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderSourcingMethodChart")
                }
              />{" "}
              <Label for="RenderSourcingMethodChart" check>
                Sourcing Methodology Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderIndustryChart"
                type="checkbox"
                checked={this.props.dashboardState.RenderIndustryChart}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderIndustryChart")
                }
              />{" "}
              <Label for="RenderIndustryChart" check>
                Industry Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderServiceLineChart"
                type="checkbox"
                checked={this.props.dashboardState.RenderServiceLineChart}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderServiceLineChart")
                }
              />{" "}
              <Label for="RenderServiceLineChart" check>
                Service Line Chart (Month)
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderDayChartSL"
                type="checkbox"
                checked={this.props.dashboardState.RenderDayChartSL}
                onChange={(e) => this.props.toggleCharts(e, "RenderDayChartSL")}
              />{" "}
              <Label for="RenderDayChartSL" check>
                Service Line Chart (Day)
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderWeekOverWeekMeeting"
                type="checkbox"
                checked={this.props.dashboardState.RenderWeekOverWeekMeeting}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderWeekOverWeekMeeting")
                }
              />{" "}
              <Label for="RenderWeekOverWeekMeeting" check>
                Meeting Count Weekly
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderSLandPropType"
                type="checkbox"
                checked={this.props.dashboardState.RenderWeekOverWeekMeeting}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderSLandPropType")
                }
              />{" "}
              <Label for="RenderSLandPropType" check>
                Business Line and Property Type Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderOtherServices"
                type="checkbox"
                checked={this.props.dashboardState.RenderOtherServices}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderOtherServices")
                }
              />{" "}
              <Label for="RenderOtherServices" check>
                Other JLL Services Chart
              </Label>
            </FormGroup>
          </Col>
          <Col xs={4} xl={4} lg={4} md={4}>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderLineChart"
                type="checkbox"
                checked={this.props.dashboardState.RenderLineChart}
                onChange={(e) => this.props.toggleCharts(e, "RenderLineChart")}
              />{" "}
              <Label for="RenderLineChart" check>
                Opp. By Market Chart (Month)
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary "
              check
            >
              <Input
                id="RenderDayChart"
                type="checkbox"
                checked={this.props.dashboardState.RenderDayChart}
                onChange={(e) => this.props.toggleCharts(e, "RenderDayChart")}
              />{" "}
              <Label for="RenderDayChart" check>
                Opp. By Market Chart (Day)
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderAgencyScorecards"
                type="checkbox"
                checked={this.props.dashboardState.RenderAgencyScorecards}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderAgencyScorecards")
                }
              />{" "}
              <Label for="RenderAgencyScorecards" check>
                Service Line Scorecards
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderMarketPerformance"
                type="checkbox"
                checked={this.props.dashboardState.RenderMarketPerformance}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderMarketPerformance")
                }
              />{" "}
              <Label for="RenderMarketPerformance" check>
                Market Performance Bar Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderWeekOverWeek"
                type="checkbox"
                checked={this.props.dashboardState.RenderWeekOverWeek}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderWeekOverWeek")
                }
              />{" "}
              <Label for="RenderWeekOverWeek" check>
                Opp. Status (Weekly) Bar Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderMonthOverMonth"
                type="checkbox"
                checked={this.props.dashboardState.RenderMonthOverMonth}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderMonthOverMonth")
                }
              />
              <Label for="RenderMonthOverMonth" check>
                Opp. Status (Monthly) Bar Chart
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderSalesStage"
                type="checkbox"
                checked={this.props.dashboardState.RenderSalesStage}
                onChange={(e) => this.props.toggleCharts(e, "RenderSalesStage")}
              />
              <Label for="RenderSalesStage" check>
                Sales Stage
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderStageEstRev"
                type="checkbox"
                checked={this.props.dashboardState.RenderStageEstRev}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderStageEstRev")
                }
              />
              <Label for="RenderStageEstRev" check>
                Sales Stage Est. Rev
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderBrokerEstRev"
                type="checkbox"
                checked={this.props.dashboardState.RenderBrokerEstRev}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderBrokerEstRev")
                }
              />
              <Label for="RenderBrokerEstRev" check>
                Broker Est. Revenue
              </Label>
            </FormGroup>
            <FormGroup
              className="checkbox mb-2 abc-checkbox abc-checkbox-primary"
              check
            >
              <Input
                id="RenderEstRevenueQuarter"
                type="checkbox"
                checked={this.props.dashboardState.RenderEstRevenueQuarter}
                onChange={(e) =>
                  this.props.toggleCharts(e, "RenderEstRevenueQuarter")
                }
              />
              <Label for="RenderEstRevenueQuarter" check>
                Est. Revenue Quarter
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </>
    );
  }
}

export default ToggleCharts;
