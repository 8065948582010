import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-dates/initialize";
import cx from "classnames";
import Transition from "react-transition-group/Transition";
import FilterPanel from "../components/FilterPanel/filterpanel";
import { createFilterSnapshot } from "../../../actions/projects";
import {
  Col,
  Row,
  Label,
  Progress,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  ButtonDropdown,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Fade,
} from "reactstrap";

import Widget from "../../../components/Widget/Widget";
import Section from "../../../components/Section/Section";
import "react-dates/lib/css/_datepicker.css";
import {
  updateAllFilters,
  updateAddBrokerFilter,
  updateSourcingBrokerFilter,
  updateServiceLineFilter,
  updateOppNameFilter,
  updateGreaterorlessFilter,
  updateSizeFilter,
  updateStatusFilter,
  updateTechUsedFilter,
  updateActiveFilters,
  updateEndDate,
  updateStartDate,
  updateShortcut,
  updateTypeFilter,
} from "../../../actions/dashboardfilters";
import s from "../MainDashboard.module.scss";
import ErrorPage from "../../error/ErrorPage";
import Loading from "../components/loading";

import BuyerEnablementStats from "../components/Charts/BuyerEnablementStats";
import Geocode from "react-geocode";
import CurrentDashboardSettings from "../components/CurrentSettingsPanel/currentsettings";
import ToggleCharts from "../components/ToggleChartsPanel/togglecharts";

import DataTable from "../components/datatablev2/DataTable";
import TopBrokerWinRate from "../components/Charts/TopBrokerWinRateChart";
import { range } from "lodash";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
var moment = require("moment");

function cleanPersonList(person) {
  let list = [];
  person.forEach((item) => list.push(item.fullname));
  let theperson = list.join(", ");
  return theperson;
}
function cleanServiceList(serviceline) {
  let list = [];
  serviceline.forEach((item) => list.push(item.servicelinename));
  let theservice = list.join(", ");
  return theservice;
}

class YearEndReport extends Component {
  constructor(props) {
    super(props);
    this.filterboxchild = React.createRef();
    // this.toggleOne = this.toggleOne.bind(this);
    // this.toggleTwo = this.toggleTwo.bind(this);
    // this.toggleThree = this.toggleThree.bind(this);
    // this.toggleFour = this.toggleFour.bind(this);
    this.filterOpportunities = this.filterOpportunities.bind(this);
    this.filterUpdates = this.filterUpdates.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.startFiltering = this.startFiltering.bind(this);
    this.findSectionsToHide = this.findSectionsToHide.bind(this);
    this.toggleCharts = this.toggleCharts.bind(this);
    this.hideCustomDashboardSaveModal =
      this.hideCustomDashboardSaveModal.bind(this);

    this.state = {
      presetFilters: false,
      filterbox: false,
      exportbox: false,
      toggleChartsPanel: false,
      toggleCurrentSettings: false,
      saveCustomDashboardToggle: false,
      beginningofyear: "",
      endofyear: "",
      key: "selection",
      sBrokerFilter: "",
      brokerFilter: "",
      dropdownOpenOne: false,
      dropdownOpenTwo: false,
      dropdownOpenThree: false,
      dropdownOpenFour: false,
      filteringopps: false,
      slineFilter: "",
      oppNameFilter: "",
      statusFilter: "",
      DateRangeFiltered: [],
      dateFilter: "",
      cleandata: "",
      oppnamelist: [],
      blist: [],
      renderMonthStats: false,
      updates: [],
      newopps: [],
      statusWins: [],
      statusLosses: [],
      locations: [],
      clientlist: [],
      currentfiltersnapshot: {},
      statusOpps: [],
      opps: [],
      wins: [],
      losses: [],
      thismonthstatusupdates: [],
      thismonthsstatusopps: [],
      slist: [],
      RenderDonut: true,
      RenderDefaultStats: true,
      RenderWinsTable: false,
      RenderLossesTable: false,
      RenderNewOpps: false,
      RenderDayChart: false,
      RenderDayChartSL: false,
      RenderBigStats: true,
      RenderColumnOneStats: true,
      RenderLocationsMap: false,
      RenderMonthOverMonthMeeting: false,
      RenderWeekOverWeekMeeting: false,
      RenderSLandPropType: true,
      RenderMonthOverMonth: false,
      RenderTechTools: true,
      RenderMarketPerformance: false,
      RenderWeekOverWeek: false,
      RenderSourcingMethodChart: true,
      RenderIndustryChart: true,
      RenderColumnTwoStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: true,
      RenderThresholdChart: true,
      RenderBrokerPerf: true,
      RenderAgencyScorecards: false,
      RenderCalendar: true,
      RenderTableData: true,
      RenderTechUsedAverage: false,
      pdf: false,
    };
  }

  toggleCharts(e, id) {
    this.setState({
      [id]: e.target.checked,
    });
  }

  printDocument() {
    const input = document.getElementById("divToPrint");
    html2canvas(input).then((canvas) => {
      // canvas.width = 600
      // canvas.height = 1200
      const imgData = canvas.toDataURL("image/png");

      // var w=window.open('about:blank','image from canvas');
      // w.document.write("<img src='"+imgData+"' alt='from canvas'/>");
      const pdf = new jsPDF("p", "mm", "tabloid");

      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();

      const widthRatio = pageWidth / canvas.width;
      const heightRatio = pageHeight / canvas.height;
      const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

      const canvasWidth = canvas.width * ratio;
      const canvasHeight = canvas.height * ratio;

      const marginX = (pageWidth - canvasWidth) / 2;
      const marginY = (pageHeight - canvasHeight) / 2;
      pdf.setFillColor(237, 240, 242);
      pdf.rect(0, 0, pageWidth, pageHeight, "F");
      pdf.text(
        25,
        10,
        `${this.props.marketname} Sales Enablement Dashboard | ${moment(
          new Date()
        ).format("DD-MM-YYYY H:MM")}`
      );
      pdf.addImage(
        imgData,
        "JPEG",
        marginX,
        marginY + 15,
        canvasWidth,
        canvasHeight - 25
      );

      // var ratio = canvas.width/canvas.height;
      // var width = pdf.internal.pageSize.getWidth();
      // var height = width / ratio;
      // pdf.addImage(imgData, 'JPEG', 0, 0, canvas.width*0.2,canvas.height*0.2);
      // pdf.output('dataurlnewwindow');
      pdf.save(
        `${this.props.marketname}-SEdashboard_${moment(new Date()).format(
          "DD-MM-YYYY_H:MM"
        )}_.pdf`
      );
    });
  }

  hideCustomDashboardSaveModal() {
    this.setState({
      saveCustomDashboardToggle: false,
    });
  }
  getHistoricalData() {
    this.clearFilters();
  }
  wonOppsShortcut() {
    this.clearFilters();
    this.setState({
      RenderDonut: false,
      renderMonthStats: false,
      RenderAgencyScorecards: false,
      RenderColumnOneStats: false,
      RenderColumnTwoStats: false,
    });
    this.props.dispatch(updateStatusFilter("Won"));
    this.props.dispatch(updateShortcut(true));
  }

  jteFilterShortcut() {
    this.clearFilters();
    this.props.dispatch(updateAllFilters({ jteFilter: true }));
    this.props.dispatch(updateShortcut(true));
  }

  fiftyThousandShortcut() {
    // this.setState({
    //   RenderDonut: false
    // })
    this.clearFilters();
    this.props.dispatch(updateSizeFilter("50000"));
    this.props.dispatch(updateShortcut(true));
  }

  thisMonthShortcut() {
    this.startFiltering();
    this.clearFilters();
    this.props.dispatch(updateStartDate(moment().startOf("month")));
    this.props.dispatch(updateEndDate(moment().endOf("month")));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTimeRangeStats: true,
      RenderTableData: false,
      RenderDayChart: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
    });
  }

  startFiltering() {
    this.setState({
      filteringopps: true,
    });
  }

  jteShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateTechUsedFilter([{ name: "JLL Tenant Experience", id: 0 }])
    );
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderIndustryChart: true,
      RenderSourcingMethodChart: true,
      RenderTechTools: true,
      RenderLocationsMap: true,
      renderMonthStats: false,
    });
  }

  returnDateRangeFromFilter(date) {
    date = date.replace("Between ", "");
    let str = date.split(" and ");
    let fromdate = moment(str[0], "MMMM Do, YYYY");
    let todate = moment(str[1], "MMMM Do, YYYY");
    let array = [fromdate, todate];
    return array;
  }

  findSectionsToHide() {
    let activefilters = this.props.activefilters;
    let RenderDonut = this.state.RenderDonut;
    let RenderDefaultStats = this.state.RenderDefaultStats;
    let RenderWinsTable = this.state.RenderWinsTable;
    let RenderLossesTable = this.state.RenderLossesTable;
    let RenderNewOpps = this.state.RenderNewOpps;
    let RenderDayChart = this.state.RenderDayChart;
    let RenderBigStats = this.state.RenderBigStats;
    let RenderColumnOneStats = this.state.RenderColumnOneStats;
    let RenderColumnTwoStats = this.state.RenderColumnTwoStats;
    let RenderLineChart = this.state.RenderLineChart;
    let RenderServiceLineChart = this.state.RenderServiceLineChart;
    let RenderBrokerPerf = this.state.RenderBrokerPerf;
    let RenderTableData = this.state.RenderTableData;
    let RenderDayChartSL = this.state.RenderDayChartSL;
    let RenderTimeRangeStats = this.state.RenderTimeRangeStats;
    let therange = [];

    if (activefilters.length > 0) {
      activefilters.forEach((item) => {
        if (item.type === "serviceLineFilter") {
          RenderBigStats = false;
        } else if (
          item.type === "brokerTeamFilter" ||
          item.type === "sourcingBrokerFilter"
        ) {
          RenderBrokerPerf = false;
          RenderBigStats = false;
        } else if (
          item.type === "endDate" &&
          item.value !== "" &&
          item.value !== null
        ) {
          if (item.value.includes("Between")) {
            therange = this.returnDateRangeFromFilter(item.value);
            RenderTimeRangeStats = true;
            RenderNewOpps = true;
            RenderWinsTable = true;
            RenderDefaultStats = false;
            RenderTableData = false;
            RenderLossesTable = true;

            if (therange[0] !== "" && therange[1] !== "") {
              if (therange[1].diff(therange[0], "days") <= 60) {
                RenderDayChart = true;
                RenderLineChart = false;
                RenderDayChartSL = true;
              }
            }
          }
          RenderBigStats = false;
        } else if (item.type === "statusFilter") {
          RenderDonut = false;
          RenderBigStats = false;
        }
      });
    }
    this.setState({
      RenderDonut: RenderDonut,
      RenderDefaultStats: RenderDefaultStats,
      DateRangeFiltered: therange,
      RenderTimeRangeStats: RenderTimeRangeStats,
      RenderWinsTable: RenderWinsTable,
      RenderLossesTable: RenderLossesTable,
      RenderNewOpps: RenderNewOpps,
      RenderDayChart: RenderDayChart,
      RenderDayChartSL: RenderDayChartSL,
      RenderBigStats: RenderBigStats,
      RenderColumnOneStats: RenderColumnOneStats,
      RenderColumnTwoStats: RenderColumnTwoStats,
      RenderLineChart: RenderLineChart,
      RenderServiceLineChart: RenderServiceLineChart,
      RenderBrokerPerf: RenderBrokerPerf,
      RenderTableData: RenderTableData,
    });
  }

  filterOpportunities(opps) {
    if (!opps) {
      console.log(opps);
    }
  }

  filterUpdates(updates, statusWins, statusLosses, newopps, opps) {
    let beginningofyear = moment().dayOfYear(1);
    let endofyear = moment().dayOfYear(366);
    updates = updates.filter(
      (item) =>
        moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isAfter(
          beginningofyear
        ) && moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isBefore(endofyear)
    );
    let newStatusOpps = this.props.opps.filter(
      (item1) =>
        updates.some((item2) => item2.opportunityid === item1.opportunityid) ||
        (opps.some((item3) => item3.opportunityid === item1.opportunityid) &&
          (item1.winloss === "Won" || item1.winloss === "Lost"))
    );
    let wins = opps.filter((item) => item.winloss === "Won");
    let losses = opps.filter((item) => item.winloss === "Lost");

    let newStatusWins = newStatusOpps.filter((item) => item.winloss === "Won");
    let newStatusLosses = newStatusOpps.filter(
      (item) => item.winloss === "Lost"
    );

    wins = wins.concat(newStatusWins);
    losses = losses.concat(newStatusLosses);

    // Remove duplciatees
    wins = wins.reduce((unique, o) => {
      if (!unique.some((obj) => obj.opportunityid === o.opportunityid)) {
        unique.push(o);
      }
      return unique;
    }, []);
    losses = losses.reduce((unique, o) => {
      if (!unique.some((obj) => obj.opportunityid === o.opportunityid)) {
        unique.push(o);
      }
      return unique;
    }, []);

    this.setState({
      wins: wins,
      opps: opps,
      losses: losses,
      statusUpdates: updates,
      statusWins: newStatusWins,
      statusLosses: newStatusLosses,
      newopps: newopps,
      filteringopps: false,
    });
  }

  thisWeekShortcut() {
    this.clearFilters();
    this.props.dispatch(updateStartDate(moment().startOf("week")));
    this.props.dispatch(updateEndDate(moment().endOf("week")));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderWinsTable: true,
      RenderLossesTable: true,
      RenderNewOpps: true,
      RenderDefaultStats: false,
      RenderTableData: false,
      RenderDayChart: true,
      RenderTimeRangeStats: true,
      RenderLineChart: false,
      RenderServiceLineChart: false,
      RenderDayChartSL: true,
      RenderWeekOverWeek: false,
    });
  }

  TenantRepShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateServiceLineFilter([{ name: "Tenant Rep", id: 1 }])
    );
    this.props.dispatch(updateShortcut(true));
  }

  AgencyShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateServiceLineFilter([{ name: "Agency Leasing", id: 1 }])
    );
    this.props.dispatch(updateShortcut(true));
  }

  GeneralShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter([{ name: "General", id: 1 }]));
    this.props.dispatch(updateShortcut(true));
  }

  RetailShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter([{ name: "Retail", id: 1 }]));
    this.props.dispatch(updateShortcut(true));
  }

  IndustrialShortcut() {
    this.clearFilters();
    this.props.dispatch(
      updateServiceLineFilter([{ name: "Industrial", id: 1 }])
    );
    this.props.dispatch(updateShortcut(true));
  }

  ipsShortcut() {
    this.clearFilters();
    this.props.dispatch(updateTypeFilter("IPS"));
    this.props.dispatch(updateShortcut(true));
  }

  tenantrepOppsShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter("Tenant Rep"));
    this.props.dispatch(updateShortcut(true));
  }

  retailOppsShortcut() {
    this.clearFilters();
    this.props.dispatch(updateServiceLineFilter("Retail"));
    this.props.dispatch(updateShortcut(true));
  }

  // checkForPresetFilters(){

  //   this.props.dispatch(updateAllFilters(this.state.currentfiltersnapshot))

  // }

  clearFilters() {
    this.props.dispatch(
      updateAllFilters({
        seLeadFilter: "",
        digitalAFilter: "",
        startDate: "",
        endDate: "",
        marketsFilter: "",
        sourcingBrokerFilter: "",
        typeFilter: "",
        addBrokerFilter: "",
        serviceLineFilter: "",
        brokerTeamFilter: "",
        techUsedFilter: "",
        jteFilter: false,
        sourcingMethodFilter: "",
        otherServicesFilter: "",
        industryFilter: "",
        oppNameFilter: "",
        teamIncludeorExact: "includes",
        toolsIncludeorExact: "includes",
        marketIncludeExclude: "includes",
        sizeFilter: "",
        greaterorlessFilter: "greater",
        statusFilter: "",
        activefilters: [],
        inboundfilter: "",
        propertytypefilter: "",
        meetingtypefilter: "",
        shortcutBoolean: false,
      })
    );

    this.props.dispatch(
      createFilterSnapshot({
        seLeadFilter: "",
        digitalAFilter: "",
        startDate: "",
        endDate: "",
        marketsFilter: "",
        sourcingBrokerFilter: "",
        typeFilter: "",
        addBrokerFilter: "",
        jteFilter: false,
        serviceLineFilter: "",
        brokerTeamFilter: "",
        techUsedFilter: "",
        sourcingMethodFilter: "",
        otherServicesFilter: "",
        industryFilter: "",
        oppNameFilter: "",
        teamIncludeorExact: "includes",
        toolsIncludeorExact: "includes",
        marketIncludeExclude: "includes",
        sizeFilter: "",
        greaterorlessFilter: "greater",
        statusFilter: "",
        activefilters: [],
        inboundfilter: "",
        propertytypefilter: "",
        meetingtypefilter: "",
        shortcutBoolean: false,
      })
    );

    let statusupdates = [];

    if (this.props.updates.OppStatusUpdates) {
      statusupdates = this.props.updates;
    }

    this.setState(
      {
        RenderDonut: true,
        RenderDefaultStats: true,
        renderMonthStats: false,
        RenderColumnOneStats: true,
        RenderTimeRangeStats: false,
        RenderWinsTable: false,
        RenderLossesTable: false,
        RenderNewOpps: false,
        RenderColumnTwoStats: true,
        RenderServiceLineChart: true,
        RenderLineChart: true,
        RenderBrokerPerf: true,
        RenderAgencyScorecards: true,
        RenderCalendar: true,
        RenderTableData: true,
        RenderDayChart: false,
        RenderDayChartSL: false,
        // opps: this.props.opps,
        statusUpdates: statusupdates,
      },
      this.updateDashboard()
    );

    // this.filterboxchild.current.clearFilters()
  }

  getLocations(opps) {
    // const sortedprops = [].concat(clientdata.property).sort((a,b) => (a.address > b.address) ? 1 : ((b.address > a.address) ? -1 : 0));

    let marketobjects = [];
    let finalmarketobjects = [];
    let currentmarket = "";
    let promises = [];
    let clientlist = [];

    opps.forEach((item, index) => {
      currentmarket = "Unknown";
      if (item.market.length > 0) {
        currentmarket = item.market[0].marketname;
      }
      if (item.clientcompany.length > 0) {
        clientlist.push(item.clientcompany[0]);
      }
      let foundmarket = marketobjects.find(
        (element) => element.marketname === currentmarket
      );
      if (!(foundmarket === undefined)) {
        foundmarket.count += 1;
      } else {
        if (!(currentmarket === "Unknown")) {
          let newobj = {
            marketname: currentmarket,
            count: 1,
          };
          marketobjects.push(newobj);
        }
      }
    });

    Geocode.setApiKey("AIzaSyC9pPjr83c5Ml_WIs8Us6SbmAPqfpPDTy0");
    marketobjects.forEach((item) => {
      promises.push(
        Geocode.fromAddress(item.marketname).then(
          (response) => {
            const coords = response.results[0].geometry.location;
            let newobj = {
              z: item.count,
              keyword: item.marketname,
              lat: coords.lat,
              lon: coords.lng,
            };
            finalmarketobjects.push(newobj);
            setTimeout(function () {}, 3000);
          },
          (error) => {
            console.log("ERROR");
            console.error(error);
          }
        )
      );
    });
    Promise.all(promises).then(() => {
      this.setState({
        locations: finalmarketobjects,
        clientlist: clientlist,
      });
    });
  }

  checkfilters(filters) {
    let filterarray = [];
    Object.keys(filters).forEach(function (key) {
      if (
        key !== "greaterorlessFilter" &&
        key !== "sizeFilter" &&
        key !== "startDate" &&
        key !== "endDate"
      ) {
        if (filters[key] !== "") {
          filterarray.push(filters[key]);
        }
      } else if (key === "sizeFilter" && filters[key] !== "") {
        filterarray.push(
          filters["greaterorlessFilter"].toUpperCase() +
            " than " +
            filters["sizeFilter"] +
            " sf"
        );
      } else if (key === "endDate" && filters[key] !== "") {
        filterarray.push(
          "Between " +
            moment(filters["startDate"]).format('MMMM Do, YYYY"') +
            " and " +
            moment(filters["endDate"]).format("MMMM Do, YYYY")
        );
      }
    });

    let joined = this.props.activefilters.concat(filterarray);
    this.props.dispatch(updateActiveFilters(joined));
  }

  static defaultProps = {
    projects: [],
    visits: {},
    performance: {},
    server: {},
    revenue: [],
    mainChart: [],
    isReceiving: false,
  };

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  updateDashboard() {
    if (this.state.RenderLocationsMap) {
      this.getLocations(this.state.opps);
    }

    if (this.props.currentdashboardsettings) {
      let parsedsettings = JSON.parse(
        this.props.currentdashboardsettings.settings
      );
      let currentfilters = parsedsettings.dashboardFilters;
      let currenttoggles = parsedsettings.dashboardCharts;

      if (
        currentfilters.endDate !== null &&
        currentfilters.endDate !== "" &&
        currentfilters.endDate !== undefined
      ) {
        let enddate = moment(currentfilters.endDate);
        let startdate = moment(currentfilters.startDate);
        currentfilters.endDate = enddate;
        currentfilters.startDate = startdate;
      }

      this.props.dispatch(updateAllFilters(currentfilters));
      this.props.dispatch(updateShortcut(true));
      this.setState({
        ...this.state,
        opps: this.props.opps,
        statusUpdates: this.props.updates,
        ...currenttoggles,
      });
    } else {
      let statusUpdates = [];
      let marketname = this.props.marketname;
      let renderMap = this.state.RenderLocationsMap;
      let renderTech = this.state.RenderTechTools;
      let renderSourcing = this.state.RenderSourcingMethodChart;
      let renderIndustries = this.state.RenderIndustryChart;
      let renderWoW = this.state.RenderWeekOverWeek;
      let renderMPerformance = this.state.RenderMarketPerformance;
      let RenderTechUsedAverage = this.state.RenderTechUsedAverage;
      let renderLineChart = this.state.RenderLineChart;

      if (marketname === "JTE") {
        // this.thisMonthShortcut()
        renderMPerformance = true;
        renderWoW = true;
        renderLineChart = true;
        renderMap = true;
        renderTech = true;
        RenderTechUsedAverage = true;
        renderSourcing = true;
        renderIndustries = true;
      }
      if (marketname === "National") {
        renderLineChart = true;
      }

      // For year end report only - filter updates and opps to year
      // let opps = this.props.opps
      // let beginningofyear = moment().dayOfYear(1)
      // let endofyear = moment().dayOfYear(366)
      // let beginningofyearformatted = beginningofyear.format("MM-DD-YYYY")
      // let endofyearformatted = endofyear.format("MM-DD-YYYY")

      // Need to only do 2020 for now
      let opps = this.props.opps;
      // let beginningofyear =  moment('01-01-2020', "MM-DD-YYYY")
      // let endofyear = moment('12-31-2020', "MM-DD-YYYY")
      // let beginningofyearformatted = beginningofyear.format("MM-DD-YYYY")
      // let endofyearformatted = endofyear.format("MM-DD-YYYY")

      // opps = opps.filter(item => moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(beginningofyear) &&  moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(endofyear))

      // let filteredupdates = this.props.updates.filter(item => moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isAfter(beginningofyear) &&  moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isBefore(endofyear))

      // let statusOpps = this.props.opps.filter(item1 => filteredupdates.some(item2 => (item2.opportunityid === item1.opportunityid)) || (opps.some(item3 => item3.opportunityid === item1.opportunityid) && (item1.winloss === "Won" || item1.winloss ==="Lost")))
      let wins = opps.filter((item) => item.winloss === "Won");
      let losses = opps.filter((item) => item.winloss === "Lost");

      // let statusWins = statusOpps.filter(item => item.winloss === "Won")
      // let statusLosses = statusOpps.filter(item => item.winloss === "Lost")

      // wins = wins.concat(statusWins)
      // losses = losses.concat(statusLosses)

      // Remove duplciatees
      //    wins = wins.reduce((unique, o) => {
      //     if(!unique.some(obj => obj.opportunityid === o.opportunityid)) {
      //       unique.push(o);
      //     }
      //     return unique;
      // },[]);
      //       losses = losses.reduce((unique, o) => {
      //         if(!unique.some(obj => obj.opportunityid === o.opportunityid)) {
      //           unique.push(o);
      //         }
      //         return unique;
      //     },[]);

      this.setState({
        opps: opps,
        wins: wins,
        losses: losses,
        statusUpdates: this.props.updates,
        RenderLineChart: renderLineChart,
        RenderLocationsMap: renderMap,
        RenderWeekOverWeek: renderWoW,
        RenderTechUsedAverage: RenderTechUsedAverage,
        RenderMarketPerformance: renderMPerformance,
        RenderTechTools: renderTech,
        RenderSourcingMethodChart: renderSourcing,
        RenderIndustryChart: renderIndustries,
      });
    }
  }

  componentDidMount() {
    // let daterangestatusupdates= this.props.updates.OppStatusUpdates.filter(item => moment(item.updateTime, 'MM-DD-YYYY hh:mm:ss').isSame(moment(), 'month'))
    // let daterangestatusopps = this.props.opps.filter(item => thismonthstatusupdates.some(item2 => item2.opportunityid === item.opportunityid))
    this.updateDashboard();
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
    this.clearFilters();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.activefilters !== prevProps.activefilters) {
      this.findSectionsToHide();
    }

    if (
      this.state.RenderLocationsMap !== prevState.RenderLocationsMap &&
      this.state.RenderLocationsMap
    ) {
      this.getLocations(this.state.opps);
    }

    if (
      this.state.RenderIndustryChart !== prevState.RenderIndustryChart &&
      this.state.RenderIndustryChart
    ) {
      this.getLocations(this.state.opps);
    }
    if (
      prevProps.currentdashboardsettings !== this.props.currentdashboardsettings
    ) {
      this.clearFilters();
      this.updateDashboard();
    }
  };

  render() {
    const { activefilters, marketname } = this.props;
    const {
      wins,
      losses,
      filterbox,
      toggleChartsPanel,
      RenderDonut,
      RenderLineChart,
      RenderServiceLineChart,
      RenderBrokerPerf,
      RenderTableData,
      opps,
    } = this.state;

    let winrate = ((wins.length / (wins.length + losses.length)) * 100).toFixed(
      1
    );
    let lossrate = (
      (losses.length / (wins.length + losses.length)) *
      100
    ).toFixed(1);

    return (
      <div>
        <ErrorPage>
          <div className={s.analyticsSide}>
            <Row>
              <Col className="title-block-wnotice" xs={12} md={6} lg={6} xl={6}>
                <h1 className="page-title">
                  <span className="fw-semi-bold">{marketname}</span> Year Over
                  Year Report
                </h1>
                {/* {
        this.state.beginningofyear !== ""
        ?
        <small>Displaying opportunities created between {this.state.beginningofyear} and {this.state.endofyear}</small>
        :
        null
      } */}
              </Col>
              <Col xs={12} md={6} lg={6} xl={6}>
                <div className="pb-md h-100 mb-0 float-right exportAndBack">
                  <ul>
                    {/* <li><Link to={'/app/reporting/calendar'}><Button className="mb-xs mr-xs btn-block btn-primary float-right"  color="primary">Calendar</Button></Link></li> */}
                    <li>
                      <Button
                        className="mb-xs mr-xs btn-block btn-primary float-right"
                        onClick={() => this.toggle("filterbox")}
                        color="primary"
                      >
                        Filter
                      </Button>
                    </li>
                    {/* <li><Button className="mb-xs mr-xs btn-block btn-primary float-right"  onClick={() => this.toggle('exportbox')} color="primary">Export Report</Button></li> */}
                    <li>
                      <Button
                        className="mb-xs mr-xs btn-block btn-primary float-right"
                        onClick={() => this.toggle("toggleChartsPanel")}
                        color="primary"
                      >
                        Toggle Charts
                      </Button>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
            {this.state.filteringopps ? (
              <Row>
                <Loading />
              </Row>
            ) : null}

            <Row className={activefilters.length > 0 ? "show" : "d-none"}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="pb-xlg h-100">
                  <Widget className="mb-0 h-100" collapse bodyClass="mt-lg">
                    <div>
                      <span className="fw-semi-bold">Filtering by:</span>
                      {activefilters.length > 0 ? (
                        activefilters.map((filter, key) => {
                          return (
                            <Button
                              style={{
                                backgroundColor: "#dee4ee",
                                color: "#495057",
                              }}
                              key={key}
                              disabled
                              className={
                                s.filtertag + "justify-content-between m-4"
                              }
                            >
                              {filter.value}
                            </Button>
                          );
                        })
                      ) : (
                        <div>No filters</div>
                      )}

                      <Button
                        className="float-right m-4"
                        style={{ color: "#fff" }}
                        onClick={this.clearFilters}
                      >
                        Clear filters
                      </Button>
                    </div>
                  </Widget>
                </div>
              </Col>
            </Row>
          </div>
          <div className={s.sidesWrapper}>
            <div
              className={s.analyticsSide}
              style={{ backgroundColor: "#EDF0F2" }}
              id="divToPrint"
            >
              <Section classes="reportSection" h>
                {/* Default Overhead Stats*/}

                {/*End default overhead stats*/}

                {/* End Timerange stats */}

                {/* Begin BuyerEnablement stats */}

                <BuyerEnablementStats
                  opps={opps}
                  newopps={this.state.newopps}
                  rawopps={this.props.opps}
                  wonopps={this.state.statusWins}
                  lostopps={this.state.statusLosses}
                  updates={this.props.updates}
                />

                <Col xs={12} className="mb-lg">
                  <Widget
                    className="mb-0 h-100"
                    close
                    bodyClass="mt-lg"
                    title={
                      <h4>
                        {" "}
                        Opportunity <strong>Table</strong>
                      </h4>
                    }
                  >
                    <DataTable data={opps} marketname={marketname} />
                  </Widget>
                </Col>

                {/* End BuyerEnablement stats */}

                {/* 
            <Row>

          {
            RenderBrokerPerf
            ?
              <Col lg={6} >

                <TopBrokerWinRate data={opps}  />

              </Col>
              : null
          }
       
              </Row> */}
              </Section>
            </div>

            <div className={s.analyticsSide}>
              <Row>
                <Col xs={12} md={6} xl={12} className={s.lastSideElement}>
                  <div className="pb-xlg h-100">
                    <Widget
                      bodyClass="mt"
                      close
                      className="mb-0 h-100"
                      title={<h5>Shortcuts</h5>}
                    >
                      <legend className="w-100 m-1">Business Line</legend>
                      <Button
                        onClick={(e) => this.TenantRepShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Tenant Rep
                      </Button>
                      <Button
                        onClick={(e) => this.AgencyShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Agency
                      </Button>
                      <Button
                        onClick={(e) => this.IndustrialShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Industrial
                      </Button>
                      <Button
                        onClick={(e) => this.GeneralShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        General
                      </Button>
                      <Button
                        onClick={(e) => this.RetailShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        Retail
                      </Button>
                      <Button
                        onClick={(e) => this.ipsShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        IPS
                      </Button>
                      <Button
                        onClick={(e) => this.jteFilterShortcut()}
                        className="w-100 m-1"
                        color="primary"
                      >
                        JTE
                      </Button>
                    </Widget>
                  </div>
                </Col>
                <Col xs={12} md={6} xl={12} className={s.lastSideElement}>
                  <div className="pb-xlg h-100">
                    <Widget
                      bodyClass="mt-lg"
                      close
                      className="mb-0 h-100"
                      title={<h5>Filters</h5>}
                    >
                      <FilterPanel
                        filterSnapshot={this.props.filterSnapshot}
                        findSectionsToHide={this.findSectionsToHide}
                        ref={this.filterboxchild}
                        opps={opps}
                        updates={this.state.statusUpdates}
                        rawupdates={this.props.updates}
                        market={marketname}
                        startFiltering={this.startFiltering}
                        filterUpdates={this.filterUpdates}
                        filterOpportunities={this.filterOpportunities}
                      />
                    </Widget>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            size="lg"
            isOpen={filterbox}
            toggle={() => this.toggle("filterbox")}
          >
            <ModalHeader toggle={() => this.toggle("filterbox")}>
              Dashboard Filter
            </ModalHeader>
            <ModalBody className="bg-white">
              <FilterPanel
                filterSnapshot={this.state.currentfiltersnapshot}
                findSectionsToHide={this.findSectionsToHide}
                market={marketname}
                opps={opps}
                updates={this.state.statusUpdates}
                rawupdates={this.props.updates}
                filterUpdates={this.filterUpdates}
                startFiltering={this.startFiltering}
                filterOpportunities={this.filterOpportunities}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>

          {/* Begin Toggle box */}
          <Modal
            size="lg"
            isOpen={toggleChartsPanel}
            toggle={() => this.toggle("toggleChartsPanel")}
          >
            <ModalHeader toggle={() => this.toggle("toggleChartsPanel")}>
              Toggle Charts
            </ModalHeader>
            <ModalBody className="bg-white">
              <ToggleCharts
                dashboardState={this.state}
                market={marketname}
                toggleCharts={this.toggleCharts}
              />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </ErrorPage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    filterSnapshot: state.projects.filterSnapshot,
    cleandataready: state.projects.cleandataready,
    userdata: state.authentication.userprofile,
    filters: state.dashboardfilters,
    filterbox: state.filterbox,
    filteredprojects: state.projects.filteredprojects,
    activefilters: state.dashboardfilters.activefilters,
    oppnamelist: state.projects.oppnamelist,
    marketdata: state.projects.marketdata,
    blist: state.projects.blist,
    sllist: state.projects.servicelinelist,
  };
}

export default connect(mapStateToProps)(YearEndReport);
