export const UPDATE_START_DATE = "UPDATE_START_DATE";
export const UPDATE_END_DATE = "UPDATE_END_DATE";
export const UPDATE_CLOSE_END_DATE = "UPDATE_CLOSE_END_DATE";
export const UPDATE_CLOSE_START_DATE = "UPDATE_CLOSE_START_DATE";
export const UPDATE_MEETING_END_DATE = "UPDATE_MEETING_END_DATE";
export const UPDATE_MEETING_START_DATE = "UPDATE_MEETING_START_DATE";
export const UPDATE_SOURCING_BROKER_FILTER = "UPDATE_SOURCING_BROKER_FILTER";
export const UPDATE_ADD_BROKER_FILTER = "UPDATE_ADD_BROKER_FILTER";
export const UPDATE_SERVICELINE_FILTER = "UPDATE_SERVICELINE_FILTER";
export const UPDATE_OPP_NAME_FILTER = "UPDATE_OPP_NAME_FILTER";
export const UPDATE_GREATERORLESS_FILTER = "UPDATE_GREATERORLESS_FILTER";
export const UPDATE_SIZE_FILTER = "UPDATE_SIZE_FILTER";
export const UPDATE_OPPSTATS_FILTER = "UPDATE_OPPSTATS_FILTER";
export const UPDATE_ACTIVE_FILTERS = "UPDATE_ACTIVE_FILTERS";
export const UPDATE_SHORTCUT_BOOLEAN = "UPDATE_SHORTCUT_BOOLEAN";
export const UPDATE_TYPE_FILTER = "UPDATE_TYPE_FILTER";
export const UPDATE_TECH_FILTER = "UPDATE_TECH_FILTER";
export const UPDATE_BROKER_TEAM_FILTER = "UPDATE_BROKER_TEAM_FILTER";
export const UPDATE_TEAMINCLUDE_EXACT_FILTER =
  "UPDATE_TEAMINCLUDE_EXACT_FILTER";
export const UPDATE_TOOLINCLUDE_EXACT_FILTER =
  "UPDATE_TOOLINCLUDE_EXACT_FILTER";
export const UPDATE_INBOUND_FILTER = "UPDATE_INBOUND_FILTER";
export const UPDATE_PROPERTYTYPE_FILTER = "UPDATE_PROPERTYTYPE_FILTER";
export const UPDATE_MEETINGTYPE_FILTER = "UPDATE_MEETINGTYPE_FILTER";
export const UPDATE_INDUSTRY_FILTER = "UPDATE_INDUSTRY_FILTER";
export const UPDATE_SOURCING_METHOD_FILTER = "UPDATE_SOURCING_METHOD_FILTER";
export const UPDATE_OTHER_SERVICES_FILTER = "UPDATE_OTHER_SERVICES_FILTER";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_EST_START_DATE_START = "UPDATE_EST_START_DATE_START";
export const UPDATE_EST_START_DATE_END = "UPDATE_EST_START_DATE_END";

export function updateAllFilters(payload) {
  return {
    type: UPDATE_FILTERS,
    payload,
  };
}

export function updateInboundFilter(payload) {
  return {
    type: UPDATE_INBOUND_FILTER,
    payload,
  };
}

export function updatePropertyTypeFilter(payload) {
  return {
    type: UPDATE_PROPERTYTYPE_FILTER,
    payload,
  };
}

export function updateMeetingTypeFilter(payload) {
  return {
    type: UPDATE_MEETINGTYPE_FILTER,
    payload,
  };
}

export function updateStartDate(payload) {
  return {
    type: UPDATE_START_DATE,
    payload,
  };
}

export function updateEndDate(payload) {
  return {
    type: UPDATE_END_DATE,
    payload,
  };
}

export function updateMeetingStartDate(payload) {
  return {
    type: UPDATE_MEETING_START_DATE,
    payload,
  };
}

export function updateMeetingEndDate(payload) {
  return {
    type: UPDATE_MEETING_END_DATE,
    payload,
  };
}

export function updateEstStartDateStart(payload) {
  return {
    type: UPDATE_EST_START_DATE_START,
    payload,
  };
}

export function updateEstStartDateEnd(payload) {
  return {
    type: UPDATE_EST_START_DATE_END,
    payload,
  };
}

export function updateCloseStartDate(payload) {
  return {
    type: UPDATE_CLOSE_START_DATE,
    payload,
  };
}

export function updateCloseEndDate(payload) {
  return {
    type: UPDATE_CLOSE_END_DATE,
    payload,
  };
}

export function updateSourcingMethodFilter(payload) {
  return {
    type: UPDATE_SOURCING_METHOD_FILTER,
    payload,
  };
}

export function updateOtherServicesFilter(payload) {
  return {
    type: UPDATE_OTHER_SERVICES_FILTER,
    payload,
  };
}

export function updateIndustryFilter(payload) {
  return {
    type: UPDATE_INDUSTRY_FILTER,
    payload,
  };
}

export function updateSourcingBrokerFilter(payload) {
  return {
    type: UPDATE_SOURCING_BROKER_FILTER,
    payload,
  };
}

export function updateBrokerTeamFilter(payload) {
  return {
    type: UPDATE_BROKER_TEAM_FILTER,
    payload,
  };
}

export function updateAddBrokerFilter(payload) {
  return {
    type: UPDATE_ADD_BROKER_FILTER,
    payload,
  };
}

export function updateServiceLineFilter(payload) {
  return {
    type: UPDATE_SERVICELINE_FILTER,
    payload,
  };
}

export function updateTechUsedFilter(payload) {
  return {
    type: UPDATE_TECH_FILTER,
    payload,
  };
}

export function updateOppNameFilter(payload) {
  return {
    type: UPDATE_OPP_NAME_FILTER,
    payload,
  };
}

export function updateTypeFilter(payload) {
  return {
    type: UPDATE_TYPE_FILTER,
    payload,
  };
}

export function updateGreaterorlessFilter(payload) {
  return {
    type: UPDATE_GREATERORLESS_FILTER,
    payload,
  };
}

export function updateTeamIncludeOrExactFilter(payload) {
  return {
    type: UPDATE_TEAMINCLUDE_EXACT_FILTER,
    payload,
  };
}

export function updateToolIncludeOrExactFilter(payload) {
  return {
    type: UPDATE_TOOLINCLUDE_EXACT_FILTER,
    payload,
  };
}

export function updateSizeFilter(payload) {
  return {
    type: UPDATE_SIZE_FILTER,
    payload,
  };
}

export function updateShortcut(payload) {
  return {
    type: UPDATE_SHORTCUT_BOOLEAN,
    payload,
  };
}

export function updateStatusFilter(payload) {
  return {
    type: UPDATE_OPPSTATS_FILTER,
    payload,
  };
}

export function updateActiveFilters(payload) {
  return {
    type: UPDATE_ACTIVE_FILTERS,
    payload,
  };
}
