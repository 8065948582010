import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";

function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}
export default class BrokerEstRev extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
    };
    this.BrokerRev = React.createRef();
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    isReceiving: false,
  };

  handleclick(event) {
    this.setState({
      test: event,
    });
  }

  chartData = () => {
    const { data } = this.props;

    let filteredData = data.filter(
      (item) => item.winloss === "Won" && item.est_revenue > 0
    );

    let brokerHM = {};

    filteredData.forEach((item, index) => {
      if (item.sourcingbroker.length > 0) {
        if (brokerHM[item.sourcingbroker[0].fullname]) {
          brokerHM[item.sourcingbroker[0].fullname] += item.est_revenue;
        } else {
          brokerHM[item.sourcingbroker[0].fullname] = item.est_revenue;
        }
      }
    });
    //Begin creation of chart data
    let brokerList = [];
    let categories = [];
    //Look in each of the client objects and match numbers to types
    Object.keys(brokerHM).forEach((item) => {
      if (item.length > 0) {
        let newBroker = {
          name: item,
          data: {
            total: brokerHM[item],
          },
        };
        brokerList.push(newBroker);
      }
    });

    //create series
    let series = [
      {
        name: "Total",
        data: [],
      },
    ];

    brokerList.sort(
      (a, b) => parseFloat(b.data.total) - parseFloat(a.data.total)
    );

    //for every client, add to the data and series
    brokerList.every((item) => {
      categories.push(item.name);
      series
        .find((item) => item.name.toLowerCase() === "total")
        .data.push(item.data.total);
      return true;
    });
    let ytotal = 0;
    if (series[0].data.length > 0) {
      ytotal = series[0].data[0] + 10000;
    }

    let chartOptions = {
      credits: {
        enabled: false,
      },
      // width: 700,
      chart: {
        height: 200,
        // width: 700,
        backgroundColor: "rgba(0,0,0,0)",
        type: "bar",
      },
      title: false,
      exporting: {
        enabled: true,
        chartOptions: {
          title: {
            text: null,
          },
        },
      },
      legend: {
        reversed: true,
        itemStyle: {
          color: "#000000",
        },
        itemHoverStyle: {
          color: "#020202",
        },
      },
      yAxis: {
        min: 0,
        max: ytotal,
        labels: {
          style: {
            color: "#000000",
          },
          formatter: function () {
            return "$" + nFormatter(this.value, 0);
          },
        },
      },

      annotations: {
        visible: false,
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            align: "right",
            x: 35,
            formatter: function () {
              return "$" + Highcharts.numberFormat(this.y, 0);
            },
          },
          point: {
            events: {
              click() {
                console.log(this.series.name);
                console.log(this.category);
              },
            },
          },
        },
      },
    };

    return {
      ...chartOptions,
      series: series,
      xAxis: {
        min: 0,
        max: 5,

        tickLength: 0,
        scrollbar: {
          enabled: true,
        },

        tickLength: 0,
        categories: categories,
      },
    };
  };

  render() {
    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={12}>
              {this.props.customtitle ? (
                <h5>{this.props.customtitle}</h5>
              ) : (
                <h5>
                  Broker<span className="fw-semi-bold"> Won Revenue</span>
                </h5>
              )}
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend" />
            </Col>
          </Row>
        }
      >
        <HighchartsReact
          highcharts={Highcharts}
          ref={this.BrokerRev}
          options={this.chartData()}
        />
      </Widget>
    );
  }
}
