import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
var moment = require('moment');

// const styles = StyleSheet.create({
//   page: { backgroundColor: '#edf0f2', fontFamily: "Montserrat"},
//
//   section: { color: 'black', textAlign: 'center', margin: 30 },
//
//   statWidget: {display: 'block', position: 'relative', marginBottom: 40, backgroundColor:'#fff', fontSize: "10pt", width: '25%', height: '25%', borderRadius: 0.35, boxShadow: '0 23px 20px -20px' },
//
//   widgetHeader: {padding:"padding: 15px 20px 0", fontSize: "15pt" },
//
//   widgetHeaderBold: {fontWeight:"600"}

// });

Font.register({ family: 'KadwaRegular', src:'https://fonts.gstatic.com/s/kadwa/v2/rnCm-x5V0g7ipiTAT8M.ttf' } );
Font.register( { family: 'MontserratRegular', src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf' } );
Font.register( { family: 'MontserratBold', src: 'https://fonts.gstatic.com/s/montserrat/v14/JTURjIg1_i6t8kCHKm45_dJE3gnD-w.ttf'} );

function sortPursuits(pursuits, props){

 return pursuits.sort((a, b) => new Date(b.meetingdate) - new Date(a.meetingdate))
}

function getDateRange(projects){
  let firstItem = projects[0];
  let lastItem = projects[projects.length-1];

  let fromdate = moment(firstItem.datecreated, "MM-DD-YYYY hh:mm:ss").format("MM/DD/YYYY")
  let todate =  moment(lastItem.datecreated, "MM-DD-YYYY hh:mm:ss").format("MM/DD/YYYY")
  let string = `From ${fromdate} to ${todate}`
  return string
}

const Report = (props) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Text>{props.brokername}</Text>
          <Text style={styles.Scorecard}>Scorecard</Text>
          <Text style={styles.daterange}>{getDateRange(props.relevantProjects)}</Text>
        </View>
        <View style={styles.headerRight}>
          <Text>{moment().format('MMMM Do, YYYY')}</Text>
          <Text></Text>
          <Text></Text>
        </View>
      </View>
      <Image src={props.activityChart} style={styles.linechart}></Image>

      <View style={styles.ribbon}>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Total Pursuits</Text>
          <Text style={styles.ribbonValue}>{props.relevantProjects.length}</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Current Win Rate</Text>
          <Text style={styles.ribbonValue}>{props.winrate}%</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Total Pursuit SF</Text>
          <Text style={styles.ribbonValue}>{(props.relevantProjects.reduce((a, {pursuitsize}) => a + pursuitsize, 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} sf </Text>
        </View>
      </View>

      <Image style={styles.statusreport} src={props.statusChart} ></Image>


      <View style={styles.summary}>
        <Text style={styles.summaryMeta}>
        Recent Pursuits
        </Text>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRowB}>
          <Text style={styles.tableHeadingA}>
            Industry
          </Text>
          <Text style={styles.tableHeadingB}>
            Pursuit Name
          </Text>
          <Text style={styles.tableHeadingC}>
            Meeting Date
          </Text>
        </View>

        { sortPursuits(props.relevantProjects, props).slice(0, 5).map((item,item_index) =>

          <View key={item_index} style={ item_index % 2 ? styles.tableRowB : styles.tableRowA}>
            <Text style={styles.serviceName}>
              {item.clientcompany[0].clientindustry}
            </Text>
            <Text style={styles.serviceDescription}>
                {item.pursuitname}
            </Text>
            <Text style={styles.serviceDate}>
              {moment(item.meetingdate).format("MMMM Do, YYYY")}
            </Text>
          </View>

        )}

      </View>




    </Page>
  </Document>
);

const styles = StyleSheet.create({
  header: {
    borderTopWidth: 4,
    borderTopColor: '#005792',
    color: '#898989',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  linechart:{
    // height: 65,
    width: 400,
    alignSelf: 'center',
  },
  statusreport:{
    // height: 93,
    width: 400,
    alignSelf: 'center',
  },
  headerLeft:{
    textAlign: 'left',
    flex: 1,
    alignSelf: 'center',
    fontSize: 32,
    fontWeight: 900,
    lineHeight: 1,
    color: '#005792',
    fontFamily: 'MontserratBold',
  },
  Scorecard:{
    fontSize: 20,
    fontWeight: 400,
    fontFamily: 'MontserratRegular',
  },
  daterange:{
    marginTop:3,
    fontSize: 10,
    fontWeight: 300,
    fontFamily: 'MontserratRegular',
  },
  headerRight: {
    textAlign: 'right',
    fontSize: 12,
    flex: 1,
    fontFamily: 'MontserratRegular',
  },

  ribbon: {
    backgroundColor: '#005792',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: '#FFF',
  },
  ribbonGrey: {
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: '#005792',
  },
  ribbonBox: {
    width: '33.333333%',
  },
  ribbonLabel: {
    fontSize: 14,
    fontFamily: 'MontserratRegular',
  },
  ribbonValue: {
    fontSize: 28,
    fontFamily: 'MontserratBold',
  },

  table: {
    paddingHorizontal: 20,
    display: 'flex',
    marginBottom: 20,
  },
  tableRowA: {
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  tableRowB: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  serviceName: {
    fontSize: 10,
    width: '25%',
    fontFamily: 'MontserratBold',
  },
  serviceDescription: {
    fontSize: 10,
    width: '50%',
    fontFamily: 'MontserratRegular',
  },
  serviceAmount: {
    fontSize: 20,
    width: '25%',
    textAlign: 'right',
    fontFamily: 'MontserratBold',
  },
  serviceDate:{
    fontSize: 10,
    width: '25%',
    textAlign: 'right',
    fontFamily: 'MontserratBold',
  },
  tableHeadingA: {
    width: '25%',
    fontSize: 14,
    color: '#005792',
    fontFamily: 'MontserratBold',
  },
  tableHeadingB: {
    width: '50%',
    fontSize: 14,
    color: '#005792',
    fontFamily: 'MontserratBold',
  },
  tableHeadingC: {
    width: '25%',
    textAlign: 'right',
    fontSize: 14,
    color: '#005792',
    fontFamily: 'MontserratBold',
  },
  summary: {
    backgroundColor: '#005792',
    color: '#FFF',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  summaryMeta: {
    width: '75%',
    fontFamily: 'MontserratBold',
  },
  summaryAmount: {
    fontSize: 20,
    width: '25%',
    textAlign: 'right',
    fontFamily: 'MontserratBold',
  },

  howToPay: {
    paddingHorizontal: 20,
    textAlign: 'center',
    fontSize: 20,
    color: '#005792',
    fontFamily: 'MontserratBold',
  },

  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
    borderTopColor: '#EDEDED',
    borderTopWidth: 1,
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'MontserratRegular',
  },
});





export default Report
