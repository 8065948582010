import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Navbar, Nav } from "reactstrap";

// import cx from 'classnames';
import { Link } from "react-router-dom";

import s from "./Header.module.scss";
var moment = require("moment");

class Header extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool.isRequired,
    sidebarStatic: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      notificationsTabSelected: 1,
      focus: false,
      showNewMessage: false,
      hideMessage: true,
      run: true,
      notificationsRead: false,
    };
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }
  render() {
    const { focus } = this.state;
    const {
      navbarType,
      navbarColor,
      openUsersList,
      user,
      userdataready,
      notifications,
      dateLastUpdated,
      isGettingProjects,
    } = this.props;

    let formattedLastDate = moment(dateLastUpdated).format("MM/DD/YY k:mm");

    // const user = JSON.parse(localStorage.getItem('user') || '{}');
    // const firstUserLetter = (user.username | user.email || "P")[0].toUpperCase();

    return (
      <div>
        <Navbar
          className={`${s.root} d-print-none`}
          style={{ backgroundColor: "#666", zIndex: !openUsersList ? 100 : 0 }}
        >
          <Nav style={{ paddingTop: "7px", marginLeft: "10px" }}>
            <Link to={"/app"}>
              <svg
                width="100"
                height="35"
                viewBox="0 0 106 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M63.4727 9.43854H54.4961H51.6949V10.5301C52.2679 10.5943 52.7135 10.6585 53.0955 10.7227C53.5411 10.8511 53.8595 10.9795 54.1141 11.1721C54.8144 11.75 54.8144 12.9058 54.8144 14.9604V28.8293C54.8144 31.6544 55.0691 35.6353 51.1219 35.5711C49.403 35.5711 47.6841 34.3511 47.1748 32.1038H45.8378C46.4108 35.8279 48.3844 38.3962 53.1592 38.3962C57.2336 38.3962 60.8625 36.5342 60.7988 30.1134V19.3265V14.9604C60.7988 12.9058 60.7988 11.8142 61.4991 11.1721C61.9447 10.7869 62.3267 10.5943 63.5363 10.4659V9.43854H63.4727Z"
                    fill="white"
                  />
                  <path
                    d="M77.0967 9.43854H65.2552V10.4659V10.5301C66.4649 10.6585 66.8468 10.8511 67.2925 11.2364C67.8655 11.75 67.9928 12.5205 67.9928 13.8689C67.9928 14.2541 67.9928 14.7678 67.9928 15.2814V31.847C67.9928 32.3607 67.9928 32.8101 67.9928 33.2596C67.9928 34.6079 67.8655 35.4426 67.2925 35.8921C66.8468 36.2773 66.4649 36.47 65.2552 36.5984V37.6257H77.0967H77.1604H83.1447L85.0547 32.1038H83.654C82.2534 35.1216 80.1526 35.5068 78.2426 35.5068C76.651 35.5068 75.5688 35.4426 74.8685 34.929C74.2955 34.5437 74.0408 33.5164 74.0408 31.7828V15.2814V14.9604C74.0408 12.9058 74.0408 11.75 74.7411 11.1721C75.1868 10.7869 75.9507 10.6585 77.1604 10.5301V9.43854H77.0967Z"
                    fill="white"
                  />
                  <path
                    d="M96.8324 9.43854H84.991V10.4659V10.5301C86.2006 10.6585 86.5826 10.8511 87.0282 11.2364C87.6012 11.75 87.7285 12.5205 87.7285 13.8689C87.7285 14.2541 87.7285 14.7678 87.7285 15.2814V31.847C87.7285 32.3607 87.7285 32.8101 87.7285 33.2596C87.7285 34.6079 87.6012 35.4426 87.0282 35.8921C86.5826 36.2773 86.2006 36.47 84.991 36.5984V37.6257H96.8324H96.8961H102.88L104.79 32.1038H103.39C101.989 35.1216 99.8883 35.5068 97.9147 35.5068C96.3231 35.5068 95.2408 35.4426 94.5405 34.929C93.9675 34.5437 93.7129 33.5164 93.7129 31.7828V15.2814V14.9604C93.7129 12.9058 93.7129 11.75 94.4132 11.1721C94.8588 10.7869 95.6228 10.6585 96.8324 10.5301V9.43854Z"
                    fill="white"
                  />
                  <path
                    d="M17.3802 46.1011C17.9531 45.8443 18.4625 45.5232 18.9718 45.2022C19.0991 45.138 19.1628 45.0738 19.2901 45.0096C19.9267 44.5601 20.4997 43.9822 21.009 43.4044C21.7093 42.6339 22.3459 41.7992 22.9189 40.9645C23.3646 40.2582 23.8102 39.5519 24.1285 38.8456C25.5928 35.9563 26.4841 32.7459 26.9934 29.6639C27.3117 27.6093 27.439 25.5546 27.439 23.5642C27.439 21.5738 27.3117 19.5191 26.9934 17.4645C26.4841 14.3183 25.5928 11.1079 24.1285 8.28279C23.9375 7.96175 23.8102 7.64071 23.6192 7.31967C22.8553 8.73224 22.2186 10.2732 21.7093 11.8142C22.6643 14.6393 23.1736 17.5929 23.4282 20.6107C23.4919 21.638 23.4919 22.6011 23.5556 23.6284C23.5556 24.6557 23.5556 25.6831 23.4919 26.6462C23.1736 30.1776 22.4096 33.7732 21.009 37.1762C20.4997 38.4604 19.8631 39.7445 19.0991 40.9645C18.9081 41.2213 18.7808 41.5423 18.5898 41.7992C18.2078 42.377 17.8258 42.8907 17.3802 43.4686C16.8709 44.1107 16.3616 44.6885 15.7886 45.2022C15.2156 45.7801 14.579 46.3579 13.8787 46.8074C13.815 46.8716 13.7513 46.8716 13.6877 46.9358V47C15.0246 47 16.2342 46.679 17.3802 46.1011Z"
                    fill="white"
                  />
                  <path
                    d="M24.7015 46.1011C25.2744 45.8442 25.7837 45.5232 26.2931 45.2022C26.4204 45.138 26.484 45.0738 26.6114 45.0095C28.7123 43.4044 30.3039 41.1571 31.5135 38.8456C32.9777 35.9563 33.869 32.7459 34.3783 29.6639C34.6967 27.6093 34.824 25.5546 34.824 23.5642C34.824 21.5738 34.6967 19.5191 34.3783 17.4645C33.869 14.3183 32.9777 11.1079 31.5135 8.28278C30.4312 6.09972 28.9669 4.04507 27.1207 2.50409C26.5477 3.14617 25.9747 3.85245 25.4654 4.55873C25.6564 4.81556 25.8474 5.0724 25.9747 5.32923C28.9669 9.82376 30.4312 15.2172 30.8132 20.6106C30.8768 21.638 30.9405 22.6011 30.9405 23.6284C30.9405 24.6557 30.8768 25.683 30.8132 26.6462C30.4312 32.0396 29.0306 37.433 25.9747 41.9276C25.5928 42.5055 25.2108 43.0191 24.7651 43.597C24.2558 44.2391 23.7465 44.8169 23.1735 45.3306C22.6006 45.9085 21.9639 46.4863 21.2636 46.9358C21.2 47 21.1363 47 21.0726 47.0642V47.1284C22.2822 47 23.5555 46.6789 24.7015 46.1011Z"
                    fill="white"
                  />
                  <path
                    d="M33.8691 45.0096C35.97 43.4044 37.5616 41.1571 38.7712 38.8456C40.2354 35.9563 41.1267 32.7459 41.636 29.6639C41.9543 27.6093 42.0817 25.5546 42.0817 23.5642C42.0817 21.5738 41.9543 19.5191 41.636 17.4645C41.1267 14.3183 40.2354 11.1079 38.7712 8.28279C37.5616 5.9071 35.97 3.72405 33.8691 2.11885C32.2775 0.898908 30.3039 0.128417 28.3303 0.064209V0.128417C30.2402 1.47678 31.8955 3.3388 33.1688 5.26503C36.161 9.75956 37.6252 15.153 38.0072 20.5464C38.0709 21.5738 38.1345 22.5369 38.1345 23.5642C38.1345 24.5915 38.0709 25.6189 38.0072 26.582C37.6252 31.9754 36.2246 37.3689 33.1688 41.8634C31.8955 43.7896 30.2402 45.6516 28.3303 47V47.0642C30.3039 46.9358 32.2775 46.1653 33.8691 45.0096Z"
                    fill="white"
                  />
                  <path
                    d="M24.7015 0.898907C24.1285 1.15574 23.6192 1.47678 23.1099 1.79781C22.9826 1.86202 22.9189 1.92623 22.7916 1.99044C22.155 2.43989 21.582 3.01776 21.0727 3.59563C20.3724 4.36612 19.7357 5.20082 19.1628 6.03552C18.7171 6.7418 18.2715 7.44809 17.9532 8.15437C16.4889 11.0437 15.5976 14.2541 15.0883 17.3361C14.77 19.3907 14.6426 21.4454 14.6426 23.4358C14.6426 25.4262 14.77 27.4809 15.0883 29.5355C15.5976 32.6817 16.4889 35.8921 17.9532 38.7172C18.1441 39.0383 18.2715 39.3593 18.4625 39.6803C19.2264 38.2678 19.8631 36.7268 20.3724 35.1858C19.4174 32.3607 18.9081 29.4071 18.6535 26.3893C18.5898 25.362 18.5898 24.3989 18.5261 23.3716C18.5261 22.3443 18.5261 21.3169 18.5898 20.3538C18.8444 16.7582 19.6084 13.0984 20.9453 9.75956C21.4547 8.47541 22.0913 7.19126 22.8553 5.97131C23.0462 5.71448 23.1736 5.39344 23.3646 5.13661C23.7465 4.55874 24.1285 4.04508 24.5742 3.46721C25.0835 2.82514 25.5928 2.24727 26.1658 1.73361C26.7387 1.15574 27.3754 0.577869 28.0757 0.128415C28.1393 0.0642077 28.203 0.0642077 28.2667 0V0C27.0571 0 25.8474 0.385246 24.7015 0.898907Z"
                    fill="white"
                  />
                  <path
                    d="M17.3802 0.898906C16.8072 1.15574 16.2979 1.47677 15.7886 1.79781C15.6613 1.86202 15.5976 1.92623 15.4703 1.99044C13.3694 3.59563 11.7778 5.8429 10.5682 8.15437C9.10394 11.0437 8.21265 14.2541 7.70334 17.3361C7.38502 19.3907 7.25769 21.4454 7.25769 23.4358C7.25769 25.4262 7.38502 27.4809 7.70334 29.5355C8.21265 32.6817 9.10394 35.8921 10.5682 38.7172C11.6505 40.9003 13.1147 42.9549 14.961 44.4959C15.534 43.8538 16.1069 43.1475 16.6162 42.4413C16.4253 42.1844 16.2343 41.9276 16.1069 41.6708C13.1147 37.1762 11.6505 31.7828 11.2685 26.3893C11.2048 25.362 11.1412 24.3989 11.1412 23.3716C11.1412 22.3443 11.2048 21.3169 11.2685 20.3538C11.6505 14.9604 13.0511 9.56694 16.1069 5.0724C16.4889 4.49453 16.8709 3.98087 17.3165 3.403C17.8259 2.76093 18.3352 2.18306 18.9081 1.6694C19.4811 1.09153 20.1177 0.51366 20.818 0.0642063C20.8817 -1.31875e-06 20.9454 -1.31875e-06 21.009 -0.064209V-1.31875e-06C19.7994 -1.31875e-06 18.5262 0.385245 17.3802 0.898906Z"
                    fill="white"
                  />
                  <path
                    d="M8.21261 2.05465C6.11171 3.65984 4.52012 5.9071 3.31051 8.21858C1.84625 11.1079 0.954955 14.3183 0.445646 17.4003C0.127327 19.4549 0 21.5096 0 23.5C0 25.4904 0.127327 27.5451 0.445646 29.5997C0.954955 32.7459 1.84625 35.9563 3.31051 38.7814C4.52012 41.1571 6.11171 43.3402 8.21261 44.9454C9.8042 46.1653 11.7778 46.9358 13.7514 47V46.9358C11.8414 45.5874 10.1862 43.7254 8.91291 41.7992C5.92072 37.3046 4.45646 31.9112 4.07447 26.5178C4.01081 25.4904 3.94715 24.5273 3.94715 23.5C3.94715 22.4727 4.01081 21.4454 4.07447 20.4822C4.45646 15.0888 5.92072 9.69536 8.91291 5.20082C10.1862 3.27459 11.8414 1.41257 13.7514 0.0642077V0C11.7778 0.0642077 9.8042 0.8347 8.21261 2.05465Z"
                    fill="white"
                  />
                  <path
                    d="M22.2822 44.6243C22.8552 43.9822 23.4282 43.276 23.9375 42.5697C23.7465 42.3128 23.5555 42.056 23.4282 41.7992C23.2372 41.5423 23.1099 41.2213 22.9189 40.9645C22.3459 41.8634 21.7093 42.6339 21.009 43.4044C21.4546 43.8538 21.8366 44.2391 22.2822 44.6243Z"
                    fill="white"
                  />
                  <path
                    d="M26.2295 45.2022C25.7202 45.5232 25.2109 45.8443 24.6379 46.1011C25.7838 46.6147 27.0571 46.9358 28.2667 47V46.9358C27.5664 46.4221 26.8661 45.8443 26.2295 45.2022Z"
                    fill="white"
                  />
                  <path
                    d="M20.3723 46.9358C20.5633 46.9358 20.7543 46.8716 20.9453 46.8716C20.7543 46.7432 20.5633 46.6147 20.3723 46.4221C19.863 46.0369 19.4174 45.6516 18.9717 45.2022C18.4624 45.5232 17.9531 45.8443 17.3801 46.1011C18.1441 46.4221 18.9081 46.6789 19.7357 46.8716C19.9267 46.9358 20.1177 46.9358 20.3723 46.9358ZM21.009 47V46.9358C20.9453 46.9358 20.9453 46.8716 20.8816 46.8716C20.6906 46.9358 20.4996 46.9358 20.3087 46.9358C20.5633 47 20.818 47 21.009 47Z"
                    fill="white"
                  />
                  <path
                    d="M19.7358 2.43988C19.1628 3.08196 18.5898 3.78824 18.0805 4.49452C18.2715 4.75136 18.4625 5.00819 18.5898 5.26502C18.7808 5.52185 18.9718 5.77868 19.0991 6.03551C19.6721 5.1366 20.3087 4.36611 21.009 3.59562C20.627 3.21037 20.1814 2.76092 19.7358 2.43988Z"
                    fill="white"
                  />
                  <path
                    d="M15.7886 1.79781C16.2979 1.47678 16.8072 1.15574 17.3802 0.898907C16.2342 0.385246 15.0246 0 13.7513 0V0.0642077C14.4516 0.577869 15.1519 1.15574 15.7886 1.79781Z"
                    fill="white"
                  />
                  <path
                    d="M24.7015 0.898907C23.9375 0.577869 23.1735 0.321038 22.3459 0.128415C22.1549 0.0642077 21.9639 0.0642077 21.7729 0.0642077C21.5183 0 21.2636 0 21.009 0V0.0642077C21.0726 0.0642077 21.0726 0.128415 21.1363 0.128415C21.3273 0.256831 21.5183 0.385246 21.7093 0.577869C22.2186 0.963115 22.6642 1.34836 23.1099 1.79781C23.6192 1.47678 24.1285 1.15574 24.7015 0.898907Z"
                    fill="white"
                  />
                  <path
                    d="M103.008 9.31009C103.517 9.31009 104.026 9.4385 104.472 9.69534C104.918 9.95217 105.3 10.3374 105.554 10.7869C105.809 11.2363 105.936 11.75 105.936 12.2636C105.936 12.7773 105.809 13.291 105.554 13.7404C105.3 14.1899 104.918 14.5751 104.472 14.8319C104.026 15.0888 103.517 15.2172 103.008 15.2172C102.499 15.2172 101.989 15.0888 101.544 14.8319C101.098 14.5751 100.716 14.1899 100.461 13.7404C100.207 13.291 100.079 12.7773 100.079 12.2636C100.079 11.75 100.207 11.2363 100.461 10.7869C100.716 10.3374 101.098 9.95217 101.544 9.69534C102.053 9.4385 102.499 9.31009 103.008 9.31009ZM103.071 9.88796C102.689 9.88796 102.244 10.0164 101.926 10.209C101.544 10.4016 101.225 10.7227 101.034 11.1079C100.843 11.4931 100.716 11.8784 100.716 12.3278C100.716 12.7773 100.843 13.1625 101.034 13.5478C101.225 13.933 101.544 14.2541 101.926 14.4467C102.308 14.6393 102.689 14.7677 103.135 14.7677C103.581 14.7677 103.963 14.6393 104.345 14.4467C104.727 14.2541 105.045 13.933 105.236 13.5478C105.427 13.1625 105.554 12.7773 105.554 12.3278C105.554 11.8784 105.427 11.4931 105.236 11.1079C105.045 10.7227 104.727 10.4016 104.345 10.209C103.835 10.0164 103.453 9.88796 103.071 9.88796Z"
                    fill="white"
                  />
                  <path
                    d="M104.09 13.1625C103.963 12.9699 103.899 12.8415 103.899 12.7773C103.835 12.6489 103.772 12.5847 103.708 12.5205C103.644 12.4563 103.581 12.4563 103.517 12.3921C103.772 12.3278 103.963 12.2636 104.09 12.1352C104.217 12.0068 104.281 11.8142 104.281 11.5574C104.281 11.4289 104.217 11.2363 104.154 11.1079C104.09 10.9795 103.963 10.8511 103.899 10.7869C103.772 10.7227 103.644 10.6584 103.453 10.6584C103.39 10.6584 103.071 10.6584 102.499 10.6584H101.862V13.6762H102.499V12.4563H102.626C102.817 12.4563 102.944 12.5205 103.071 12.5847C103.199 12.6489 103.326 12.9057 103.517 13.2268L103.772 13.7404H104.536L104.09 13.1625ZM103.326 12.071C103.262 12.071 103.008 12.1352 102.69 12.1352H102.435V11.3005H102.69C103.008 11.3005 103.262 11.3005 103.326 11.3647C103.39 11.3647 103.453 11.4289 103.517 11.4931C103.581 11.5574 103.581 11.6216 103.581 11.75C103.581 11.8142 103.581 11.9426 103.517 12.0068C103.517 11.9426 103.453 12.0068 103.326 12.071Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="106" height="47" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </Nav>
          <Nav>
            {/* <h2 style={{paddingTop:"4.5%", color: "white"}} className="mb-0 headerText">Gamification</h2> */}
          </Nav>

          {/* <NavLink className={`${s.navbarBrand} d-md-none ${chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""}`}>
          RTO
        </NavLink> */}
        </Navbar>
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    getHistoricalData: store.authentication.getHistoricalData,
    isGettingProjects: store.projects.isReceiving,
    token: store.authentication.token,
    user: store.authentication.userdata,
    userdataready: store.authentication.userdataready,
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    navbarType: store.layout.navbarType,
    navbarColor: store.layout.navbarColor,
    notifications: store.projects.notifications,
    dateLastUpdated: store.projects.dataLastupdated,

    // openUsersList: store.chat.openUsersList
  };
}

export default withRouter(connect(mapStateToProps)(Header));
