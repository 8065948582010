import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Table, Button, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap';
import DataTable from './DataTable'
import axios from 'axios';
import s from './TableContainer.module.scss';

const states = {
  sent: 'info',
  pending: 'success',
  declined: 'danger',
};

class RecipientTracker extends PureComponent {
  constructor(props) {
    super(props);
    this.state ={
      recipients: [],
      completed: []
    }
    this.sendCompleteConfirm = this.sendCompleteConfirm.bind(this);
  }


  sendCompleteConfirm(value, id){

    axios.defaults.headers ={
      "Content-Type": "application/json",
      "Authorization": `Token ${this.props.token}`
    } 
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    let data = {
      value: value,
      recipientid: id,
      templateid: this.props.currentRefreshEmailID
    } 
    axios.post(`/api/v1/refreshlogcomplete/${this.props.currentLogID}/`, data).then(
          (res) => {
          this.props.setRefreshLogs(res.data)
        },
          (error) => { this.setState({
            errorLogsData: error,
            doneFetchingLogs: true,
            fetchingError: false
          })}
        )
  }
  
  componentDidMount(){
    this.setState({
      recipients: this.props.details.recipients,
      completed: this.props.details.followupcompleted
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.details){
      if(prevProps.details !== this.props.details){
        this.setState({
          recipients: this.props.details.recipients,
          completed: this.props.details.followupcompleted
        })
      }
    }
   
  }

  render() {
    const { recipients, completed } = this.state;
  

    return (
      <Row >
        <Col lg={12}>
        <h3 className="mt-5">Campaign Recipient Status</h3>
        <p>The below table lists recipients of the selected campaign. If the broker has responded and the broker updates
           are completed, check the box next to the broker name to mark as compeleted.</p>
      <Row className="refreshLogsTable">
        <Col lg={12}> 
        {/* <Table className={cx('mb-0', s.table) } borderless responsive>
        <thead>
          <tr className="text-muted">
          <th scope="col"><span className="pl-2">Updates Complete</span></th>
          <th scope="col">Recipient Name</th>
          <th scope="col">Send Reminder</th>
          </tr>
        </thead>
        <tbody className="text-dark">
          {
            recipients.map(({ fullname, id, email}) => (
              <tr key={id}>
                <td className="check-width"><FormGroup className="checkbox abc-checkbox" check>
                      <Input id="checkbox1" type="checkbox" />{' '}&nbsp;
                      <Label for="checkbox1" check>
                        Default
                      </Label>
                  </FormGroup></td>
                <td>{fullname}</td>
                <td>
                  <Button
                    color="primary"
                    size="xs"
                    className="px-2"
                  >
                    Send Reminder
                  </Button>
                </td>
              </tr>
            ))
          }
        </tbody>
      </Table> */}
      {
        recipients.length > 0
        ?
        <DataTable token={this.props.token} currentLogID={this.props.currentLogID} sendCompleteConfirm={this.sendCompleteConfirm} recipients={recipients} completed={completed} />
        :
        null
      }
      </Col>
      </Row>
      </Col>
      </Row>
      
    );
  }
}

export default RecipientTracker;
