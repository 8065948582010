import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import { connect } from 'react-redux';
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class NationalBrokerPerformance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
      simpleSelectDropdownValue: 'Choose number displayed',

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };


  chartData = () => {
    const { nationalbrokerdata } = this.props;
    let data = nationalbrokerdata
    let numberofshownbrokers = 5
    let brokerchartlist = []
    let brokerlist = []

    brokerlist = data
    // data.forEach(item => {
    //   let foundperson = brokerlist.find(element => element.name === item.sourcingbroker)
    //
    //   //check if exists in array
    //   if(!(foundperson === undefined)){
    //
    //     if((item.winloss).toLowerCase() === "won"){
    //       foundperson.data.wins += 1
    //       foundperson.data.total += 1
    //     }else if((item.winloss).toLowerCase() === "lost"){
    //       foundperson.data.losses += 1
    //       foundperson.data.total += 1
    //     }else{
    //       foundperson.data.open += 1
    //       foundperson.data.total += 1
    //     }
    //   } else{
    //     let name = item.sourcingbroker
    //     if (name === ""){
    //       name = "Not Specified"
    //     }
    //     let newperson = {
    //       name: name,
    //       data: {
    //         wins: (item.winloss).toLowerCase() === "won" ? 1 : 0,
    //         losses: (item.winloss).toLowerCase() === "lost" ? 1 : 0,
    //         open: (item.winloss).toLowerCase() === "open" ? 1 : 0,
    //         total: 1
    //       }
    //     }
    //     brokerlist.push(newperson)
    //   }
    // })
    //
    brokerlist.sort((a,b) => parseFloat(b.data.total) - parseFloat(a.data.total))

    //Begin creation of chart data
    let categories = []
    let series = [
      {
      name: 'Wins',
      data:[],
      color: '#21AE8C',

      },
      {
      name: 'Losses',
      data: [],
      color: '#FD5F00',

      },
      {
      name: 'Open',
      data:[],
      color: '#1A86D0',
        }
      ]
      if(!(isNaN(this.state.simpleSelectDropdownValue))){
          numberofshownbrokers = this.state.simpleSelectDropdownValue
      }

//function(event){console.log("Open Event"); console.log(event)}

    brokerlist.every(item => {

      categories.push(item.name)
      series.find(item => (item.name).toLowerCase() === 'wins').data.push(item.data.wins)
      series.find(item => (item.name).toLowerCase() === 'losses').data.push(item.data.losses)
      series.find(item => (item.name).toLowerCase() === 'open').data.push(item.data.open)

      if (categories.length < numberofshownbrokers){
        return true
      } else{
        return false
      }
    })

    // this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
    return {
      ...this.chartOptions,
      series: series,
      xAxis: {
        min: 0,
        max: 4,
        scrollbar: {
            enabled: true
        },
        tickLength: 0,
        categories: categories
      },

    }
  };


  redirectToScorecard = (e) => {
    console.log(this)
    this.props.history.push("/app/reporting/brokerscorecard/"+e.target.point.category)
  }

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 200,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'bar'
    },
    title: false,
    exporting: {
      enabled: true
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {

      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },

    annotations: {
      visible: false
    },
    plotOptions: {
    series: {
    	stacking:'normal',
      point: {
        events: {
          // click: this.redirectToScorecard
        }
      }
    }
  },
  };




  // click: function(e) {
  //   let name = e.target.point.category
  //   console.log(e.target.point.category)
  //   this.redirectToScorecard(e.target.point.category)
  // }

  // click() {
  //   // (this.props.history.push("/app/brokerscorecard/"+this.series.name)).bind(this)
  //   this.redirectToScorecard(this.series.name)
  //
  //   console.log(this.series.name)
  //   console.log(this.category)
  // }


  changeSelectDropdownSimple = (e) => {
    var chart = this.refs.chartref.chart;
    this.setState({ simpleSelectDropdownValue: parseFloat(e.currentTarget.textContent) });

    chart.update(this.chartData())

  }

  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                <span className="fw-semi-bold">Top Brokers</span> By Opportunities Entered
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
         <UncontrolledButtonDropdown className="float-right">
           <DropdownToggle
             caret color="default"
             className="dropdown-toggle-split mr-xs"
           >
             {this.state.simpleSelectDropdownValue}
           </DropdownToggle>
           <DropdownMenu>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               5
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               20
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               50
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               100
             </DropdownItem>
           </DropdownMenu>
         </UncontrolledButtonDropdown>
      </Widget>
    );
  }
}




export default NationalBrokerPerformance
