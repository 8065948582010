import React, { Fragment } from "react";
import { connect } from "react-redux";
import chroma from "chroma-js";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
  Navbar,
  Nav,
  Dropdown,
  NavItem,
  Col,
  NavLink,
  // Badge,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  // InputGroupAddon,
  // InputGroup,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
// import cx from 'classnames';
import { NavbarTypes } from "../../reducers/layout";
import Notifications from "../Notifications";
import { logout, toggleHistoricalData } from "../../actions/authentication";
import EmailReports from "../../pages/emailreports/EmailReports";

import DataRefresh from "./DataRefresh";
// import Joyride, { STATUS } from "react-joyride";
import {
  toggleSidebar,
  openSidebar,
  closeSidebar,
  changeActiveSidebarItem,
} from "../../actions/navigation";
import {
  getProjectsRequest,
  getNewOppUpdates,
  getOppStatusUpdates,
  getOppDateUpdates,
  getNotificationsandUpdates,
  getMarketData,
} from "../../actions/projects";
import { readNotifications } from "../../actions/projects";

// import a5 from '../../images/people/a5.jpg';

import s from "./Header.module.scss";
var moment = require("moment");

class Header extends React.Component {
  static propTypes = {
    sidebarOpened: PropTypes.bool.isRequired,
    sidebarStatic: PropTypes.bool.isRequired,
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.toggleMenu = this.toggleMenu.bind(this);
    this.switchSidebar = this.switchSidebar.bind(this);
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.readNotifications = this.readNotifications.bind(this);
    this.state = {
      menuOpen: false,
      notificationsOpen: false,
      notificationsTabSelected: 1,
      focus: false,
      showNewMessage: false,
      hideMessage: true,
      run: true,
      notificationsRead: false,
      steps: [
        {
          content: "You can adjust sidebar, or leave it closed 😃",
          placement: "bottom",
          target: "#toggleSidebar",
          textAlign: "center",
          disableBeacon: true,
        },
        {
          content: "Admin can check out his messages and tasks easily 😃",
          placement: "bottom",
          target: ".dropdown-toggle",
        },
        {
          content:
            "Clickable cog can provide you with link to important pages 😄",
          placement: "bottom",
          target: ".tutorial-dropdown",
        },
        {
          content:
            "Open theme cusomizer sidebar, play with it or watch tour! ❤️",
          placement: "left",
          target: ".helper-button",
        },
      ],
    };
  }

  // handleJoyrideCallback = (CallBackProps) => {
  //   const { status } = CallBackProps;
  //   if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
  //     this.setState({ run: false });
  //   }
  // };

  refreshAllData = () => {
    if (this.props.token) {
      this.props.dispatch(getProjectsRequest(this.props.token));
      this.props.dispatch(getNewOppUpdates(this.props.token));
      this.props.dispatch(getOppStatusUpdates(this.props.token));
      this.props.dispatch(getOppDateUpdates(this.props.token));
      this.props.dispatch(getNotificationsandUpdates(this.props.token));
      this.props.dispatch(getMarketData(this.props.token));
    }
  };

  readNotifications = () => {
    this.props.dispatch(
      readNotifications(
        this.props.notifications,
        this.props.token,
        this.state.notificationsRead
      )
    );
    this.setState({
      notificationsRead: true,
    });
  };

  start = () => {
    this.setState({
      run: true,
    });
  };

  toggleFocus = () => {
    this.setState({ focus: !this.state.focus });
  };

  toggleNotifications() {
    this.setState({
      notificationsOpen: !this.state.notificationsOpen,
    });
  }

  doLogout() {
    this.props.history.push("/logout");
  }

  // collapse/uncolappse
  switchSidebar() {
    if (this.props.sidebarOpened) {
      this.props.dispatch(closeSidebar());
      this.props.dispatch(changeActiveSidebarItem(null));
    } else {
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      this.props.dispatch(openSidebar());
      this.props.dispatch(changeActiveSidebarItem(paths.join("/")));
    }
  }

  // static/non-static
  toggleSidebar() {
    this.props.dispatch(toggleSidebar());
    if (this.props.sidebarStatic) {
      localStorage.setItem("staticSidebar", "false");
      this.props.dispatch(changeActiveSidebarItem(null));
    } else {
      localStorage.setItem("staticSidebar", "true");
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      this.props.dispatch(changeActiveSidebarItem(paths.join("/")));
    }
  }

  logout() {
    this.props.dispatch(logout());
  }

  firstUserLetter() {
    let user = this.props.authentication.userdata;
    let letter = "";
    if (user.username.length() > 0) {
      letter = user.username[0].toUpperCase();
    } else if (user.email.length() > 0) {
      letter = user.email[0].toUpperCase();
    }
    return letter;
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }
  render() {
    const { focus } = this.state;
    const {
      navbarType,
      navbarColor,
      openUsersList,
      user,
      userdataready,
      notifications,
      dateLastUpdated,
      isGettingProjects,
    } = this.props;

    let formattedLastDate = moment(dateLastUpdated).format("MM/DD/YY k:mm");

    // const user = JSON.parse(localStorage.getItem('user') || '{}');
    // const firstUserLetter = (user.username | user.email || "P")[0].toUpperCase();

    return (
      <div>
        {userdataready ? (
          <Navbar
            className={`${s.root} d-print-none ${
              navbarType === NavbarTypes.FLOATING ? s.navbarFloatingType : ""
            }`}
            style={{
              backgroundColor: navbarColor,
              zIndex: !openUsersList ? 100 : 0,
            }}
          >
            <Nav>
              <NavItem>
                <NavLink
                  className="d-md-down-none ml-5"
                  id="toggleSidebar"
                  onClick={this.toggleSidebar}
                >
                  <i
                    className={`la la-bars ${
                      chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""
                    }`}
                  />
                </NavLink>
                <UncontrolledTooltip placement="bottom" target="toggleSidebar">
                  Turn on/off
                  <br />
                  sidebar
                  <br />
                  collapsing
                </UncontrolledTooltip>
                <NavLink
                  className="fs-lg d-lg-none"
                  onClick={this.switchSidebar}
                >
                  <span
                    className={`rounded rounded-lg d-md-none d-sm-down-block`}
                  >
                    <i
                      className="la la-bars"
                      style={{
                        fontSize: 30,
                        color:
                          navbarColor === "#ffffff"
                            ? "#ffffff"
                            : chroma(navbarColor).luminance() < 0.4
                            ? "#ffffff"
                            : "",
                      }}
                    />
                  </span>
                  <i
                    className={`la la-bars ml-3 d-sm-down-none ${
                      chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""
                    }`}
                  />
                </NavLink>
              </NavItem>
              {/*         
          <NavItem id="refreshDDData" className="d-sm-down-none">
            {
              isGettingProjects
              ?
             <NavLink className="px-2"  >
                <div className="loader animated fadeIn handle ">
                  <span className="spinner">
                    <small className="text-muted">Fetching data...</small>
                    <i className="fa fa-spinner fa-spin" />
                  </span>
                </div>
              </NavLink>
              :
              <NavLink onClick={this.refreshAllData} className="px-2">
            {
              dateLastUpdated
              ?
              <DataRefresh />
              :
              null
            }
          </NavLink>
            }
            <UncontrolledTooltip placement="bottom" target="refreshDDData">
              Fetch recent<br />data
            </UncontrolledTooltip>
          </NavItem>
          <NavItem>
            <FormGroup className="display-inline-block checkbox-ios">
              <Label for="checkbox-ios1" className="switch">
                <Input
                  type="checkbox" className="ios" defaultChecked={this.props.getHistoricalData}
                  id="checkbox-ios1"
                  onClick={() => this.props.dispatch(toggleHistoricalData())}
                /><i />
              </Label>
            </FormGroup>
          </NavItem> */}
            </Nav>
            <NavLink
              className={`${s.navbarBrand} d-md-none ${
                chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""
              }`}
            >
              <i className="fa fa-circle text-primary mr-n-sm" />
              <i className="fa fa-circle text-jllred" />
              &nbsp; SE &nbsp;
              <i className="fa fa-circle text-jllred mr-n-sm" />
              <i className="fa fa-circle text-primary" />
            </NavLink>

            {/* Notifications section */}
            <Nav className="ml-auto">
              {userdataready ? (
                <Dropdown
                  nav
                  isOpen={this.state.notificationsOpen}
                  toggle={this.toggleNotifications}
                  onClick={this.readNotifications}
                  id="basic-nav-dropdown"
                  // className={`${s.notificationsMenu} d-sm-down-none`}
                >
                  <DropdownToggle
                    nav
                    // caret
                    className={`${
                      chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""
                    }`}
                  >
                    <span
                      className={`${s.avatar} rounded-circle thumb-sm float-left mr-2`}
                    >
                      <span>
                        {(user.username || user.email)[0].toUpperCase()}
                      </span>
                    </span>
                    <span
                      className={`small ${
                        this.props.sidebarStatic ? s.adminEmail : ""
                      } ${
                        chroma(navbarColor).luminance() < 0.4
                          ? "text-white"
                          : ""
                      }`}
                    >
                      {user.username || user.email || ""}
                    </span>

                    <span className="ml-1 circle bg-primary text-white fw-bold">
                      {notifications.length}
                    </span>
                  </DropdownToggle>

                  <DropdownMenu
                    right
                    className={`${s.notificationsWrapper} py-0 animated animated-fast fadeInUp`}
                  >
                    <Notifications />
                  </DropdownMenu>
                </Dropdown>
              ) : null}
              <Dropdown
                nav
                isOpen={this.state.menuOpen}
                toggle={this.toggleMenu}
                className="d-sm-down-none tutorial-dropdown pr-4"
              >
                <DropdownToggle nav>
                  <i
                    className={`la la-cog ${
                      chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""
                    }`}
                  />
                </DropdownToggle>
                <DropdownMenu right className={`super-colors`}>
                  <Link to={"/app/reporting/calendar"}>
                    <DropdownItem>Calendar</DropdownItem>
                  </Link>
                  {/* <Link to={"/app/profile/emailreports"}>
                    <DropdownItem>Email Reports</DropdownItem>
                  </Link> */}
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Navbar>
        ) : null}
      </div>
    );
  }
}

// <Joyride
//   callback={this.handleJoyrideCallback}
//   continuous={true}
//   run={this.state.run}
//   showSkipButton={true}
//   steps={this.state.steps}
//   spotlightPadding={-10}
//   disableOverlay={true}
//   disableScrolling
//   styles={{
//     options: {
//       arrowColor: '#ffffff',
//       backgroundColor: '#ffffff',
//       overlayColor: 'rgba(79, 26, 0, 0.4)',
//       primaryColor: '#000',
//       textColor: '#495057',
//       spotlightPadding: 0,
//       zIndex: 1000,
//       padding: 5,
//       width: 240,
//     },
//     tooltip: {
//       fontSize: 15,
//       padding: 15,
//     },
//     tooltipContent: {
//       padding: '20px 5px 0',
//     },
//     floater: {
//       arrow: {
//         padding: 10
//       },
//     },
//     buttonClose: {
//       display: 'none'
//     },
//     buttonNext: {
//       backgroundColor: "#21AE8C",
//       fontSize: 13,
//       borderRadius: 4,
//       color: "#ffffff",
//       fontWeight: "bold",
//       outline: "none"
//     },
//     buttonBack: {
//       color: "#798892",
//       marginLeft: 'auto',
//       fontSize: 13,
//       marginRight: 5,
//     },
//     buttonSkip: {
//       color: "#798892",
//       fontSize: 13,
//     },
//   }}
// />

// <NavItem className="d-sm-down-none">
//    <NavLink className="px-2">
//      <i className={`la la-refresh ${chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""}`}/>
//    </NavLink>
//  </NavItem>
//  <NavItem className="d-sm-down-none">
//    <NavLink className="px-2">
//      <i className={`la la-times ${chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""}`} />
//    </NavLink>
//  </NavItem>

//
// <Form className={`d-sm-down-none ml-5 ${s.headerSearchInput}`} inline>
//   <FormGroup>
//     <InputGroup onFocus={this.toggleFocus} onBlur={this.toggleFocus} className={
//       cx('input-group-no-border', {'focus' : !!focus})
//     }>
//       <InputGroupAddon addonType="prepend">
//         <i className="la la-search" />
//       </InputGroupAddon>
//       <Input id="search-input" placeholder="Search Dashboard" className={cx({'focus' : !!focus})} />
//     </InputGroup>
//   </FormGroup>
// </Form>

function mapStateToProps(store) {
  return {
    getHistoricalData: store.authentication.getHistoricalData,
    isGettingProjects: store.projects.isReceiving,
    token: store.authentication.token,
    user: store.authentication.userdata,
    userdataready: store.authentication.userdataready,
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    navbarType: store.layout.navbarType,
    navbarColor: store.layout.navbarColor,
    notifications: store.projects.notifications,
    dateLastUpdated: store.projects.dataLastupdated,

    // openUsersList: store.chat.openUsersList
  };
}

export default withRouter(connect(mapStateToProps)(Header));
