import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class TopBrokerWinRate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
      opps: [],
      simpleSelectDropdownValue: 'Choose number displayed',

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  getbrokerlist(opps){
    let blist = []
  
    let brokers = opps.map(({sourcingbroker}) => [sourcingbroker])
  
    brokers.forEach(item => blist.push(item))
    let sbrokers = opps.map(({
      sourcingbroker
    }) => [sourcingbroker])
    sbrokers.forEach(item => blist.push(item))
    let merged = [].concat.apply([], blist)
    let newarray = []
    merged.forEach(item => {
      if (item.includes(",")) {
        let newitem = item.split(', ')
        newarray.push(newitem)
      } else {
        newarray.push(item)
      }
    })
    merged = [].concat.apply([], newarray)
    blist = [...new Set(merged)]
    blist = blist.sort()
 
    return blist
  }

  cleanPersonList(person){
    let list =[]
    person.forEach(item => list.push(item.fullname))
    let theperson = list.join(", ")
    return theperson
  }

  chartData = () => {
    let numberofshownbrokers = 5
    let brokerchartlist = []
    let brokerlist = []
    let data = this.state.opps
    // const { opps } = this.state;

    
    let cleandata = data.map(project => {
      return {
        id: project.opportunityid,
        datecreated: project.datecreated,
        sourcingbroker: `${this.cleanPersonList(project.sourcingbroker)}`,
        meetingtype: project.meetingtype,
        opportunityid: project.opportunityid,
        winloss: project.winloss,
      };
    })

    let allbrokers = this.getbrokerlist(cleandata)

    let winratelist = []

    // get the win rate of each broker
    allbrokers.forEach(item =>{

      let concludedopps = data.filter(item2 => item2.sourcingbroker.length > 0)
       concludedopps = concludedopps.filter(item2 => item2.sourcingbroker[0].fullname === item)
       concludedopps = concludedopps.filter(item2 => item2.winloss === "Won" || item2.winloss === "Lost")
      if(concludedopps.length > 4){
        let wins = concludedopps.filter(item3 => item3.winloss === "Won").length
        let losses = concludedopps.filter(item4 => item4.winloss === "Lost").length
        let winrate = parseInt(((wins / (wins + losses)) * 100).toFixed(1))
        if(winrate === NaN){
          winrate = 0
        }
        let newobj = {'brokername': item, 'winrate': winrate}
        if(winrate > 0){
          winratelist.push(newobj)
        }
      }
    })
    winratelist = winratelist.sort((a,b) => b.winrate - a.winrate)
    // cleandata.forEach(item => {
    //   let foundperson = brokerlist.find(element => element.name === item.sourcingbroker)

    //   //check if exists in array
    //   if(!(foundperson === undefined)){

    //     if((item.winloss).toLowerCase() === "won"){
    //       foundperson.data.wins += 1
    //       foundperson.data.total += 1
    //     }else if((item.winloss).toLowerCase() === "lost"){
    //       foundperson.data.losses += 1
    //       foundperson.data.total += 1
    //     }else{
    //       foundperson.data.open += 1
    //       foundperson.data.total += 1
    //     }
    //   } else{
    //     let name = item.sourcingbroker
    //     if (name === ""){
    //       name = "Not Specified"
    //     }
    //     let newperson = {
    //       name: name,
    //       data: {
    //         wins: (item.winloss).toLowerCase() === "won" ? 1 : 0,
    //         losses: (item.winloss).toLowerCase() === "lost" ? 1 : 0,
    //         open: (item.winloss).toLowerCase() === "open" ? 1 : 0,
    //         total: 1
    //       }
    //     }
    //     brokerlist.push(newperson)
    //   }
    // })


    //Begin creation of chart data
    let categories = []
    let series = [
      {
      name: 'Winrate',
      data:[],
      },
      ]
      if(!(isNaN(this.state.simpleSelectDropdownValue))){
          numberofshownbrokers = this.state.simpleSelectDropdownValue
      }

//function(event){console.log("Open Event"); console.log(event)}

winratelist.every(item => {

      categories.push(item.brokername)
      series.find(item => (item.name).toLowerCase() === 'winrate').data.push(item.winrate)

      if (categories.length < numberofshownbrokers){
        return true
      } else{
        return false
      }
    })

    let ytotal = 100

   
    // this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
    return {
      ...this.chartOptions,
      series: series,

      tooltip: {
        pointFormat: 'Win Rate: <b>{point.y:.1f}</b>%'
    },
      yAxis: {
        min: 0,
        max: ytotal,
        labels: {
          style: {
            color: "#000000"
          }
        },
        title:{
          text: "Win Rate by %"
        },
        tooltip: {
          pointFormat: 'Win Rate: <b>{point.y:.1f}</b> %'
      },
      },
      xAxis: {
        min: 0,
        max: 5,
        scrollbar: {
            enabled: true
        },
        tickLength: 0,
        categories: categories
      },

    }
  };


  redirectToScorecard = (e) => {
    console.log(this)
    this.props.history.push("/app/reporting/brokerscorecard/"+e.target.point.category)
  }

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 200,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'bar'
    },
    title: false,
    exporting: {
      enabled: true,
      chartOptions: {
        title: {
          text: null
        }
      }
    },
   
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      title: false,

      tooltip: {
        pointFormat: 'Win Rate: <b>{point.y:.1f}</b> %'
    },
      labels: {
        style: {
          color: "#000000"
        }
      }
    },

    annotations: {
      visible: false
    },
    plotOptions: {
    series: {
    	stacking:'normal',
      point: {
        events: {
          click: this.redirectToScorecard
        }
      }
    }
  },
  };




  // click: function(e) {
  //   let name = e.target.point.category
  //   console.log(e.target.point.category)
  //   this.redirectToScorecard(e.target.point.category)
  // }

  // click() {
  //   // (this.props.history.push("/app/brokerscorecard/"+this.series.name)).bind(this)
  //   this.redirectToScorecard(this.series.name)
  //
  //   console.log(this.series.name)
  //   console.log(this.category)
  // }


  changeSelectDropdownSimple = (e) => {
    var chart = this.refs.chartref.chart;
    this.setState({ simpleSelectDropdownValue: parseFloat(e.currentTarget.textContent) });
    chart.update(this.chartData())
  
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(prevProps.data !== this.props.data){
      this.setState({
        opps: this.props.data
      })
    }
  }

  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5} lg={10}>
              <h5>
                {
                  this.props.customtitle
                  ?
                  <span>{this.props.customtitle}</span>
                  :
                  <span>Top Brokers By<span className="fw-semi-bold"> Win Rate</span> </span>
                }

              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
         <UncontrolledButtonDropdown className="float-right">
           <DropdownToggle
             caret color="default"
             className="dropdown-toggle-split mr-xs"
           >
             {this.state.simpleSelectDropdownValue}
           </DropdownToggle>
           <DropdownMenu>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               5
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               20
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               50
             </DropdownItem>
             <DropdownItem onClick={this.changeSelectDropdownSimple}>
               100
             </DropdownItem>
           </DropdownMenu>
         </UncontrolledButtonDropdown>
      </Widget>
    );
  }
}

export default withRouter(TopBrokerWinRate)
