import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import exporting from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";

exporting(Highcharts);
exportData(Highcharts);

function getQuarter(dateString) {
  const date = new Date(dateString);
  const month = date.getMonth();
  const year = date.getFullYear();
  return `Q${Math.floor(month / 3) + 1} ${year}`;
}
// Helper function to get the quarter for a given date string
function getChartData(data) {
  const quarters = {};
  const seriesData = {
    Open: {},
    Won: {},
  };

  const sortedData = data.sort(
    (a, b) => new Date(a.est_start_date) - new Date(b.est_start_date)
  );

  sortedData.reduce((_, item) => {
    const quarter = getQuarter(item.est_start_date);
    quarters[quarter] = quarter;
    if (item.winloss === "Open" && item.est_revenue > 1) {
      seriesData.Open[quarter] =
        (seriesData.Open[quarter] || 0) + item.est_revenue;
      seriesData.Won[quarter] = seriesData.Won[quarter] || 0;
    } else if (item.winloss === "Won" && item.est_revenue > 1) {
      seriesData.Won[quarter] =
        (seriesData.Won[quarter] || 0) + item.est_revenue;
      seriesData.Open[quarter] = seriesData.Open[quarter] || 0;
    }

    return null;
  }, null);

  // Create the series array for Highcharts
  const tempSeries = [
    {
      name: "Open",
      data: Object.values(seriesData.Open),
    },
    {
      name: "Won",
      data: Object.values(seriesData.Won),
    },
  ];

  // Get the quarters and create the categories array for Highcharts
  const tempCategories = Object.keys(quarters).map((key) => quarters[key]);

  return {
    series: tempSeries,
    categories: tempCategories,
  };
}

const staticOptions = {
  chart: {
    type: "column",
  },
  title: {
    text: "",
  },
  credits: {
    enabled: false,
  },
  yAxis: {
    title: {
      text: "Total Est. Revenue",
    },
  },

  plotOptions: {
    column: {
      dataLabels: {
        enabled: true,
        formatter: function () {
          return "$" + Highcharts.numberFormat(this.y, 0, ".", ",");
        },
      },
    },
  },
};

const EstRevenueQuarter = ({ data }) => {
  const [categories, setCategories] = React.useState([]);
  const [series, setSeries] = React.useState([]);

  const options = {
    ...staticOptions,
    xAxis: {
      categories: categories,
    },
    series: series,
  };

  React.useEffect(() => {
    const { series: tempSeries, categories: tempCategories } =
      getChartData(data);

    setCategories(tempCategories);
    setSeries(tempSeries);
  }, [data]);

  return (
    <Widget
      bodyClass="mt"
      className="mb-xlg"
      collapse
      close
      title={
        <Row>
          <Col xs={12} sm={5}>
            <h5>
              Est. Revenue <span className="fw-semi-bold">By Quarter</span>
            </h5>
          </Col>
        </Row>
      }
    >
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Widget>
  );
};

export default EstRevenueQuarter;
