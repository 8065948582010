import React, { Fragment } from "react";
import {
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import { pageLoading } from "../../actions/layout";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Widget from "../../components/Widget";
import axios from "axios";
import { updateUserProfile } from "../../actions/authentication";
import s from "./DashboardPicker.module.scss";

class MarketSelector extends React.Component {
  constructor(props) {
    super(props);

    this.removeDashboard = this.removeDashboard.bind(this);
    this.startLoading = this.startLoading.bind(this);
  }

  componentDidMount() {}
  startLoading() {
    this.props.dispatch(pageLoading(true));
  }
  removeDashboard(dashboardid) {
    // Check inputs
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.post(`/api/v1/userdashboardremove/${dashboardid}/`).then((res) => {
      this.props.dispatch(updateUserProfile(res.data));
    });
  }

  render() {
    const { user, marketdata, marketdataready } = this.props;

    let markets = [];

    for (var o in user.groups) {
      markets.push(user.groups[o].name);
    }

    function formatNumber(num) {
      return num?.toString()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    markets.sort();

    const sortedmarkets = []
      .concat(marketdata)
      .sort((a, b) =>
        a.marketname > b.marketname ? 1 : b.marketname > a.marketname ? -1 : 0
      );
    let nationalmarketinfo = sortedmarkets.find(
      (item) => item.marketname === "National"
    );

    return (
      <div className={s.root}>
        {marketdataready ? (
          <Fragment>
            <h1 className="page-title">
              Markets &nbsp;
              <br></br>
              <small>
                <small>Choose a market to see a dashboard.</small>
              </small>
            </h1>

            <Row>
              {markets.includes("National") &&
              nationalmarketinfo &&
              !markets.includes("All Markets") ? (
                <Col lg={12} xs={12}>
                  <Widget title={<h2>National</h2>}>
                    <div className="widget-body market-stats-widget undo_padding">
                      <Widget className=" bg-transparent">
                        <div className="stats-row">
                          <div className="stat-item">
                            <span className="fw-semi-bold">
                              <h5 className="name market">Win Rate</h5>
                            </span>
                            <p>{nationalmarketinfo.winrate}%</p>
                          </div>
                          <div className="stat-item">
                            <span className="fw-semi-bold">
                              <h5 className="name market">
                                Total Opportunities
                              </h5>
                            </span>
                            <p>{formatNumber(nationalmarketinfo.totalopps)}</p>
                          </div>
                        </div>
                      </Widget>
                    </div>
                    <footer className="bg-body-light mt">
                      <Link to={`/app/dashboard/National`}>
                        <Button
                          className="btn-block btn-primary"
                          color="primary"
                        >
                          View Dashboard
                        </Button>
                      </Link>
                    </footer>
                  </Widget>
                </Col>
              ) : null}
              {markets.includes("All Markets") && nationalmarketinfo ? (
                <Col lg={12} xs={12}>
                  <Widget title={<h2>National</h2>}>
                    <div className="widget-body market-stats-widget undo_padding">
                      <Widget className=" bg-transparent">
                        <div className="stats-row">
                          <div className="stat-item">
                            <span className="fw-semi-bold">
                              <h5 className="name market">Win Rate</h5>
                            </span>
                            <p>{nationalmarketinfo.winrate}%</p>
                          </div>
                          <div className="stat-item">
                            <span className="fw-semi-bold">
                              <h5 className="name market">
                                Total Opportunities
                              </h5>
                            </span>
                            <p>{formatNumber(nationalmarketinfo.totalopps)}</p>
                          </div>
                        </div>
                      </Widget>
                    </div>
                    <footer className="bg-body-light mt">
                      <Link to={`/app/dashboard/1000`}>
                        <Button
                          className="btn-block btn-primary"
                          color="primary"
                        >
                          View Dashboard
                        </Button>
                      </Link>
                    </footer>
                  </Widget>
                </Col>
              ) : null}

              {sortedmarkets.map((item, index) => {
                if (
                  !(
                    item.marketname === "National" ||
                    item.marketname === "monthlyreport" ||
                    item.marketname === "All Markets"
                  )
                ) {
                  return (
                    <Col key={index} lg={4} xs={12}>
                      <Widget title={<h2>{item.marketname} </h2>}>
                        <div>
                          <div className="stats-row">
                            <div className="stat-item">
                              <span className="fw-semi-bold">
                                <h5 className="name market">Win Rate</h5>
                              </span>
                              <p>{item.winrate}%</p>
                            </div>
                            <div className="stat-item">
                              <span className="fw-semi-bold">
                                <h5 className="name market">
                                  Total Opportunities
                                </h5>
                              </span>
                              <p>{formatNumber(item.totalopps)}</p>
                            </div>
                          </div>
                        </div>
                        <footer className="bg-body-light mt">
                          <Link to={`/app/dashboard/${item.market_id}`}>
                            <Button
                              className="btn-block btn-primary"
                              color="primary"
                            >
                              View Dashboard
                            </Button>
                          </Link>
                        </footer>
                      </Widget>
                    </Col>
                  );
                }
              })}
            </Row>
          </Fragment>
        ) : (
          <div className="loader animated fadeIn handle initialLoader">
            <span className="spinner">
              <i className="fa fa-spinner fa-spin" />
            </span>
            <Col xs={12} md={12} lg={12} xl={12}>
              Loading Data...
            </Col>
          </div>
        )}
        {this.props.userprofile.dashboards.length > 0 ? (
          <Fragment>
            <h1 className="page-title">
              User Dashboards &nbsp;
              <br></br>
              <small>
                <small>
                  The following are custom dashboards with preset filters and
                  chart arrangements. To create a custom dashboard, navigate to
                  a market dashboard and begin filtering.
                </small>
              </small>
            </h1>

            <Row>
              {this.props.userprofile.dashboards.map((item, index) => {
                let themarkets = item.market;
                themarkets = themarkets.map((item2) => item2.marketname);
                let thesettings = JSON.parse(item.settings);
                let filterRows = thesettings.friendlyFilters;

                return (
                  <Col
                    style={{ marginBottom: "1%", marginTop: "1%" }}
                    key={index}
                    lg={4}
                    xs={12}
                  >
                    <Widget
                      style={{
                        height: "100%",
                      }}
                      title={<h2>{thesettings.dashboardname}</h2>}
                    >
                      <div className="float-right">
                        <UncontrolledDropdown>
                          <DropdownToggle nav>
                            <i class="fa fa-ellipsis-h"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem>
                              <Button
                                className="btn-link"
                                onClick={() => {
                                  this.removeDashboard(item.dashboardid);
                                }}
                              >
                                Remove Dashboard
                              </Button>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="widget-body market-stats-widget undo_padding">
                        <Widget className=" bg-transparent">
                          {thesettings.dashboarddesc.length ? (
                            <div className="stats-row">
                              <strong>Description:</strong>&nbsp;
                              {thesettings.dashboarddesc}
                            </div>
                          ) : null}

                          {/* TODO iterate over the filters. they were just added to the settings json */}
                          {filterRows.length > 0 ? (
                            <div className="stats-row">
                              <strong>Filters:</strong>
                              <br />
                              {filterRows.map((item, index) => {
                                if (filterRows.length === index + 1) {
                                  return item.value;
                                } else {
                                  return `${item.value}, `;
                                }
                              })}
                            </div>
                          ) : null}

                          {themarkets.length > 0 ? (
                            <div className="stats-row">
                              <strong>
                                {themarkets.length === 1
                                  ? "Market:"
                                  : "Markets:"}
                              </strong>
                              <br />
                              {themarkets.map((item, index) => {
                                if (themarkets.length === index + 1) {
                                  return item;
                                } else {
                                  return `${item}, `;
                                }
                              })}
                            </div>
                          ) : null}
                        </Widget>
                      </div>
                      <footer
                        style={{
                          position: "absolute",
                          width: "100%",
                          bottom: "3.5%",
                        }}
                        className="bg-body-light mt"
                      >
                        <Link
                          to={`/app/dashboard/userdashboard/${item.dashboardid}`}
                        >
                          <Button
                            className="btn-block btn-primary"
                            color="primary"
                          >
                            View Dashboard
                          </Button>
                        </Link>
                      </footer>
                    </Widget>
                  </Col>
                );
              })}
            </Row>
          </Fragment>
        ) : null}
      </div>
    );
  }
}

//
// <Row>
//
// <Col lg={7}>
//   <Widget className="bg-transparent">
//
//   </Widget>
// </Col>
// <Col lg={1} />
// </Row>

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    user: state.authentication.userdata,
    userprofile: state.authentication.userprofile,
    marketdataready: state.projects.marketdataready,
    userdataready: state.authentication.userdataready,
    marketdata: state.projects.marketdata,
  };
}

export default connect(mapStateToProps)(MarketSelector);
