import React from "react";
import {
  Button,
  Progress,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { withRouter } from "react-router";
import { Col, Row } from "reactstrap";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
// import {
//   TableHeaderColumn,
//   ExportCSVButton
// } from 'react-bootstrap-table';
import ExportAllButton from "./export_all_button";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { reactTableData, reactBootstrapTableData } from "./data";
import Widget from "../../../../components/Widget/Widget";
import s from "./Dynamic.modules.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
var moment = require("moment");

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const states = {
  won: "success",
  lost: "danger",
  dormant: "warning",
  open: "info",
  pending: "warning",
};

class Dynamic extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reactTable: reactTableData(),
      reactBootstrapTable: reactBootstrapTableData(),
    };
  }

  cleanServicesList(services) {
    let list = [];
    services.forEach((item) => list.push(item.servicename));
    return list;
  }

  cleanMarketList(market) {
    let list = [];
    market.forEach((item) => list.push(item.marketname));
    let themarket = list.join(", ");
    return themarket;
  }
  cleanPersonList(person) {
    let list = [];
    person.forEach((item) => list.push(item.fullname));
    // let theperson = list.join(", ")
    return list;
  }

  cleanSourcingMethodList(method) {
    let list = [];
    method.forEach((item) => list.push(item.methodname));
    return list;
  }
  cleanServiceList(serviceline) {
    let list = [];
    serviceline.forEach((item) => list.push(item.servicelinename));
    let theservice = list.join(", ");
    return theservice;
  }
  cleanPracticeGroups(method) {
    let list = [];
    method.forEach((item) => list.push(item.name));
    return list;
  }

  cleanCompany(company) {
    let list = [];
    company.forEach((item) => list.push(item.clientconame));
    return list;
  }

  cleanCompanyContact(company) {
    let list = [];
    company.forEach((item) => list.push(item.clientcontactname));
    return list;
  }

  cleanTechList(tech) {
    let list = [];
    tech.forEach((item) => list.push(item.techname));
    let thetool = list.join(", ");
    return thetool;
  }
  cleanCompanyIndustry(company) {
    let list = [];
    company.forEach((item) => list.push(item.clientindustry));
    return list;
  }

  redirectToScorecard = (e) => {
    console.log(this);
    // this.props.history.push("/app/brokerscorecard/"+e.target.point.category)
  };

  renderSizePerPageDropDown = (props) => {
    const limits = [];
    props.sizePerPageList.forEach((limit) => {
      limits.push(
        <DropdownItem
          key={limit}
          onClick={() => props.changeSizePerPage(limit)}
        >
          {limit}
        </DropdownItem>
      );
    });

    return (
      <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
        <DropdownToggle color="default" caret>
          {props.currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>{limits}</DropdownMenu>
      </Dropdown>
    );
  };

  render() {
    const { data } = this.props;
    const options = {
      sizePerPage: 10,
      scrollX: true,
      paginationSize: 3,

      sizePerPageDropDown: this.renderSizePerPageDropDown,
      ExportCSVButton,
    };

    data.sort(function (a, b) {
      var dateA = moment(a.datecreated, "MM-DD-YYYY hh:mm:ss"),
        dateB = moment(b.datecreated, "MM-DD-YYYY hh:mm:ss");
      return dateB - dateA;
    });

    function infoFormatter(cell, row, index) {
      return (
        <div style={{ width: 100 }}>
          <Link to={"/app/reporting/opportunity/" + row.opportunityid}>
            <Button
              color={states[cell.toLowerCase()]}
              size="xs"
              className="px-2"
            >
              {cell}
            </Button>
          </Link>
        </div>
      );
    }

    function formatNumber(num) {
      return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    function numberFormatter(cell) {
      return (
        <div style={{ width: 150 }}>
          <small style={{ width: 100 }}>{formatNumber(cell)}</small>
        </div>
      );
    }

    function revFormatter(cell) {
      return (
        <div style={{ width: 150 }}>
          <small style={{ width: 100 }}>
            {cell ? "$" + cell.toLocaleString() : ""}
          </small>
        </div>
      );
    }

    function dateFormatter(cell) {
      if (cell) {
        return (
          <div style={{ width: 100 }}>
            {moment(cell, "MM-DD-YYYY hh:mm:ss").format("MM/DD/YYYY")}
          </div>
        );
      } else {
        return <div style={{ width: 100 }}></div>;
      }
    }

    function generalText(cell) {
      return (
        <div style={{ width: 150 }}>
          <small>{cell?.toString()}</small>
        </div>
      );
    }

    function digitaladvisorformater(cell) {
      return (
        <div style={{ width: 150 }}>
          <small>
            {cell.map(function (item, i) {
              if (item.role.length > 0) {
                if (item.role[0].rolename == "Digital Advisor") {
                  return <span>{item.fullname + " "}</span>;
                } else {
                  return "";
                }
              } else {
                return "";
              }
            })}
          </small>
          <br />
        </div>
      );
    }

    function brokerListFormatter(cell) {
      let type = typeof cell;
      return (
        <div style={{ width: 150 }}>
          <small>
            {cell.map(function (item, i) {
              if (cell.length == i + 1) {
                return (
                  <Link to={"/app/reporting/brokerscorecard/" + item}>
                    {item + " "}
                  </Link>
                );
              } else {
                return (
                  <Link to={"/app/reporting/brokerscorecard/" + item}>
                    {item + ", "}
                  </Link>
                );
              }
            })}
          </small>
          <br />
        </div>
      );
    }

    function sememberformater(cell) {
      return (
        <div style={{ width: 150 }}>
          <small>
            {cell.map((item, index) => {
              if (item.role.length > 0) {
                if (item.role[0].rolename == "Sales Enablement Member") {
                  return <span>{item.fullname + " "}</span>;
                } else {
                  return "";
                }
              } else {
                return "";
              }
            })}
          </small>
          <br />
        </div>
      );
    }

    function semembercsvformater(cell) {
      return cell.map((item) => {
        if (item.role.length > 0) {
          if (item.role[0].rolename == "Sales Enablement Member") {
            return item.fullname + " ";
          }
        }
      });
    }

    function digitaladvisorcsvformater(cell) {
      return cell.map((item) => {
        if (item.role.length > 0) {
          if (item.role[0].rolename == "Digital Advisor") {
            return item.fullname + " ";
          }
        }
      });
    }

    function daFilter(cell, row) {
      return cell.map((item) => {
        if (item.role.length > 0) {
          if (item.role[0].rolename == "Digital Advisor") {
            return item.fullname;
          }
        }
      });
    }

    function seFilter(cell, row) {
      return cell.map((item) => {
        if (item.role.length > 0) {
          if (item.role[0].rolename == "Sales Enablement Member") {
            return item.fullname;
          }
        }
      });
    }

    function sourcingmethodformat(cell) {
      return (
        <div style={{ width: 100 }}>
          <small>
            {cell.map(function (item, index) {
              if (!(item == " ") && !(item == "")) {
                if (cell.length == index + 1) {
                  return <span>{item}</span>;
                } else {
                  return <span>{item + ", "}</span>;
                }
              }
            })}
          </small>
          <br />
        </div>
      );
    }

    function editFormatter(cell) {
      return (
        <div style={{ width: 150 }}>
          <a
            href={"https://aero.jll.com/prospecting/opportunity/" + cell}
            target="blank"
          >
            <Button color="#e30615" size="xs" className="px-2 jll-edit">
              Edit
            </Button>
          </a>
        </div>
      );
    }

    function servicelineformatter(cell) {
      return (
        <div style={{ width: 150 }}>
          <small>{cell}</small>
        </div>
      );
    }

    function pursuitFormatter(cell) {
      return (
        <div style={{ width: 100 }}>
          <small>
            <Link to={"/app/reporting/opportunity/" + cell}>{cell}</Link>
          </small>
          <br />
        </div>
      );
    }

    function cleanDealType(dealType) {
      let list = [];
      dealType.forEach((item) => list.push(item.name));
      let dealT = list.join(", ");
      return dealT;
    }

    let cleandata = data.map((project) => {
      return {
        id: project.opportunityid,
        pursuitname: project.pursuitname,
        datecreated: project.datecreated,
        confidential: project.confidential,
        clientcompany: this.cleanCompany(project.clientcompany),
        clientcompanycontact: this.cleanCompanyContact(project.clientcompany),
        clientcompanyindustry: this.cleanCompanyIndustry(project.clientcompany),
        type: project.type,
        sourcingbroker: this.cleanPersonList(project.sourcingbroker),
        bdmember: project.bdmember,
        meetingtype: project.meetingtype,
        date_closed: project.date_closed,
        broker: this.cleanPersonList(project.broker),
        meetingdate: project.meetingdate,
        opportunityid: project.opportunityid,
        propertytype: project.propertytype,
        inboundbusiness: project.inboundbusiness,
        winloss: project.winloss,
        pursuitsize: project.pursuitsize,
        sales_stage: project.sales_stage,
        deal_type: cleanDealType(project.deal_type),
        deal_sub_type: cleanDealType(project.deal_sub_type),
        est_revenue: project.est_revenue,
        est_start_date: project.est_start_date,
        practicegroups: this.cleanPracticeGroups(project.practicegroups),
        otherJllServices: this.cleanServicesList(project.otherJllServices),
        market: this.cleanMarketList(project.market),
        techused: this.cleanTechList(project.techused),
        sourcingmethodology: this.cleanSourcingMethodList(
          project.sourcingmethodology
        ),
        numberofmeetings: project.numberofmeetings,
        servicelines: `${this.cleanServiceList(project.servicelines)}`,
      };
    });

    let columns = [
      {
        dataField: "winloss",
        text: "Status",
        formatter: infoFormatter,
        sort: true,
        classes: " align-middle",
        width: "150px",
      },
      {
        dataField: "opportunityid",
        text: "ID",
        formatter: pursuitFormatter,
        sort: true,
        classes: " align-middle",
        width: "150px",
      },
      {
        dataField: "pursuitname",
        text: "Pursuit Name",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
        formatter: (col) => {
          return <span style={{ display: "block", width: 250 }}>{col}</span>;
        },
      },
      {
        dataField: "clientcompany",
        text: "Client Company Name",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
        formatter: (col) => {
          return <span style={{ display: "block", width: 250 }}>{col}</span>;
        },
      },
      {
        dataField: "clientcompanycontact",
        text: "Client Company Contact",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
        formatter: (col) => {
          return <span style={{ display: "block", width: 250 }}>{col}</span>;
        },
      },
      {
        dataField: "clientcompanyindustry",
        text: "Client Company Industry",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
        formatter: (col) => {
          return <span style={{ display: "block", width: 250 }}>{col}</span>;
        },
      },
      {
        dataField: "sourcingbroker",
        text: "Primary Broker",
        sort: true,
        formatter: brokerListFormatter,
        classes: " align-middle",
        width: "150px",
      },
      {
        dataField: "broker",
        text: "Team",
        sort: true,
        formatter: brokerListFormatter,
        classes: "align-middle",
      },
      {
        dataField: "bdmember",
        text: "Digital Advisor",
        sort: true,
        formatter: digitaladvisorformater,
        classes: " align-middle",
        csvFormatter: digitaladvisorcsvformater,
        filterValue: (cell, row) => daFilter(cell, row),
      },
      {
        dataField: "bdmember",
        text: "Sales Enablement Member",
        formatter: sememberformater,
        sort: true,
        classes: " align-middle",
        csvFormatter: semembercsvformater,
        filterValue: (cell, row) => seFilter(cell, row),
      },
      {
        dataField: "servicelines",
        text: "Service Line",
        sort: true,
        formatter: servicelineformatter,
        classes: " align-middle",
      },
      {
        dataField: "type",
        text: "Opp. Type",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "propertytype",
        text: "Property Type",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "meetingtype",
        text: "Meeting Type",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "pursuitsize",
        text: "Size (sf)",
        formatter: numberFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "meetingdate",
        text: "Meeting Date",
        formatter: dateFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "techused",
        text: "Tech Tools",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "market",
        text: "Market",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "datecreated",
        text: "Date Created",
        formatter: dateFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "date_closed",
        text: "Date Closed",
        formatter: dateFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "sourcingmethodology",
        text: "Sourcing Methodology",
        formatter: sourcingmethodformat,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "otherJllServices",
        text: "Other JLL Services",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "inboundbusiness",
        text: "Inbound Business",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "numberofmeetings",
        text: "Number of Meetings",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "practicegroups",
        text: "Practice Group",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "confidential",
        text: "Confidential?",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "sales_stage",
        text: "Sales Stage",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "deal_type",
        text: "Deal Type",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "deal_sub_type",
        text: "Deal Sub Type",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "est_start_date",
        text: "Est. Start Date",
        formatter: dateFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "est_revenue",
        text: "Est. Revenue",
        formatter: revFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "opportunityid",
        text: "Edit in Aero",
        formatter: editFormatter,
        sort: true,
        classes: "align-middle",
        csvExport: false,
      },
    ];
    let filename =
      this.props.marketname +
      "-" +
      moment().format("MM-DD-YYYY-HH:MM") +
      ".csv";
    //  deal_type,  competitor, deal_sub_type,  practicegroups, sourcingmethodology
    return (
      <ToolkitProvider
        keyField="opportunityid"
        data={cleandata}
        columns={columns}
        exportCSV={{
          fileName: filename,
          onlyExportFiltered: true,
        }}
        search
      >
        {(props) => (
          <div>
            <Row className="mb-2">
              <Col xs={6} md={6} lg={6} xl={8}>
                <ExportCSVButton {...props.csvProps}>
                  Export CSV
                </ExportCSVButton>
                <ExportAllButton data={data} />
              </Col>
              <Col xs={6} md={6} lg={6} xl={4}>
                <SearchBar {...props.searchProps} />
              </Col>
            </Row>
            <div>
              <BootstrapTable
                {...props.baseProps}
                pagination={paginationFactory()}
                options={options}
                striped={true}
                tableContainerClass={`table-striped jll-opp-table table-hover ${s.bootstrapTable}`}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

export default withRouter(Dynamic);
