import React from 'react';
import {
  Row,
  Col,
  Table,
  ButtonToolbar,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import s from '../../Invoice.module.scss';
import Widget from '../../../../components/Widget/Widget';


export default class NoAccess extends React.Component {

    render() {
         const {message, header} = this.props;
         return (
           <>
          <Row>
           <Col lg={11}>
             <Row className={s.root}>
               <Col xs={12}>
                 <Widget bodyClass={"p-0"}>
                   <div className="widget">
                     <header>
                     <Row className="mb-lg">
                         <Col sm={6} className="col-print-6">
                         <h2 className="text-muted no-margin">{header}</h2>
                         </Col>
                       </Row>
                     </header>
                       <section >
                         <div className="text-center">{message}</div>
                       </section>
                   </div>
                 </Widget>
               </Col>
             </Row>
           </Col>
         </Row>
         </> 
         
         )
     }
   }

