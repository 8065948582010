import React, { Component } from "react";
import { Button, FormGroup, Input, Label, Collapse } from "reactstrap";
import { connect } from "react-redux";
import DateRangePickerWrapper from "../../../../components/DateRange/DateRange";
import DateClosedPicker from "./DateRange/DateRange";
import DateMeetingPicker from "./DateRangeMeeting/DateRange";
import DateRangeStartDate from "./DateRangeStartDate/DateRange";
import {
  updateAllFilters,
  updateActiveFilters,
  updateCloseEndDate,
  updateCloseStartDate,
  updateMeetingEndDate,
  updateMeetingStartDate,
  updateStartDate,
  updateEndDate,
  updateShortcut,
  updateEstStartDateStart,
  updateEstStartDateEnd,
} from "../../../../actions/dashboardfilters";
import { Multiselect } from "multiselect-react-dropdown";
import { createFilterSnapshot } from "../../../../actions/projects";

var moment = require("moment");

class FilterPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: null,
      accordionFirst: [false],
      techoptions: [],
      sourcingmethods: [],
      marketcodes: [],
      finalmarketlist: [],
      servicelines: [],
      otherservices: [],
      industrylist: [],
      opptypelist: [],
      propertytypelist: [],
      statuslist: [],
      selist: [],
      dalist: [],
      brokerlist: [],
      salesStageList: [],
      dealSubTypeList: [],
      dealTypeList: [],
    };
    this.marketCodeRef = React.createRef();
    this.marketMultiselectRef = React.createRef();
    this.servicelineref = React.createRef();
    this.brokerTeamFilter = React.createRef();
    this.propertytypefilter = React.createRef();
    this.opportunitytypefilter = React.createRef();
    this.statusfilter = React.createRef();
    this.multiselectRef = React.createRef();
    this.industryRef = React.createRef();
    this.sourcingMethodRef = React.createRef();
    this.primaryBrokerRef = React.createRef();
    this.otherServicesRef = React.createRef();
    this.seLeadRef = React.createRef();
    this.daRef = React.createRef();
    this.practiceGroupRef = React.createRef();
    this.salesStageFilter = React.createRef();
    this.dealTypeFilter = React.createRef();
    this.dealSubTypeFilter = React.createRef();
  }

  // Capture the changes to the filter form
  handleInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const id = target.id;

    if (name === "addBrokerFilter") {
      this.props.dispatch(updateAllFilters({ addBrokerFilter: value }));
    } else if (name === "industryFilter") {
      this.props.dispatch(updateAllFilters({ industryFilter: value }));
    } else if (name === "sourcingMethodFilter") {
      this.props.dispatch(updateAllFilters({ sourcingMethodFilter: value }));
    } else if (name === "otherServicesFilter") {
      this.props.dispatch(updateAllFilters({ otherServicesFilter: value }));
    } else if (name === "serviceLineFilter") {
      this.props.dispatch(updateAllFilters({ serviceLineFilter: value }));
    } else if (name === "oppNameFilter") {
      this.props.dispatch(updateAllFilters({ oppNameFilter: value }));
    } else if (name === "statusFilter") {
      this.props.dispatch(updateAllFilters({ statusFilter: value }));
    } else if (name === "greaterlessthan") {
      this.props.dispatch(updateAllFilters({ greaterorlessFilter: value }));
    } else if (name === "sizeFilter") {
      this.props.dispatch(updateAllFilters({ sizeFilter: value }));
    } else if (name === "toolsincludeorexact") {
      this.props.dispatch(updateAllFilters({ toolsIncludeorExact: value }));
    } else if (name === "marketIncludeExclude") {
      this.props.dispatch(updateAllFilters({ marketIncludeExclude: value }));
    } else if (name === "teamincludeorexact") {
      this.props.dispatch(updateAllFilters({ teamIncludeorExact: value }));
    } else if (name === "meetingtypefilter") {
      this.props.dispatch(updateAllFilters({ meetingtypefilter: value }));
    } else if (name === "inboundbusinessfilter") {
      this.props.dispatch(updateAllFilters({ inboundfilter: value }));
    } else if (name === "estRevFilter") {
      this.props.dispatch(updateAllFilters({ estRevFilter: value }));
    } else if (name === "estRevGreaterLess") {
      this.props.dispatch(updateAllFilters({ estRevGreaterLess: value }));
    } else if (name === "dealTypeFilter") {
      this.props.dispatch(updateAllFilters({ dealTypeFilter: value }));
    } else if (name === "dealSubTypeFilter") {
      this.props.dispatch(updateAllFilters({ dealSubTypeFilter: value }));
    } else if (name === "salesStageFilter") {
      this.props.dispatch(updateAllFilters({ salesStageFilter: value }));
    }
  }

  clearFilterInputs() {
    let refs = this.refs;
    if (this.props.market == "JTE" || this.props.market == "National") {
      this.marketMultiselectRef.current.resetSelectedValues();
    }
    this.multiselectRef.current.resetSelectedValues();
    this.servicelineref.current.resetSelectedValues();
    this.brokerTeamFilter.current.resetSelectedValues();
    this.sourcingMethodRef.current.resetSelectedValues();
    this.otherServicesRef.current.resetSelectedValues();
    this.daRef.current.resetSelectedValues();
    this.seLeadRef.current.resetSelectedValues();
    this.primaryBrokerRef.current.resetSelectedValues();
    this.industryRef.current.resetSelectedValues();
    this.practiceGroupRef.current.resetSelectedValues();
    this.propertytypefilter.current.resetSelectedValues();
    this.opportunitytypefilter.current.resetSelectedValues();
    this.dealTypeFilter.current.resetSelectedValues();
    this.dealSubTypeFilter.current.resetSelectedValues();
    this.statusfilter.current.resetSelectedValues();
    this.props.dispatch(updateCloseStartDate(null));
    this.props.dispatch(updateCloseEndDate(null));
    this.props.dispatch(updateMeetingStartDate(null));
    this.props.dispatch(updateMeetingEndDate(null));
    this.props.dispatch(updateEstStartDateStart(null));
    this.props.dispatch(updateEstStartDateEnd(null));
    for (const key in refs) {
      if (!(key === "daterange")) {
        refs[key].value = "";
      } else if (key === "techUsedFilter") {
        this.multiselectRef.current.resetSelectedValues();
      } else {
        refs[key].forceUpdate();
        this.props.dispatch(updateStartDate(null));
        this.props.dispatch(updateEndDate(null));
      }
    }
  }
  toggleAccordionFirst(id) {
    const arr = [];
    arr.length = this.state.accordionFirst.length;
    arr.fill(false);
    arr[id] = !this.state.accordionFirst[id];
    this.setState({
      accordionFirst: arr,
    });
  }
  clearFilters() {
    this.props.dispatch(
      updateAllFilters({
        seLeadFilter: "",
        digitalAFilter: "",
        startDate: "",
        endDate: "",
        startCloseDate: "",
        endCloseDate: "",
        marketsFilter: "",
        sourcingBrokerFilter: "",
        typeFilter: "",
        propertytypefilter: "",
        statusFilter: "",
        addBrokerFilter: "",
        serviceLineFilter: "",
        brokerTeamFilter: "",
        techUsedFilter: "",
        sourcingMethodFilter: "",
        otherServicesFilter: "",
        industryFilter: "",
        jteFilter: false,
        crossSellingTwo: false,
        oppNameFilter: "",
        teamIncludeorExact: "includes",
        toolsIncludeorExact: "includes",
        marketIncludeExclude: "includes",
        sizeFilter: "",
        greaterorlessFilter: "greater",
        activefilters: [],
        inboundfilter: "",
        meetingtypefilter: "",
        shortcutBoolean: false,
        practiceGroupFilter: "",
        estStartDateStart: "",
        estStartDateEnd: "",
        dealTypeFilter: "",
        dealSubTypeFilter: "",
        salesStageFilter: "",
        estRevGreaterLess: "greater",
        estRevFilter: "",
      })
    );
    this.props.dispatch(
      createFilterSnapshot({
        seLeadFilter: "",
        digitalAFilter: "",
        startDate: "",
        endDate: "",
        startCloseDate: "",
        endCloseDate: "",
        marketsFilter: "",
        sourcingBrokerFilter: "",
        typeFilter: "",
        addBrokerFilter: "",
        serviceLineFilter: "",
        jteFilter: false,
        brokerTeamFilter: "",
        techUsedFilter: "",
        sourcingMethodFilter: "",
        otherServicesFilter: "",
        industryFilter: "",
        oppNameFilter: "",
        teamIncludeorExact: "includes",
        toolsIncludeorExact: "includes",
        marketIncludeExclude: "includes",
        sizeFilter: "",
        greaterorlessFilter: "greater",
        statusFilter: "",
        activefilters: [],
        inboundfilter: "",
        propertytypefilter: "",
        meetingtypefilter: "",
        shortcutBoolean: false,
        practiceGroupFilter: "",
        estStartDateStart: "",
        estStartDateEnd: "",
        dealTypeFilter: "",
        dealSubTypeFilter: "",
        salesStageFilter: "",
        estRevGreaterLess: "greater",
        estRevFilter: "",
      })
    );
  }

  onSelectMarket(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ marketsFilter: selectedList }));
  }

  onRemoveMarket(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ marketsFilter: selectedList }));
  }

  onSelectMarketCode(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ marketcodeFilter: selectedList }));
  }

  onRemoveMarketCode(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ marketcodeFilter: selectedList }));
  }

  onSelectTool(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ techUsedFilter: selectedList }));
  }

  onRemoveTool(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ techUsedFilter: selectedList }));
  }

  onSelectOtherServices(selectedList, selectedItem) {
    this.props.dispatch(
      updateAllFilters({ otherServicesFilter: selectedList })
    );
  }

  onRemoveOtherServices(selectedList, removedItem) {
    this.props.dispatch(
      updateAllFilters({ otherServicesFilter: selectedList })
    );
  }

  onSelectOppType(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ typeFilter: selectedList }));
  }
  onRemoveOppType(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ typeFilter: selectedList }));
  }

  onSelectPropertyType(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ propertytypefilter: selectedList }));
  }
  onRemovePropertyType(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ propertytypefilter: selectedList }));
  }

  onSelectStatus(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ statusFilter: selectedList }));
  }
  onRemoveStatus(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ statusFilter: selectedList }));
  }

  onChangeMultiselect(selectedList, dataKey) {
    this.props.dispatch(updateAllFilters({ [dataKey]: selectedList }));
  }
  onSelectPrimaryBroker(selectedList, selectedItem) {
    this.props.dispatch(
      updateAllFilters({ sourcingBrokerFilter: selectedList })
    );
  }

  onRemovePrimaryBroker(selectedList, removedItem) {
    this.props.dispatch(
      updateAllFilters({ sourcingBrokerFilter: selectedList })
    );
    //  this.props.dispatch(updateSourcingBrokerFilter(selectedList))
  }

  onSelectSourcingMethod(selectedList, selectedItem) {
    this.props.dispatch(
      updateAllFilters({ sourcingMethodFilter: selectedList })
    );
    // this.props.dispatch(updateAllFilters({sourcingMethodFilter: selectedList}))
  }

  onRemoveSourcingMethod(selectedList, removedItem) {
    this.props.dispatch(
      updateAllFilters({ sourcingMethodFilter: selectedList })
    );
    // this.props.dispatch(updateAllFilters({sourcingMethodFilter: selectedList}))
  }

  onSelectSELead(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ seLeadFilter: selectedList }));
  }

  onRemoveSELead(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ seLeadFilter: selectedList }));
  }

  onSelectDA(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ digitalAFilter: selectedList }));
  }

  onRemoveDA(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ digitalAFilter: selectedList }));
  }

  onSelectPracticeGroup(selectedList, selectedItem) {
    this.props.dispatch(
      updateAllFilters({ practiceGroupFilter: selectedList })
    );
    // this.props.dispatch(updateIndustryFilter(selectedList))
  }

  onRemovePracticeGroup(selectedList, selectedItem) {
    this.props.dispatch(
      updateAllFilters({ practiceGroupFilter: selectedList })
    );
    // this.props.dispatch(updateIndustryFilter(selectedList))
  }

  onSelectIndustry(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ industryFilter: selectedList }));
    // this.props.dispatch(updateIndustryFilter(selectedList))
  }

  onRemoveIndustry(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ industryFilter: selectedList }));
    //  this.props.dispatch(updateIndustryFilter(selectedList))
  }

  onSelectServiceLine(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ serviceLineFilter: selectedList }));
    // this.props.dispatch(updateServiceLineFilter(selectedList))
  }

  onRemoveServiceLine(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ serviceLineFilter: selectedList }));
    // this.props.dispatch(updateServiceLineFilter(selectedList))
  }

  onSelectBroker(selectedList, selectedItem) {
    this.props.dispatch(updateAllFilters({ brokerTeamFilter: selectedList }));
    // this.props.dispatch(updateBrokerTeamFilter(selectedList))
  }

  onRemoveBroker(selectedList, removedItem) {
    this.props.dispatch(updateAllFilters({ brokerTeamFilter: selectedList }));
    //  this.props.dispatch(updateBrokerTeamFilter(selectedList))
  }

  // Pass the needed changes to this function from the main dashboardfilters
  // For each of the changes- match to a ref and input the value
  // Once all are in, run the filterButton function

  componentDidUpdate(prevProps, prevState) {
    if (this.props.filters.shortcutBoolean) {
      this.filterButton();
      this.props.dispatch(updateShortcut(false));
    }
  }

  componentDidMount() {
    let finaldalist = this.props.finaldalist;
    let finalselist = this.props.finalselist;
    let finalmarketcodelist = this.props.finalmarketcodelist;
    let finalindustries = this.props.finalindustries;
    let finalsourcingmethods = this.props.finalsourcingmethods;
    let finalservicelinelist = this.props.finalservicelinelist;
    let finalotherservices = this.props.finalotherservices;
    let finaltechlist = this.props.finaltechlist;
    let finalbrokerlist = this.props.finalbrokerlist;
    let finalpracticegrouplist = this.props.finalpracticegrouplist;
    let finalstatuslist = this.props.finalstatuslist;
    let finalopptypelist = this.props.finalopptypelist;
    let finalpropertytypelist = this.props.finalpropertytypelist;
    let finalmarketlist = this.props.marketoptions;
    let finalDealTypeList = this.props.finalDealTypeList;
    let finalDealSubtypeList = this.props.finalDealSubtypeList;
    let finalSalesStageList = this.props.finalSalesStageList;

    let settings = null;
    if (this.props.filterSnapshot) {
      settings = this.props.filterSnapshot;
    }

    this.setState({
      settings: settings,
      marketcodes: finalmarketcodelist,
      opptypelist: finalopptypelist,
      propertytypelist: finalpropertytypelist,
      statuslist: finalstatuslist,
      selist: finalselist,
      dalist: finaldalist,
      techoptions: finaltechlist,
      markets: finalmarketlist,
      sourcingmethods: finalsourcingmethods,
      practicegrouplist: finalpracticegrouplist,
      otherservices: finalotherservices,
      industrylist: finalindustries,
      brokerlist: finalbrokerlist,
      servicelines: finalservicelinelist,
      salesStageList: finalSalesStageList,
      dealTypeList: finalDealTypeList,
      dealSubTypeList: finalDealSubtypeList,
    });
  }

  addFilterButton() {
    console.log();
  }

  filterButton(option, updates = this.props.rawupdates) {
    this.props.startFiltering();
    let preDateFiltered = null;
    let refs = this.refs;
    this.props.dispatch(updateActiveFilters([]));
    let filtersnap = this.props.filters;
    filtersnap = Object.keys(filtersnap)
      .filter((key) => key !== "activefilters")
      .reduce(
        (newObj, currKey) => ((newObj[currKey] = filtersnap[currKey]), newObj),
        {}
      );
    this.props.dispatch(createFilterSnapshot(filtersnap));

    let filteredprojects = this.props.opps;
    let filteredupdates = updates;

    //Primary broker filter
    if (
      this.props.filters.sourcingBrokerFilter !== "" &&
      this.props.filters.sourcingBrokerFilter.length > 0
    ) {
      let listofprimarybrokers = [];
      this.props.filters.sourcingBrokerFilter.forEach((item) => {
        listofprimarybrokers.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.sourcingbroker.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        item.sourcingbroker.some((obj) =>
          listofprimarybrokers.includes(obj.fullname)
        )
      );
    }
    //Add broker filter
    if (this.props.filters.addBrokerFilter !== "") {
      filteredprojects = filteredprojects.filter((item) =>
        item.broker.includes(this.props.filters.addBrokerFilter)
      );
    }
    //Broker team filter
    if (
      this.props.filters.brokerTeamFilter !== "" &&
      this.props.filters.brokerTeamFilter.length > 0
    ) {
      let brokerlist = [];
      this.props.filters.brokerTeamFilter.forEach((item) => {
        brokerlist.push(item.name);
      });
      if (this.props.filters.teamIncludeorExact === "includes") {
        filteredprojects = filteredprojects.filter(
          (item) =>
            item.sourcingbroker.some((obj) =>
              brokerlist.includes(obj.fullname)
            ) || item.broker.some((obj) => brokerlist.includes(obj.fullname))
        );
      } else if (this.props.filters.teamIncludeorExact === "exact") {
        filteredprojects = filteredprojects.filter(
          ({ broker, sourcingbroker }) =>
            brokerlist.every(
              (v) =>
                broker.some(({ fullname }) => fullname === v) ||
                sourcingbroker.some(({ fullname }) => fullname === v)
            )
        );
      } else if (this.props.filters.teamIncludeorExact === "exclude") {
        filteredprojects = filteredprojects.filter(
          (item) =>
            !item.sourcingbroker.some((obj) =>
              brokerlist.includes(obj.fullname)
            ) && !item.broker.some((obj) => brokerlist.includes(obj.fullname))
        );
      }
    }
    //Markets filter
    if (
      this.props.filters.marketsFilter !== "" &&
      this.props.filters.marketsFilter.length !== 0
    ) {
      let marketlist = [];
      this.props.filters.marketsFilter.forEach((item) => {
        marketlist.push(item.name);
      });
      if (this.props.filters.marketIncludeExclude === "includes") {
        filteredprojects = filteredprojects.filter((item) =>
          item.market.some((obj) => marketlist.includes(obj.marketname))
        );
      } else if (this.props.filters.marketIncludeExclude === "exclude") {
        filteredprojects = filteredprojects.filter(
          (item) =>
            !item.market.some((obj) => marketlist.includes(obj.marketname))
        );
      }
    }

    //Type filter
    if (
      this.props.filters.typeFilter !== "" &&
      this.props.filters.typeFilter.length !== 0
    ) {
      let listofoptions = [];
      this.props.filters.typeFilter.forEach((item) => {
        listofoptions.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.type.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        listofoptions.includes(item.type)
      );
    }

    //Service line filter
    if (
      this.props.filters.serviceLineFilter !== "" &&
      this.props.filters.serviceLineFilter.length > 0
    ) {
      let listofservicelines = [];
      this.props.filters.serviceLineFilter.forEach((item) => {
        listofservicelines.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.servicelines.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        item.servicelines.some((obj) =>
          listofservicelines.includes(obj.servicelinename)
        )
      );
    }
    //Market code filter
    if (this.props.filters.marketcodeFilter.length > 0) {
      let listofmarketcodes = [];
      this.props.filters.marketcodeFilter.forEach((item) => {
        listofmarketcodes.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.sourcingbroker.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        item.sourcingbroker.some(function (broker) {
          if (broker.marketcode) {
            return listofmarketcodes.includes(broker.marketcode.fulldesc);
          }
        })
      );
    }
    //Opp name filter
    if (this.props.filters.oppNameFilter !== "") {
      filteredprojects = filteredprojects.filter((item) =>
        item.pursuitname.includes(this.props.filters.oppNameFilter)
      );
    }
    //Status filter
    if (
      this.props.filters.statusFilter !== "" &&
      this.props.filters.statusFilter.length > 0
    ) {
      let listofoptions = [];
      this.props.filters.statusFilter.forEach((item) => {
        listofoptions.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.winloss.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        listofoptions.includes(item.winloss)
      );
    }

    //Tech used filter
    if (
      this.props.filters.techUsedFilter !== "" &&
      this.props.filters.techUsedFilter.length > 0
    ) {
      let listoftech = [];
      this.props.filters.techUsedFilter.forEach((item) => {
        listoftech.push(item.name);
      });
      if (this.props.filters.toolsIncludeorExact === "includes") {
        filteredprojects = filteredprojects.filter((item) =>
          item.techused.some((obj) => listoftech.includes(obj.techname))
        );
      } else {
        filteredprojects = filteredprojects.filter(
          ({ techused }) =>
            techused.length == listoftech.length &&
            listoftech.every((v) =>
              techused.some(({ techname }) => techname === v)
            )
        );
      }
    }
    //Industry filter
    if (
      this.props.filters.industryFilter !== "" &&
      this.props.filters.industryFilter.length > 0
    ) {
      let listofindustries = [];
      this.props.filters.industryFilter.forEach((item) => {
        listofindustries.push(item.name);
      });
      filteredprojects = filteredprojects.filter((item) =>
        item.clientcompany.some((obj) =>
          listofindustries.includes(obj.clientindustry)
        )
      );
    }
    //Tech advisor filter
    if (
      this.props.filters.digitalAFilter !== "" &&
      this.props.filters.digitalAFilter.length > 0
    ) {
      let listofda = [];
      this.props.filters.digitalAFilter.forEach((item) => {
        listofda.push(item.name);
      });
      filteredprojects = filteredprojects.filter((item) =>
        item.bdmember.some((obj) => listofda.includes(obj.fullname))
      );
    }
    //SE Lead filter
    if (
      this.props.filters.seLeadFilter !== "" &&
      this.props.filters.seLeadFilter.length > 0
    ) {
      let listofseleads = [];
      this.props.filters.seLeadFilter.forEach((item) => {
        listofseleads.push(item.name);
      });
      filteredprojects = filteredprojects.filter((item) =>
        item.bdmember.some((obj) => listofseleads.includes(obj.fullname))
      );
    }
    //JTE filter
    if (this.props.filters.jteFilter) {
      filteredprojects = filteredprojects.filter(
        (item) =>
          item.techused.some(
            (obj) => obj.techname === "JLL Tenant Experience"
          ) ||
          item.techused.length >= 2 ||
          item.techused.some((obj) => obj.techname === "JLL Journeys")
      );
      filteredprojects = filteredprojects.filter(
        (item) => item.type !== "BRO Agency Leasing"
      );
    }
    //Cross-selling Two filter
    if (this.props.filters.crossSellingTwo) {
      filteredprojects = filteredprojects.filter(
        (item) => item.otherJllServices.length >= 2
      );
    }
    //Sourcing method filter
    if (
      this.props.filters.sourcingMethodFilter !== "" &&
      this.props.filters.sourcingMethodFilter.length > 0
    ) {
      let listofsourcingmethods = [];
      this.props.filters.sourcingMethodFilter.forEach((item) => {
        listofsourcingmethods.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.sourcingmethodology.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        item.sourcingmethodology.some((obj) =>
          listofsourcingmethods.includes(obj.methodname)
        )
      );
    }
    //Practice group filter
    if (
      this.props.filters.practiceGroupFilter !== "" &&
      this.props.filters.practiceGroupFilter.length > 0
    ) {
      let listofpracticegroups = [];
      this.props.filters.practiceGroupFilter.forEach((item) => {
        listofpracticegroups.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.practicegroups.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        item.practicegroups.some((obj) =>
          listofpracticegroups.includes(obj.name)
        )
      );
    }
    // Other services filter
    if (
      this.props.filters.otherServicesFilter !== "" &&
      this.props.filters.otherServicesFilter.length > 0
    ) {
      let listofotherservices = [];
      this.props.filters.otherServicesFilter.forEach((item) => {
        listofotherservices.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.otherJllServices.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        item.otherJllServices.some((obj) =>
          listofotherservices.includes(obj.servicename)
        )
      );
    }
    // Size filter
    if (this.props.filters.sizeFilter !== "") {
      if (this.props.filters.greaterorlessFilter === "greater") {
        filteredprojects = filteredprojects.filter(
          (item) => item.pursuitsize > this.props.filters.sizeFilter
        );
      } else {
        filteredprojects = filteredprojects.filter(
          (item) => item.pursuitsize < this.props.filters.sizeFilter
        );
      }
    }

    // Inbound filter
    if (this.props.filters.inboundfilter !== "") {
      if (this.props.filters.inboundfilter.toLowerCase() === "true") {
        filteredprojects = filteredprojects.filter(
          (item) => item.inboundbusiness
        );
      } else if (this.props.filters.inboundfilter.toLowerCase() === "false") {
        filteredprojects = filteredprojects.filter(
          (item) => !item.inboundbusiness
        );
      }
    }

    // Property type filter
    if (
      this.props.filters.propertytypefilter !== "" &&
      this.props.filters.propertytypefilter.length > 0
    ) {
      let listofpropertypes = [];
      this.props.filters.propertytypefilter.forEach((item) => {
        listofpropertypes.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.propertytype.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        listofpropertypes.includes(item.propertytype)
      );
    }
    // Meeting type filter
    if (this.props.filters.meetingtypefilter !== "") {
      filteredprojects = filteredprojects.filter((item) =>
        item.meetingtype.includes(this.props.filters.meetingtypefilter)
      );
    }
    let newopps = filteredprojects;
    // Time range filter
    if (
      this.props.filters.startDate !== "" &&
      this.props.filters.startDate !== null &&
      this.props.filters.endDate !== "" &&
      this.props.filters.endDate !== null
    ) {
      preDateFiltered = filteredprojects;
      filteredprojects = filteredprojects.filter(
        (item) =>
          moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startDate
          ) &&
          moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endDate
          )
      );
      newopps = newopps.filter(
        (item) =>
          moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startDate
          ) &&
          moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endDate
          )
      );
    }
    // Date Closed filter
    if (
      this.props.filters.startCloseDate !== "" &&
      this.props.filters.startCloseDate !== null &&
      this.props.filters.endCloseDate !== "" &&
      this.props.filters.endCloseDate !== null
    ) {
      preDateFiltered = filteredprojects;
      filteredprojects = filteredprojects.filter(
        (item) =>
          moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startCloseDate
          ) &&
          moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endCloseDate
          )
      );
      newopps = newopps.filter(
        (item) =>
          moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startCloseDate
          ) &&
          moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endCloseDate
          )
      );
    }

    // Meeting Date filter
    if (
      this.props.filters.startMeetingDate !== "" &&
      this.props.filters.startMeetingDate !== null &&
      this.props.filters.endMeetingDate !== "" &&
      this.props.filters.endMeetingDate !== null
    ) {
      preDateFiltered = filteredprojects;
      filteredprojects = filteredprojects.filter(
        (item) =>
          moment(item.meetingdate, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startMeetingDate
          ) &&
          moment(item.meetingdate, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endMeetingDate
          )
      );
      newopps = newopps.filter(
        (item) =>
          moment(item.meetingdate, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startMeetingDate
          ) &&
          moment(item.meetingdate, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endMeetingDate
          )
      );
    }

    // Est Start Date filter
    if (
      this.props.filters.estStartDateStart !== "" &&
      this.props.filters.estStartDateStart !== null &&
      this.props.filters.estStartDateEnd !== "" &&
      this.props.filters.estStartDateEnd !== null
    ) {
      preDateFiltered = filteredprojects;
      filteredprojects = filteredprojects.filter(
        (item) =>
          moment(item.est_start_date, "MM-DD-YYYY").isAfter(
            this.props.filters.estStartDateStart
          ) &&
          moment(item.est_start_date, "MM-DD-YYYY").isBefore(
            this.props.filters.estStartDateEnd
          )
      );
      newopps = newopps.filter(
        (item) =>
          moment(item.est_start_date, "MM-DD-YYYY").isAfter(
            this.props.filters.estStartDateStart
          ) &&
          moment(item.est_start_date, "MM-DD-YYYY").isBefore(
            this.props.filters.estStartDateStart
          )
      );
    }

    // Deal type filter
    if (
      this.props.filters.dealTypeFilter !== "" &&
      this.props.filters.dealTypeFilter.length > 0
    ) {
      let listOfDealTypes = [];
      this.props.filters.dealTypeFilter.forEach((item) => {
        listOfDealTypes.push(item.name);
      });
      filteredprojects = filteredprojects.filter((item) =>
        item.deal_type.some((obj) => listOfDealTypes.includes(obj.name))
      );
    }

    // Deal Sub-Type filter
    if (
      this.props.filters.dealSubTypeFilter !== "" &&
      this.props.filters.dealSubTypeFilter.length > 0
    ) {
      let listOfDealSubTypes = [];
      this.props.filters.dealSubTypeFilter.forEach((item) => {
        listOfDealSubTypes.push(item.name);
      });
      filteredprojects = filteredprojects.filter((item) =>
        item.deal_sub_type.some((obj) => listOfDealSubTypes.includes(obj.name))
      );
    }

    // Sales Stage filter
    if (
      this.props.filters.salesStageFilter !== "" &&
      this.props.filters.salesStageFilter.length > 0
    ) {
      let listOfStageFilters = [];
      this.props.filters.salesStageFilter.forEach((item) => {
        listOfStageFilters.push(item.name);
      });
      filteredprojects = filteredprojects.filter(
        (item) => item.sales_stage?.length > 0
      );
      filteredprojects = filteredprojects.filter((item) =>
        listOfStageFilters.includes(item.sales_stage)
      );
    }

    // Est. Revenue Filter
    if (this.props.filters.estRevFilter !== "") {
      if (this.props.filters.estRevGreaterLess === "greater") {
        filteredprojects = filteredprojects.filter(
          (item) => item.est_revenue > this.props.filters.estRevFilter
        );
      } else {
        filteredprojects = filteredprojects.filter(
          (item) => item.est_revenue < this.props.filters.estRevFilter
        );
      }
    }

    let statusOpps = [];
    //if not dated filted- flter updates based only on the opp filters
    if (
      !(
        this.props.filters.startDate !== "" &&
        this.props.filters.startDate !== null &&
        this.props.filters.endDate !== "" &&
        this.props.filters.endDate !== null
      )
    ) {
      filteredupdates = updates.filter((item1) =>
        filteredprojects.some(
          (item2) => item2.opportunityid === item1.opportunityid
        )
      );
    }

    if (preDateFiltered) {
      statusOpps = preDateFiltered.filter(
        (item) =>
          moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isAfter(
            this.props.filters.startDate
          ) &&
          moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isBefore(
            this.props.filters.endDate
          ) &&
          (item.winloss === "Won" || item.winloss === "Lost")
      );
    } else {
      // No longer need to search for al opportunties won- because we can edit the date closed.
      statusOpps = filteredprojects.filter(
        (item1) => item1.winloss === "Win" || item1.winloss === "Lost"
      );
    }
    // else {
    //   statusOpps = this.props.opps.filter(
    //     (item1) =>
    //       filteredupdates.some(
    //         (item2) => item2.opportunityid === item1.opportunityid
    //       ) ||
    //       (filteredprojects.some(
    //         (item3) => item3.opportunityid === item1.opportunityid
    //       ) &&
    //         (item1.winloss === "Won" || item1.winloss === "Lost"))
    //   );
    // }

    let statusWins = statusOpps.filter((item) => item.winloss === "Won");
    let statusLosses = statusOpps.filter((item) => item.winloss === "Lost");

    // filteredprojects = filteredprojects.concat(statusWins);
    // filteredprojects = filteredprojects.concat(statusLosses);

    //Remove duplicates
    // filteredprojects = filteredprojects.reduce((unique, o) => {
    //   if (!unique.some((obj) => obj.opportunityid === o.opportunityid)) {
    //     unique.push(o);
    //   }
    //   return unique;
    // }, []);

    //  this.props.filterOpportunities(filteredprojects)
    this.props.filterUpdates(
      filteredupdates,
      statusWins,
      statusLosses,
      newopps,
      filteredprojects
    );
    this.checkfilters(this.props.filters);
    if (this.props.toggleFilterBox) {
      this.props.toggleFilterBox("filterbox");
    }
  }

  checkfilters(filters) {
    let filterarray = [];

    Object.keys(filters).forEach(function (key) {
      if (
        key !== "greaterorlessFilter" &&
        key !== "marketsFilter" &&
        key !== "marketIncludeExclude" &&
        key !== "marketcodeFilter" &&
        key !== "digitalAFilter" &&
        key !== "seLeadFilter" &&
        key !== "filters" &&
        key !== "teamIncludeorExact" &&
        key !== "sourcingMethodFilter" &&
        key !== "industryFilter" &&
        key !== "practiceGroupFilter" &&
        key !== "toolsIncludeorExact" &&
        key !== "techUsedFilter" &&
        key !== "brokerTeamFilter" &&
        key !== "sizeFilter" &&
        key !== "startDate" &&
        key !== "startCloseDate" &&
        key !== "startMeetingDate" &&
        key !== "jteFilter" &&
        key !== "crossSellingTwo" &&
        key !== "endDate" &&
        key !== "endCloseDate" &&
        key !== "endMeetingDate" &&
        key !== "estStartDateEnd" &&
        key !== "estStartDateStart" &&
        key !== "dealTypeFilter" &&
        key !== "dealSubTypeFilter" &&
        key !== "estRevGreaterLess" &&
        key !== "estRevFilter" &&
        key !== "activefilters" &&
        key !== "salesStageFilter" &&
        key !== "shortcutBoolean" &&
        key !== "serviceLineFilter" &&
        key !== "otherServicesFilter" &&
        key !== "sourcingBrokerFilter" &&
        key !== "inboundfilter" &&
        key !== "typeFilter" &&
        key !== "propertytypefilter" &&
        key !== "statusFilter"
      ) {
        if (filters[key] !== "") {
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: filters[key],
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "marketsFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let marketnames = [];
          filters[key].forEach((item) => {
            marketnames.push(" " + item.name);
          });
          let factor = filters["marketIncludeExclude"];

          marketnames = marketnames.toString();
          marketnames = "Markets " + factor + ": " + marketnames;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: filters["marketIncludeExclude"],
            value: marketnames,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "otherServicesFilter" && filters[key] !== "") {
        let services = [];
        filters[key].forEach((item) => {
          services.push(" " + item.name);
        });
        services = services.toString();
        services = "JLL Services: " + services;
        let activefilterobject = {
          type: key,
          rawvalue: filters[key],
          includeexclude: null,
          value: services,
        };
        filterarray.push(activefilterobject);
      } else if (key === "marketcodeFilter" && filters[key] !== "") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let marketcodes = [];
          filters[key].forEach((item) => {
            marketcodes.push(" " + item.name);
          });
          marketcodes = marketcodes.toString();
          marketcodes = "Market Codes: " + marketcodes;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: marketcodes,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "sizeFilter" && filters[key] !== "") {
        let activefilterobject = {
          type: key,
          rawvalue: filters[key],
          includeexclude: filters["greaterorlessFilter"],
          value:
            filters["greaterorlessFilter"].toUpperCase() +
            " than " +
            filters["sizeFilter"] +
            " sf",
        };
        filterarray.push(activefilterobject);
      } else if (key === "jteFilter" && filters[key]) {
        let activefilterobject = {
          type: key,
          rawvalue: filters[key],
          includeexclude: null,
          value: "JTE Opportunities",
        };
        filterarray.push(activefilterobject);
      } else if (key === "crossSellingTwo" && filters[key]) {
        let activefilterobject = {
          type: key,
          rawvalue: filters[key],
          includeexclude: null,
          value: "Two or more Other JLL Services",
        };
        filterarray.push(activefilterobject);
      } else if (key === "inboundfilter" && filters[key] !== "") {
        let activefilterobject = {
          type: key,
          rawvalue: filters[key],
          includeexclude: null,
          value: `Inbound opportunity: ${filters[key]}`,
        };
        filterarray.push(activefilterobject);
      } else if (
        key === "endDate" &&
        filters[key] !== "" &&
        key === "endDate" &&
        filters[key] !== null
      ) {
        let activefilterobject = {
          type: key,
          rawvalue: `${filters["startDate"]} to ${filters["endDate"]}`,
          includeexclude: null,
          value:
            "Between " +
            moment(filters["startDate"]).format("MMMM Do, YYYY") +
            " and " +
            moment(filters["endDate"]).format("MMMM Do, YYYY"),
        };
        filterarray.push(activefilterobject);
      } else if (
        key === "endCloseDate" &&
        filters[key] !== "" &&
        key === "endCloseDate" &&
        filters[key] !== null
      ) {
        let activefilterobject = {
          type: key,
          rawvalue: `${filters["startCloseDate"]} to ${filters["endCloseDate"]}`,
          includeexclude: null,
          value:
            "Closed between " +
            moment(filters["startCloseDate"]).format("MMMM Do, YYYY") +
            " and " +
            moment(filters["endCloseDate"]).format("MMMM Do, YYYY"),
        };
        filterarray.push(activefilterobject);
      } else if (
        key === "endMeetingDate" &&
        filters[key] !== "" &&
        key === "endMeetingDate" &&
        filters[key] !== null
      ) {
        let activefilterobject = {
          type: key,
          rawvalue: `${filters["startMeetingDate"]} to ${filters["endMeetingDate"]}`,
          includeexclude: null,
          value:
            "Meeting date is between " +
            moment(filters["startMeetingDate"]).format("MMMM Do, YYYY") +
            " and " +
            moment(filters["endMeetingDate"]).format("MMMM Do, YYYY"),
        };
        filterarray.push(activefilterobject);
      } else if (
        key === "estStartDateEnd" &&
        filters[key] !== "" &&
        key === "estStartDateEnd" &&
        filters[key] !== null
      ) {
        let activefilterobject = {
          type: key,
          rawvalue: `${filters["estStartDateStart"]} to ${filters["estStartDateEnd"]}`,
          includeexclude: null,
          value:
            "Est. Start date is between " +
            moment(filters["estStartDateStart"]).format("MMMM Do, YYYY") +
            " and " +
            moment(filters["estStartDateEnd"]).format("MMMM Do, YYYY"),
        };
        filterarray.push(activefilterobject);
      } else if (key === "estRevFilter" && filters[key] !== "") {
        let activefilterobject = {
          type: key,
          rawvalue: filters[key],
          includeexclude: filters["estRevGreaterLess"],
          value:
            filters["estRevGreaterLess"].toUpperCase() +
            " than " +
            "$ " +
            filters["estRevFilter"],
        };
        filterarray.push(activefilterobject);
      } else if (key === "salesStageFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let salesStages = [];
          filters[key].forEach((item) => {
            salesStages.push(" " + item.name);
          });
          salesStages = salesStages.toString();
          salesStages = "Sales Stage includes" + ": " + salesStages;

          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            value: salesStages,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "dealTypeFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let dealTypes = [];
          filters[key].forEach((item) => {
            dealTypes.push(" " + item.name);
          });
          dealTypes = dealTypes.toString();
          dealTypes = "Deal Type includes" + ": " + dealTypes;

          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            value: dealTypes,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "dealSubTypeFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let dealSubTypes = [];
          filters[key].forEach((item) => {
            dealSubTypes.push(" " + item.name);
          });
          dealSubTypes = dealSubTypes.toString();
          dealSubTypes = "Deal Sub-Type includes" + ": " + dealSubTypes;

          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            value: dealSubTypes,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "sourcingBrokerFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let brokers = [];
          filters[key].forEach((item) => {
            brokers.push(" " + item.name);
          });
          brokers = brokers.toString();
          brokers = "Primary Broker (Includes): " + brokers;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: brokers,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "techUsedFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let technames = [];
          filters[key].forEach((item) => {
            technames.push(" " + item.name);
          });
          let factor = filters["toolsIncludeorExact"];
          technames = technames.toString();
          technames = "Tech used " + factor + ": " + technames;

          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: filters["toolsIncludeorExact"],
            value: technames,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "serviceLineFilter") {
        let activefilterobject = {
          type: key,
          value: "Service Line: " + filters[key],
        };
        if (filters[key].length > 0 && filters[key] !== "") {
          let serviceline = [];
          filters[key].forEach((item) => {
            serviceline.push(" " + item.name);
          });
          serviceline = serviceline.toString();
          serviceline = "Service Line: " + serviceline;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: serviceline,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "typeFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let methods = [];
          filters[key].forEach((item) => {
            methods.push(" " + item.name);
          });
          methods = methods.toString();
          methods = "Opportunity Type: " + methods;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: methods,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "propertytypefilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let methods = [];
          filters[key].forEach((item) => {
            methods.push(" " + item.name);
          });
          methods = methods.toString();
          methods = "Property Type: " + methods;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: methods,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "statusFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let methods = [];
          filters[key].forEach((item) => {
            methods.push(" " + item.name);
          });
          methods = methods.toString();
          methods = "Opportunity Status: " + methods;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: methods,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "sourcingMethodFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let methods = [];
          filters[key].forEach((item) => {
            methods.push(" " + item.name);
          });
          methods = methods.toString();
          methods = "Other JLL Services: " + methods;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: methods,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "practiceGroupFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let groups = [];
          filters[key].forEach((item) => {
            groups.push(" " + item.name);
          });
          groups = groups.toString();
          groups = "Practice Group(s): " + groups;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: groups,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "digitalAFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let digitala = [];
          filters[key].forEach((item) => {
            digitala.push(" " + item.name);
          });
          digitala = digitala.toString();
          digitala = "Digital Advisor(s): " + digitala;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: digitala,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "seLeadFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let selead = [];
          filters[key].forEach((item) => {
            selead.push(" " + item.name);
          });
          selead = selead.toString();
          selead = "Sales Enablement Lead: " + selead;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: selead,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "brokerTeamFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let brokernames = [];
          filters[key].forEach((item) => {
            brokernames.push(" " + item.name);
          });
          let factor = filters["teamIncludeorExact"];

          brokernames = brokernames.toString();
          brokernames = "Broker Team " + factor + ": " + brokernames;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: filters["teamIncludeorExact"],
            value: brokernames,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "sourcingMethodFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let methods = [];
          filters[key].forEach((item) => {
            methods.push(" " + item.name);
          });
          methods = methods.toString();
          methods = "Sourcing methodology: " + methods;
          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: methods,
          };
          filterarray.push(activefilterobject);
        }
      } else if (key === "industryFilter") {
        if (filters[key].length > 0 && filters[key] !== "") {
          let industries = [];
          filters[key].forEach((item) => {
            industries.push(" " + item.name);
          });
          industries = industries.toString();
          industries = "Client company industry: " + industries;

          let activefilterobject = {
            type: key,
            rawvalue: filters[key],
            includeexclude: null,
            value: industries,
          };
          filterarray.push(activefilterobject);
        }
      }
    });

    this.props.dispatch(updateActiveFilters(filterarray));
    this.props.findSectionsToHide();
  }

  render() {
    const { meetingtypelist, oppnamelist } = this.props;

    return (
      <form className="form-inline mr-auto filter-form">
        <div className="col">
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="projectfilter">Opportunity Name</label>
            <input
              ref="projectfilter"
              onChange={this.handleInputChange.bind(this)}
              name="oppNameFilter"
              placeholder="Select opportunity..."
              type="text"
              id="projectfilter"
              list="filteredData"
              className="form-control bg-light filterform-input border-0"
            />
            <datalist id="filteredData">
              {oppnamelist.map((item, key) => (
                <option key={key} value={item} />
              ))}
            </datalist>
          </div>

          <div className="form-group mb-3  filterslabel justify-content-between">
            <label for="meetingtypefilter">Meeting Type</label>
            <input
              ref="meetingtypefilter"
              onChange={this.handleInputChange.bind(this)}
              name="meetingtypefilter"
              placeholder="Select meeting type..."
              type="text"
              id="meetingtypefilter"
              list="meetingtypelist"
              className="filterform-input form-control bg-light border-0"
            />
            <datalist id="meetingtypelist">
              {meetingtypelist.map((item, key) => (
                <option key={key} value={item} />
              ))}
            </datalist>
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="inboundbusinessfilter">Inbound Business</label>
            <input
              ref="inboundbusinessfilter"
              onChange={this.handleInputChange.bind(this)}
              name="inboundbusinessfilter"
              placeholder="Select inbound status..."
              type="text"
              id="inboundbusinessfilter"
              list="inboundlist"
              className="filterform-input form-control bg-light border-0"
            />
            <datalist id="inboundlist">
              <option>True</option>
              <option>False</option>
            </datalist>
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="sourcingbrokerfilter">
              Primary Broker Name (Includes)
            </label>

            <Multiselect
              options={this.state.brokerlist} // Options to display in the dropdown
              onSelect={this.onSelectPrimaryBroker.bind(this)} // Function will trigger on select event
              onRemove={this.onRemovePrimaryBroker.bind(this)} // Function will trigger on remove event
              id="sourcingbrokerfilter"
              ref={this.primaryBrokerRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          {/* Change this to multiple  */}

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="brokerTeamFilter">Broker Team</label>
            <div className="toggle-radios">
              <FormGroup className="radio abc-radio">
                <Input
                  ref="teamincludeorexact"
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  name="teamincludeorexact"
                  id="radio3"
                  value="includes"
                  defaultChecked
                />
                <Label className="gltlabels" for="radio3">
                  Includes
                </Label>
              </FormGroup>
              <FormGroup className="radio abc-radio">
                <Input
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  id="radio4"
                  name="teamincludeorexact"
                  value="exact"
                />
                <Label className="gltlabels" for="radio4">
                  Exact
                </Label>
              </FormGroup>
              <FormGroup className="radio abc-radio">
                <Input
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  id="radio5"
                  name="teamincludeorexact"
                  value="exclude"
                />
                <Label className="gltlabels" for="radio5">
                  Exclude
                </Label>
              </FormGroup>
            </div>
            {/* <input ref="addbrokerfilter" onChange={(this.handleInputChange).bind(this)} name="addBrokerFilter" placeholder="Select broker..." type="text" id="addbrokerfilter" list="brokers" className="form-control bg-light border-0" /> */}
            <Multiselect
              options={this.state.brokerlist} // Options to display in the dropdown
              onSelect={this.onSelectBroker.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveBroker.bind(this)} // Function will trigger on remove event
              id="brokerTeamFilter"
              ref={this.brokerTeamFilter}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="techused">Tech Tools Used</label>
            <div className="toggle-radios">
              <FormGroup className="radio abc-radio">
                <Input
                  ref="toolsincludeorexact"
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  name="toolsincludeorexact"
                  id="radio5"
                  value="includes"
                  defaultChecked
                />
                <Label className="gltlabels" for="radio5">
                  Includes
                </Label>
              </FormGroup>
              <FormGroup className="radio abc-radio">
                <Input
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  id="radio6"
                  name="toolsincludeorexact"
                  value="exact"
                />
                <Label className="gltlabels" for="radio6">
                  Exact
                </Label>
              </FormGroup>
            </div>

            <Multiselect
              options={this.state.techoptions} // Options to display in the dropdown
              onSelect={this.onSelectTool.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveTool.bind(this)} // Function will trigger on remove event
              id="techUsedFilter"
              ref={this.multiselectRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          {this.props.market === "National" || this.props.market === "JTE" ? (
            <div className="form-group mb-3 filterslabel justify-content-between">
              <label for="techused">Sourcing Market</label>
              <div className="toggle-radios">
                <FormGroup className="radio abc-radio">
                  <Input
                    ref="marketincludeexclude"
                    onChange={this.handleInputChange.bind(this)}
                    type="radio"
                    name="marketIncludeExclude"
                    id="radio7"
                    value="includes"
                    defaultChecked
                  />
                  <Label className="gltlabels" for="radio7">
                    Includes
                  </Label>
                </FormGroup>
                <FormGroup className="radio abc-radio">
                  <Input
                    onChange={this.handleInputChange.bind(this)}
                    type="radio"
                    id="radio8"
                    name="marketIncludeExclude"
                    value="exclude"
                  />
                  <Label className="gltlabels" for="radio8">
                    Exclude
                  </Label>
                </FormGroup>
              </div>

              <Multiselect
                options={this.state.markets} // Options to display in the dropdown
                onSelect={this.onSelectMarket.bind(this)} // Function will trigger on select event
                onRemove={this.onRemoveMarket.bind(this)} // Function will trigger on remove event
                id="techUsedFilter"
                ref={this.marketMultiselectRef}
                displayValue="name" // Property name to display in the dropdown options
              />
            </div>
          ) : null}

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="sourcingMethodFilter">
              Sourcing Methodology (Includes)
            </label>

            <Multiselect
              options={this.state.sourcingmethods} // Options to display in the dropdown
              onSelect={this.onSelectSourcingMethod.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveSourcingMethod.bind(this)} // Function will trigger on remove event
              id="sourcingMethodFilter"
              ref={this.sourcingMethodRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="seLeadFilter">Sales Enablement Lead</label>

            <Multiselect
              options={this.state.selist} // Options to display in the dropdown
              onSelect={this.onSelectSELead.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveSELead.bind(this)} // Function will trigger on remove event
              id="seLeadFilter"
              ref={this.seLeadRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="marketCodeFilter">Market Code</label>

            <Multiselect
              options={this.state.marketcodes} // Options to display in the dropdown
              onSelect={this.onSelectMarketCode.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveMarketCode.bind(this)} // Function will trigger on remove event
              id="marketCodeFilter"
              ref={this.marketCodeRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="daFilter">Digital Advisor</label>

            <Multiselect
              options={this.state.dalist} // Options to display in the dropdown
              onSelect={this.onSelectDA.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveDA.bind(this)} // Function will trigger on remove event
              id="daFilter"
              ref={this.daRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
        </div>
        <div className="col">
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="otherServicesFilter">
              Other JLL Services (Includes)
            </label>

            <Multiselect
              options={this.state.otherservices} // Options to display in the dropdown
              onSelect={this.onSelectOtherServices.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveOtherServices.bind(this)} // Function will trigger on remove event
              id="otherServicesFilter"
              ref={this.otherServicesRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="industryFilter">Client Industry (Includes)</label>

            <Multiselect
              options={this.state.industrylist} // Options to display in the dropdown
              onSelect={this.onSelectIndustry.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveIndustry.bind(this)} // Function will trigger on remove event
              id="industryFilter"
              ref={this.industryRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="industryFilter">Practice Groups (Includes)</label>
            <Multiselect
              options={this.state.practicegrouplist} // Options to display in the dropdown
              onSelect={this.onSelectPracticeGroup.bind(this)} // Function will trigger on select event
              onRemove={this.onRemovePracticeGroup.bind(this)} // Function will trigger on remove event
              id="practicegroupFilter"
              ref={this.practiceGroupRef}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="serviceLineFilter">Service Line (Includes)</label>

            <Multiselect
              options={this.state.servicelines} // Options to display in the dropdown
              onSelect={this.onSelectServiceLine.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveServiceLine.bind(this)} // Function will trigger on remove event
              id="serviceLineFilter"
              ref={this.servicelineref}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="serviceLineFilter">Opportunity Type (Includes)</label>

            <Multiselect
              options={this.state.opptypelist} // Options to display in the dropdown
              onSelect={this.onSelectOppType.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveOppType.bind(this)} // Function will trigger on remove event
              id="opportunitytypefilter"
              ref={this.opportunitytypefilter}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="serviceLineFilter">Property Type (Includes)</label>

            <Multiselect
              options={this.state.propertytypelist} // Options to display in the dropdown
              onSelect={this.onSelectPropertyType.bind(this)} // Function will trigger on select event
              onRemove={this.onRemovePropertyType.bind(this)} // Function will trigger on remove event
              id="propertytypefilter"
              ref={this.propertytypefilter}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label>Date Created Range</label>
            <DateRangePickerWrapper
              enableOutsideDays={true}
              isOutsideRange={() => false}
              ref="daterange"
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label>Date Closed Range</label>
            <DateClosedPicker
              enableOutsideDays={true}
              isOutsideRange={() => false}
              ref="daterange"
            />
          </div>
          <div className="form-group mb-3 filterslabel justify-content-between">
            <label>Meeting Date Range</label>
            <DateMeetingPicker
              enableOutsideDays={true}
              isOutsideRange={() => false}
              ref="daterange"
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="serviceLineFilter">Project Status (Includes)</label>
            <Multiselect
              options={this.state.statuslist} // Options to display in the dropdown
              onSelect={this.onSelectStatus.bind(this)} // Function will trigger on select event
              onRemove={this.onRemoveStatus.bind(this)} // Function will trigger on remove event
              id="winlossfilter"
              ref={this.statusfilter}
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>

          <div className="form-group mb-3 filterslabel justify-content-between">
            <label for="greaterlessthan">Project Size (in SF)</label>
            <div className="toggle-radios">
              <FormGroup className="radio abc-radio">
                <Input
                  ref="greaterlessthan"
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  name="greaterlessthan"
                  id="radio1"
                  value="greater"
                  defaultChecked
                />
                <Label className="gltlabels" for="radio1">
                  Greater than
                </Label>
              </FormGroup>
              <FormGroup className="radio abc-radio">
                <Input
                  onChange={this.handleInputChange.bind(this)}
                  type="radio"
                  id="radio2"
                  name="greaterlessthan"
                  value="less"
                />
                <Label className="gltlabels" for="radio2">
                  Less than
                </Label>
              </FormGroup>
            </div>
            <input
              ref="sizeFilter"
              onChange={this.handleInputChange.bind(this)}
              name="sizeFilter"
              placeholder="Enter number..."
              type="number"
              id="sizeFilter"
              className="form-control filterform-input bg-light border-0"
            />
          </div>
        </div>
        {/* PM Filters Start */}
        <div className="w-100 ">
          <div className="col">
            <div
              className="card-header panel-header bg-light mb-4"
              role="button"
              onClick={() => {
                this.toggleAccordionFirst(1);
              }}
            >
              {/* eslint-enable */}
              <div className="mb-0">
                {/* eslint-disable-next-line */}
                <a className="accordion-toggle" role="button">
                  Property Management Filters
                  <i
                    className={`fa fa-angle-down ${
                      this.state.accordionFirst[1] ? "expanded" : ""
                    }`}
                  />
                </a>
              </div>
            </div>
            <Collapse
              className="panel-body"
              isOpen={this.state.accordionFirst[1]}
            >
              <div className="col">
                <div className="form-group mb-3 filterslabel justify-content-between">
                  <label>Est. Start Date Range</label>
                  <DateRangeStartDate
                    enableOutsideDays={true}
                    isOutsideRange={() => false}
                    ref="daterange"
                  />
                </div>
                <div className="form-group mb-3 filterslabel justify-content-between">
                  <label for="serviceLineFilter">Deal Type (Includes)</label>
                  <Multiselect
                    options={this.state.dealTypeList} // Options to display in the dropdown
                    onSelect={(list) =>
                      this.onChangeMultiselect(list, "dealTypeFilter")
                    }
                    onRemove={(list) =>
                      this.onChangeMultiselect(list, "dealTypeFilter")
                    }
                    id="dealTypeFilter"
                    ref={this.dealTypeFilter}
                    displayValue="name" // Property name to display in the dropdown options
                  />
                </div>
                <div className="form-group mb-3 filterslabel justify-content-between">
                  <label for="serviceLineFilter">
                    Deal Sub-Type (Includes)
                  </label>
                  <Multiselect
                    options={this.state.dealSubTypeList} // Options to display in the dropdown
                    onSelect={(list) =>
                      this.onChangeMultiselect(list, "dealSubTypeFilter")
                    }
                    onRemove={(list) =>
                      this.onChangeMultiselect(list, "dealSubTypeFilter")
                    }
                    id="dealSubTypeFilter"
                    ref={this.dealSubTypeFilter}
                    displayValue="name" // Property name to display in the dropdown options
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group mb-3 filterslabel justify-content-between">
                  <label for="serviceLineFilter">Sales Stage (Includes)</label>
                  <Multiselect
                    options={this.state.salesStageList} // Options to display in the dropdown
                    onSelect={(list) =>
                      this.onChangeMultiselect(list, "salesStageFilter")
                    }
                    onRemove={(list) =>
                      this.onChangeMultiselect(list, "salesStageFilter")
                    }
                    id="salesStageFilter"
                    ref={this.salesStageFilter}
                    displayValue="name"
                  />
                </div>
                <div className="form-group mb-3 filterslabel justify-content-between">
                  <label for="estRevGreaterLess">Estimated Revenue </label>
                  <div className="toggle-radios">
                    <FormGroup className="radio abc-radio">
                      <Input
                        ref="estRevGreaterLess"
                        onChange={this.handleInputChange.bind(this)}
                        type="radio"
                        name="estRevGreaterLess"
                        id="radio1"
                        value="greater"
                        defaultChecked
                      />
                      <Label className="gltlabels" for="radio1">
                        Greater than
                      </Label>
                    </FormGroup>
                    <FormGroup className="radio abc-radio">
                      <Input
                        onChange={this.handleInputChange.bind(this)}
                        type="radio"
                        id="radio2"
                        name="estRevGreaterLess"
                        value="less"
                      />
                      <Label className="gltlabels" for="radio2">
                        Less than
                      </Label>
                    </FormGroup>
                  </div>
                  <input
                    ref="estRevFilter"
                    onChange={this.handleInputChange.bind(this)}
                    name="estRevFilter"
                    placeholder="Enter number..."
                    type="number"
                    id="estRevFilter"
                    className="form-control filterform-input bg-light border-0"
                  />
                </div>
              </div>
            </Collapse>

            {this.props.orFilterBox ? (
              <Button
                onClick={(e) => this.addFilterButton()}
                className="w-100"
                color="primary"
              >
                Filter
              </Button>
            ) : (
              <Button
                onClick={(e) => this.filterButton()}
                className="w-100"
                color="primary"
              >
                Filter
              </Button>
            )}
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    marketcodes: state.projects.marketcodes,
    marketoptions: state.projects.marketoptions,
    dalist: state.projects.dalist,
    selist: state.projects.selist,
    practicegrouplist: state.projects.practicegrouplist,
    meetingtypelist: state.projects.meetingtypelist,
    opptypelist: state.projects.opptypelist,
    propertytypelist: state.projects.propertytypelist,
    filters: state.dashboardfilters,
    sourcingmethodologylist: state.projects.sourcingmethodologylist,
    otherserviceslist: state.projects.otherserviceslist,
    industrylist: state.projects.industrylist,
    marketdata: state.projects.marketdata,
    activefilters: state.dashboardfilters.activefilters,
    projects: state.projects.projects,
    cleandata: state.projects.cleandata,
    oppnamelist: state.projects.oppnamelist,
    techlist: state.projects.techlist,
    techUsedFilter: state.dashboardfilters.techUsedFilter,
    blist: state.projects.blist,
    servicelinelist: state.projects.servicelinelist,
    finaldalist: state.projects.finaldalist,
    finalselist: state.projects.finalselist,
    finalmarketcodelist: state.projects.finalmarketcodelist,
    finalindustries: state.projects.finalindustries,
    finalsourcingmethods: state.projects.finalsourcingmethods,
    finalservicelinelist: state.projects.finalservicelinelist,
    finalotherservices: state.projects.finalotherservices,
    finaltechlist: state.projects.finaltechlist,
    finalbrokerlist: state.projects.finalbrokerlist,
    finalpracticegrouplist: state.projects.finalpracticegrouplist,
    finalstatuslist: state.projects.finalstatuslist,
    finalopptypelist: state.projects.finalopptypelist,
    finalpropertytypelist: state.projects.finalpropertytypelist,
    finalDealTypeList: state.projects.finalDealTypeList,
    finalDealSubtypeList: state.projects.finalDealSubtypeList,
    finalSalesStageList: state.projects.finalSalesStageList,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  FilterPanel
);
