import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import TopBrokerWinRate from "./TopBrokerWinRateChart";
import TopBrokerCycleTime from "./TopBrokerCycleTimeChart";
import YearOverYearBarChart from "./YearOverYearBarChart";
import YearOverYearWinRateBarChart from "./YearOverYearBarWinRateBarChart";
import YearOverYearLineChart from "./YearOverYearLineChart";
import YearOverYearWinRateLineChart from "./YearOverYearWinRateLineChart";
import MarketPerformance from './marketperformance'

var moment = require("moment");

class BuyerEnablementStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      avgcycletimeprevyear: "",
      avgcycletimethisyear: "",
      avgcycletimelastyearjte: "",
      avgcycletimethisyearjte: "",
      conclusivePrevYear: "",
      conclusiveThisYear: "",
      prevYearWinrate: "",
      thisYearWinrate: "",
      prevyearopps: [],
      thisyearopps: [],
      jteoppsthisyear: [],
      jteoppsprevyr: [],
      opportunities: [],
      wonopps: [],
      lostopps: [],
      newopps: [],
    };
  }

  setOppsandUpdates() {
    // Find not only the opps that are changed through a status update but also those created in current date range with status of won or lost
    // let filteredopps = this.props.rawopps.filter(item1 =>
    //   this.props.updates.some(item2 => (item2.opportunityid === item1.opportunityid)) || (this.props.opps.some(item3 => (item3.opportunityid === item1.opportunityid)) && (item1.winloss == "Won" || item1.winloss == "Lost")))
    // let wonopps = filteredopps.filter(item => item.winloss === "Won")
    // let lostopps = filteredopps.filter(item => item.winloss === "Lost")

    let opps = this.props.opps;
    let jlltech = "JLL Tenant Experience";
    // get count of opps prev year (2020)
    let beginningofyear = moment("01-01-2020", "MM-DD-YYYY");
    let endofyear = moment("12-31-2020", "MM-DD-YYYY");
    let prevyearopps = opps.filter(
      (item) =>
        moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(
          beginningofyear
        ) && moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(endofyear)
    );
    let wonlostupdates = this.props.updates.filter(
      (item) => item.toStatus === "Won" || item.toStatus == "Lost"
    );
    let prevyearupdates = wonlostupdates.filter(
      (item) =>
        moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isAfter(
          beginningofyear
        ) && moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isBefore(endofyear)
    );
    let wonoppsprevyear = prevyearopps.filter((item) => item.winloss === "Won");
    let conclusivePrevYear = prevyearopps.filter(
      (item) => item.winloss === "Won" || item.winloss === "Lost"
    );
    let prevyearwinrate = (
      (wonoppsprevyear.length / conclusivePrevYear.length) *
      100
    ).toFixed(1);

    // get count of opps this year (2021)
    let thisyear = moment("01-01-2021", "MM-DD-YYYY");
    let endofthisyear = moment("12-31-2021", "MM-DD-YYYY");
    let thisyearopps = opps.filter(
      (item) =>
        moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(thisyear) &&
        moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(endofthisyear)
    );
    let thisyearupdates = wonlostupdates.filter(
      (item) =>
        moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isAfter(endofthisyear) &&
        moment(item.updateTime, "MM-DD-YYYY hh:mm:ss").isBefore(endofthisyear)
    );
    let wonoppsthisyear = thisyearopps.filter((item) => item.winloss === "Won");
    let conclusiveThisYear = thisyearopps.filter(
      (item) => item.winloss === "Won" || item.winloss === "Lost"
    );
    let thisYearwinrate = (
      (wonoppsthisyear.length / conclusiveThisYear.length) *
      100
    ).toFixed(1);

    // get cycle time of opps for 2020
    let newarray = [];
    wonlostupdates.forEach((item) => {
      let foundopp = prevyearopps.find(
        (obj) => obj.opportunityid === item.opportunityid
      );
      if (foundopp) {
        let newobject = {
          opportunityid: item.opportunityid,
          datecreated: foundopp.datecreated,
          dateclosed: item.updateTime,
        };
        newarray.push(newobject);
      }
    });
    let prevyeardatedifference = newarray.map((item) => {
      let diff = moment(item.dateclosed).diff(item.datecreated, "days");
      return diff;
    });
    var prevyearcounter = 0;
    for (var i = 0; i < prevyeardatedifference.length; i++) {
      prevyearcounter += prevyeardatedifference[i];
    }
    var avgcycletimeprevyear = (
      prevyearcounter / prevyeardatedifference.length
    ).toFixed(1);

    // get cycle time of  opps for 2021
    let newarray2 = [];
    wonlostupdates.forEach((item) => {
      let foundopp2 = thisyearopps.find(
        (obj) => obj.opportunityid === item.opportunityid
      );
      if (foundopp2) {
        let newobject = {
          opportunityid: item.opportunityid,
          datecreated: foundopp2.datecreated,
          dateclosed: item.updateTime,
        };
        newarray2.push(newobject);
      }
    });
    let thisyeardatedifference = newarray2.map((item) => {
      let diff = moment(item.dateclosed).diff(item.datecreated, "days");
      return diff;
    });
    let avgcycletimethisyear = 0;
    if (thisyeardatedifference.length === 1) {
      avgcycletimethisyear = thisyeardatedifference[0];
    }

    if (
      thisyeardatedifference.length !== 1 &&
      thisyeardatedifference.length > 0
    ) {
      let thisyearcounter = 0;
      for (var i = 0; i < thisyeardatedifference.length; i++) {
        thisyearcounter += thisyeardatedifference[i];
      }
      avgcycletimethisyear = (
        thisyearcounter / thisyeardatedifference.length
      ).toFixed(1);
    }

    this.setState({
      avgcycletimeprevyear: avgcycletimeprevyear,
      avgcycletimethisyear: avgcycletimethisyear,
      conclusivePrevYear: conclusivePrevYear,
      conclusiveThisYear: conclusiveThisYear,
      prevyearopps: prevyearopps,
      thisyearopps: thisyearopps,
      prevyearupdates: prevyearupdates,
      wonlostupdates: wonlostupdates,
      thisyearupdates: thisyearupdates,
      prevYearWinrate: prevyearwinrate,
      thisYearWinrate: thisYearwinrate,
    });
  }

  componentDidMount() {
    this.setOppsandUpdates();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.opps != prevProps.opps) {
      this.setOppsandUpdates();
    }
  };

  // (opps.length).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  // {(opps.reduce((a, {pursuitsize}) => a + pursuitsize, 0) / opps.length).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
  render() {
    return (
      <>
        <Row>
          <Col>
            <h1 className="float-left mb-2">Summary</h1>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={12}>
            <YearOverYearLineChart data={this.props.opps} />
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={12}>
            <YearOverYearWinRateLineChart data={this.props.opps} />
          </Col>
        </Row>

        <Row>
          <Col lg={6}>
            <YearOverYearBarChart data={this.props.opps} />
          </Col>
          <Col lg={6}>
            <YearOverYearWinRateBarChart data={this.props.opps} />
          </Col>
          <Col lg={12} xs={12}>
              <MarketPerformance data={this.props.opps} />
          </Col>
        </Row>

        <Row>
          <Col>
            <h1 className="float-left mb-2">2020</h1>
          </Col>
        </Row>
        <Row>
          {this.state.prevyearopps.length > 0 ? (
            <>
              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    className="mb-0 h-100"
                    close
                    bodyClass="mt-lg"
                    title={
                      <h5>
                        <span className="fw-semi-bold">Total</span>{" "}
                        Opportunities
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.prevyearopps.length}
                      </h2>
                    </div>
                  </Widget>
                </div>
              </Col>
              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    close
                    className="mb-0 h-100"
                    title={
                      <h5>
                        Total <span className="fw-semi-bold">Conclusive</span>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.conclusivePrevYear.length}
                      </h2>
                      {/* <i className="la la-arrow-right text-success rotate-315"/> */}
                    </div>
                  </Widget>
                </div>
              </Col>

              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    close
                    className="mb-0 h-100"
                    title={
                      <h5>
                        Total<span className="fw-semi-bold"> Win Rate</span>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.prevYearWinrate} %
                      </h2>
                      {/* <i className="la la-arrow-right text-danger rotate-45"/> */}
                    </div>
                  </Widget>
                </div>
              </Col>

              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    close
                    className="mb-0 h-100"
                    title={
                      <h5>
                        Average <span className="fw-semi-bold">Cycle Time</span>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {" "}
                        {this.state.avgcycletimeprevyear} Days
                      </h2>
                      {/* <h2 style={{fontSize: '2.1rem'}}> {(((this.props.rawopps.filter(object => object.winloss === "Won").length) / (this.props.rawopps.filter(object => object.winloss === "Won" || object.winloss === "Lost"  ).length)) * 100).toFixed(1)} %</h2> */}
                      {/* <i className="la la-arrow-right text-success rotate-315"/> */}
                    </div>
                  </Widget>
                </div>
              </Col>
            </>
          ) : null}
        </Row>
        <Row>
          <Col lg={6}>
            <TopBrokerWinRate data={this.state.prevyearopps} />
          </Col>
          <Col lg={6}>
            <TopBrokerCycleTime
              data={this.state.prevyearopps}
              updates={this.state.wonlostupdates}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="float-left mb-2">2021</h1>
          </Col>
        </Row>
        <Row>
          <>
            <Col xs={12} xl={3} md={6}>
              <div className="pb-xlg h-100">
                <Widget
                  className="mb-0 h-100"
                  close
                  bodyClass="mt-lg"
                  title={
                    <h5>
                      <span className="fw-semi-bold">Total</span> Opportunities
                    </h5>
                  }
                >
                  <div className="d-flex justify-content-between align-items-center mb h3">
                    <h2 style={{ fontSize: "2.1rem" }}>
                      {this.state.thisyearopps.length}
                    </h2>
                  </div>
                </Widget>
              </div>
            </Col>
            <Col xs={12} xl={3} md={6}>
              <div className="pb-xlg h-100">
                <Widget
                  bodyClass="mt"
                  close
                  className="mb-0 h-100"
                  title={
                    <h5>
                      Total <span className="fw-semi-bold">Conclusive</span>
                    </h5>
                  }
                >
                  <div className="d-flex justify-content-between align-items-center mb h3">
                    <h2 style={{ fontSize: "2.1rem" }}>
                      {this.state.conclusiveThisYear.length}
                    </h2>
                    {/* <i className="la la-arrow-right text-success rotate-315"/> */}
                  </div>
                </Widget>
              </div>
            </Col>

            <Col xs={12} xl={3} md={6}>
              <div className="pb-xlg h-100">
                <Widget
                  bodyClass="mt"
                  close
                  className="mb-0 h-100"
                  title={
                    <h5>
                      Total<span className="fw-semi-bold"> Win Rate</span>
                    </h5>
                  }
                >
                  <div className="d-flex justify-content-between align-items-center mb h3">
                    {this.state.thisYearWinrate > 0 ? (
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.thisYearWinrate}%
                      </h2>
                    ) : (
                      "Not enough data."
                    )}
                  </div>
                </Widget>
              </div>
            </Col>

            <Col xs={12} xl={3} md={6}>
              <div className="pb-xlg h-100">
                <Widget
                  bodyClass="mt"
                  close
                  className="mb-0 h-100"
                  title={
                    <h5>
                      Average <span className="fw-semi-bold">Cycle Time</span>
                    </h5>
                  }
                >
                  <div className="d-flex justify-content-between align-items-center mb h3">
                    {this.state.avgcycletimethisyear > 0 ? (
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {" "}
                        {this.state.avgcycletimethisyear} Days
                      </h2>
                    ) : (
                      "Not enough data."
                    )}
                  </div>
                </Widget>
              </div>
            </Col>
          </>
        </Row>
        <Row>
          <Col lg={6}>
            <TopBrokerWinRate data={this.state.thisyearopps} />
          </Col>
          <Col lg={6}>
            <TopBrokerCycleTime
              data={this.state.thisyearopps}
              updates={this.state.wonlostupdates}
            />
          </Col>
         
        </Row>
      </>
    );
  }
}

export default BuyerEnablementStats;
