import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "react-dates/initialize";
import cx from "classnames";
import Transition from "react-transition-group/Transition";
import {
  Col,
  Row,
  Label,
  Progress,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Fade,
} from "reactstrap";
import DropDownStats from "../components/DropdownStats/DropdownStats";
import { Link } from "react-router-dom";
import Widget from "../../../components/Widget/Widget";
import "react-dates/lib/css/_datepicker.css";
import NationalMainChart from "../components/MarketCharts/NationalMainChart";
import NationalBrokerPerformance from "../components/MarketCharts/NationalBrokerPerformance";
import NationalDonutChart from "../components/MarketCharts/NationalDonut";
import TaskContainer from "../components/TaskContainer/TaskContainer";
import BigStat from "../components/BigStat/BigStat";
import TableContainer from "../components/TableContainer/TableContainer";
import Calendar from "../components/calendar/Calendar";
import FilterPanel from "../components/FilterPanel/filterpanel";
import HighchartsReact from "highcharts-react-official";
import BrokerPerformance from "../components/Charts/BrokerPerformance";

import {
  updateAddBrokerFilter,
  updateSourcingBrokerFilter,
  updateServiceLineFilter,
  updateOppNameFilter,
  updateGreaterorlessFilter,
  updateSizeFilter,
  updateStatusFilter,
  updateActiveFilters,
  updateEndDate,
  updateStartDate,
  updateShortcut,
} from "../../../actions/dashboardfilters";
import s from "../MainDashboard.module.scss";
import {
  setFilteredProjects,
  setCleanData,
  getMarketSpecificData,
} from "../../../actions/projects";
import ErrorPage from "../../error/ErrorPage";

var moment = require("moment");
// import { getProjectsRequest } from '../../actions/projects';

function cleanPersonList(person) {
  let list = [];
  person.forEach((item) => list.push(item.fullname));
  let theperson = list.join(", ");
  return theperson;
}
function cleanServiceList(serviceline) {
  let list = [];
  serviceline.forEach((item) => list.push(item.servicelinename));
  let theservice = list.join(", ");
  return theservice;
}

class NationalLimitedDashboard extends Component {
  constructor(props) {
    super(props);
    this.filterboxchild = React.createRef();
    // this.toggleOne = this.toggleOne.bind(this);
    // this.toggleTwo = this.toggleTwo.bind(this);
    // this.toggleThree = this.toggleThree.bind(this);
    // this.toggleFour = this.toggleFour.bind(this);

    this.state = {
      filterbox: false,
      key: "selection",
      sBrokerFilter: "",
      brokerFilter: "",
      dropdownOpenOne: false,
      dropdownOpenTwo: false,
      dropdownOpenThree: false,
      dropdownOpenFour: false,
      slineFilter: "",
      oppNameFilter: "",
      statusFilter: "",
      dateFilter: "",
      cleandata: "",
      oppnamelist: [],
      blist: [],
      servicelinelist: [],
      RenderDonut: true,
      RenderColumnOneStats: true,
      RenderColumnTwoStats: true,
      RenderLineChart: true,
      RenderBrokerPerf: true,
      RenderAgencyScorecards: true,
      RenderCalendar: true,
      RenderTableData: true,
      activefilters: [],
      pdf: false,
      market: "National",
    };
  }
  //charttypea = Broker friendly
  //charttypeb= Opportunity friendly
  //charttypec= Both

  wonOppsShortcut() {
    this.clearFilters(this.props.projects);
    this.setState({
      RenderDonut: false,
      RenderAgencyScorecards: false,
      RenderColumnOneStats: false,
      RenderColumnTwoStats: false,
    });
    this.props.dispatch(updateStatusFilter("Won"));
    this.props.dispatch(updateShortcut(true));
  }

  fiftyThousandShortcut() {
    // this.setState({
    //   RenderDonut: false
    // })
    this.clearFilters(this.props.projects);
    this.props.dispatch(updateSizeFilter("50000"));
    this.props.dispatch(updateShortcut(true));
  }

  thisMonthShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(updateStartDate(moment().startOf("month")));
    this.props.dispatch(updateEndDate(moment().endOf("month")));
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderLineChart: false,
    });
  }

  lastMonthShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(
      updateStartDate(moment().subtract(1, "months").startOf("month"))
    );
    this.props.dispatch(
      updateEndDate(moment().subtract(1, "months").endOf("month"))
    );
    this.props.dispatch(updateShortcut(true));
    this.setState({
      RenderLineChart: false,
    });
  }

  thisYearShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(updateStartDate(moment().startOf("year")));
    this.props.dispatch(updateEndDate(moment().endOf("year")));
    this.props.dispatch(updateShortcut(true));
  }

  lastYearShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(
      updateStartDate(moment().subtract(1, "year").startOf("year"))
    );
    this.props.dispatch(
      updateEndDate(moment().subtract(1, "year").endOf("year"))
    );
    this.props.dispatch(updateShortcut(true));
  }

  industrialOppsShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(updateServiceLineFilter("Industrial"));
    this.props.dispatch(updateShortcut(true));
  }

  tenantrepOppsShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(updateServiceLineFilter("Tenant Rep"));
    this.props.dispatch(updateShortcut(true));
  }

  retailOppsShortcut() {
    this.clearFilters(this.props.projects);
    this.props.dispatch(updateServiceLineFilter("Retail"));
    this.props.dispatch(updateShortcut(true));
  }

  clearFilters(data) {
    this.props.dispatch(updateSourcingBrokerFilter(""));
    this.props.dispatch(updateAddBrokerFilter(""));
    this.props.dispatch(updateServiceLineFilter(""));
    this.props.dispatch(updateOppNameFilter(""));
    this.props.dispatch(updateStatusFilter(""));
    this.props.dispatch(updateSizeFilter(""));
    this.props.dispatch(updateActiveFilters([]));
    this.props.dispatch(updateStartDate(null));
    this.props.dispatch(updateEndDate(null));
    this.props.dispatch(updateActiveFilters([]));
    this.props.dispatch(setCleanData(this.props.projects, this.state.market));
    this.setState({
      RenderDonut: true,
      RenderColumnOneStats: true,
      RenderColumnTwoStats: true,
      RenderLineChart: true,
      RenderBrokerPerf: true,
      RenderAgencyScorecards: true,
      RenderCalendar: true,
      RenderTableData: true,
    });
  }

  checkfilters(filters) {
    let filterarray = [];
    Object.keys(filters).forEach(function (key) {
      if (
        key !== "greaterorlessFilter" &&
        key !== "sizeFilter" &&
        key !== "startDate" &&
        key !== "endDate"
      ) {
        if (filters[key] !== "") {
          filterarray.push(filters[key]);
        }
      } else if (key === "sizeFilter" && filters[key] !== "") {
        filterarray.push(
          filters["greaterorlessFilter"].toUpperCase() +
            " than " +
            filters["sizeFilter"] +
            " sf"
        );
      } else if (key === "endDate" && filters[key] !== "") {
        filterarray.push(
          "Between " +
            moment(filters["startDate"]).format('MMMM Do, YYYY"') +
            " and " +
            moment(filters["endDate"]).format("MMMM Do, YYYY")
        );
      }
    });

    let joined = this.props.activefilters.concat(filterarray);
    this.props.dispatch(updateActiveFilters(joined));
  }

  static defaultProps = {
    projects: [],
    visits: {},
    performance: {},
    server: {},
    revenue: [],
    mainChart: [],
    isReceiving: false,
  };
  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }
  generateDownload() {
    this.setState({
      pdf: true,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
    this.clearFilters(this.props.projects);
  }
  componentDidMount() {
    // if (this.props.cleandataready){
    //   this.props.dispatch(getMarketSpecificData(this.state.market, this.props.cleandata))
    // }
    // this.props.dispatch(getProjectsRequest());
  }

  render() {
    const { projects, marketdata, nationallinechartdata, specificmarketdata } =
      this.props;
    const {
      filterbox,
      RenderDonut,
      RenderLineChart,
      RenderBrokerPerf,
      market,
      activefilters,
    } = this.state;

    let winrate = (
      (marketdata.filter((object) => object.winloss === "Won").length /
        marketdata.filter(
          (object) => object.winloss === "Won" || object.winloss === "Lost"
        ).length) *
      100
    ).toFixed(1);

    let lossrate = (
      (marketdata.filter((object) => object.winloss === "Lost").length /
        marketdata.filter(
          (object) => object.winloss === "Won" || object.winloss === "Lost"
        ).length) *
      100
    ).toFixed(1);

    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }
    const sortedmarkets = []
      .concat(specificmarketdata)
      .sort((a, b) =>
        a.marketname > b.marketname ? 1 : b.marketname > a.marketname ? -1 : 0
      );

    let nationalmarketinfo = sortedmarkets.find(
      (item) => item.marketname === "National"
    );

    //Filter form on right sidebar

    //Shortcuts
    //IPS deals
    //One-offs
    //Meeting Makers
    //Transaction Size
    //Brokers

    //
    // <li><Button className="mb-xs mr-xs btn-block btn-primary "  onClick={(this.generateDownload).bind(this)} color="primary">Export</Button></li>
    //Onchartclick- show data.

    return (
      <div>
        <ErrorPage>
          {/*<div className={s.analyticsSide}>*/}
          <div>
            <Row>
              <Col className="title-block-wnotice" xs={12} md={8} lg={8} xl={8}>
                <h1 className="page-title">
                  <span className="fw-semi-bold">{market}</span> Dashboard
                </h1>
                {/*<small className="text-muted">Reporting from January 1, 2020</small>*/}
              </Col>
              <Col xs={12} md={4} lg={4} xl={4}>
                <div className="pb-md h-100 mb-0 float-right exportAndBack">
                  <ul>
                    <li>
                      <Link to={"/app/dashboard"}>
                        <Button
                          className="mb-xs mr-xs btn-block btn-primary float-right"
                          color="primary"
                        >
                          Market Selector
                        </Button>
                      </Link>
                    </li>
                    {/*
      <li><Button className="mb-xs mr-xs btn-block btn-primary float-right"  onClick={() => this.toggle('filterbox')} color="primary">Filter</Button></li>
      */}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className={activefilters.length > 0 ? "show" : "d-none"}>
              <Col xs={12} md={12} lg={12} xl={12}>
                <div className="pb-xlg h-100">
                  <Widget className="mb-0 h-100" collapse bodyClass="mt-lg">
                    <div>
                      <span className="fw-semi-bold">Filtering by:</span>
                      {activefilters.length > 0 ? (
                        activefilters.map((filter, key) => {
                          return (
                            <Button
                              style={{ backgroundColor: "#dee4ee" }}
                              key={key}
                              className={
                                s.filtertag + "justify-content-between m-4"
                              }
                            >
                              {filter}
                            </Button>
                          );
                        })
                      ) : (
                        <div>No filters</div>
                      )}

                      <Button
                        className="float-right m-4"
                        style={{ color: "#fff" }}
                        onClick={() => this.clearFilters(projects)}
                      >
                        Clear filters
                      </Button>
                    </div>
                  </Widget>
                </div>
              </Col>
            </Row>
          </div>
          <div className={s.sidesWrapper}>
            {/*  <div className={s.analyticsSide}> */}
            <div>
              <Row>
                <Col xs={12} xl={3} md={6}>
                  <div className="pb-xlg h-100">
                    <Widget
                      className="mb-0 h-100"
                      close
                      bodyClass="mt-lg"
                      title={
                        <h5>
                          <span className="fw-semi-bold">Total</span> Pursuits
                        </h5>
                      }
                    >
                      <div className="d-flex justify-content-between align-items-center mb h3">
                        <h2 style={{ fontSize: "2.1rem" }}>
                          {formatNumber(nationalmarketinfo.totalopps)}
                        </h2>
                      </div>
                    </Widget>
                  </div>
                </Col>
                <Col xs={12} xl={3} md={6}>
                  <div className="pb-xlg h-100">
                    <Widget
                      bodyClass="mt"
                      close
                      className="mb-0 h-100"
                      title={
                        <h5>
                          Current <span className="fw-semi-bold">Win Rate</span>
                        </h5>
                      }
                    >
                      <div className="d-flex justify-content-between align-items-center mb h3">
                        <h2 style={{ fontSize: "2.1rem" }}>
                          {nationalmarketinfo.winrate} %
                        </h2>
                        <i className="la la-arrow-right text-success rotate-315" />
                      </div>
                    </Widget>
                  </div>
                </Col>

                <Col xs={12} xl={3} md={6}>
                  <div className="pb-xlg h-100">
                    <Widget
                      bodyClass="mt"
                      close
                      className="mb-0 h-100"
                      title={
                        <h5>
                          Current{" "}
                          <span className="fw-semi-bold">Loss Rate</span>
                        </h5>
                      }
                    >
                      <div className="d-flex justify-content-between align-items-center mb h3">
                        <h2 style={{ fontSize: "2.1rem" }}>
                          {nationalmarketinfo.lossrate} %
                        </h2>
                        <i className="la la-arrow-right text-danger rotate-45" />
                      </div>
                    </Widget>
                  </div>
                </Col>
                <Col xs={12} xl={3} md={6}>
                  <div className="pb-xlg h-100">
                    <Widget
                      className="mb-0 h-100"
                      close
                      bodyClass="mt-lg"
                      title={
                        <h5>
                          Average Pursuit{" "}
                          <span className="fw-semi-bold">Size</span>
                        </h5>
                      }
                    >
                      <div className="d-flex justify-content-between align-items-center mb h3">
                        <h2 style={{ fontSize: "2.1rem" }}>
                          {nationalmarketinfo.total_sf
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          sf
                        </h2>
                        {/*<i className="la la-arrow-right text-success rotate-315"/>*/}
                      </div>
                    </Widget>
                  </div>
                </Col>
              </Row>
              {/*End new overhead stats*/}

              <Row>
                {RenderBrokerPerf ? (
                  <Col lg={7}>
                    <NationalBrokerPerformance
                      nationalbrokerdata={this.props.nationalbrokerdata}
                    />
                  </Col>
                ) : null}

                {RenderDonut ? (
                  <Col>
                    <NationalDonutChart
                      nationalstatusdata={this.props.nationalstatusdata}
                    />
                  </Col>
                ) : null}
                {RenderLineChart ? (
                  <Col lg={12} xs={12}>
                    {nationallinechartdata.length > 0 ? (
                      <NationalMainChart
                        nationallinechartdata={nationallinechartdata}
                      />
                    ) : null}
                  </Col>
                ) : null}
                {/*
          {
            RenderTableData
            ?
              <Col xs={12} className="mb-lg">

                <Widget
                  className="mb-0 h-100"
                  close
                  bodyClass="mt-lg"
                  title={<h4> Opportunity <strong>Table</strong></h4>}
                  >
                <Dynamic data={marketdata} />
                </Widget>

              </Col>
            : null
          }
          */}
              </Row>
            </div>
            {/*
          <div className={s.analyticsSide}>

            <Row>
              <Col xs={12} md={6} xl={12} className={s.lastSideElement}>

                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    close
                    className="mb-0 h-100"

                    title={<h5>Shortcuts</h5>}
                  >
                <legend className="w-100 m-1">Pursuit Status</legend>
                <Button onClick={(e) => this.wonOppsShortcut()} className="w-100 m-1" color="primary">Won Opportunities</Button>

                <legend className="w-100 m-1">Timeframe</legend>
                <Button onClick={(e) => this.thisMonthShortcut()} className="w-100 m-1" color="primary">This Month</Button>

                <Button onClick={(e) => this.lastMonthShortcut()} className="w-100 m-1" color="primary">Last Month</Button>

                <Button onClick={(e) => this.thisYearShortcut()} className="w-100 m-1" color="primary">This Year</Button>

                <Button onClick={(e) => this.lastYearShortcut()} className="w-100 m-1" color="primary">Last Year</Button>

                  </Widget>
                </div>

              </Col>
              <Col xs={12} md={6} xl={12}  className={s.lastSideElement}>
                <Sortable options={{
                    group: "shared",
                    ghostClass: 'widget-placeholder-react',
                    animation: 350,
                    filter: ".locked"
                  }}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt-lg"
                    close
                    className="mb-0 h-100"

                    title={<h5>Filters</h5>}
                  >
                    <FilterPanel ref={this.filterboxchild} market={market} />
                  </Widget>
                </div>
              </Sortable>
              </Col>

            </Row>
          </div> */}
          </div>
          {/* Begin filter box */}
          <Modal
            size="lg"
            isOpen={filterbox}
            toggle={() => this.toggle("filterbox")}
          >
            <ModalHeader toggle={() => this.toggle("filterbox")}>
              Dashboard Filter
            </ModalHeader>
            <ModalBody className="bg-white">
              <FilterPanel market={market} />
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </ErrorPage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    filters: state.dashboardfilters,
    filterbox: state.filterbox,
    oppnamelist: state.projects.oppnamelist,
    blist: state.projects.blist,
    servicelinelist: state.projects.servicelinelist,
    marketdata: state.projects.marketspecificdata,
    specificmarketdata: state.projects.marketdata,
  };
}

export default connect(mapStateToProps)(NationalLimitedDashboard);
