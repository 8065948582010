import React from "react";
import {
  Row,
  Col,
  Alert,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import CalendarV2 from "./Calendarv2";
import Loading from "./loading";
import s from "./Calendar.module.scss";

class CalendarHolder extends React.Component {
  constructor(props) {
    super(props);
    this.selectMarketForFilter = this.selectMarketForFilter.bind(this);
    this.state = {
      fetchingMeetingData: false,
      opportunities: [],
      marketlist: [],
      marketSelected: "",
      filtercal: false,
      alerts: [
        {
          id: "al-4",
          type: "danger",
          msg: '<span class="fw-semi-bold">Notice:</span> To use this tool, you must be subscribed to at least one local market or to the Master National Dashboard.',
          visible: [true, true, true],
        },
      ],
    };
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
  }

  closeAlert(index, alertGroup) {
    const newAlerts = [...this.state.alerts];
    newAlerts[index].visible[alertGroup] = false;

    this.setState({ alerts: newAlerts });
  }

  filterOppsByMarket(market) {
    let opportunities = this.props.opportunities;
    if (market != "National" && market != "All Markets" && market != "JTE") {
      opportunities = opportunities.filter(
        (item) => item.market.length > 0 && item.market[0].marketname === market
      );
    } else if (market === "JTE") {
      opportunities = opportunities.filter(
        (item) =>
          item.techused.length > 0 &&
          item.techused.some((obj) => obj.techname == "JLL Tenant Experience")
      );
    }
    return opportunities;
  }

  selectMarketForFilter(e) {
    let newmarket = e.value;
    let opps = this.filterOppsByMarket(newmarket);
    this.setState({
      marketSelected: e.value,
      opportunities: opps,
      filtercal: false,
    });
  }

  getBrokerMeetings() {
    let markets = [];

    for (var x in this.props.user.groups) {
      markets.push(this.props.user.groups[x]);
    }

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };

    if (
      markets.some((e) => e.name === "All Markets") &&
      markets.some((e) => e.name === "National")
    ) {
      axios.get(`/api/v1/marketdata/100/`).then(
        (res) => {
          let markets = [];
          let opps = res.data.opportunities;
          opps.sort((a, b) => a.datecreated - b.datecreated);

          this.setState({
            opportunities: opps,
            fetchingMeetingData: false,
          });
        },
        (error) => {
          this.setState({
            brokerRetrieveError: error,
            fetchingMeetingData: false,
          });
        }
      );
    } else {
      let opps = [];
      let promises = [];

      markets.forEach((item) => {
        promises.push(
          axios.get(`/api/v1/marketdata/${item.market_id}/`).then(
            (res) => {
              let opportunities = res.data.opportunities;
              opps.push(opportunities);
            },
            (error) => {
              console.log(error);
            }
          )
        );
      });
      //end foreach

      Promise.all(promises).then(() => {
        opps = [].concat.apply([], opps);
        opps.sort((a, b) => a.datecreated - b.datecreated);

        this.setState({
          opportunities: opps,
          fetchingMeetingData: false,
        });
      });
    }
  }

  getMarketList(markets) {
    let marketlist = markets.map(({ marketname }) => marketname);
    marketlist = marketlist.sort();
    let amindex = marketlist.indexOf("All Markets");
    if (amindex > -1) {
      marketlist.splice(amindex, 1);
    }
    marketlist = marketlist.map((item) => ({ value: item, label: item }));

    return marketlist;
  }
  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  componentDidMount() {
    let marketlist = this.getMarketList(this.props.markets);
    let opportunities = this.props.opportunities;
    this.setState({
      marketlist: marketlist,
      opportunities: opportunities,
    });
  }

  render() {
    const { user, opportunities } = this.props;
    const { filtercal } = this.state;

    let markets = [];

    for (var o in user.groups) {
      markets.push(user.groups[o].name);
    }

    return (
      <div className={s.root}>
        {markets.length == 1 && markets.includes("National") ? (
          <div>
            {this.state.alerts.map((alert, index) => (
              <Alert
                className="alert-transparent"
                key={alert.id}
                isOpen={alert.visible[1]}
                toggle={() => this.closeAlert(index, 1)}
                color={alert.type}
              >
                <span dangerouslySetInnerHTML={{ __html: alert.msg }} />
              </Alert>
            ))}
          </div>
        ) : null}
        <Row>
          <Col
            className="title-block-wnotice"
            xs={12}
            md={8}
            lg={8}
            xl={8}
          ></Col>
          <Col xs={12} md={4} lg={4} xl={4}>
            <div className="pb-md h-100 mb-0 float-right exportAndBack">
              <ul>
                <li>
                  <Button
                    className="mb-xs mr-xs btn-block btn-primary float-right"
                    onClick={() => this.toggle("filtercal")}
                    color="primary"
                  >
                    Filter
                  </Button>
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        {this.props.opportunities.length > 0 &&
        !this.state.fetchingMeetingData &&
        user ? (
          <CalendarV2
            opportunities={this.state.opportunities}
            market={this.state.marketSelected}
          />
        ) : (
          <Loading />
        )}

        <Modal
          size="lg"
          isOpen={filtercal}
          toggle={() => this.toggle("filtercal")}
        >
          <ModalHeader toggle={() => this.toggle("filtercal")}>
            Filter calendar
          </ModalHeader>
          <ModalBody className="bg-white">
            {this.state.marketlist.length > 0 ? (
              <div>
                <Label md="3" className="pb-md h-100" for="default-select">
                  Select a market
                </Label>
                <Col md="4" className="pb-md h-100">
                  <Select
                    // defaultValue={{ label: this.state.marketSelected, value: this.state.marketSelected }}
                    ref="oppmarketSelector"
                    className="selectCustomization"
                    options={this.state.marketlist}
                    onChange={this.selectMarketForFilter}
                  />
                </Col>
              </div>
            ) : (
              <div>No markets available.</div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    markets: state.projects.marketdata,
    user: state.authentication.userdata,
    opportunities: state.projects.opportunities,
  };
}

export default connect(mapStateToProps)(CalendarHolder);
