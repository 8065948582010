import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { stringify } from 'uuid';
var moment = require('moment');

export default class YearOverYearWinRateLineChart extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  chartData = () => {
      let { data } = this.props;
      let filterdate = moment("01-01-2020 01:01:01", "MM-DD-YYYY hh:mm:ss")
      data = data.filter(item => moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter( filterdate) )
      let sorteddata = data.sort((a,b) => moment(a.datecreated, 'MM-DD-YYYY hh:mm:ss') - moment(b.datecreated, 'MM-DD-YYYY hh:mm:ss'))

      // Workingobject will contain all servicelines and dates in addition to the total

      let ticks =[]
      let series =[]
      // formatteddate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
    
      let yearlist = [...new Set(data.map(event => new Date(event.datecreated).getFullYear()))];
      let monthlist = moment.monthsShort()
      let datalist = []
  
      yearlist.forEach(year => {
        let yearlist = []
        monthlist.forEach(month => {
          let filterdatestart = `${month} 1, ${year}`
          let filterdateend = `${month} ${moment(month, "MMM").endOf('Month').format("DD")}, ${year}`
          let concludedopps = data.filter(opp => 
            (moment(opp.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(filterdatestart) 
          && moment(opp.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(filterdateend))
          && (opp.winloss === "Won" || opp.winloss === "Lost"))
          let wonopps = concludedopps.filter(opp => opp.winloss === "Won")
          let winrate = parseInt(((wonopps.length/ concludedopps.length) * 100).toFixed(1))
          let newobject = {
            'year': year,
            'month': month,
            'winrate': winrate
          }
          yearlist.push(newobject)
        })
        let newyear = {
          'year': year,
          'data': yearlist
        }
        datalist.push(newyear)
      })
    
      
      datalist = datalist.sort((a,b) => a.year - b.year)

  
      datalist.forEach(dateobject => {
        let founddate = series.find(element => element.name === dateobject.year)  
     
        if(!(founddate === undefined)){
          dateobject.data.forEach(thefounddate => {
            
            founddate.data.push(thefounddate.winrate)
          })
         
        }else{
          let newobject = {
            name: dateobject.year,
            data: []
          }

          dateobject.data.forEach(thefounddate => {
            
            newobject.data.push(thefounddate.winrate)
          })
          series.push(newobject)
        }

      })

      if(series.length > 0){
        series[series.length - 1].color = "#E30613"
       }

      // workingobjects.forEach(slobject => {
      //   let thename = slobject.name;
      //   let tabledata = slobject.data.map(({date, data}) => [date, data]);
      //   // object dates and data are being converted to arrays
      //   let ydata = slobject.data.map(({data}) => [data]);

      //     let newchartobject = {
      //       type: 'spline',
      //       name: thename,
      //       data: slobject.data
      //     };
      //     series.push(newchartobject)
     
      // })
     // series = [
     //    {
     //      name: 'Total',
     //      data: workingobjects[0].data,
     //      color: 'rgba(26,134,208,.2)',
     //      type: 'areaspline',
     //      fillOpacity: 0.2,
     //      lineWidth: 0
     //    },
     //    {
     //      type: 'spline',
     //      name: 'Industrial',
     //      data: workingobjects[1].data,
     //      // color: '#FD5F00',
     //    },
     //    {
     //      type: 'spline',
     //      name: 'Tenant Rep',
     //      data: workingobjects[2].data,
     //      // color: '#005792'
     //    }
     //  ]

     ticks = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  

    return {
      ...this.ticks,
      ...this.chartOptions,
      series
    }
  }




  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      enabled: true,
        chartOptions: {
          title: {
            text: null
          }
        }
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      // labels: {
      //   formatter: function() { 
      //     let themonth = this.value + 1
      //     return moment(themonth, "M").format("MMMM")},
      // }
    },
    // xAxis: {
    //   type: 'datetime',
    //   dateTimeLabelFormats: { 
    // month: '%b'
    // },
    // },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          symbol: 'circle'
        },
        dataLabels:{
          enabled: true,
          format: '{y}%'
        },
        // pointInterval: 28*24*3600*1000,
        // pointInterval: 3600000 * 25, // every day
        // pointStart: Date.UTC(2016, 1, 1),
        // pointInterval: 28*24*3600*1000,
        tooltip: {
          shared: true,
          crosshairs: true,
          // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
          pointFormatter() {
         
            return `<span style="color: ${this.color}">${this.series.name}: ${this.y}%</span>`;
          }
          
          // formatter: function(){
          //   return `<span style="color: ${this.color}">${this.series.name}: ${this.y}%</span>`;
          // }
        }
        
    },
  
  },
  };


  render() {

    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
              <span className="fw-semi-bold">Opportunity Win Rate </span> Year Over Year
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}
