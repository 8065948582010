import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Row, Col } from "reactstrap";
import Header from "./Header";
import Widget from "../../components/Widget/Widget";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TopScores from "./Components/TopScores";
import TotalScorecard from "./Components/TotalScorecard";
import TeamScorecard from "./Components/TeamScorecard";
import TeamScorecardWeek from "./Components/TeamScorecardWeek";
import TotalScorecardWeek from "./Components/TotalScorecardWeek";
import s from "./Layout.module.scss";
var moment = require("moment");

const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #BFBFBF",
    marginBottom: "2%",
  },
  indicator: {
    backgroundColor: "black",
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    // fontWeight: theme.typography.fontWeightRegular,
    // marginRight: theme.spacing(4),
    "&:hover": {
      color: "black",
      opacity: 1,
    },
    "&$selected": {
      color: "black",
      // fontWeight: theme.typography.fontWeightMedium,
    },
    "&:focus": {
      color: "black",
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function sortTeams(a, b) {
  const teamA = a.team_score;
  const teamB = b.team_score;
  let comparison = 0;
  if (teamA < teamB) {
    comparison = 1;
  } else if (teamA > teamB) {
    comparison = -1;
  }
  return comparison;
}

function sortTeamsNew(a, b) {
  const teamA = a.score;
  const teamB = b.score;
  let comparison = 0;
  if (teamA < teamB) {
    comparison = 1;
  } else if (teamA > teamB) {
    comparison = -1;
  }
  return comparison;
}

function sortWeeks(a, b) {
  let weekA = a.sortWeeks;
  let weekB = b.sortWeeks;
  let comparison = 0;
  if (weekA < weekB) {
    comparison = 1;
  } else if (weekA > weekB) {
    comparison = -1;
  }
  return comparison;
}

function sortCustom(a, b) {
  let teamA = a.score;
  let teamB = b.score;
  let comparison = 0;
  if (teamA < teamB) {
    comparison = 1;
  } else if (teamA > teamB) {
    comparison = -1;
  }
  return comparison;
}

class Gamification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      eventDetails: null,
      currentWeek: null,
      winningTeam: "",
      filteredScores: [],
      eventWeeks: [],
      eventTeams: [],
      weeklyScores: [],
      totalScores: [],
      topScores: [],
    };
  }
  handleChange = (event, newValue) => {
    let currentWeekIndex = newValue - 1;
    let currentWeek = this.state.eventWeeks[currentWeekIndex];
    this.setState({
      currentTab: newValue,
      currentWeek: currentWeek,
    });
  };

  getEvent(id) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios.get(`/api/v1/gamificationevent/${id}/`).then(
      (res) => {
        this.setState({
          eventDetails: res.data,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
        });
      }
    );
  }

  getWeeks(id) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios.get(`/api/v1/gamificationweeks/${id}`).then(
      (res) => {
        let data = res?.data?.sort(sortWeeks);
        this.setState({
          eventWeeks: data,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
        });
      }
    );
  }

  getTeams(id) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios.get(`/api/v1/gamificationteams/${id}`).then(
      (res) => {
        let data = res?.data?.sort(sortTeams);
        this.setState({
          eventTeams: data,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
        });
      }
    );
  }

  getWeekScores(id) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios.get(`/api/v1/memberscoreweeks/${id}`).then(
      (res) => {
        this.setState({
          weeklyScores: res.data,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
        });
      }
    );
  }

  getTotalScores(id) {
    axios.defaults.headers = {
      "Content-Type": "application/json",
    };
    axios.get(`/api/v1/memberscoretotals/${id}`).then(
      (res) => {
        this.setState({
          totalScores: res.data,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
        });
      }
    );
  }

  updateFilteredScores(week) {
    this.setState({
      filteredScores: [],
    });
    let filteredScores = this.state.weeklyScores.filter(
      (item) => item.week?.id === week.id
    );

    this.setState({
      filteredScores: filteredScores,
    });
  }

  getCurrentWinningTeam() {
    if (this.state.eventDetails?.type !== "adoption") {
      if (this.state.currentTab === 0) {
        let sortedTeams = this.state.eventTeams.sort(sortTeams);
        let topScores = [];
        sortedTeams.forEach((team) => {
          let teamScores = this.state.totalScores.filter(
            (score) => score.team?.name === team.name
          );
          let totalScore = teamScores.reduce(
            (accum, item) => accum + item.total_member_score,
            0
          );
          let newTotalScore = {
            team: team,
            totalScore: totalScore,
          };
          topScores.push(newTotalScore);
        });

        let scoresSorted = topScores.sort(sortTeamsNew);
        let topScore = scoresSorted[0];
        let winningTeam = sortedTeams.find(
          (item) => item.name === topScore.team.name
        );

        this.setState({
          topScores: topScores,
          winningTeam: winningTeam,
        });
      } else {
        let teams = [];
        let topScores = [];
        let currentWeekIndex = this.state.currentTab - 1;
        let weeksLeading = [];
        let i = 0;
        while (i <= currentWeekIndex) {
          let week = this.state.eventWeeks[i];
          weeksLeading.push(week);
          i += 1;
        }
        let scoresNeeded = this.state.weeklyScores.filter((score) =>
          weeksLeading.some((week) => week.id === score.week.id)
        );
        this.state.eventTeams.forEach((team) => {
          let teamScores = scoresNeeded.filter(
            (score) => score.team?.name === team.name
          );
          let totalScore = teamScores.reduce(
            (accum, item) => accum + item.score,
            0
          );
          let newTotalScore = {
            team: team,
            totalScore: totalScore,
          };
          topScores.push(newTotalScore);
        });

        this.state.eventTeams.forEach((item) => {
          teams.push({ name: item.name });
        });
        teams.forEach((item, index) => {
          let filtered = this.state.filteredScores.filter(
            (score) => score.team?.name === item.name
          );
          teams[index].scores = filtered;
        });

        let teamScores = [];
        teams.forEach((item) => {
          let finalScore = item.scores.reduce(
            (total, obj) => obj.score + total,
            0
          );
          teamScores.push({ name: item.name, score: finalScore });
        });
        teamScores = teamScores.sort(sortCustom);

        let scoresSorted = topScores.sort(sortTeamsNew);
        let topScore = scoresSorted[0];
        let winningTeam = teams.find(
          (item) => item.name === topScore.team.name
        );

        this.setState({
          topScores: topScores,
          winningTeam: winningTeam,
        });
      }
    } else {
      return;
    }
  }

  componentDidMount() {
    if (this.props.match.params.eventid) {
      let id = this.props.match.params.eventid;
      this.getEvent(id);
      this.getTeams(id);
      this.getWeeks(id);
      this.getWeekScores(id);
      this.getTotalScores(id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentTab !== this.state.currentTab) {
      if (this.state.currentTab !== 0) {
        this.setState({
          winningTeam: "",
        });
        this.updateFilteredScores(
          this.state.eventWeeks[this.state.currentTab - 1]
        );
      } else {
        this.getCurrentWinningTeam();
      }
    }
    if (prevState.filteredScores !== this.state.filteredScores) {
      this.getCurrentWinningTeam();
    }
    if (
      this.state.eventTeams !== prevState.eventTeams &&
      this.state.eventTeams.length > 0
    ) {
      this.getCurrentWinningTeam();
    }
    if (
      this.state.totalScores !== prevState.totalScores &&
      this.state.totalScores.length > 0
    ) {
      this.getCurrentWinningTeam();
    }
  }

  render() {
    return (
      <>
        <div className={[s.root, "se-dashboard", `dashboard-light`].join(" ")}>
          <Header />
          <main className={s.content}>
            <Fragment>
              <Row className="mb-4">
                <Col lg={4}>
                  <h1 className="page-title">
                    {this.state.eventDetails?.name} &nbsp;
                    <br></br>
                  </h1>
                </Col>
                <Col lg={8}>
                  {this.state.eventDetails?.type !== "adoption" ? (
                    <TopScores
                      topScores={this.state.topScores}
                      winningTeam={this.state.winningTeam}
                      teams={this.state.eventTeams}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <AntTabs
                    value={this.state.currentTab}
                    className="antTabButton"
                    onChange={this.handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <AntTab label="Total Score" />
                    {this.state.eventWeeks?.map((item, index) => (
                      <AntTab key={index} label={item.label} />
                    ))}
                  </AntTabs>
                  <Typography />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Row>
                    <Col lg={4} style={{ height: "75px" }}>
                      <Widget className="portalWidget scorecardShadow">
                        {this.state.currentTab === 0 ? (
                          <TotalScorecard
                            eventDetails={this.state.eventDetails}
                            scores={this.state.totalScores}
                          />
                        ) : (
                          <TotalScorecardWeek
                            eventDetails={this.state.eventDetails}
                            scores={this.state.filteredScores}
                          />
                        )}
                      </Widget>
                    </Col>
                    <Col lg={8}>
                      <Row>
                        {this.state.eventTeams?.map((item, index) => (
                          <Col key={index} lg={6}>
                            <Widget
                              className=""
                              className={
                                " portalWidget " +
                                  this.state.winningTeam?.name ===
                                item.name
                                  ? "winningShadow"
                                  : "scorecardShadow"
                              }
                            >
                              {this.state.currentTab === 0 ? (
                                <TeamScorecard
                                  team={item}
                                  eventDetails={this.state.eventDetails}
                                  scores={this.state.totalScores}
                                />
                              ) : (
                                <TeamScorecardWeek
                                  team={item}
                                  eventDetails={this.state.eventDetails}
                                  scores={this.state.filteredScores}
                                />
                              )}
                            </Widget>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Fragment>
          </main>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(Gamification);
