import {
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  AUTH_LOGOUT,
  SET_HISTORICAL_TOGGLE,
  GET_NATIONAL_DASHBOARD_DATA,
  UPDATE_USER_PROFILE,
  RECEIVED_PROFILE_DATA_SUCCESS,
} from "../actions/authentication";

import { updateObject } from "../actions/utility";

const defaultState = {
  token: null,
  error: null,
  authLoading: false,
  getHistoricalData: false,
  userdataready: false,
  userroles: [],
  userprofile: {
    dashboards: [],
  },
  userdata: {
    username: null,
    email: "Null",
    first_name: "Null",
    last_name: "Null",
    groups: [],
  },
};

const authStart = (state, action) => {
  return updateObject(state, {
    error: null,
    authLoading: true,
  });
};

const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    authLoading: false,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    authLoading: false,
  });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
  });
};

const authReducer = (state = defaultState, action) => {
  switch (action.type) {
    case AUTH_START:
      return authStart(state, action);
    case AUTH_SUCCESS:
      return authSuccess(state, action);
    case AUTH_FAIL:
      return authFail(state, action);
    case AUTH_LOGOUT:
      return authLogout(state, action);
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        userprofile: action.payload,
      };
    case RECEIVED_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        userdataready: true,
        userdata: action.payload.useraccount,
        userroles: action.payload.role,
        userprofile: action.payload,
      };
    case SET_HISTORICAL_TOGGLE:
      return {
        ...state,
        getHistoricalData: !state.getHistoricalData,
      };
    default:
      return state;
  }
};

export default authReducer;
