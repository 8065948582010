import React from "react";
import { Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";

const columns = [
  {
    dataField: "member",
    text: "Name",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "team",
    text: "Team",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "score",
    text: "Total Score",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
];

const adoptionColumns = [
  {
    dataField: "member",
    text: "Name",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "team",
    text: "Team",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "opportunities",
    text: "Total Opportunities",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
];

const defaultSortedBy = [
  {
    dataField: "score",
    order: "desc",
  },
];
const defaultAdoptionSortedBy = [
  {
    dataField: "opportunities",
    order: "desc",
  },
];

const TotalScorecardWeek = (props) => {
  let scores = props.scores;
  let formattedData = scores.map((score) => {
    return {
      opportunities: score.opportunities,
      member: score.member?.fullname,
      team: score.team?.name,
      score: score.score,
      wins: score.wins,
    };
  });

  return (
    <>
      <Row>
        <Col lg={12}>
          <h2 className="scorecardHeader">Total Scorecard</h2>
        </Col>
      </Row>
      <Row className="scorecardTableRow">
        <Col lg={12} className="pl-0 pr-0">
          <BootstrapTable
            bordered={false}
            defaultSorted={
              props.eventDetails?.type !== "adoption"
                ? defaultSortedBy
                : defaultAdoptionSortedBy
            }
            keyField="id"
            data={formattedData}
            columns={
              props.eventDetails?.type !== "adoption"
                ? columns
                : adoptionColumns
            }
          />
        </Col>
      </Row>
      <Row className="scorecardBottomRow"></Row>
    </>
  );
};

export default TotalScorecardWeek;
