import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Label,
  Form,
  FormGroup,
  Alert,
  Tooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment/moment";
import Widget from "../../components/Widget/Widget";
import s from "./Profile.module.scss";
import Geocode from "react-geocode";
import OpportunityDropdown from "./OpportunityDropdown";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Report from "./components/oppreport";
import axios from "axios";
import Loading from "./components/loading";
import OpportunityCard from "./OpportunityCard2";

Geocode.setApiKey("AIzaSyC9pPjr83c5Ml_WIs8Us6SbmAPqfpPDTy0");
Geocode.setLanguage("en");

class OpportunityCardHolder extends React.Component {
  constructor(props) {
    super(props);
    this.selectOpportunityForCard = this.selectOpportunityForCard.bind(this);
    this.chooseOppforCard = this.chooseOppforCard.bind(this);
    this.selectMarketForOpps = this.selectMarketForOpps.bind(this);
    this.state = {
      opportunityid: "",
      opplist: [],
      oppdata: [],
      marketlist: [],
      keyopportunity: null,
      marketSelected: "",
      dropdownSelected: "",
      fetchingOppData: false,
      oppRetreiveError: null,
      alerts: [
        {
          id: "al-4",
          type: "danger",
          msg: '<span class="fw-semi-bold">Notice:</span> To use this tool, you must be subscribed to at least one local market or to the Master National Dashboard.',
          visible: [true, true, true],
        },
      ],
      pdf: false,
    };
    this.printComponent = React.createRef();
  }

  closeAlert(index, alertGroup) {
    const newAlerts = [...this.state.alerts];
    newAlerts[index].visible[alertGroup] = false;

    this.setState({ alerts: newAlerts });
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
  }

  getLatLong(address) {
    Geocode.fromAddress(address).then(
      (response) => {
        const coords = response.results[0].geometry.location;
        this.setState({
          latitude: coords.lat,
          longitude: coords.lng,
        });
      },
      (error) => {
        console.log("ERROR");
        console.error(error);
      }
    );
  }

  getFormattedDate = (date) => {
    return moment(date).format("MMM DD, YYYY");
  };

  chooseOppforCard() {
    if (!(this.state.dropdownSelected === "")) {
      this.props.history.push(
        `/app/reporting/opportunity/${this.state.dropdownSelected}`
      );
      this.setState({
        opportunityid: this.state.dropdownSelected,
      });
    }
  }

  getMarketList(opps) {
    let marketlist = opps.map(({ market }) => market[0].marketname);
    marketlist = marketlist.reduce((acc, current) => {
      const x = acc.find((item) => item === current);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return marketlist;
  }

  filterOppList(market, opplist) {
    opplist = opplist.filter(
      (item) => item.market && item.market[0].marketname === market
    );
    opplist = opplist.map(({ pursuitname, opportunityid }) => ({
      value: opportunityid,
      label: pursuitname,
    }));
    opplist.sort((a, b) => a.value - b.value);
    opplist.unshift({ value: "", label: "" });
    return opplist;
  }

  getOpportunityData() {
    this.setState({
      fetchingOppData: true,
    });

    let markets = [];
    let defaultmarket = "";
    let marketlist = [];
    let opplist = [];
    let opportunities = [];
    let keyopportunity = null;

    if (!(this.props.opportunities.length > 0)) {
      // if opps not loaded yet

      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`,
      };
      let oppid = this.props.match.params.oppid;
      axios.get(`/api/v1/opportunity/${oppid}/`).then(
        (res) => {
          keyopportunity = res.data;
          this.setState({
            marketSelected: defaultmarket,
            marketlist: marketlist,
            opplist: opplist,
            oppdata: opportunities,
            fetchingOppData: false,
            keyopportunity: keyopportunity,
          });
        },
        (error) => {
          this.setState({
            brokerRetrieveError: error,
            fetchingBrokerData: false,
          });
        }
      );
    } else {
      opportunities = this.props.opportunities;
      marketlist = this.getMarketList(opportunities);
      marketlist.sort();
      marketlist = marketlist.map((item) => ({ value: item, label: item }));
      //Set default market and filter opps accordingly
      if (marketlist.length > 0) {
        defaultmarket = marketlist[0].value;
      }
      opplist = this.filterOppList(defaultmarket, opportunities);

      this.setState({
        marketSelected: defaultmarket,
        marketlist: marketlist,
        opplist: opplist,
        oppdata: opportunities,
        fetchingOppData: false,
        keyopportunity: keyopportunity,
      });
    }
  }

  selectOppforCard() {
    if (!(this.refs["oppSelector"].state.value == null)) {
      let id = this.refs["oppSelector"].state.value.value;
      this.props.history.push("/app/reporting/opportunity/" + id);
      this.findOpp();
    }
  }

  selectOpportunityForCard(event) {
    this.setState({
      dropdownSelected: event.value,
    });
  }

  selectMarketForOpps(e) {
    let newmarket = e.value;
    let opps = this.state.oppdata;

    opps = this.filterOppList(newmarket, opps);

    this.setState({
      marketSelected: e.value,
      opplist: opps,
    });
  }

  redirectToDashboard() {
    this.props.history.push("/app/dashboard");
  }

  findOpp() {
    const params = this.props.match.params;
    if (!(params.opportunityID === undefined)) {
      let opportunityid = params.opportunityID;
      let opportunityobject = this.props.projects.find(
        (item) => opportunityid === item.opportunityid
      );
      if (!(opportunityobject === undefined)) {
        this.setState(
          {
            opportunityobject: opportunityobject,
          },
          this.retreiveOppInfo(opportunityobject)
        );
      }
    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.oppid !== prevProps.match.params.oppid) {
      let opp = "";
      if (this.props.match.params.oppid) {
        opp = this.props.match.params.oppid;
      }
      this.setState({
        opportunityid: opp,
      });
    }
  };

  componentDidMount() {
    this.getOpportunityData();
    if (this.props.match.params.oppid) {
      let oppid = this.props.match.params.oppid;
      this.setState({
        opportunityid: oppid,
      });
    }
  }

  render() {
    const {
      projects,
      surveys,
      cleandata,
      blist,
      oppobjects,
      user,
      marketdata,
    } = this.props;
    const {
      opportunityID,
      opportunityobject,
      sourcingbroker,
      brokerteam,
      oppclientaddress,
      sourcingbrokerarray,
      brokerteamarray,
      opportunityId,
      alerts,
    } = this.state;

    let brokerDropdownOptions = [];

    let markets = [];

    for (var o in user.groups) {
      markets.push(user.groups[o].name);
    }

    blist.forEach((item) => {
      let newitem = {
        value: item,
        label: item,
      };
      brokerDropdownOptions.push(newitem);
    });

    return (
      <div>
        <div className={s.root}>
          {this.state.pdf && (
            <div>
              <PDFDownloadLink
                document={<Report object={opportunityobject} />}
                fileName="somename.pdf"
              >
                {({ blob, url, loading, error }) =>
                  loading ? "Loading document..." : "Download now!"
                }
              </PDFDownloadLink>
            </div>
          )}
          <Row>
            <Col xs={12} md={8} lg={8} xl={8}>
              <h1 className="page-title">
                Opportunity <span className="fw-semi-bold">Card</span>
              </h1>
            </Col>
            <Col xs={12} md={4} lg={4} xl={4}>
              <div className="pb-md h-100 mb-0 float-right exportAndBack">
                <ul>
                  <li>
                    <a
                      href={
                        "https://aero.jll.com/prospecting/opportunity/" +
                        this.state.opportunityid
                      }
                      target="blank"
                    >
                      <Button
                        className="mb-xs mr-xs btn-block btn-primary float-right"
                        color="primary"
                      >
                        Edit
                      </Button>
                    </a>
                  </li>
                  <li>
                    <Button
                      className="mb-xs mr-xs btn-block btn-primary "
                      onClick={this.redirectToDashboard.bind(this)}
                      color="primary"
                    >
                      Back to Dashboard
                    </Button>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div>
            {markets.length == 1 && markets.includes("National") ? (
              <div>
                {alerts.map((alert, index) => (
                  <Alert
                    className="alert-transparent"
                    key={alert.id}
                    isOpen={alert.visible[1]}
                    toggle={() => this.closeAlert(index, 1)}
                    color={alert.type}
                  >
                    <span dangerouslySetInnerHTML={{ __html: alert.msg }} />
                  </Alert>
                ))}
              </div>
            ) : null}

            {this.state.marketlist.length > 0 &&
            this.state.opportunityid === "" ? (
              <>
                <Row>
                  <Label md="3" className="pb-md h-100" for="default-select">
                    Select a market
                  </Label>
                  <Col md="4" className="pb-md h-100">
                    <Select
                      defaultValue={{
                        label: this.state.marketSelected,
                        value: this.state.marketSelected,
                      }}
                      ref="oppmarketSelector"
                      className="selectCustomization"
                      options={this.state.marketlist}
                      onChange={this.selectMarketForOpps}
                    />
                  </Col>
                </Row>

                {this.state.opplist.length > 0 ? (
                  <OpportunityDropdown
                    selectOpportunityForCard={this.selectOpportunityForCard}
                    chooseOppforCard={this.chooseOppforCard}
                    opportunityoptions={this.state.opplist}
                  />
                ) : null}
              </>
            ) : null}

            {this.state.opportunityid !== "" &&
            this.state.oppdata.length > 0 ? (
              <OpportunityCard
                opportunities={this.state.oppdata}
                oppid={this.state.opportunityid}
                token={this.props.token}
                keyopportunity={this.state.keyopportunity}
              />
            ) : this.state.opportunityid !== "" && this.state.keyopportunity ? (
              <OpportunityCard
                opportunities={this.state.oppdata}
                oppid={this.state.opportunityid}
                token={this.props.token}
                keyopportunity={this.state.keyopportunity}
              />
            ) : null}

            {this.state.fetchingOppData ? <Loading /> : null}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    opportunities: state.projects.opportunities,
    user: state.authentication.userdata,
    marketdata: state.projects.marketdata,
    cleandata: state.projects.cleandata,
    oppobjects: state.projects.oppobjects,
    surveys: state.projects.surveys,
    projects: state.projects.projects,
    blist: state.projects.blist,
  };
}

export default connect(mapStateToProps)(OpportunityCardHolder);
