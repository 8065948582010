import {
  UPDATE_INBOUND_FILTER,
  UPDATE_CLOSE_END_DATE,
  UPDATE_CLOSE_START_DATE,
  UPDATE_FILTERS,
  UPDATE_OTHER_SERVICES_FILTER,
  UPDATE_SOURCING_METHOD_FILTER,
  UPDATE_INDUSTRY_FILTER,
  UPDATE_PROPERTYTYPE_FILTER,
  UPDATE_MEETINGTYPE_FILTER,
  UPDATE_TEAMINCLUDE_EXACT_FILTER,
  UPDATE_TOOLINCLUDE_EXACT_FILTER,
  UPDATE_START_DATE,
  UPDATE_BROKER_TEAM_FILTER,
  UPDATE_TECH_FILTER,
  UPDATE_END_DATE,
  UPDATE_ADD_BROKER_FILTER,
  UPDATE_SOURCING_BROKER_FILTER,
  UPDATE_SERVICELINE_FILTER,
  UPDATE_TYPE_FILTER,
  UPDATE_OPP_NAME_FILTER,
  UPDATE_GREATERORLESS_FILTER,
  UPDATE_SIZE_FILTER,
  UPDATE_MEETING_END_DATE,
  UPDATE_MEETING_START_DATE,
  UPDATE_OPPSTATS_FILTER,
  UPDATE_ACTIVE_FILTERS,
  UPDATE_SHORTCUT_BOOLEAN,
  UPDATE_EST_START_DATE_START,
  UPDATE_EST_START_DATE_END,
} from "../actions/dashboardfilters";

const defaultState = {
  seLeadFilter: "",
  marketsFilter: "",
  digitalAFilter: "",
  marketcodeFilter: [],
  startDate: "",
  endDate: "",
  startCloseDate: "",
  endCloseDate: "",
  startMeetingDate: "",
  endMeetingDate: "",
  sourcingBrokerFilter: "",
  typeFilter: "",
  addBrokerFilter: "",
  serviceLineFilter: "",
  brokerTeamFilter: "",
  practiceGroupFilter: "",
  techUsedFilter: "",
  jteFilter: false,
  sourcingMethodFilter: "",
  otherServicesFilter: "",
  industryFilter: "",
  oppNameFilter: "",
  teamIncludeorExact: "includes",
  toolsIncludeorExact: "includes",
  marketIncludeExclude: "includes",
  sizeFilter: "",
  greaterorlessFilter: "greater",
  statusFilter: "",
  activefilters: [],
  inboundfilter: "",
  propertytypefilter: "",
  meetingtypefilter: "",
  shortcutBoolean: false,

  estStartDateStart: "",
  estStartDateEnd: "",
  dealTypeFilter: "",
  dealSubTypeFilter: "",
  salesStageFilter: "",
  estRevGreaterLess: "greater",
  estRevFilter: "",
};

export default function dashboardfilters(state = defaultState, action) {
  switch (action.type) {
    case UPDATE_FILTERS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_INDUSTRY_FILTER:
      return {
        ...state,
        industryFilter: action.payload,
      };
    case UPDATE_OTHER_SERVICES_FILTER:
      return {
        ...state,
        otherServicesFilter: action.payload,
      };
    case UPDATE_SOURCING_METHOD_FILTER:
      return {
        ...state,
        sourcingMethodFilter: action.payload,
      };
    case UPDATE_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case UPDATE_END_DATE:
      return {
        ...state,
        endDate: action.payload,
      };
    case UPDATE_START_DATE:
      return {
        ...state,
        startDate: action.payload,
      };
    case UPDATE_MEETING_START_DATE:
      return {
        ...state,
        startMeetingDate: action.payload,
      };
    case UPDATE_MEETING_END_DATE:
      return {
        ...state,
        endMeetingDate: action.payload,
      };
    case UPDATE_CLOSE_START_DATE:
      return {
        ...state,
        startCloseDate: action.payload,
      };
    case UPDATE_CLOSE_END_DATE:
      return {
        ...state,
        endCloseDate: action.payload,
      };

    case UPDATE_BROKER_TEAM_FILTER:
      return {
        ...state,
        brokerTeamFilter: action.payload,
      };
    case UPDATE_TECH_FILTER:
      return {
        ...state,
        techUsedFilter: action.payload,
      };

    case UPDATE_TOOLINCLUDE_EXACT_FILTER:
      return {
        ...state,
        toolsIncludeorExact: action.payload,
      };

    case UPDATE_TEAMINCLUDE_EXACT_FILTER:
      return {
        ...state,
        teamIncludeorExact: action.payload,
      };

    case UPDATE_ADD_BROKER_FILTER:
      return {
        ...state,
        addBrokerFilter: action.payload,
      };
    case UPDATE_SOURCING_BROKER_FILTER:
      return {
        ...state,
        sourcingBrokerFilter: action.payload,
      };
    case UPDATE_SERVICELINE_FILTER:
      return {
        ...state,
        serviceLineFilter: action.payload,
      };
    case UPDATE_OPP_NAME_FILTER:
      return {
        ...state,
        oppNameFilter: action.payload,
      };
    case UPDATE_SIZE_FILTER:
      return {
        ...state,
        sizeFilter: action.payload,
      };
    case UPDATE_INBOUND_FILTER:
      return {
        ...state,
        inboundfilter: action.payload,
      };

    case UPDATE_PROPERTYTYPE_FILTER:
      return {
        ...state,
        propertytypefilter: action.payload,
      };

    case UPDATE_MEETINGTYPE_FILTER:
      return {
        ...state,
        meetingtypefilter: action.payload,
      };
    case UPDATE_GREATERORLESS_FILTER:
      return {
        ...state,
        greaterorlessFilter: action.payload,
      };

    case UPDATE_OPPSTATS_FILTER:
      return {
        ...state,
        statusFilter: action.payload,
      };
    case UPDATE_TYPE_FILTER:
      return {
        ...state,
        typeFilter: action.payload,
      };
    case UPDATE_ACTIVE_FILTERS:
      return {
        ...state,
        activefilters: action.payload,
      };
    case UPDATE_SHORTCUT_BOOLEAN:
      return {
        ...state,
        shortcutBoolean: action.payload,
      };
    case UPDATE_EST_START_DATE_START:
      return {
        ...state,
        estStartDateStart: action.payload,
      };
    case UPDATE_EST_START_DATE_END:
      return {
        ...state,
        estStartDateEnd: action.payload,
      };
    default:
      return state;
  }
}
