import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
import { stringify } from 'uuid';
var moment = require('moment');

export default class RevenueChart extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };



  chartData = () => {
      let { data } = this.props;
      let filterdate = moment("01-01-2020 01:01:01", "MM-DD-YYYY hh:mm:ss")
      data = data.filter(item => moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter( filterdate) )
  
      let sorteddata = data.sort((a,b) => moment(a.datecreated, 'MM-DD-YYYY hh:mm:ss') - moment(b.datecreated, 'MM-DD-YYYY hh:mm:ss'))

      // Workingobject will contain all servicelines and dates in addition to the total
      let workingobjects = [
       
      ]
      let currentsl = ""
      let ticks =[]
      let series =[]
      // formatteddate = moment(date).format('MMMM Do YYYY, h:mm:ss a')


  
    sorteddata.forEach(item => {
      let date =  moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss');
      let shortdate = moment(date).format('MMM YYYY')
      let year = date.year()
      let month = date.format('MMMM')
      date = moment.utc(shortdate, 'MMM YYYY').valueOf();

 
      let currentyear = year

      let founddate = workingobjects.find(element => element.name === year)
      // Either add to the existing object or create a new serviceline object within workingobjects
  
      
    

      if(!(founddate === undefined)){
       
        let foundmonth = founddate.data.find(themonth => themonth.month === month)
        
        if(foundmonth.y === null){
          foundmonth.y = 1
        }else{
          foundmonth.y += 1
        }

      } else {
        let newitem =
          {
            name: currentyear,
            data: [
            { 
              month: "January",
              y: 0 },
            { 
              month: "February",
              y: 0 },
            { 
              month: "March",
              y: 0 },
            {
              month: "April",
              y: 0 },
            { 
              month: "May",
              y: 0 },
            { 
              month: "June",
              y: 0 },
            { 
              month: "July",
              y: 0 },
            { 
              month: "August",
              y: 0 },
            { 
              month: "September",
              y: 0 },
            { 
              month: "October",
              y: 0 },
            { 
              month: "November",
              y: 0 },
            { 
              month: "December",
              y: 0 },
            ]  
          }
        
        let foundmonth = newitem.data.find(themonth => themonth.month === month)
        if(foundmonth.y === null){
          foundmonth.y = 1
        }else{
          foundmonth.y += 1
        }
        workingobjects.push(newitem)
      }
      })
      
      workingobjects = workingobjects.sort((a,b) => a.name - b.name)

  
      workingobjects.forEach(dateobject => {
        let founddate = series.find(element => element.name === dateobject.name)  
     
        if(!(founddate === undefined)){
          dateobject.data.forEach(thefounddate => {
            
            founddate.data.push(thefounddate.y)
          })
         
        }else{
          let newobject = {
            name: dateobject.name,
            data: []
          }

          dateobject.data.forEach(thefounddate => {
            
            newobject.data.push(thefounddate.y)
          })
          series.push(newobject)
        }

      })

     if(series.length > 0){
      series[series.length - 1].color = "#E30613"
     }

     ticks = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
  

    return {
      ...this.ticks,
      ...this.chartOptions,
      series
    }
  }




  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      enabled: true,
        chartOptions: {
          title: {
            text: null
          }
        }
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      // labels: {
      //   formatter: function() { 
      //     let themonth = this.value + 1
      //     return moment(themonth, "M").format("MMMM")},
      // }
    },
    // xAxis: {
    //   type: 'datetime',
    //   dateTimeLabelFormats: { 
    // month: '%b'
    // },
    // },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle',
        },
        dataLabels:{
          enabled: true,
    
        },
        // pointInterval: 28*24*3600*1000,
        // pointInterval: 3600000 * 25, // every day
        // pointStart: Date.UTC(2016, 1, 1),
        // pointInterval: 28*24*3600*1000,
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      // pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
      pointFormatter() {
      
        return `<span style="color: ${this.color}">${this.series.name} at ${this.y}</span>`;
      }
      
      // formatter: function(){
      //   return `<span style="color: ${this.color}">${this.series.name} at ${this.y}</span>`;
      // }
    }
  },
  };


  render() {

    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
               <span className="fw-semi-bold">Opportunities Entered </span>Year Over Year
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}
