import React, { Fragment } from "react";
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { connect } from "react-redux";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import s from "./Calendar.module.scss";
import Widget from "../../../components/Widget/Widget";
import presetcolors from "./colors";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
// import timeGridPlugin from "@fullcalendar/timegrid";

class CalendarV2 extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    this.state = {
      event: {},
      modal: false,
      modalEvent: false,
      thisweeksmeetings: [],
      calendarView: "dayGridMonth",
      currentMonth: moment().format("MMM YYYY"),
      currentDay: moment().format("dddd"),
      marketobjects: [],
      alerts: [
        {
          id: "al-4",
          type: "danger",
          msg: '<span class="fw-semi-bold">Notice:</span> To use this tool, you must be subscribed to at least one local market or to the Master National Dashboard.',
          visible: [true, true, true],
        },
      ],
      calendarOptions: {
        header: {
          left: "",
          center: "",
          right: "",
        },
        events: [],
        selectable: true,
        selectHelper: true,
        editable: false,
        droppable: false,
      },
      calendarPlugins: [dayGridPlugin],
    };
  }
  calendarRef = React.createRef();

  getDifference(a, b) {
    var i = 0;
    var j = 0;
    var result = "";

    while (j < b.length) {
      if (a[i] != b[j] || i == a.length) result += b[j];
      else i++;
      j++;
    }
    return result;
  }

  cleanPersonList(person) {
    let list = [];
    person.forEach((item) => list.push(item.fullname));
    let theperson = list.join(", ");
    return theperson;
  }

  createMarketColor(marketname) {
    let marketobjects = this.state.marketobjects;
    let currentobject = marketobjects.length;
    let newitem = {
      marketname: marketname,
      color: presetcolors[currentobject],
    };
    marketobjects.push(newitem);

    this.setState({
      marketobjects: marketobjects,
    });
    return newitem;
  }

  closeAlert(index, alertGroup) {
    const newAlerts = [...this.state.alerts];
    newAlerts[index].visible[alertGroup] = false;

    this.setState({ alerts: newAlerts });
  }
  getWeeklyMeetings() {
    let usermarkets = [];
    let marketobjects = this.state.marketobjects;

    if (!(this.state.thisweeksmeetings.length > 0)) {
      return <div>No events scheduled this week...</div>;
    } else {
      let projectobjects = [];

      this.state.thisweeksmeetings.forEach((item) => {
        let marketname = "Undefined";
        let color = "#920000";

        if (item.market.length > 0) {
          let market = marketobjects.find(
            (m) => m.marketname == item.market[0].marketname
          );

          if (market) {
            color = market.color;
            marketname = market.marketname;
          } else {
            market = this.createMarketColor(item.market[0].marketname);
            marketname = item.market[0].marketname;
            color = market.color;
          }
        }

        let brokers =
          this.cleanPersonList(item.sourcingbroker) +
          " " +
          this.cleanPersonList(item.broker);

        let newitem = {
          opportunityid: item.opportunityid,
          pursuitname: item.pursuitname,
          brokers: brokers,
          market: marketname,
          meetingdate: item.meetingdate,
          color: color,
        };
        projectobjects.push(newitem);
      });

      return (
        <div>
          {projectobjects.map((item) => (
            <div className="external-event">
              <div className={s.customExternalEvent}>
                <i
                  className={"fa fa-circle fa-fw ml-xs mr-xs "}
                  style={{ color: item.color }}
                />
                <Link to={"/app/reporting/opportunity/" + item.opportunityid}>
                  <span className="fw-semi-bold">{item.pursuitname}</span>{" "}
                </Link>
                <br></br>
                <span>{item.brokers}</span>
                <br></br>
                <small className="text-muted">{item.market}</small>
                <br></br>
                <small>{this.getFormattedDate(item.meetingdate)}</small>
              </div>
            </div>
          ))}
        </div>
      );
    }
  }

  getBrokerMeetings() {
    let projects = this.props.opportunities;
    let marketobjects = this.state.marketobjects;
    let meetings = [];
    let markets = this.state.marketobjects;

    projects.forEach((item) => {
      let meetingdate = moment(item.meetingdate, "MM-DD-YYYY hh:mm:ss");
      // meetingdate = meetingdate.format("MM-DD-YYYY")
      meetingdate = meetingdate.format("YYYY-MM-DD");
      // meetingdate = new Date(meetingdate)
      let oppid = item.opportunityid;
      // danger
      let marketname = "Undefined";
      let backgroundColor = "#920000";
      let market;

      if (item.market.length > 0) {
        let market = marketobjects.find(
          (m) => m.marketname == item.market[0].marketname
        );
        if (market) {
          backgroundColor = market.color;
          marketname = market.marketname;
        } else {
          market = this.createMarketColor(item.market[0].marketname);
          marketname = item.market[0].marketname;
          backgroundColor = market.color;
        }
      }

      let brokers =
        this.cleanPersonList(item.sourcingbroker) +
        " " +
        this.cleanPersonList(item.broker);

      let modalDesc = [];
      modalDesc.push(
        <div>
          <span className="fw-semi-bold">Opportunity ID: </span>
          <Link to={"/app/reporting/opportunity/" + oppid}>{oppid}</Link>
        </div>
      );
      modalDesc.push(
        <div>
          <span className="fw-semi-bold">Market: </span>
          {marketname}
        </div>
      );
      modalDesc.push(
        <div>
          <span className="fw-semi-bold">Brokers: </span>
          {brokers}
        </div>
      );

      let newevent = {
        id: item.id,
        title: item.pursuitname,
        start: meetingdate,
        backgroundColor: backgroundColor,
        allDay: true,
        textColor: "#fff",
        description: modalDesc,
      };
      meetings.push(newevent);
    });

    let thisweeksmeetings = projects.filter((object) =>
      moment(object.meetingdate, "MM-DD-YYYY hh:mm:ss").isSame(
        new Date(),
        "week"
      )
    );

    this.setState({
      thisweeksmeetings: thisweeksmeetings,
      calendarOptions: {
        // header: {
        //   left: "",
        //   center: "title",
        //   right: "",
        // },
        events: meetings,
        selectable: true,
        selectHelper: true,
        editable: true,
        droppable: false,
      },
    });
  }

  componentDidMount() {
    // new Draggable(this.externalEvents, {
    //   itemSelector: '.external-event'
    // });
    this.getBrokerMeetings();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.opportunities !== prevProps.opportunities) {
      this.getBrokerMeetings();
    }
  };

  drop = (info) => {
    info.draggedEl.parentNode.removeChild(info.draggedEl);
  };

  handleChange = (e) => {
    this.setState({ event: { ...this.state.event, title: e.target.value } });
  };
  createEvent = () => {
    this.fullCalendar.getApi().addEvent(this.state.event);
    this.fullCalendar.getApi().unselect();
    this.toggleModal();
  };
  select = ({ start, end, allDay }) => {
    this.setState({
      event: {
        start,
        end,
        allDay,
        backgroundColor: "#64bd63",
        textColor: "#fff",
        editable: true,
      },
    });
    this.toggleModal();
  };
  eventClick = (e) => {
    this.setState({ event: e.event });
    this.toggleModalEvent();
  };

  prev = () => {
    this.fullCalendar.getApi().prev();
  };
  next = () => {
    this.fullCalendar.getApi().next();
  };
  today = () => {
    this.fullCalendar.getApi().today();
  };
  changeView = (view) => {
    this.fullCalendar.getApi().changeView(view);
    this.setState({ calendarView: view });
  };
  getFormattedDate = (date) => {
    return moment(date, "MM-DD-YYYY hh:mm:ss").format("MMM DD, YYYY");
  };
  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  toggleModalEvent = () => {
    this.setState({ modalEvent: !this.state.modalEvent });
  };

  //
  // + meeting.servicelines[0].servicelinename === "Tenant Rep" ? " text-primary" : meeting.servicelines[0].servicelinename === "Industrial" ? "text-info" : meeting.servicelines[0].servicelinename === "Agency Leasing" ? "text-success" : "text-danger"

  render() {
    const {
      event,
      currentMonth,
      currentDay,
      calendarOptions,
      modal,
      modalEvent,
      alerts,
      marketobjects,
    } = this.state;
    const { cleandata, projects, userdataready, user, marketdata } = this.props;

    let markets = [];

    for (var o in user.groups) {
      markets.push(user.groups[o].name);
    }

    return (
      <>
        <Row>
          <Col lg={4} xs={12} md={6}>
            <h1 className="page-title">
              {currentMonth} -{" "}
              <span className="fw-semi-bold">{currentDay}</span>
            </h1>
            <h3>
              <span className="fw-semi-bold">
                {this.props.market ? this.props.market + " " : null}
                Meetings
              </span>{" "}
              this week
            </h3>
            <p>Click on an event to view more details.</p>

            <div
              ref={(node) => {
                this.externalEvents = node;
              }}
              className="calendar-external-events mb-lg"
            >
              {this.getWeeklyMeetings()}
            </div>
          </Col>
          <Col md={6} lg={8} xs={12}>
            <Widget>
              <Row className="calendar-controls">
                <Col md={3}>
                  <ButtonGroup className="mr-sm">
                    <Button color="default" onClick={this.prev}>
                      <i className="fa fa-angle-left" />
                    </Button>
                    <Button color="default" onClick={this.next}>
                      <i className="fa fa-angle-right" />
                    </Button>
                  </ButtonGroup>
                  <Button color="default" onClick={this.today}>
                    Today
                  </Button>
                </Col>
                <Col md={9} className="calendar-controls text-right">
                  <ButtonGroup>
                    <Button
                      color="default"
                      onClick={() => this.changeView("dayGridMonth")}
                      active={this.state.calendarView === "dayGridMonth"}
                    >
                      Month
                    </Button>
                    <Button
                      color="default"
                      onClick={() => this.changeView("timeGridWeek")}
                      active={this.state.calendarView === "timeGridWeek"}
                    >
                      Week
                    </Button>
                    <Button
                      color="default"
                      onClick={() => this.changeView("timeGridDay")}
                      active={this.state.calendarView === "timeGridDay"}
                    >
                      Day
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
              {userdataready && (
                <FullCalendar
                  ref={this.calendarRef}
                  defaultView="dayGridMonth"
                  plugins={this.state.calendarPlugins}
                  select={this.select}
                  eventClick={this.eventClick}
                  drop={this.drop}
                  {...calendarOptions}
                />
              )}
            </Widget>

            <Widget>
              <div>
                <ul id="calendarLegend">
                  {marketobjects.map((item) => {
                    return (
                      <li>
                        <i
                          className={"fa fa-circle fa-fw ml-xs mr-xs "}
                          style={{ color: item.color }}
                        />
                        {item.marketname}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Widget>
          </Col>
        </Row>

        {/* <Modal isOpen={modal} toggle={this.toggleModal} id="news-close-modal">
          <ModalHeader toggle={this.toggleModal} id="news-close-modal-label">
            Create New Event
          </ModalHeader>
          <ModalBody className="bg-white">
            Just enter event name to create a new one
            <Input
              onChange={this.handleChange}
              className={s.calendarModalInput}
              value={event.title}
              type="text"
              name="title"
              placeholder="Title"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={this.toggleModal}
              data-dismiss="modal"
            >
              Close
            </Button>{" "}
            <Button
              color="success"
              onClick={this.createEvent}
              id="news-widget-remove"
            >
              Create
            </Button>
          </ModalFooter>
        </Modal> */}

        <Modal
          isOpen={modalEvent}
          toggle={this.toggleModalEvent}
          id="news-close-modal"
        >
          <ModalHeader
            toggle={this.toggleModalEvent}
            id="news-close-modal-label"
          >
            {event.title}
          </ModalHeader>
          <ModalBody className="bg-white">
            <p className="text-muted">
              <i className="la la-calendar"></i>
              {this.getFormattedDate(event.start)}
            </p>
            <p>{event.extendedProps && event.extendedProps.description}</p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              onClick={this.toggleModalEvent}
              data-dismiss="modal"
            >
              OK
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.authentication.userdata,
    userMarkets: state.authentication?.userdata?.groups,
    userdataready: state.authentication.userdataready,
  };
}

export default connect(mapStateToProps)(CalendarV2);
