import { CSVLink } from "react-csv";
import React from "react";
import moment from "moment";
import { Button } from "reactstrap";

const ExportAllButton = (props) => {
  const [cleanData, setCleanData] = React.useState([]);

  function cleanAllData() {
    console.log("Running");
    let clean = props.data?.map((item) => {
      const cleanedItem = {};
      Object.entries(item).forEach(([key, value]) => {
        if (key === "clientcompany") {
          cleanedItem[key] = item[key][0]?.clientconame;
          cleanedItem["industry"] = item[key][0]?.clientindustry;
          cleanedItem["clientcontactname"] = item[key][0]?.clientcontactname;
        } else if (key === "bdmember") {
          item[key].map(function (tm, i) {
            if (tm?.role?.length > 0) {
              if (tm?.role[0]?.rolename == "Digital Advisor") {
                cleanedItem["da_team"] = tm.fullname;
              } else if (tm?.role[0]?.rolename == "Sales Enablement Member") {
                cleanedItem["se_team"] = tm.fullname;
              }
            }
          });
        } else {
          if (Array.isArray(value)) {
            cleanedItem[key] = value
              .map((obj) => {
                if (obj?.fullname) {
                  return obj.fullname;
                } else if (obj?.marketname) {
                  return obj.marketname;
                } else if (obj?.techname) {
                  return obj.techname;
                } else if (obj?.name) {
                  return obj.name;
                } else if (obj?.servicelinename) {
                  return obj.servicelinename;
                } else if (obj?.methodname) {
                  return obj.methodname;
                } else if (obj?.clientconame) {
                  return obj.clientconame;
                } else if (obj?.companyname) {
                  return obj.companyname;
                } else if (obj?.servicename) {
                  return obj.servicename;
                } else if (obj?.name) {
                  return obj.name;
                }
                return Object.values(obj).filter(
                  (v) => typeof v === "string" || typeof v === "number"
                );
              })
              .join(", ");
          } else if (typeof value === "object") {
            cleanedItem[key] = value
              ? value?.fullname ||
                value?.clientconame ||
                value?.clientcontactname
              : "";
          } else if (typeof value === "number") {
            cleanedItem[key] = value;
          } else if (typeof value === "string") {
            let str = JSON.stringify(value);
            str = str.replace(/[,\r\n"]/g, "");
            cleanedItem[key] = str;
          } else if (typeof value === "boolean") {
            cleanedItem[key] = value.toString();
          }
        }
      });
      return cleanedItem;
    });
    console.log(clean);
    setCleanData(clean);
  }

  const headers = [
    { label: "ID", key: "opportunityid" },
    { label: "Status", key: "winloss" },
    { label: "Opportunity Name", key: "pursuitname" },
    { label: "Opportunity Type", key: "type" },
    { label: "Meeting Type", key: "meetingtype" },
    { label: "Property Type", key: "propertytype" },
    { label: "Client Company", key: "clientcompany" },
    { label: "Client Contact Name", key: "clientcontactname" },
    { label: "Client Industry", key: "industry" },
    { label: "Sourcing Method(s)", key: "sourcingmethodology" },
    { label: "Date Created", key: "datecreated" },
    { label: "Date Closed", key: "dateclosed" },
    { label: "Meeting Date", key: "meetingdate" },
    { label: "Market", key: "market" },
    { label: "Service Line", key: "servicelines" },
    { label: "Size", key: "pursuitsize" },
    { label: "Sourcing Broker", key: "sourcingbroker" },
    { label: "Broker team", key: "broker" },
    { label: "SE Lead(s)", key: "se_team" },
    { label: "Digital Advisor", key: "da_team" },
    { label: "Notes", key: "oppnotes" },
    { label: "Competitor(s)", key: "competitor" },
    { label: "Incumbent(s)", key: "incumbent" },
    { label: "Tech used", key: "techused" },
    { label: "Other services", key: "otherJllServices" },
    { label: "Deal Type", key: "deal_type" },
    { label: "Deal Sub Type", key: "deal_sub_type" },
    { label: "Sales Stage", key: "sales_stage" },
    { label: "Est. Start Date", key: "est_start_date" },
    { label: "Est. Revenue", key: "est_revenue" },
    { label: "Broker Est. Revenue", key: "broker_est_revenue" },
    { label: "Practice Group(s)", key: "practicegroups" },
    { label: "Propert Address", key: "propertyaddress" },
    { label: "Lease Expiration", key: "leaseExpirationDate" },
    { label: "Inbound", key: "inboundbusiness" },
    { label: "Multi-market", key: "multi_market" },
    { label: "Confidential", key: "confidential" },
  ];

  return (
    <CSVLink
      headers={headers}
      filename={`opp_all_columns-${moment().format("YYYY-MM-DD-HH-mm-ss")}.csv`}
      data={cleanData}
      asyncOnClick={true}
      onClick={cleanAllData}
    >
      <Button className="ml-2 react-bs-table-csv-btn btn btn-default ">
        Export All Columns
      </Button>
    </CSVLink>
  );
};

export default ExportAllButton;
