import React, { Component } from 'react';
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import { withRouter,  } from "react-router";
import { Col, Row, Modal, ModalHeader, ModalBody,  ModalFooter, FormGroup, Input, Label} from 'reactstrap';
import axios from 'axios';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import s from './Dynamic.modules.scss';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Tooltip from '@material-ui/core/Tooltip';

var moment = require('moment');

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const states = {
  won: 'success',
  lost: 'danger',
  open: 'info',
  pending: 'warning'
};

class Dynamic extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      previewEmail: false,
      emailPreviewCode: null,
      recipients: [],
      completed: []
    };
    this.checkBox = this.checkBox.bind(this)
    this.checkboxChange = this.checkboxChange.bind(this)
    this.rowStyle = this.rowStyle.bind(this);
    this.sortCheckbox = this.sortCheckbox.bind(this);
  }

  rowStyle(row, rowIndex){
    let foundComplete = this.state.completed.find(item => item.id === row.id)
    let completed = false
    if(foundComplete){
      completed = true
    }
    if(completed){
      return {backgroundColor: "#daf3cdd4", 
      transition: 'background-color 250ms linear'}
    }else{
      return {backgroundColor: "inherit", 
      transition: 'background-color 150ms linear'}
    }
  }
  sortCheckbox(a, b, order, dataField, rowA, rowB){
    let aFound = this.state.completed.find(item => item.id === a)
    let bFound = this.state.completed.find(item => item.id === b)

    let aComplete = false
    let bComplete = false
    if(aFound){
      aComplete = true
    }
    if(bFound){
      bComplete = true
    }
    
    if (order === 'asc') {
      return bComplete - aComplete;
    }
    return aComplete - bComplete; // desc
  }

  sendTestFormatter(cell, row, index) {
   
    return (
      <div style={{textAlign: 'center'}} >
        <SendButton
          cell={cell} row={row} index={index} token={this.formatExtraData.token} currentLogID={this.formatExtraData.currentLogID}
        >
        </SendButton>
      </div>
    );
  }
  checkboxChange(value, id){
    
    this.props.sendCompleteConfirm(value, id)
  }


   checkBox(id){
    let foundComplete = this.state.completed.find(item => item.id === id)
    let completed = false
    if(foundComplete){
      completed = true
    }
    return(
      <CheckBoxRefreshed checkboxChange={this.checkboxChange} id={id} completed ={completed} />)
  }


  renderSizePerPageDropDown = (props) => {
    const limits = [];
    props.sizePerPageList.forEach((limit) => {
      limits.push(<DropdownItem key={limit} onClick={() => props.changeSizePerPage(limit)}>{ limit }</DropdownItem>);
    });

    return (
      <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
        <DropdownToggle color="default" caret>
          { props.currSizePerPage }
        </DropdownToggle>
        <DropdownMenu>
          { limits }
        </DropdownMenu>
      </Dropdown>
    );
  };

  componentDidMount(){
    if(this.props.recipients && this.props.completed){
      this.setState({
        recipients: this.props.recipients,
        completed: this.props.completed
      })
    }
  }

  componentDidUpdate(prevProps){
    if(prevProps.completed !== this.props.completed || prevProps.recipients !== this.props.recipients){
      this.setState({
        recipients: this.props.recipients,
        completed: this.props.completed
      })
    }
  }

  render() {
    const { recipients, completed } = this.state;
    const defaultSorted = [{
      dataField: 'fullname', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }];
    const pagination = paginationFactory({
      sizePerPage: 50
    });    
    const options = {
      // sizePerPage: 50,
      scrollX: true,
      // paginationSize: 3,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      ExportCSVButton
    };

  

    function personFormat(name){
      return name
    }

    let columns = [ {
      dataField: 'id',
      text: 'Follow-up Complete',
      sort: true,
      formatter: this.checkBox,
      classes: ' align-middle',
      headerStyle: (colum, colIndex) => {
        return { width: '190px' };
      },
      formatExtraData: completed,
      sortFunc: this.sortCheckbox,
    }, 
   {
      dataField: 'fullname',
      text: 'Recipients',
      // formatter: sourcingmethodformat,
      sort: true,
      classes: 'align-middle',
    },  
    {
      dataField: 'id',
      text: 'Send Reminder',
      formatter: this.sendTestFormatter,
      classes: 'align-middle',
      headerStyle: (colum, colIndex) => {
        return { width: '190px', textAlign: 'center'};
      },
      formatExtraData: {token: this.props.token, currentLogID: this.props.currentLogID}
    }, 
  ];

    let filename =  "Refresh Portal logs" + "-" + moment().format("MM-DD-YYYY-HH:MM") + ".csv"
    return (
      <>
            
      <ToolkitProvider
              keyField="id"
              data={ recipients }
              columns={ columns }
              exportCSV={ {
                fileName: filename,
                onlyExportFiltered: true
              } }
              >
        {
          props => (
            <div>
              <Row className="mb-2">
              <Col xs={6} md={6} lg={6} xl={8} > 
              <ExportCSVButton { ...props.csvProps }>Export CSV</ExportCSVButton>
              </Col>
              <Col xs={6} md={6} lg={6} xl={4} > 
              <SearchBar { ...props.searchProps } />
              </Col>
              </Row>
              <div>
              <BootstrapTable rowStyle={this.rowStyle}  {...props.baseProps}  options={options} defaultSorted = {defaultSorted} striped={false} tableContainerClass={`table-striped jll-opp-table table-hover ${s.bootstrapTable}`} />
            </div>
          
            </div>
          )
        }
      </ToolkitProvider>
     
    </>
        
    );
  }

}

class CheckBoxRefreshed extends Component {
  constructor(props){
    super(props);
    this.state={
      completed: false
    };
  }

  componentDidMount(){
    this.setState({completed:this.props.completed})
  }

  componentDidUpdate(prevProps){
    if(prevProps.completed !== this.props.completed){
      this.setState({completed:this.props.completed})
    }
  }
  
  render(){
    return(
    <FormGroup className="checkbox abc-checkbox" check>
      <Input onChange={() => this.props.checkboxChange(!this.state.completed, this.props.id)} checked={this.state.completed} id={`checkbox-${this.props.id}`} type="checkbox" />&nbsp;
      <Label for={`checkbox-${this.props.id}`} check>
        &nbsp;  Complete
      </Label>
      </FormGroup>
  )
  }

}

class SendButton extends Component {
  constructor(props) {
      super(props);
      this.state = {
        previewEmail: false,
        emailPreviewCode: null,
        fetching: false,
        complete: false,
        error: false,
        success: false,
      }
      this.handleClick = this.handleClick.bind(this);
  }

  toggle(id){
    this.setState(prevState => ({
      [id]: !prevState[id],
    }));
  }

  handleClick(cell, row, rowIndex) {
    this.setState({
      fetching: true,      
    })
    axios.defaults.headers ={
      "Content-Type": "application/json",
      "Authorization": `Token ${this.props.token}`
    } 
    axios.defaults.xsrfCookieName = 'csrftoken'
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
    let data = {
      campaignLogID: this.props.currentLogID,
      recipientID: cell
    } 

    axios.post(`/api/v1/sendreminderemail/${this.props.currentLogID}/`, data).then(
          (res) => {
            let emailcode = JSON.stringify(res.data)
            emailcode = JSON.parse(emailcode)
            this.setState({
            emailPreviewCode: emailcode,
            previewEmail: false,
            complete: true,
            success: true,
            fetching: false,
          })
        },
          (error) => { this.setState({
            error: true,
            fetching: false,
            complete:true
          })}
        )

  }

 render() {
      const { cell, row, rowIndex } = this.props;
      const {previewEmail, emailPreviewCode} = this.state;

      function ShowHtml(props) {
        return (
         <div  dangerouslySetInnerHTML={{__html: props}} />
        )
      }
      return (
        <>
        {
          !this.state.fetching && !this.state.success && !this.state.error
          ?
          <React.Fragment>
            <Tooltip title={<div style={{fontSize:"12px"}}>Send email reminder to broker.</div>} placement="top">
          <Button
               color="primary"
               size="xs"
               className="px-2"
              onClick={() => this.handleClick(cell, row, rowIndex)}
          >Send Reminder</Button>
          </Tooltip>
          {/* <Dialog ref={(el) => { this.dialog = el }} /> */}
           </React.Fragment>
           :
           this.state.fetching
           ?
           <div>
                <i className="la la-spinner la-spin" />
            </div>
           :
           this.state.success
           ?
           <React.Fragment>
           <Tooltip title={<div style={{fontSize:"12px"}}>Your reminder was sent successfully. Click again to view the email sent.</div>} placement="top">
         <Button
              color="success"
              size="xs"
              className="px-2"
             onClick={() => this.toggle('previewEmail')}
         >View Sent Reminder</Button>
         </Tooltip>
         </React.Fragment>
           :
           this.state.error
           ?
           <React.Fragment>
            <Tooltip title={<div style={{fontSize:"12px"}}>An error occured in attempting to send your reminder.</div>} placement="top">
          <Button
               color="danger"
               size="xs"
               className="px-2"
              disabled
          >An error occured.</Button>
          </Tooltip>
          </React.Fragment>
           :
           null
        }
          <Modal size="lg" isOpen={previewEmail} toggle={() => this.toggle('previewEmail')}>
         <ModalHeader toggle={() => this.toggle('previewEmail')}>Email Preview</ModalHeader>
          <ModalBody className="bg-white emailLogPreviewBox">
                {
                  emailPreviewCode
                  ?
                  ShowHtml(emailPreviewCode)
                  :
                  null
                }
          </ModalBody>
    </Modal>
      </> 
      
      )
  }
}




export default withRouter(Dynamic);
