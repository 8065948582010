import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Alert, Button, Row, Col } from "reactstrap";
import Widget from "../../components/Widget";
import { authLogin } from "../../actions/authentication";
import jwt from "jsonwebtoken";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import AccordionDetails from "@material-ui/core/AccordionDetails";
// import microsoft from '../../images/microsoft.png';
import config from "../../config";

var moment = require("moment");

const AccordionStyle = withStyles({
  root: {
    border: "0px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(Accordion);
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}
class Login extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  static isAuthenticated(token) {
    if (!token) {
      return;
    }
    const date = new Date().getTime() / 1000;
    const data = jwt.decode(token);
    return token;
  }

  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
    };

    // this.doLogin = this.doLogin.bind(this);
    // this.googleLogin = this.googleLogin.bind(this);
    // this.microsoftLogin = this.microsoftLogin.bind(this);
    // this.changeEmail = this.changeEmail.bind(this);
    // this.changePassword = this.changePassword.bind(this);
    // this.signUp = this.signUp.bind(this);
  }
  //
  changeuserName(event) {
    this.setState({ userName: event.target.value });
  }

  changePassword(event) {
    this.setState({ password: event.target.value });
  }

  doLogin = (e) => {
    e.preventDefault();
    this.props.dispatch(authLogin(this.state.userName, this.state.password));
    if (localStorage.getItem("redirectTo")) {
      this.props.history.push(localStorage.getItem("redirectTo"));
    } else {
      this.props.history.push("/app");
    }
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get("token");
    // if (token) {
    //     this.props.dispatch(receiveToken(token));
    // }
  }

  ssoLogin() {
    window.location.href = "/signin";
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {});
  };
  render() {
    const { from } = this.props.location.state || {
      from: { pathname: "/app" },
    }; // eslint-disable-line
    const { token, error, authLoading } = this.props;

    let errorMessage = null;
    if (error) {
      errorMessage = <p>{error.response.status + error.response.statusText}</p>;
    }
    // cant access login page while logged in
    if (Login.isAuthenticated(localStorage.getItem("token"))) {
      if (localStorage.getItem("redirectTo")) {
        return <Redirect to={localStorage.getItem("redirectTo")} />;
      } else {
        return <Redirect to={from} />;
      }
    }

    return (
      <div className="auth-page">
        <Container>
          <Fragment>
            <h5 className="auth-logo">
              <i className="fa fa-circle text-primary" />
              Sales Enablement Dashboard
              <i className="fa fa-circle text-jllred" />
            </h5>
            <Widget
              className="widget-auth mx-auto"
              title={<h4 className="mt-0">Click to login:</h4>}
            >
              <div className="social-buttons">
                <Button
                  onClick={this.ssoLogin}
                  className="social-button jll-red mb-2"
                >
                  <i className="social-icon social-jll"></i>
                </Button>
              </div>
            </Widget>
          </Fragment>
        </Container>

        <footer className="auth-footer">
          {moment().format("YYYY")} &copy; Sales Enablement Dashboard.
        </footer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    error: state.authentication.error,
    authLoading: state.authentication.authLoading,
  };
}

export default withRouter(connect(mapStateToProps)(Login));
