import React, { useRef } from "react";
import { Row, Col, Button } from "reactstrap";
import moment from "moment";
import JoditEditor from "jodit-react";
import { DateRange } from "react-date-range";
import Divider from "@material-ui/core/Divider";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const TimelineWorkflow = (props) => {
  const editor = useRef(null);
  const [startDate, setStartDate] = React.useState(new Date());
  const [dueDate, setDueDate] = React.useState(new Date());
  const [boxData, setBoxData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [dateRange, setDateRange] = React.useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);

  const createTimelineData = () => {
    let start = moment(dateRange[0].startDate);
    let end = moment(dateRange[0].endDate);
    let difference = end.diff(start, "days");
    let draftCount = difference > 3 && difference < 5 ? 2 : difference > 5 ? 3 : 1;
  
    let gapBetweenDrafts = Math.floor(difference / draftCount);
    let rollingDate = start;
    let draftDates = [];
    let finalData = [];

    for (let x = 0; x <= difference && draftDates.length < draftCount; x++) {
      if (
        (draftDates.length === 0 ||
          rollingDate.diff(
            moment(draftDates[draftDates.length - 1], "ddd, MMMM Do, YYYY"),
            "days"
          ) >= gapBetweenDrafts) &&
        rollingDate.isoWeekday() !== 6 &&
        rollingDate.isoWeekday() !== 7
      ) {
        console.log("Route 1")
        draftDates.push(rollingDate.format("ddd, MMMM Do, YYYY"));
      } else if (
        rollingDate.isoWeekday() == 6 ||
        rollingDate.isoWeekday() == 7
      ) {
        // draftCount -= 1;
      }
      rollingDate = rollingDate.add(1, "days");
    }
    console.log(draftDates)

    let previousReviewDate = "";
    let previousDraftDate = "";

    draftDates.forEach((item, index) => {
      let draftDate = item;
      if (draftDate === previousReviewDate) {
        draftDate = moment(draftDate, "ddd, MMMM Do, YYYY")
          .add(1, "days")
          .format("ddd, MMMM Do, YYYY");
      }
      let newDraftObject = {
        date: draftDate,
        key: index,
        time: "EOD",
        message: `SE to circulate draft #${index + 1}`,
      };
      let reviewDate = moment(draftDate, "ddd, MMMM Do, YYYY").add(1, "days");
      reviewDate = checkOrReturnNextWeekday(reviewDate);
      let newReviewObject = {
        date: reviewDate.format("ddd, MMMM Do, YYYY"),
        key: index + 1,
        time: "EOD",
        message:
          index === draftDates.length - 1
            ? `REHEARSAL/Team to provide final edits`
            : `TEAM to send edits/missing content`,
      };
      finalData.push(newDraftObject);
      finalData.push(newReviewObject);
      previousReviewDate = reviewDate.format("ddd, MMMM Do, YYYY");
      previousDraftDate = draftDate;

      if (index === draftDates.length - 1) {
        let finalObject = {
          date: end.format("ddd, MMMM Do, YYYY"),
          index: index + 2,
          time: "EOD",
          message: "SE to circulate FINAL DRAFT.",
        };
        finalData.push(finalObject);
      }
    });
    setData(finalData);
    let finalCode = createTableCode(finalData);
    updateTables(finalCode);
  };

  function copyHTMLTable(el) {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
    }
  }

  const updateTables = (data) => {
    setBoxData(data);
    let outputBox = document.getElementById("liveBox");
    outputBox.innerHTML = data.trim();
  };

  const createTableCode = (data) => {
    let start =
      '<table style="font-family:arial; width: 60%;"><tbody><tr><td style="border: 0.5px solid #d1d1d1; width: 100%; text-align: center; background-color: #ed1c24; color: white; padding: 2px;"colspan="3"><strong>Project Timeline</strong></td></tr><tr><td style="border: 0.5px solid #d1d1d1; width: 40%; background-color: rgb(255, 255, 255);padding: 2px;"><strong>Date</strong></td><td style="border: 0.5px solid #d1d1d1; width: 20%; background-color: rgb(255, 255, 255);padding: 2px;"><strong>Time</strong></td><td style="border: 0.5px solid #d1d1d1; width: 45%; background-color: rgb(255, 255, 255);padding: 2px;"><strong>Action Item</strong></td></tr>';
    data.forEach((item) => {
      let newTableRow = `<tr><td style="padding: 2px; border: 0.5px solid #d1d1d1; width: 40%; background-color: rgb(255, 255, 255);">${item.date}</td>
          <td style="padding: 2px; border: 0.5px solid #d1d1d1; background-color: rgb(255, 255, 255);">${item.time}</td>
          <td style="padding: 2px; border: 0.5px solid #d1d1d1; background-color: rgb(255, 255, 255);">${item.message}</td></tr>`;
      start = start.concat(newTableRow);
    });
    start = start.concat("</tbody></table>");

    return start;
  };

  const checkOrReturnNextWeekday = (date) => {
    let tempDate = date;
    while (!(tempDate.isoWeekday() !== 6 && tempDate.isoWeekday() !== 7)) {
      tempDate = tempDate.add(1, "days");
    }
    return tempDate;
  };

  React.useEffect(() => {
    createTimelineData();
  }, [startDate, dueDate, dateRange]);
  return (
    <>
      <div>
        <Row>
          <Col
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "4px",
            }}
            lg={12}
          >
            <h2 className="scorecardHeader">Timeline Automation</h2>
            <Divider />
          </Col>
        </Row>
        <Row>
          {/* Input */}
          <Col
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "4px",
              maxWidth: "430px",
            }}
            sm={5}
          >
            <h2>Input</h2>
            <div className="mb-lg">
              Select date of first draft and final draft.
            </div>
            <div
              style={{
                padding: "5%",
                boxShadow: "0 5px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <DateRange
                ranges={dateRange}
                onChange={(item) => setDateRange([item.selection])}
              />
            </div>
          </Col>
          <Col
            style={{
              backgroundColor: "white",
              padding: "2%",
              borderRadius: "4px",
            }}
            // sm={7}
          >
            <h2>Output</h2>
            <div className="mb-lg">
              Below is the final timeline. Make changes within the first box and
              copy the final output by first selecting the table using the
              "Select Table" button and pressing CTRL/CMD + C.
            </div>
            <JoditEditor
              ref={editor}
              value={boxData}
              tabIndex={1}
              onBlur={(newContent) => updateTables(newContent)}
            />
            <div
              style={{
                marginTop: "5%",
                marginBottom: "1%",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              Final Output:
            </div>
            <div
              style={{
                border: ".5px dotted black",
                padding: "20px",
              }}
              id="liveBox"
            ></div>
            <div style={{ width: "100%" }}>
              <Button
                style={{ marginTop: "10px", float: "right" }}
                onClick={() =>
                  copyHTMLTable(document.getElementById("liveBox"))
                }
                color="primary"
              >
                Select Table
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TimelineWorkflow;
