import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import uuid from "uuid/v4";
import cx from "classnames";
import {
  Col,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Table,
  ModalFooter,
} from "reactstrap";
import Tooltip from "@material-ui/core/Tooltip";
import s from "./ChatList.module.scss";
import RecipientTracker from "./RecipientTracker/RecipientTracker";
import Widget from "../../../../../components/Widget";
import axios from "axios";

class RefreshLogDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentid: "",
      id: "",
      currentLogDetails: null,
      usedSettingsModal: false,
      emailSentCode: null,
      emailSentCodeReady: false,
      fetching: false,
      viewEmailSent: false,
    };
    this.getEmailSentCode = this.getEmailSentCode.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  getEmailSentCode() {
    this.toggle("viewEmailSent");
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/refreshemailsent/${this.props.currentID}/`).then(
      (res) => {
        let emailcode = JSON.stringify(res.data.finaltemplate);
        emailcode = JSON.parse(emailcode);
        this.setState({
          emailSentCode: emailcode,
          emailSentCodeReady: true,
          fetching: false,
        });
      },
      (error) => {
        this.setState({
          error: true,
          fetching: false,
          complete: true,
        });
      }
    );
  }

  getCurrentLogDetails() {
    this.setState({
      currentLogDetails: null,
    });
    let currentDetails = this.props.refreshlogs.find(
      (item) => item.id === this.props.currentID
    );

    this.setState({
      currentLogDetails: currentDetails,
      currentID: parseInt(this.props.currentID),
      id: this.props.id,
    });
  }

  componentDidMount() {
    this.getCurrentLogDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentID !== this.props.currentID) {
      this.getCurrentLogDetails();
    }
    if (prevProps.refreshlogs !== this.props.refreshlogs) {
      this.getCurrentLogDetails();
    }
  }

  ShowHtml(props) {
    return <div dangerouslySetInnerHTML={{ __html: props }} />;
  }

  render() {
    const {
      currentLogDetails,
      usedSettingsModal,
      viewEmailSent,
      emailSentCode,
      fetching,
      emailSentCodeReady,
    } = this.state;

    return (
      <>
        {currentLogDetails ? (
          <>
            <Row>
              <Col lg={11}>
                <Row className={s.root}>
                  <Col xs={12}>
                    <Widget bodyClass={"p-0 extrapadding"}>
                      <Row>
                        <Col lg={6} className={"border-right"}>
                          <h3>Date sent:</h3>{" "}
                          {moment(
                            currentLogDetails.datesent,
                            "YYYY-MM-DD hh:mm:ss"
                          ).format("MMM DD, YYYY")}
                          <Row>
                            <Col lg={12}>
                              <Button
                                onClick={() => this.toggle("usedSettingsModal")}
                                color="primary"
                                className="float-left btn-link"
                              >
                                View Settings Used
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={12}>
                              <Button
                                onClick={() => this.getEmailSentCode()}
                                color="primary"
                                className="float-left btn-link"
                              >
                                View Email Sent
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col lg={6}>
                          <h5># of recipients:</h5>{" "}
                          {currentLogDetails.recipients.length}
                          <br></br>
                          <h5>Follow-ups completed:</h5>{" "}
                          {currentLogDetails.followupcompleted.length}
                        </Col>
                      </Row>
                      <Row>
                        {currentLogDetails ? (
                          <RecipientTracker
                            setRefreshLogs={this.props.setRefreshLogs}
                            currentRefreshEmailID={
                              this.props.currentRefreshEmailID
                            }
                            currentLogID={currentLogDetails.id}
                            getRefreshEmailLogs={this.props.getRefreshEmailLogs}
                            token={this.props.token}
                            details={currentLogDetails}
                          />
                        ) : null}
                        {/* Recipient vs. complete list */}
                      </Row>
                    </Widget>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <Row>
            <Col lg={11}>
              <Row className={s.root}>
                <Col xs={12}>
                  <Widget bodyClass={"p-0 extrapadding"}>
                    <Row>
                      <Col lg={6}>
                        <p>No details found.</p>
                      </Col>
                    </Row>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Modal
          size="lg"
          isOpen={usedSettingsModal}
          toggle={() => this.toggle("usedSettingsModal")}
        >
          <ModalHeader toggle={() => this.toggle("usedSettingsModal")}>
            Campaign Settings
          </ModalHeader>
          <ModalBody className="bg-white">
            {currentLogDetails ? (
              <Table className={cx("mb-0", s.table)} borderless responsive>
                <thead>
                  <tr className="text-muted">
                    <th scope="col">
                      <span className="pl-2">Setting</span>
                    </th>
                    <th scope="col">Value</th>
                  </tr>
                </thead>
                <tbody className="text-dark">
                  {currentLogDetails.settingsused.map(({ key, value }) => {
                    if (value.length > 0) {
                      return (
                        <tr key={key}>
                          <td className="pl-3 fw-normal">{key}</td>
                          <td>{value}</td>
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="animated fadeIn handle initialLoader emailLoader mb-4">
                <span className="spinner">
                  <i className="fa fa-spinner fa-spin" />
                </span>
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12} className="mb-4">
                    Loading settings...
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
        <Modal
          size="lg"
          isOpen={viewEmailSent}
          toggle={() => this.toggle("viewEmailSent")}
        >
          <ModalHeader toggle={() => this.toggle("viewEmailSent")}>
            View Email Sent
          </ModalHeader>
          <ModalBody className="bg-white">
            {emailSentCodeReady ? (
              this.ShowHtml(emailSentCode)
            ) : !emailSentCodeReady && fetching ? (
              <div className="animated fadeIn handle initialLoader emailLoader mb-4">
                <span className="spinner">
                  <i className="fa fa-spinner fa-spin" />
                </span>
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12} className="mb-4">
                    Loading email...
                  </Col>
                </Row>
              </div>
            ) : (
              <div className="animated fadeIn handle initialLoader emailLoader mb-4">
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12} className="mb-4">
                    An error occured...
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
  };
}

export default connect(mapStateToProps)(RefreshLogDetails);
