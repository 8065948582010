const marketregions = [{
    "name": "West Region",
    "urlparam": "west",
    "markets": [
             {"id": 1,
            "name": "Los Angeles",
            "timezone": "America/Los_Angeles"
            },
            { "id": 14,
            "name": "Seattle",
            "timezone": "America/Los_Angeles"
            },
            { "id": 15,
            "name": "Phoenix",
            "timezone": "America/Phoenix"
            },
            { "id": 18,
            "name": "San Francisco",
            "timezone": "America/Los_Angeles"
            },
            { "id": 19,
            "name": "San Diego",
            "timezone": "America/Los_Angeles"
            },
            { "id": 20,
            "name": "East Bay",
            "timezone": "America/Los_Angeles"
            },
            { "id": 21,
            "name": "Silicon Valley",
            "timezone": "America/Los_Angeles"
            },
             {"id": 28,
            "name": "Portland",
            "timezone": "America/Los_Angeles"
            },
            {"id": 31,
            "name": "Orange County",
            "timezone": "America/New_York"
            },
           {
            "id": 72,
            "name": "Denver",
            "timezone": "America/Los_Angeles"
            }
    ]
}]

export default marketregions