import React from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Row,
  Col,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import s from './Profile.module.scss';
var moment = require('moment');


class OppDropdown extends React.Component {
  constructor(props) {
    super(props);
  
    }
  render() {

  return (

  <div className={s.root}>

    <Row>
        <Label md="6" className="pb-md h-100" for="default-select">Select an opportunity to see it's information</Label>
        <Col md="8" className="pb-md h-100">
          <Select
            ref= "oppSelector"
            className="selectCustomization"
            options={this.props.opportunityoptions}
            onChange={this.props.selectOpportunityForCard}
          />
        </Col>
        <Col md="4" className="pb-md h-100 scorecard-button">
        <Button color={'primary'} className="btn btn-block btn-primary " onClick={this.props.chooseOppforCard.bind(this)}>Submit</Button>
        </Col>
    </Row>


        
  </div>

)}
}


export default OppDropdown;
