import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmailReportItem from './EmailReportItem';
import s from './ChatList.module.scss';

class EmailList extends Component {
  constructor(props) {
    super(props)
    this.state={
      emailreports: [],
      currentid: ''
    }
    this.handleShow = this.handleShow.bind(this)
    props.emailreports.forEach(thing => {
      this[`${thing.id}_ref`] = React.createRef()
     });
    this.emailReportRef = React.createRef()
    }

  findUser = (id) => {
    return this.props.users.find(u => u.id === id);
  }

  executeScroll = () => this.scrollRef.current.scrollIntoView(); 

  handleShow = (i) => {
    // this.setState({index: i});
    this[`${i}_ref`].current.scrollIntoView({block: 'end', behavior: 'smooth'});
  }
  
  componentDidMount(){
    let emailreports = this.props.emailreports.sort((a,b) => (a.name > b.name) ? 1 : -1)
    this.setState({
      emailreports: emailreports
    })
  }
  componentDidUpdate(prevProps, prevState){
    if(prevState.emailreports.length !== this.state.emailreports.length && this.props.currentid){
      this.handleShow(this.props.currentid)
    }
    if(prevProps.currentid !== this.props.currentid && this.props.currentid){
      this.handleShow(this.props.currentid)
      this.setState({
        currentid: this.props.currentid
      })
    }
    
  }

  render() {
  const { emailreports } = this.state;
  return (
    <div className={`email-list-section`}>
      
      <section className={`chat-section ${s.chatsSectionWrap} ${s.groupChats} d-none d-lg-block`}>
        <h5>Email Reports</h5>
        <ul className={`${s.chatList}`}>

          {
            emailreports.length > 0
            ?
            <>
            {
              emailreports.map((report, i) => {
                return <div ref={this[`${report.id}_ref`]} key={i} className="emailliststyle" ><EmailReportItem currentid= {this.props.currentid} setCurrentEmail={this.props.setCurrentEmail} report={report} isActive = {report.id === this.state.currentid ? true : false} id={report.id} /></div>
              })
            }
            </>
            :
            <section >
              <div className="text-center">You do not currently have access to email report data.</div>
            </section>
          }
      
        </ul>
      </section>
  </div>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps)(EmailList);