import React, { Component } from "react";
import EmailDetails from "./EmailDetails";
import EmailList from "./components/EmailList/EmailList";
import { connect } from "react-redux";
import Widget from "../../../components/Widget";
import axios from "axios";
import CreateEditEmailReport from "./components/CreateEmailReport/CreateEmailReport";

import s from "./Chat.module.scss";
import { Row, Col, Table, ButtonToolbar, Button } from "reactstrap";

class EmailInfo extends Component {
  constructor(props) {
    super(props);
    this.setCurrentEmail = this.setCurrentEmail.bind(this);
    this.state = {
      fetching: false,
      fetchingError: false,
      errorData: "",
      doneFetching: false,
      editReportModal: false,
      createReportModal: false,
      currentReportDetails: null,
      emailreports: [],
      currentEmailid: null,
    };
    this.receiveNewlyCreatedReport = this.receiveNewlyCreatedReport.bind(this);
    this.toggleCreateorEdit = this.toggleCreateorEdit.bind(this);
  }

  getEmailReports() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/emailreports/`).then(
      (res) => {
        let emailreports = res.data.filter(
          (item) =>
            item.recipients.some(
              (recipient) =>
                recipient?.fullname === this.props.useraccount?.fullname
            ) ||
            item.cc_members.some(
              (member) => member?.fullname === this.props.useraccount?.fullname
            ) ||
            item.createdby?.fullname === this.props.useraccount?.fullname
        );
        this.setState({
          emailreports: emailreports,
          fetching: false,
          doneFetching: true,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          doneFetching: true,
          fetchingError: false,
        });
      }
    );
  }

  setCurrentEmail(id) {
    this.setState({
      currentEmailid: id,
    });
  }

  toggleCreateorEdit(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  receiveNewlyCreatedReport(report) {
    let reports = this.state.emailreports;
    this.setState({
      currentEmailid: null,
      emailreports: [],
    });
    let reportExists = reports.findIndex((item) => item.id === report.id);
    if (reportExists >= 0) {
      reports.splice(reportExists, 1);
      reports.unshift(report);
    } else {
      reports.unshift(report);
    }
    this.setState(
      {
        emailreports: reports,
      },
      this.setCurrentEmail(report.id)
    );
  }

  componentDidMount() {
    if (this.props.match.params.emailid) {
      let id = this.props.match.params.emailid;
      this.setCurrentEmail(id);
    }
    this.getEmailReports();
  }

  render() {
    const { mobileState } = this.props;
    return (
      <>
        {this.state.emailreports.length > 0 && this.state.doneFetching ? (
          <Row>
            <Col lg={4}>
              {this.state.emailreports.length > 0 ? (
                <EmailList
                  emailreports={this.state.emailreports}
                  currentid={this.state.currentEmailid}
                  setCurrentEmail={this.setCurrentEmail}
                />
              ) : null}
            </Col>
            <Col lg={8}>
              {this.state.currentEmailid ? (
                <EmailDetails
                  currentemailid={this.state.currentEmailid}
                  receiveNewlyCreatedReport={this.receiveNewlyCreatedReport}
                  toggleCreateorEdit={this.toggleCreateorEdit}
                />
              ) : (
                <>
                  <Col lg={12}>
                    <Row>
                      <Col lg={8}>
                        <h2 className="text-muted no-margin">
                          Email report details:
                        </h2>
                      </Col>
                      <Col lg={4}>
                        <Button
                          color="primary"
                          className="float-right"
                          onClick={() =>
                            this.toggleCreateorEdit("createReportModal")
                          }
                        >
                          Create Report
                        </Button>
                      </Col>
                    </Row>
                    <Widget bodyClass={"p-0 extrapadding"}>
                      <div className="widget">
                        <span>
                          Select a report from the table found on the left side
                          of this page to see report information.
                        </span>
                      </div>
                    </Widget>
                  </Col>
                </>
              )}
            </Col>
          </Row>
        ) : this.state.emailreports.length === 0 && this.state.doneFetching ? (
          <EmailDetails
            noReports={true}
            receiveNewlyCreatedReport={this.receiveNewlyCreatedReport}
          />
        ) : (
          <div className="animated fadeIn handle initialLoader emailLoader mb-4">
            <span className="spinner">
              <i className="fa fa-spinner fa-spin" />
            </span>
            <Row>
              <Col xs={12} md={12} lg={12} xl={12} className="mb-4">
                Loading Emails...
              </Col>
            </Row>
          </div>
        )}
        <CreateEditEmailReport
          token={this.props.token}
          receiveNewlyCreatedReport={this.receiveNewlyCreatedReport}
          emailreports={this.state.emailreports}
          currentemailid={this.state.currentEmailid}
          toggleCreateorEdit={this.toggleCreateorEdit}
          createReportModal={this.state.createReportModal}
          editReportModal={this.state.editReportModal}
        />
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    useraccount: state.authentication.userprofile,
  };
}

export default connect(mapStateToProps)(EmailInfo);
