import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import uuid from "uuid/v4";
import Tooltip from "@material-ui/core/Tooltip";
import s from "./ChatList.module.scss";

class RefreshEmailItem extends Component {
  constructor(props) {
    super(props);
    this.scrollRef = React.createRef();
    this.state = {
      currentid: "",
      id: "",
    };
  }

  componentDidMount() {
    this.setState({
      currentid: parseInt(this.props.currentid),
      id: this.props.id,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.currentid !== this.props.currentid) {
      this.setState({
        currentid: parseInt(this.props.currentid),
        id: this.props.id,
      });
    }
  }

  render() {
    const { isActive, report } = this.props;
    const { currentid, id } = this.state;
    return (
      <li
        ref={this.scrollRef}
        onClick={(e) => this.props.setCurrentRefreshTemplate(report.id)}
        className={`${s.chatListItem} ${id === currentid ? s.active : ""}`}
      >
        <div className={`${s.chatListItemWrapper}`}>
          <ul className={s.avatarsColumn}></ul>

          <section id={report.id} className={`${s.chatItemMain} ml-3`}>
            <header className="d-flex align-items-center justify-content-between mb-1">
              <h6 className={`${s.chatTitle}`}>{report.name}</h6>
              <span className={`ml-auto ${s.timestamp}`}>
                <Tooltip
                  title={
                    <div style={{ fontSize: "12px" }}>
                      {report.active ? "Active" : "Not Active"}
                    </div>
                  }
                  placement="top"
                >
                  <span
                    className={`emailStatus ${
                      report.active ? "bg-success" : "bg-warning"
                    }`}
                  ></span>
                </Tooltip>
              </span>
            </header>
            <p className={`${s.chatLastMessage}`}>
              <ul>
                {report.markets.length > 0 ? (
                  <li>
                    <span
                      className={`${s.ownerIndicator} emailreportdesc mr-1`}
                    >
                      Market(s):{" "}
                    </span>
                    {report.markets.map((market, index) => {
                      if (report.markets.length === index + 1) {
                        return market.marketname;
                      } else {
                        return market.marketname + ", ";
                      }
                    })}
                  </li>
                ) : null}
                <li>
                  {" "}
                  <span className={`${s.ownerIndicator} emailreportdesc mr-1`}>
                    Frequency:{" "}
                  </span>
                  {report.frequency}
                </li>
              </ul>
            </p>
          </section>
        </div>
      </li>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(RefreshEmailItem);
