import React, { Component } from 'react';
import { connect } from 'react-redux';
import RefreshEmailLogItem from './RefreshEmailLogItem';
import s from './ChatList.module.scss';
import axios from 'axios';

class RefreshEmailLogList extends Component {
  
  constructor(props) {
    super(props)
    this.state={
      refreshlogs: [],
      currentid: ''
    }
    this.handleShow = this.handleShow.bind(this)
    props.refreshlogs.forEach(thing => {
      this[`${thing.id}_ref`] = React.createRef()
     });
    this.emailReportRef = React.createRef()
    }

  findUser = (id) => {
    return this.props.users.find(u => u.id === id);
  }

  executeScroll = () => this.scrollRef.current.scrollIntoView(); 

  handleShow = (i) => {

    // this.setState({index: i});
    // this[`${i}_ref`].current.scrollIntoView({block: 'end', behavior: 'smooth'});
  }


 
  componentDidUpdate(prevProps, prevState){
    if(prevState.refreshlogs.length !== this.state.refreshlogs.length && this.props.currentid){
      this.handleShow(this.props.currentid)
      
    }
    if(prevProps.currentid !== this.props.currentid && this.props.currentid){
      this.setState({
        currentid: this.props.currentid
      })
    }
    if(prevProps.refreshlogs !== this.props.refreshlogs){
      this.setState({refreshlogs:this.props.refreshlogs})
      
    }
    
  }

  componentDidMount(){
    this.setState({
      refreshlogs: this.props.refreshlogs
    })
  }

  render() {
  const { refreshlogs } = this.state;
  return (
    <div className={`email-list-section`}>
      
      <section className={`chat-section ${s.chatsSectionWrap} ${s.groupChats} d-none d-lg-block`}>
        <h5>Campaign Logs</h5>
        <ul className={`${s.chatList}`}>

          {
            refreshlogs.length > 0
            ?
            <>
            {
              refreshlogs.map((report, i) => {
                return <div ref={this[`${report.id}_ref`]} className="emailliststyle" ><RefreshEmailLogItem currentid= {this.props.currentid} setCurrentRefreshLog={this.props.setCurrentRefreshLog} report={report} isActive = {report.id === this.state.currentid ? true : false} id={report.id} /></div>
              })
            }
            </>
            :
            <section >
              <div className="text-center mt-4">No logs currently exist for this campaign.</div>
            </section>
          }
      
        </ul>
      </section>
  </div>
    )
  }
}

function mapStateToProps(state) {
  return {

  }
}

export default connect(mapStateToProps)(RefreshEmailLogList);