import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
var moment = require('moment');

export default class ServiceLineAndPropertyType extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };



  chartData = () => {
      const { data } = this.props;

      let sorteddata = data.sort((a,b) => moment(a.datecreated, 'MM-DD-YYYY hh:mm:ss') - moment(b.datecreated, 'MM-DD-YYYY hh:mm:ss'))

      // Workingobject will contain all servicelines and dates in addition to the total
      let workingobjects = [
        {
          name: 'Total',
          data: []
        }
      ]
      let workingpropertytypeobjects = []
      let currentsl = ""
      let currentproptype = ""
      let ticks =[]
      let series =[]
      // formatteddate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      function containsObject(a, b) {
            for (var i in b) {
              if (b[i].date === a) {
                b[i].qty += 1;
                return true
                break; //Stop this loop, we found it!
              }
            }
            return false
              }

    sorteddata.forEach(item => {
      let date =  moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss');
      let shortdate = moment(date).format('MMM YYYY')
      date = moment.utc(shortdate, 'MMM YYYY').valueOf();


      //Eventual settings for objects
      let chartobjects = [{
        name: 'Total',
        data: [],
        color: 'rgba(26,134,208,.2)',
        type: 'areaspline',
        fillOpacity: 0.2,
        lineWidth: 0
      }]

      // Check to see if date is in workingobject
      let founddate = workingobjects[0].data.find(element => element.x === date)

      // either create the new date obj in the Total object or add to it.
      if(!(founddate === undefined)){
        founddate.y += 1
      } else {
        let newitem = {
          x: date,
          y: 1
        }
        workingobjects[0].data.push(newitem)
      }

      // Capture the matching serviceline obj in workingobjects or return null
      // currentsl = "Unknown"
      // currentproptype = "Other"

      if(item.propertytype !== ""){
        let findcurrentsl = item.propertytype
        currentsl = findcurrentsl
      }

      if(item.propertytype.length > 0){
        currentproptype = item.propertytype
      }

      let foundsl = workingobjects.find(element => element.name === currentsl)
      let foundproptype = workingpropertytypeobjects.find(element => element.name === currentproptype)

      // Either add to the existing object or create a new serviceline object within workingobjects
      if(!(foundsl === undefined)){

        let index = workingobjects.findIndex(element => element.name === currentsl)
        let slfounddate = workingobjects[index].data.find(element => element.x === date)

        if(!(slfounddate === undefined)){
          slfounddate.y += 1
        } else {
          let newsldateitem = {
            x: date,
            y: 1
          }
          foundsl.data.push(newsldateitem)
        }
      } else {
        if (currentsl !== ""){
          let newitem =
          {
            name: currentsl,
            data: [{
              x: date,
              y: 1
            }]
          }
        workingobjects.push(newitem)
        }
        
      }
     

      
      //Begin proptype gathering
      if(!(foundproptype === undefined)){

        let index = workingpropertytypeobjects.findIndex(element => element.name === currentproptype)
        let proptypefounddate = workingpropertytypeobjects[index].data.find(element => element.x === date)

        if(!(proptypefounddate === undefined)){
          proptypefounddate.y += 1
        } else {
          let newptdateitem = {
            x: date,
            y: 1
          }
          foundproptype.data.push(newptdateitem)
        }
      } else {
        let newitem =
          {
            name: currentproptype,
            data: [{
              x: date,
              y: 1
            }]
          }
          workingpropertytypeobjects.push(newitem)
      }
      })

      //Serviceline series
      workingobjects.forEach(slobject => {

        let thename = slobject.name;
        let tabledata = slobject.data.map(({date, data}) => [date, data]);
        // object dates and data are being converted to arrays
        let ydata = slobject.data.map(({data}) => [data]);
        let newchartobject;

        if(thename === 'Total'){
          let newchartobject = {
            name: thename,
            data: slobject.data,
            color: 'rgba(26,134,208,.2)',
            type: 'areaspline',
            fillOpacity: 0.2,
            lineWidth: 0
          };
          series.push(newchartobject)
        } else{
          let newchartobject = {
            type: 'spline',
            name: thename,
            data: slobject.data
          };
          series.push(newchartobject)
        }
      })

      //second bar series
      // workingpropertytypeobjects.forEach(ptobject => {
      //   if(ptobject.name !== ""){
      //     if(!(ptobject.name == "Other")){
      //       let thename = ptobject.name;
      //       let tabledata = ptobject.data.map(({date, data}) => [date, data]);
      //       // object dates and data are being converted to arrays
      //       let ydata = ptobject.data.map(({data}) => [data]);
    
      //       let newchartobject = {
      //         type: 'bar',
      //         name: thename,
      //         data: ptobject.data
      //       };
      //       series.push(newchartobject)
    
      //       }
            
      //   }
        
      // })


      
     // series = [
     //    {
     //      name: 'Total',
     //      data: workingobjects[0].data,
     //      color: 'rgba(26,134,208,.2)',
     //      type: 'areaspline',
     //      fillOpacity: 0.2,
     //      lineWidth: 0
     //    },
     //    {
     //      type: 'spline',
     //      name: 'Industrial',
     //      data: workingobjects[1].data,
     //      // color: '#FD5F00',
     //    },
     //    {
     //      type: 'spline',
     //      name: 'Tenant Rep',
     //      data: workingobjects[2].data,
     //      // color: '#005792'
     //    }
     //  ]

     ticks = workingobjects[0].data.map(({date}) => [new Date(date)]);
      // console.log(series)

    return {
      ...this.ticks,
      ...this.chartOptions,
      series
    }
  }




  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      enabled: true
    },
    legend: {
      useHTML: false,
    
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      bar: {
        stacking: 'normal'
      },
      series: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        // pointInterval: 3600000 * 25, // every day
        pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${this.series.name} at ${this.y.toFixed(2)}</span>`;
          }
        }
      },
    }
  };


  render() {

    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
               Opportunity <span className="fw-semi-bold">Property Type </span>By Month
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}
