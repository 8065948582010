import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import chroma from "chroma-js";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";

import Datetime from "react-datetime";
import { withRouter } from "react-router-dom";

var moment = require("moment");

class DataRefresh extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.props.history.push('/app/markets');
  }

  render() {
    const {
      navbarType,
      navbarColor,
      openUsersList,
      user,
      userdataready,
      notifications,
      dateLastUpdated,
      isGettingProjects,
    } = this.props;
    let formattedLastDate = moment(dateLastUpdated).format("MM/DD/YY k:mm");
    return (
      <>
        <small className="text-muted">Data from: &nbsp;</small>
        <small> {formattedLastDate} &nbsp;</small>

        <span>{"  "}</span>

        <i
          className={`la la-refresh ${
            chroma(navbarColor).luminance() < 0.4 ? "text-white" : ""
          }`}
        />
      </>
    );
  }
}

function mapStateToProps(store) {
  return {
    isGettingProjects: store.projects.isReceiving,
    token: store.authentication.token,
    user: store.authentication.userdata,
    userdataready: store.authentication.userdataready,
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    navbarType: store.layout.navbarType,
    navbarColor: store.layout.navbarColor,
    notifications: store.projects.notifications,
    dateLastUpdated: store.projects.dataLastupdated,

    // openUsersList: store.chat.openUsersList
  };
}
export default withRouter(connect(mapStateToProps)(DataRefresh));
