/* eslint class-methods-use-this: ["error", { "exceptMethods": ["printInvoice"] }] */
import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import s from "./Invoice.module.scss";
import DataTable from "./datatable/DataTable";
import Widget from "../../components/Widget";

class EmailReports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      fetchingError: false,
      errorData: "",
      doneFetching: false,
      emailreports: [],
    };
  }
  printInvoice() {
    window.print();
  }

  getEmailReports() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/emailreports/`).then(
      (res) => {
        this.setState({
          emailreports: res.data,
          fetching: false,
          doneFetching: true,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          fetchingError: false,
          doneFetching: true,
        });
      }
    );
  }
  componentDidMount() {
    this.getEmailReports();
  }

  render() {
    return (
      <Row>
        <Col lg={11}>
          <Row className={s.root}>
            <Col xs={12}>
              <Widget bodyClass={"p-0"}>
                <div className="widget">
                  <header>
                    <Row className="mb-lg">
                      <Col sm={6} className="col-print-6">
                        <h2 className="text-muted no-margin">Email Reports</h2>
                        <p>A formal list of all email reports active or not.</p>
                      </Col>
                    </Row>
                  </header>
                  {this.state.emailreports.length > 0 &&
                  this.state.doneFetching ? (
                    <section>
                      <DataTable
                        data={this.state.emailreports}
                        token={this.props.token}
                      />
                    </section>
                  ) : this.state.emailreports.length === 0 &&
                    this.state.doneFetching ? (
                    <section>
                      <div className="text-center">
                        You do not currently have access to email report data.
                      </div>
                    </section>
                  ) : (
                    <div className="animated fadeIn handle initialLoader emailLoader">
                      <span className="spinner">
                        <i className="fa fa-spinner fa-spin" />
                      </span>
                      <Col xs={12} md={12} lg={12} xl={12}>
                        Loading Emails...
                      </Col>
                    </div>
                  )}
                </div>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}
function mapStateToProps(state) {
  return {
    token: state.authentication.token,
  };
}

export default connect(mapStateToProps)(EmailReports);
