import { combineReducers } from 'redux';
// import auth from './auth';
import navigation from './navigation';
// import alerts from './alerts';
import layout from './layout';
// import products from './products';
// import register from './register';
import dashboardfilters from './dashboardfilters';
// import analytics from './analytics';
import projects from './projects';
import authentication from './authentication';
// import chat from './chat';

export default combineReducers({
  navigation,
  layout,
  dashboardfilters,
  projects,
  authentication
});
