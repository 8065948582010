import React from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";
import LogRocket from "logrocket";
import {
  receiveProfileDataSuccess,
  authSuccess,
} from "../actions/authentication";
import { getMarketData, getNotificationsandUpdates } from "../actions/projects";
import { Security, LoginCallback, SecureRoute } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import config from "../core/config";
// /* eslint-disable */
import FourohFour from "../pages/fourohfour";
import Gameification from "../pages/gameification/Gameification";
// /* eslint-enable */
import "../styles/theme.scss";
import LayoutComponent from "../components/Layout";
import Login from "../pages/login";
import AdminLogin from "../pages/AdminLogin/AdminLogin";
import { logout } from "../actions/authentication";

import axios from "axios";

const PrivateRoute = ({ dispatch, component, ...rest }) => {
  let location = useLocation();
  if (!localStorage.getItem("token")) {
    dispatch(logout());
    let pathname = location.pathname;
    if (pathname != "/login") {
      localStorage.setItem("redirectTo", pathname);
    }
    return <Redirect to="/login" />;
  } else {
    return (
      // eslint-disable-line
      <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
    );
  }
};

const CloseButton = ({ closeToast }) => (
  <i onClick={closeToast} className="la la-close notifications-close" />
);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.oktaAuth = new OktaAuth(config.oidc);
  }
  componentDidMount() {
    this.getInfo();
  }
  restoreOriginalUri = async (_oktaAuth, originalUri) => {
    console.log(_oktaAuth, originalUri);
    window.location.replace(
      toRelativeUrl(originalUri || "/", window.location.origin)
    );
  };

  async getUserToken(oktaAuthObject) {
    await axios.post(`/api/v1/getusertoken`, oktaAuthObject).then((res) => {
      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${res.data.token.key}`,
      };
      this.props.dispatch(authSuccess(res.data.token.key));
      let userProfile = res.data.profile;
      this.props.dispatch(receiveProfileDataSuccess(userProfile));
      LogRocket.identify(userProfile.email, {
        name: userProfile.first_name + " " + userProfile.last_name,
        email: userProfile.email,
        username: userProfile.username,
        first_name: userProfile.first_name,
        last_name: userProfile.last_name,
      });
      this.props.dispatch(getMarketData());
      this.props.dispatch(getNotificationsandUpdates());
    });
  }

  async getInfo() {
    let accessToken = await this.oktaAuth.tokenManager.get("accessToken");
    if (accessToken) {
      const userInfo = await this.oktaAuth.token.getUserInfo(accessToken);
      await this.getUserToken(userInfo);
    }
    return accessToken;
  }

  render() {
    const { cleandataready, userdataready } = this.props;
    return (
      <div>
        <div>
          <Security
            oktaAuth={this.oktaAuth}
            restoreOriginalUri={this.restoreOriginalUri}
          >
            <BrowserRouter>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={() => <Redirect to="/app/dashboard" />}
                />
                <Route
                  path="/app"
                  exact
                  render={() => <Redirect to="/app/dashboard" />}
                />

                <SecureRoute
                  path="/app"
                  dispatch={this.props.dispatch}
                  component={LayoutComponent}
                />
                <Route
                  path="/scoreboard/:eventid"
                  exact
                  component={Gameification}
                />

                <Route path="/login" exact component={Login} />
                <Route path="/adminlogin" exact component={AdminLogin} />
                <Route path="/404" exact component={FourohFour} />
                <Route path="/login/callback" component={LoginCallback} />

                <Redirect from="/*" to="/404" />
              </Switch>
            </BrowserRouter>
          </Security>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.authentication.token,
  isAuthenticated: state.authentication.token != null,
  data: state.data,
  userdataready: state.authentication.userdataready,
  user: state.authentication.userdata,
  projects: state.projects.projects,
  cleandataready: state.projects.cleandataready,
});

export default connect(mapStateToProps)(App);
