import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class SourcingMethodChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
      simpleSelectDropdownValue: 'Choose number displayed',

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };



  getSourcingMethods(opps){
    let sourcingmethodology = []

    opps.forEach(item =>{
      let currentsourcingmethod = "Unknown"

      if (item.sourcingmethodology.length >0 ){
        currentsourcingmethod = item.sourcingmethodology.map(({methodname})=> methodname)
      }
      if(!(currentsourcingmethod === "Unknown")){
        currentsourcingmethod.forEach(item => {
          let foundmethod = sourcingmethodology.find(element => element.method === item)

          if(!(foundmethod === undefined)){
            foundmethod.count += 1
          }else{
              if(!(item === "")){
                let newobj = {
                  method: item,
                  count: 1
                }
                sourcingmethodology.push(newobj)  
              } 
          }
        })
      }
    })
  
    return sourcingmethodology
  }


  chartData = () => {

  let data = this.getSourcingMethods(this.props.data)
  data = data.sort((a,b) => b.count - a.count)
  data = data.map(({method, count}) => [method, count])


  var config = {
    chart: {
        type: 'bar',
        height: 200,
    },
    title: {
      text: ''
  },
  exporting: {
    chartOptions: {
      title: {
        text: null
      }
    }
  },
    xAxis: {
        type: 'category',
        // labels: {
        //     rotation: -45,
        //     style: {
        //         fontSize: '13px',
        //         fontFamily: 'Verdana, sans-serif'
        //     }
        // }
    },
    credits: {
      enabled: false
    },
    yAxis: {
        min: 0,
        title: {
            text: ''
        }
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    tooltip: {
        pointFormat: 'Sourcing method for: <b>{point.y:.1f}</b> opportunities'
    },
    series: [{
        name: 'Opportunities',
        data: data,
        dataLabels: {
          enabled: true,
          // align: 'center',
          // color: '#ffffff'
      },

    }]
};


    // this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
    return config
  };



  changeSelectDropdownSimple = (e) => {
    var chart = this.refs.chartref.chart;
    this.setState({ simpleSelectDropdownValue: parseFloat(e.currentTarget.textContent) });

    chart.update(this.chartData())
  
  }

  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} >
            
                {
                  this.props.customtitle
                  ?
                  <h5>{this.props.customtitle}</h5>
                  :
                  <h5>
                  <span className="fw-semi-bold">Sourcing Methodology</span> For Opportunities
                </h5>
                }
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
       
      </Widget>
    );
  }
}

export default SourcingMethodChart
