import React from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Nav,
  NavLink,
  NavItem,
  Progress,
} from "reactstrap";
import Formsy from "formsy-react";
import axios from "axios";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import Widget from "../../../../../../components/Widget/Widget";
import s from "./Wizard.module.scss";

const count = 4;
const StepsComponents = {
  Step4: function Step4() {
    return (
      <fieldset>
        <h2>Thank you!</h2>
        <p>Your submission has been received.</p>
      </fieldset>
    );
  },
};

class Step4Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    function ShowHtml(props) {
      return (
        <div
          className="emailPreviewBox"
          dangerouslySetInnerHTML={{ __html: props }}
        />
      );
    }
    return (
      <>
        <Row>
          <Col lg={12}>
            <h3>Email Preview:</h3>
            <p className="help-block">
              Please verify that the design of the email preview generated looks
              accurate. <br></br> <br></br>{" "}
              <span className="fw-semi-bold">NOTE: </span>
              The query presented within the email is based on the "frequency"
              selected. If no opportunities were found in the "frequency"
              selected, the email shown in the preview may not display any
              opportunities.
            </p>
          </Col>
          <Col lg={12}>
            {this.props.emailTestCodeReady ? (
              ShowHtml(this.props.emailTestCode)
            ) : this.props.emailTestError ? (
              <div className="animated fadeIn handle initialLoader emailLoader">
                <p className="text-danger">Some error occurred...</p>
              </div>
            ) : (
              <div className="animated fadeIn handle initialLoader emailLoader">
                <span className="spinner">
                  <i className="fa fa-spinner fa-spin" />
                </span>
                <Col xs={12} md={12} lg={12} xl={12}>
                  Loading Preview...
                </Col>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

class Step3Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    return (
      <>
        <fieldset>
          <FormGroup>
            <Label for="brokerinput">Recipients*</Label>
            {this.props.recipients ? (
              <Multiselect
                options={this.props.recipients} // Options to display in the dropdown
                onSelect={this.props.onSelectRecipient.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectRecipient.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectRecipientRef}
                selectedValues={this.props.selectedRecipients}
                id="recipientInput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Please select recipients who should receive this report. If a name
              is not on the list- please reach out. Names can be added to
              recipent list at a later date.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="brokerinput">CC Members</Label>
            {this.props.recipients ? (
              <Multiselect
                options={this.props.recipients} // Options to display in the dropdown
                onSelect={this.props.onSelectCCMember.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectCCMember.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectCCMemberRef}
                selectedValues={this.props.selectedCCList}
                id="recipientInput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Please select individuals who should be cc'ed to every recipient
              email sent.
            </p>
          </FormGroup>

          <FormGroup>
            <Label for="selectedIncludeWinLoss">Include win/loss data</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedIncludeWinLoss"
              name="selectedIncludeWinLoss"
              className="selectedIncludeWinLoss"
              value={this.props.selectedIncludeWinLoss}
              options={this.props.includewinloss}
            />
            <p className="help-block">
              By default, the report will send new wins & losses. Select no to
              change this.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="selectedSendAnyway">Send if no new updates</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedSendAnyway"
              name="selectedSendAnyway"
              className="selectedSendAnyway"
              value={this.props.selectedSendAnyway}
              options={this.props.sendAnywayChoices}
            />
            <p className="help-block">
              By default, the report will not send if no new wins, losses, or
              opportunities are reported. Select to change this functionality.
            </p>
          </FormGroup>
        </fieldset>
      </>
    );
  }
}

class Step2Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    return (
      <>
        <fieldset>
          <FormGroup>
            <Label for="selectedTimerange">Time Range Filter</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedTimerange"
              name="selectedTimerange"
              className="selectedTimerange"
              value={this.props.selectedTimerange}
              options={this.props.timerangeChoices}
            />
            <p className="help-block">
              By default, the report will base the query of opportunities sent
              by the "Frequency". However- if a specific time range is desired,
              please select above. (i.e. A weekly report of Year to Date
              opportunities is desired. YTD would be selected above, Weekly
              would be selected within Frequency.)
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="pursuitsize">Pursuit Size Minimum</Label>
            <input
              type="number"
              className="form-control"
              id="pursuitsize"
              name="pursuitsize"
              value={this.props.pursuitsize}
              onChange={this.props.handleNormalInputChange}
            />
            <p className="help-block">
              Opportunities displayed will be those above the value chosen
              above. Not required.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="brokerinput">Filter by Broker List</Label>
            {this.props.brokers ? (
              <Multiselect
                options={this.props.brokers} // Options to display in the dropdown
                onSelect={this.props.onSelectBroker.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectBroker.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectBrokerRef}
                selectedValues={this.props.selectedBrokers}
                id="brokerinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Filter opportunities by brokers. (Required for broker grouping
              format)
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="businesslineinput">Business Line Filter</Label>
            {this.props.businesslines ? (
              <Multiselect
                options={this.props.businesslines} // Options to display in the dropdown
                onSelect={this.props.onSelectServiceLine.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectServiceLine.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectServiceLineRef}
                selectedValues={this.props.selectedServiceLines}
                id="businesslineinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Filter opportunities by business line. (Required for business line
              grouping format)
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="businesslineinput">Opportunity Type Filter</Label>
            {this.props.opptypes ? (
              <Multiselect
                options={this.props.opptypes} // Options to display in the dropdown
                onSelect={this.props.onSelectOppType.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectOppType.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectOppTypeRef}
                selectedValues={this.props.selectedOppType}
                id="businesslineinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Filter opportunities by opportunity type. (Required for
              opportunity type grouping format)
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="businesslineinput">Property Type Filter</Label>
            {this.props.propertyTypes ? (
              <Multiselect
                options={this.props.propertyTypes} // Options to display in the dropdown
                onSelect={this.props.onSelectPropertyType.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectPropertyType.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectPropertyTypeRef}
                selectedValues={this.props.selectedPropertyType}
                id="businesslineinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Filter opportunities by property type. (Required for opportunity
              type grouping format)
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="businesslineinput">Practice Group Filter</Label>
            {this.props.practicegroups ? (
              <Multiselect
                options={this.props.practicegroups} // Options to display in the dropdown
                onSelect={this.props.onSelectPracticeGroup.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectPracticeGroup.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectPracticeGroupRef}
                selectedValues={this.props.selectedPracticeGroup}
                id="businesslineinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Filter opportunities by practice group. (Required for practice
              group grouping format)
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="selectedGrouping">Groupings</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedGrouping"
              name="selectedGrouping"
              className="selectedGrouping"
              value={this.props.selectedGrouping}
              options={this.props.groupings}
            />
            <p className="help-block">
              By default, the report will group new opportunites and wins by
              market. Choose a value to change this behaviour. Please ensure the
              appropriate list of brokers or servicelines are selected for the
              report to generate properly.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="csvInput">Include CSV Attachment</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="csvInput"
              name="selectedIncludeCSV"
              className="selectedIncludeCSV"
              value={this.props.selectedIncludeCSV}
              options={this.props.includeCSVChoices}
            />
            <p className="help-block">
              Select if you would like a CSV of new opportunities to be included
              with the email report.
            </p>
          </FormGroup>
        </fieldset>
      </>
    );
  }
}

class Step1Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    return (
      <>
        <fieldset>
          <FormGroup>
            <Label for="reportName">Report Name*</Label>
            <input
              onChange={(e) => this.props.handleNormalInputChange(e)}
              className={`selectCustomization form-control ${
                this.props.checkedInputs &&
                (this.props.reportName.length === 0 ||
                  typeof this.props.reportName === "object")
                  ? "ng-invalid"
                  : ""
              }`}
              type="text"
              id="reportName"
              name="reportName"
              value={`${
                typeof this.props.reportName === "object"
                  ? ""
                  : this.props.reportName
              }`}
            />
            <p className="help-block">
              Name your email report for easy reference.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="email">Frequency*</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedFrequency"
              name="selectedFrequency"
              className={`selectCustomization ${
                this.props.checkedInputs &&
                (this.props.selectedFrequency.length === 0 ||
                  typeof this.props.selectedFrequency != "object")
                  ? "ng-invalid"
                  : ""
              }`}
              value={this.props.selectedFrequency}
              options={this.props.frequency}
            />
            <p className="help-block">How often should the report send?</p>
          </FormGroup>
          <FormGroup>
            <Label for="email">Active Status*</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedActive"
              name="selectedActive"
              className={`selectCustomization ${
                this.props.checkedInputs &&
                (this.props.selectedActive.length === 0 ||
                  typeof this.props.selectedActive != "object")
                  ? "ng-invalid"
                  : ""
              }`}
              value={this.props.selectedActive}
              options={this.props.reportActiveChoices}
            />
            <p className="help-block">
              Please select if this report should be sent at the selected
              frequency. Default is active.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="address">Market(s)*</Label>
            {this.props.markets ? (
              <Multiselect
                options={this.props.markets} // Options to display in the dropdown
                style={
                  this.props.checkedInputs &&
                  (this.props.selectedMarkets.length === 0 ||
                    !Object.prototype.toString.call(
                      this.props.selectedMarkets
                    ) == "[object Array]")
                    ? {
                        searchBox: {
                          border: "none",
                          borderBottom: "1px solid #eb516d",
                        },
                      }
                    : ""
                }
                onSelect={this.props.onSelectMarket.bind(this)} // Function will trigger on select event
                onRemove={this.props.onRemoveMarket.bind(this)} // Function will trigger on remove event
                ref={this.props.marketSelectRef}
                selectedValues={this.props.selectedMarkets}
                id="marketinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">Select market or markets.</p>
          </FormGroup>
          <FormGroup>
            <Label for="reportname">Report Market Name*</Label>
            <input
              onChange={(e) => this.props.handleNormalInputChange(e)}
              className={`selectCustomization form-control ${
                this.props.checkedInputs &&
                (this.props.altmarketname.length === 0 ||
                  typeof this.props.altmarketname === "object")
                  ? "ng-invalid"
                  : ""
              }`}
              type="text"
              id="altmarketname"
              value={`${
                typeof this.props.altmarketname === "object"
                  ? ""
                  : this.props.altmarketname
              }`}
              name="altmarketname"
            />
            <p className="help-block">
              This is the market name that appears throughout the report. (i.e.
              "West Region" market report. Market name is "West Region")
            </p>
          </FormGroup>
        </fieldset>
      </>
    );
  }
}

class Wizard extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      brokers: [],
      businesslines: [],
      secondFieldMissingValue: false,
      firstFieldMissingValue: false,
      inputCheck: false,
      checkedInputs: false,
      recipients: [],
      reportActiveChoices: [],
      includeCSVChoices: [],
      inputsready: false,
      markets: [],
      frequency: [],
      groupings: [],
      reportName: [],
      timerangeChoices: [],
      sendAnywayChoices: [],
      pursuitsize: "",
      altmarketname: [],
      practicegroups: [],
      propertyTypes: [],
      opptypes: [],
      includewinloss: [],
      selectedOppType: [],
      selectedPropertyType: [],
      selectedCCList: [],
      selectedPracticeGroup: [],
      selectedIncludeWinLoss: {
        value: true,
        label: "Yes",
        name: "selectedIncludeWinLoss",
      },
      selectedGrouping: [],
      selectedFrequency: [],
      selectedBrokers: [],
      selectedSendAnyway: {
        value: false,
        label: "No",
        name: "selectedSendAnyway",
      },
      selectedTimerange: [],
      selectedActive: { value: true, label: "Active", name: "selectedActive" },
      selectedServiceLines: [],
      selectedIncludeCSV: {
        value: false,
        label: "Do not include",
        name: "selectedIncludeCSV",
      },
      selectedRecipients: [],
      selectedMarkets: [],
      emailTestCode: "",
      emailTestError: false,
      emailTestCodeReady: false,
      fetching: false,
      currentStep: 1,
      progress: 25,
      isDatePickerOpen: false,
    };

    this.saveReport = this.saveReport.bind(this);
    this.saveReportChanges = this.saveReportChanges.bind(this);
    this.sendTest = this.sendTest.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNormalInputChange = this.handleNormalInputChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.onSelectMarket = this.onSelectMarket.bind(this);
    this.onRemoveMarket = this.onRemoveMarket.bind(this);
    this.onSelectRecipient = this.onSelectRecipient.bind(this);
    this.onSelectBroker = this.onSelectBroker.bind(this);
    this.onSelectCCMember = this.onSelectCCMember.bind(this);
    this.onSelectServiceLine = this.onSelectServiceLine.bind(this);
    this.onSelectOppType = this.onSelectOppType.bind(this);
    this.onSelectPropertyType = this.onSelectPropertyType.bind(this);
    this.onSelectPracticeGroup = this.onSelectPracticeGroup.bind(this);
    this.onSelectPracticeGroupRef = React.createRef();
    this.onSelectCCMemberRef = React.createRef();
    this.onSelectOppTypeRef = React.createRef();
    this.onSelectRecipientRef = React.createRef();
    this.marketSelectRef = React.createRef();
    this.onSelectBrokerRef = React.createRef();
    this.onSelectServiceLineRef = React.createRef();
    this.onSelectPropertyTypeRef = React.createRef();
  }

  onSelectCCMember(selectedList, selectedItem) {
    this.setState({
      selectedCCList: selectedList,
    });
  }

  onSelectPracticeGroup(selectedList, selectedItem) {
    this.setState({
      selectedPracticeGroup: selectedList,
    });
  }

  onSelectOppType(selectedList, selectedItem) {
    this.setState({
      selectedOppType: selectedList,
    });
  }

  onSelectBroker(selectedList, selectedItem) {
    this.setState({
      selectedBrokers: selectedList,
    });
  }

  onSelectServiceLine(selectedList, selectedItem) {
    this.setState({
      selectedServiceLines: selectedList,
    });
  }

  onSelectPropertyType(selectedList, selectedItem) {
    this.setState({
      selectedPropertyType: selectedList,
    });
  }

  onSelectRecipient(selectedList, selectedItem) {
    this.setState({
      selectedRecipients: selectedList,
    });
  }

  onSelectMarket(selectedList, selectedItem) {
    this.setState({
      selectedMarkets: selectedList,
    });
  }
  onRemoveMarket(selectedList, selectedItem) {
    this.setState({
      selectedMarkets: selectedList,
    });
  }

  handleInputChange(e) {
    const value = e.value;
    const name = e.name;
    this.setState({
      [name]: e,
    });
  }

  handleNormalInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  checkSecondRequired() {
    let objectFieldList = [this.state.selectedRecipients];
    let fieldIsNotMissingValue = true;

    objectFieldList.forEach((field) => {
      if (
        field.length === 0 ||
        !Object.prototype.toString.call(field) == "[object Array]"
      ) {
        fieldIsNotMissingValue = false;
      }
    });

    return fieldIsNotMissingValue;
  }

  saveReport() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let data = {
      reportName: this.state.reportName,
      selectedOppType: this.state.selectedOppType,
      selectedCCList: this.state.selectedCCList,
      selectedPracticeGroup: this.state.selectedPracticeGroup,
      selectedIncludeWinLoss: this.state.selectedIncludeWinLoss,
      selectedSendAnyway: this.state.selectedSendAnyway,
      selectedActive: this.state.selectedActive,
      selectedTimerange: this.state.selectedTimerange,
      selectedIncludeCSV: this.state.selectedIncludeCSV,
      selectedMarkets: this.state.selectedMarkets,
      selectedRecipients: this.state.selectedRecipients,
      selectedPropertyType: this.state.selectedPropertyType,
      selectedGrouping: this.state.selectedGrouping,
      selectedFrequency: this.state.selectedFrequency,
      selectedBrokers: this.state.selectedBrokers,
      selectedServiceLines: this.state.selectedServiceLines,
      altmarketname: this.state.altmarketname,
      pursuitsize: this.state.pursuitsize,
    };

    axios.post(`/api/v1/emailreportsave/`, data).then(
      (res) => {
        this.props.receiveNewlyCreatedReport(res.data);
        this.props.toggleCreateorEdit("createReportModal");
      },
      (error) => {
        console.log(error);
        this.setState({
          emailTestError: true,
        });
      }
    );
    this.nextStep();
  }

  saveReportChanges() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let reportID = this.props.currentReportDetails.id;
    let data = {
      reportID: reportID,
      reportName: this.state.reportName,
      selectedOppType: this.state.selectedOppType,
      selectedCCList: this.state.selectedCCList,
      selectedPracticeGroup: this.state.selectedPracticeGroup,
      selectedIncludeWinLoss: this.state.selectedIncludeWinLoss,
      selectedSendAnyway: this.state.selectedSendAnyway,
      selectedActive: this.state.selectedActive,
      selectedTimerange: this.state.selectedTimerange,
      selectedIncludeCSV: this.state.selectedIncludeCSV,
      selectedMarkets: this.state.selectedMarkets,
      selectedRecipients: this.state.selectedRecipients,
      selectedGrouping: this.state.selectedGrouping,
      selectedFrequency: this.state.selectedFrequency,
      selectedBrokers: this.state.selectedBrokers,
      selectedPropertyType: this.state.selectedPropertyType,
      selectedServiceLines: this.state.selectedServiceLines,
      altmarketname: this.state.altmarketname,
      pursuitsize: this.state.pursuitsize,
    };

    axios.post(`/api/v1/emailreportsavechanges/`, data).then(
      (res) => {
        this.props.receiveNewlyCreatedReport(res.data);
        this.props.toggleCreateorEdit("editReportModal");
      },
      (error) => {
        console.log(error);
        this.setState({
          emailTestError: true,
        });
      }
    );
    this.nextStep();
  }
  sendTest() {
    this.setState({
      emailTestCode: "",
      emailTestCodeReady: false,
      emailTestError: false,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let data = {
      reportName: this.state.reportName,
      selectedOppType: this.state.selectedOppType,
      selectedCCList: this.state.selectedCCList,
      selectedPracticeGroup: this.state.selectedPracticeGroup,
      selectedIncludeWinLoss: this.state.selectedIncludeWinLoss,
      selectedSendAnyway: this.state.selectedSendAnyway,
      selectedActive: this.state.selectedActive,
      selectedTimerange: this.state.selectedTimerange,
      selectedIncludeCSV: this.state.selectedIncludeCSV,
      selectedMarkets: this.state.selectedMarkets,
      selectedRecipients: this.state.selectedRecipients,
      selectedGrouping: this.state.selectedGrouping,
      selectedPropertyType: this.state.selectedPropertyType,
      selectedFrequency: this.state.selectedFrequency,
      selectedBrokers: this.state.selectedBrokers,
      selectedServiceLines: this.state.selectedServiceLines,
      altmarketname: this.state.altmarketname,
      pursuitsize: this.state.pursuitsize,
    };

    axios.post(`/api/v1/emailreporttest/`, data).then(
      (res) => {
        this.setState({
          emailTestCode: res.data,
          emailTestCodeReady: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          emailTestError: true,
        });
      }
    );
    this.nextStep();
  }

  checkFirstRequired() {
    let normalFieldList = [this.state.reportName, this.state.altmarketname];
    let objectFieldList = [
      this.state.selectedFrequency,
      this.state.selectedMarkets,
    ];
    let fieldIsNotMissingValue = true;
    normalFieldList.forEach((field) => {
      if (field.length === 0) {
        fieldIsNotMissingValue = false;
      }
    });
    objectFieldList.forEach((field) => {
      if (
        field.length === 0 ||
        !Object.prototype.toString.call(field) == "[object Array]"
      ) {
        fieldIsNotMissingValue = false;
      }
    });

    return fieldIsNotMissingValue;
  }

  checkIfIntialValues(initialState) {
    if (this.props.currentReportDetails) {
      let initialData = this.props.currentReportDetails;
      let markets = initialData.markets.map((item) => ({
        name: item.marketname,
        index: item.market_id,
      }));
      let brokers = initialData.brokerlist.map((item) => ({
        name: item.fullname,
        index: item.id,
      }));
      let recipients = initialData.recipients.map((item) => ({
        name: item.fullname,
        index: item.id,
      }));
      let servicelines = initialData.serviceline.map((item) => ({
        name: item.servicelinename,
        index: item.id,
      }));
      let selectedPracticeGroup = initialData.practicegroup.map((item) => ({
        name: item.name,
        index: item.id,
      }));
      let selectedCCList = initialData.cc_members.map((item) => ({
        name: item.fullname,
        index: item.id,
      }));

      let active = initialState.active.find(
        (item) => item.value === initialData.active
      );
      let includeCSV = initialState.includeCSVChoices.find(
        (item) => item.value === initialData.includecsv
      );
      let frequency = initialState.frequency.find(
        (item) => item.value === initialData.frequency
      );
      let grouping = initialState.groupings.find(
        (item) => item.value === initialData.specialfilter
      );
      let sendAnyway = initialState.sendAnywayChoices.find(
        (item) => item.value === initialData.sendreportifnoopps
      );
      let selectedTimeRange = initialState.timerangeChoices.find(
        (item) => item.value === initialData.timerangefilter
      );
      let selectedIncludeWinLoss = initialState.includewinloss.find(
        (item) => item.value === initialData.includewinloss
      );

      let selectedOppType = [];
      if (initialData.opportunitytype.length > 0) {
        selectedOppType = initialData.opportunitytype.split(", ");
        selectedOppType = selectedOppType.map((item, index) => ({
          name: item,
          index: index,
        }));
      }

      let selectedPropertyType = [];
      if (initialData.property_type_list.length > 0) {
        selectedPropertyType = initialData.property_type_list.split(", ");
        selectedPropertyType = selectedPropertyType.map((item, index) => ({
          name: item,
          index: index,
        }));
      }

      this.setState({
        reportName: initialData.name,
        selectedMarkets: markets,
        selectedActive: active,
        selectedOppType: selectedOppType,
        selectedIncludeWinLoss: selectedIncludeWinLoss,
        selectedPracticeGroup: selectedPracticeGroup,
        selectedCCList: selectedCCList,
        selectedIncludeCSV: includeCSV,
        selectedPropertyType: selectedPropertyType,
        selectedTimerange: selectedTimeRange,
        selectedRecipients: recipients,
        selectedSendAnyway: sendAnyway,
        selectedGrouping: grouping,
        selectedFrequency: frequency,
        selectedBrokers: brokers,
        selectedServiceLines: servicelines,
        altmarketname: initialData.altname,
        pursuitsize: initialData.sfthreshold,
      });
    }
  }

  nextStep() {
    let currentStep = this.state.currentStep;
    let firstFieldMissingValue = false;
    let secondFieldMissingValue = false;
    let checkedInputs = true;

    if (currentStep === 1) {
      firstFieldMissingValue = this.checkFirstRequired();
      if (firstFieldMissingValue) {
        currentStep += 1;
      }
    } else if (currentStep === 2) {
      currentStep += 1;
    } else if (currentStep === 3) {
      secondFieldMissingValue = this.checkSecondRequired();
      if (secondFieldMissingValue) {
        currentStep += 1;
      }
    }
    this.setState({
      checkedInputs,
      firstFieldMissingValue,
      currentStep,
      secondFieldMissingValue,
      progress: (100 / count) * currentStep,
    });
  }

  sortList(a, b) {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  }

  getInputValues() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/reportinputvalues/`).then(
      (res) => {
        let data = res.data;

        // let brokerlist = data.brokerlist.map((item, index) => {'name': item.fullname, 'index': index})
        let brokerlist = data.brokerlist.map(function (item, index) {
          return { name: item.fullname, index: index };
        });
        let businesslines = data.businesslinelist.map(function (item, index) {
          return { name: item.servicelinename, index: index };
        });
        let recipients = data.recipients.map(function (item, index) {
          return { name: item.fullname, index: index };
        });

        let markets = data.markets.map(function (item, index) {
          return { name: item.marketname, index: index };
        });
        let practicegroups = data.practicegroups.map(function (item, index) {
          return { name: item.value, index: index };
        });
        let opportunitytypes = data.opptypes.map(function (item, index) {
          return { name: item.value, index: index };
        });

        let propertyTypes = data.propertyTypes.map(function (item, index) {
          return { name: item.value, index: index };
        });

        let activechoices = data.activechoices;
        let includewinloss = data.includewinloss;
        let opportunityTimerange = data.opportunityTimerange;
        let includeCSVChoices = data.includeCSVChoices;
        let sendAnywayChoices = data.sendEvenIfBlank;
        markets = markets.sort((a, b) => this.sortList(a, b));

        recipients = recipients.sort((a, b) => this.sortList(a, b));

        brokerlist = brokerlist.sort((a, b) => this.sortList(a, b));

        businesslines = businesslines.sort((a, b) => this.sortList(a, b));

        practicegroups = practicegroups.sort((a, b) => this.sortList(a, b));

        opportunitytypes = opportunitytypes.sort((a, b) => this.sortList(a, b));

        let frequency = data.frequency;
        let groupings = data.groupinglist;
        let objForInitialState = {
          includewinloss: includewinloss,
          sendAnywayChoices: sendAnywayChoices,
          timerangeChoices: opportunityTimerange,
          active: activechoices,
          includeCSVChoices: includeCSVChoices,
          brokerlist: brokerlist,
          opportunitytypes: opportunitytypes,
          businesslines: businesslines,
          recipients: recipients,
          practicegroups: practicegroups,
          markets: markets,
          frequency: frequency,
          groupings: groupings,
        };
        this.checkIfIntialValues(objForInitialState);
        this.setState({
          reportActiveChoices: activechoices,
          includewinloss: includewinloss,
          sendAnywayChoices: sendAnywayChoices,
          opptypes: opportunitytypes,
          timerangeChoices: opportunityTimerange,
          includeCSVChoices: includeCSVChoices,
          brokers: brokerlist,
          practicegroups: practicegroups,
          businesslines: businesslines,
          propertyTypes: propertyTypes,
          recipients: recipients,
          markets: markets,
          frequency: frequency,
          groupings: groupings,
          fetching: false,
          inputsready: true,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          fetchingError: false,
        });
      }
    );
  }
  previousStep() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep,
    });
  }

  componentDidMount() {
    this.getInputValues();
  }

  render() {
    const currentStep = this.state.currentStep;
    return (
      <div className={s.root}>
        <>
          <Row>
            <Col lg={12}>
              {this.state.inputsready ? (
                <Widget
                  className={s.formWizard}
                  title={
                    <div>
                      <h4>Email Report Form&nbsp;</h4>
                      <p className="text-muted">
                        Please fill out the following form to create or edit a
                        custom email report.
                      </p>
                    </div>
                  }
                >
                  <Nav pills justified className={s.wizardNavigation}>
                    <NavItem>
                      <NavLink active={currentStep === 1}>
                        <small>1.</small>
                        &nbsp; Basic Information
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentStep === 2}>
                        <small>2.</small>
                        &nbsp; Filters
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentStep === 3}>
                        <small>3.</small>
                        &nbsp; Recipients
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentStep === 4}>
                        <small>4.</small>
                        &nbsp; Confirmation
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Progress
                    value={this.state.progress}
                    color="info"
                    className="progress-xs"
                  />
                  <div className="tab-content">
                    <div className={s.stepBody}>
                      {currentStep === 1 && (
                        <Step1Component
                          selectedActive={this.state.selectedActive}
                          reportActiveChoices={this.state.reportActiveChoices}
                          checkedInputs={this.state.checkedInputs}
                          altmarketname={this.state.altmarketname}
                          selectedMarkets={this.state.selectedMarkets}
                          reportName={this.state.reportName}
                          marketSelectRef={this.marketSelectRef}
                          handleNormalInputChange={this.handleNormalInputChange}
                          selectedFrequency={this.state.selectedFrequency}
                          onRemoveMarket={this.onRemoveMarket}
                          onSelectMarket={this.onSelectMarket}
                          handleInputChange={this.handleInputChange}
                          markets={this.state.markets}
                          frequency={this.state.frequency}
                        />
                      )}

                      {currentStep === 2 && (
                        <Step2Component
                          timerangeChoices={this.state.timerangeChoices}
                          selectedTimerange={this.state.selectedTimerange}
                          handleInputChange={this.handleInputChange}
                          pursuitsize={this.state.pursuitsize}
                          groupings={this.state.groupings}
                          selectedIncludeCSV={this.state.selectedIncludeCSV}
                          includeCSVChoices={this.state.includeCSVChoices}
                          onSelectPracticeGroup={this.onSelectPracticeGroup}
                          onSelectPracticeGroupRef={
                            this.onSelectPracticeGroupRef
                          }
                          onSelectOppType={this.onSelectOppType}
                          onSelectOppTypeRef={this.onSelectOppTypeRef}
                          onSelectPropertyType={this.onSelectPropertyType}
                          opptypes={this.state.opptypes}
                          propertyTypes={this.state.propertyTypes}
                          practicegroups={this.state.practicegroups}
                          selectedGrouping={this.state.selectedGrouping}
                          onSelectBrokerRef={this.onSelectBrokerRef}
                          onSelectServiceLineRef={this.onSelectServiceLineRef}
                          brokers={this.state.brokers}
                          businesslines={this.state.businesslines}
                          selectedOppType={this.state.selectedOppType}
                          selectedPropertyType={this.state.selectedPropertyType}
                          selectedPracticeGroup={
                            this.state.selectedPracticeGroup
                          }
                          onSelectServiceLine={this.onSelectServiceLine}
                          handleNormalInputChange={this.handleNormalInputChange}
                          onSelectBroker={this.onSelectBroker}
                          selectedBrokers={this.state.selectedBrokers}
                          selectedServiceLines={this.state.selectedServiceLines}
                        />
                      )}

                      {currentStep === 3 && (
                        <Step3Component
                          onSelectCCMember={this.onSelectCCMember}
                          onSelectCCMemberRef={this.onSelectCCMemberRef}
                          selectedIncludeWinLoss={
                            this.state.selectedIncludeWinLoss
                          }
                          includewinloss={this.state.includewinloss}
                          selectedCCList={this.state.selectedCCList}
                          handleInputChange={this.handleInputChange}
                          sendAnywayChoices={this.state.sendAnywayChoices}
                          selectedSendAnyway={this.state.selectedSendAnyway}
                          checkedInputs={this.state.checkedInputs}
                          selectedRecipients={this.state.selectedRecipients}
                          recipients={this.state.recipients}
                          onSelectRecipient={this.onSelectRecipient}
                          onSelectRecipientRef={this.onSelectRecipientRef}
                        />
                      )}
                      {currentStep === 4 && (
                        <Step4Component
                          emailTestError={this.state.emailTestError}
                          checkedInputs={this.state.checkedInputs}
                          emailTestCode={this.state.emailTestCode}
                          emailTestCodeReady={this.state.emailTestCodeReady}
                        />
                      )}
                    </div>

                    <div className="description">
                      <ul className="pager wizard">
                        <li className="previous">
                          <Button
                            disabled={currentStep === 1}
                            color="primary"
                            onClick={this.previousStep}
                          >
                            <i className="fa fa-caret-left" />
                            &nbsp;Previous
                          </Button>
                        </li>
                        {currentStep < count && currentStep < 3 ? (
                          <li className="next">
                            <Button color="primary" onClick={this.nextStep}>
                              Next <i className="fa fa-caret-right" />
                            </Button>
                          </li>
                        ) : currentStep < count &&
                          currentStep == 3 &&
                          !this.state.firstFieldMissingValue &&
                          !this.state.secondFieldMissingValue ? (
                          <li className="next">
                            <Button color="primary" onClick={this.sendTest}>
                              View Preview <i className="fa fa-caret-right" />
                            </Button>
                          </li>
                        ) : (currentStep < count &&
                            this.state.firstFieldMissingValue &&
                            currentStep == 3) ||
                          (currentStep < count &&
                            this.state.secondFieldMissingValue &&
                            currentStep == 3) ? (
                          <li className="next">
                            <Button color="primary" disabled>
                              Preview Report <i className="fa fa-caret-right" />
                            </Button>
                          </li>
                        ) : null}
                        {currentStep === count &&
                        !this.state.saveError &&
                        !this.props.currentReportDetails ? (
                          <li className="finish">
                            <Button color="primary" onClick={this.saveReport}>
                              Save Report <i className="fa fa-check" />
                            </Button>
                          </li>
                        ) : currentStep === count &&
                          !this.state.saveError &&
                          this.props.currentReportDetails ? (
                          <li className="finish">
                            <Button
                              color="primary"
                              onClick={this.saveReportChanges}
                            >
                              Save Changes <i className="fa fa-check" />
                            </Button>
                          </li>
                        ) : currentStep === count && this.state.saveError ? (
                          <li className="finish">
                            <Button color="danger" disabled>
                              Error <i className="fa fa-check" />
                            </Button>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </Widget>
              ) : null}
            </Col>
          </Row>
        </>
      </div>
    );
  }
}

export default Wizard;
