import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Button,
  ButtonDropdown,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import s from '../../MainDashboard.module.scss';


class DropDownStats extends Component {


  constructor(props) {
    super(props);
    this.toggleOne = this.toggleOne.bind(this);
  }

  static test(){
    console.log("Testing")
  }

  state = {
    simpleSelectDropdownValue: 'This Month',
    dropdownOpenOne: false,
    dropDownValue: ""
   }

  changeSelectDropdownSimple = (e) => {
    this.setState({ simpleSelectDropdownValue: e.currentTarget.textContent });
  }

  toggleOne() {
    this.setState({
      dropdownOpenOne: !this.state.dropdownOpenOne,
    });
  }

  changeValue(e){

    this.setState({dropDownValue: e})
  }



  render() {
    const { data, type, sl } = this.props;

    let renderedlist= []
    if(type === "Won" || type === "Lost"){
      sl.forEach((item, key) => {
        let filteredprojects = data.filter((object => object.type.toLowerCase().includes(item.toLowerCase())).bind(item))

        let filteredwinsobj = filteredprojects.filter(object => object.winloss === type)
        let wins = filteredwinsobj.length
        let total = data.filter((object => object.type.toLowerCase().includes(item.toLowerCase()) && (object.winloss === "Won" || object.winloss === "Lost")).bind(item)).length
        let winrate= (wins/total * 100).toFixed(2)


        renderedlist.push(
          <DropdownItem >
          <div key={key} onClick={() => this.changeValue(key)} className={cx('mt')}>
              <h6>{winrate}%</h6>
              <p className="text-muted mb-0 mr">
                  <small>{item}</small>
              </p>
          </div>
        </DropdownItem>)
      })

    }else if(type === "size"){

      sl.forEach( (item) =>{
        renderedlist.push(
          <DropdownItem >
          <div key={item}  className={cx('mt')}>
              <h6>{data.filter((object => object.servicelines === item).bind(item)).reduce((a, {pursuitsize}) => a + pursuitsize, 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} sf</h6>
              <p className="text-muted mb-0 mr">
                  <small>{item}</small>
              </p>
          </div>
        </DropdownItem>)
        })
    }

    return (
      <ButtonDropdown isOpen={this.state.dropdownOpenOne} toggle={this.toggleOne} className={s.customDropdown}>
        <Button size="sm" id="dropdownFour" color="gray">
          {(() => {
        switch (this.state.dropDownValue) {
          case "":  return renderedlist[1];
          default:  return renderedlist[this.state.dropDownValue];
        }
        })()}

      </Button>
        <DropdownToggle size="sm" caret color="gray" className="dropdown-toggle-split" />
        <DropdownMenu>
          {renderedlist}
        </DropdownMenu>
      </ButtonDropdown>
    );
  }
}

export default DropDownStats;
