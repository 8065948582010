import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import download from "downloadjs";
import PropTypes from "prop-types";
import { Button, Row, Col, FormGroup, Input, Label } from "reactstrap";
import { connect } from "react-redux";
import Widget from "../../../../components/Widget/Widget";
import {
  updateMeetingTypeFilter,
  updateIndustryFilter,
  updateSourcingMethodFilter,
  updatePropertyTypeFilter,
  updateInboundFilter,
  updateToolIncludeOrExactFilter,
  updateTeamIncludeOrExactFilter,
  updateTypeFilter,
  updateBrokerTeamFilter,
  updateTechUsedFilter,
  updateAddBrokerFilter,
  updateSourcingBrokerFilter,
  updateServiceLineFilter,
  updateOppNameFilter,
  updateGreaterorlessFilter,
  updateSizeFilter,
  updateStatusFilter,
  updateActiveFilters,
  updateStartDate,
  updateEndDate,
  updateShortcut,
} from "../../../../actions/dashboardfilters";
import { Multiselect } from "multiselect-react-dropdown";
import {
  setFilteredProjects,
  setCleanData,
} from "../../../../actions/projects";
import { preventDefault } from "@fullcalendar/core";

var moment = require("moment");

class ExportReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      techoptions: [],
      timerangeoptions: [
        { label: "This Month", value: "cmonth" },
        {
          label: "Last Month",
          value: "lmonth",
        },
      ],
      markets: [],
      selectedmarket: "",
      selectedtimerange: "",
    };
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.value;
    const key = target.key;
    const name = target.name;
    const id = target.id;
    if (name === "marketFilter") {
      this.setState({
        selectedmarket: value,
      });
    } else if (name === "reportTimeRange") {
      this.setState({
        selectedtimerange: value,
      });
    }
  }

  componentDidMount() {
    let markets = this.props.markets;
    let finalmarkets = [];
    markets = markets.sort((a, b) => a.marketname > b.marketname);
    markets.forEach((item) => {
      if (item.market_id !== "200" && item.market_id !== "125") {
        let newmarket = {
          marketname: item.marketname,
          marketid: item.market_id,
        };
        finalmarkets.push(newmarket);
      }
    });

    this.setState({
      markets: finalmarkets,
    });
  }

  downloadReport(e) {
    if (
      this.state.selectedtimerange !== "" &&
      this.state.selectedmarket !== ""
    ) {
      if (
        this.state.selectedtimerange !== "" &&
        this.state.selectedmarket !== ""
      ) {
        let filename = `${this.state.selectedmarket}-${
          this.state.selectedtimerange
        }-Report_${moment().format("MM-DD-YYYY")}.pdf`;
        axios.defaults.headers = {
          // "Content-Type": "application/json",
          Authorization: `Token ${this.props.token}`,
        };
        axios
          .get(
            `/generate/pdf?market=${this.state.selectedmarket}&daterange=${this.state.selectedtimerange}`,
            {
              responseType: "blob", // had to add this one here
            }
          )
          .then((response) => {
            const content = response.headers["content-type"];
            download(response.data, filename, content);
          })
          .catch((error) => console.log(error));
      }
    }
  }

  render() {
    return (
      <form className="form-inline mr-auto filter-form">
        <div className="col">
          <div className="form-group mb-3  justify-content-between">
            <label for="marketFilter">Market</label>
            <input
              ref="marketFilter"
              onChange={this.handleInputChange.bind(this)}
              name="marketFilter"
              placeholder="Select market..."
              type="text"
              id="marketFilter"
              list="marketDataList"
              className="form-control bg-light filterform-input border-0"
            />
            <datalist id="marketDataList">
              {this.state.markets.map((item) => (
                <option key={item.marketid} value={item.marketname}></option>
              ))}
            </datalist>
          </div>

          <div className="form-group mb-3  justify-content-between">
            <label for="timeFilter">Time Range</label>
            <input
              ref="timeFilter"
              onChange={this.handleInputChange.bind(this)}
              name="reportTimeRange"
              placeholder="Select time range..."
              type="text"
              id="timeFilter"
              list="timeFilterList"
              className="form-control bg-light filterform-input border-0"
            />
            <datalist id="timeFilterList">
              {this.state.timerangeoptions.map((item) => (
                <option key={item.value} value={item.label}></option>
              ))}
            </datalist>
          </div>

          <Button
            onClick={(e) => this.downloadReport()}
            className="w-100"
            color="primary"
          >
            Export Report
          </Button>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  return {
    markets: state.projects.marketdata,
    token: state.authentication.token,
  };
}

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ExportReport
);
