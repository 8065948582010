import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

var moment = require("moment");

export default class RevenueChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: {},
      optionsReady: false,
    };
  }

  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    isReceiving: false,
  };

  chartData() {
    let { data } = this.props;

    if (data.length > 0) {
      if (this.props.dateRange.length > 0) {
        let startDate = this.props.dateRange[0];
        let endDate = this.props.dateRange[1];
        data = this.props.data.filter(
          (item) =>
            moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isAfter(
              startDate
            ) &&
            moment(item.datecreated, "MM-DD-YYYY hh:mm:ss").isBefore(endDate)
        );
      }
      // Workingobject will contain all servicelines and dates in addition to the total
      let workingobjects = [
        {
          name: "Total",
          data: [],
        },
      ];
      let currentmarket = "";
      let ticks = [];
      let series = [];
      // formatteddate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      function containsObject(a, b) {
        for (var i in b) {
          if (b[i].date === a) {
            b[i].qty += 1;
            return true;
            break; //Stop this loop, we found it!
          }
        }
        return false;
      }

      data.forEach((item) => {
        let date = moment(item.datecreated, "MM-DD-YYYY hh:mm:ss");
        // let date = new Date(item.datecreated);
        let shortdate = moment(date).format("MMM YYYY");
        // let shortdate =
        //
        date = moment.utc(shortdate, "MMM YYYY").valueOf();

        // date = Date(date);

        //Eventual settings for objects
        let chartobjects = [
          {
            name: "Total",
            data: [],
            color: "rgba(26,134,208,.2)",
            type: "areaspline",
            fillOpacity: 0.2,
            lineWidth: 0,
          },
        ];

        // Check to see if date is in workingobject
        let founddate = workingobjects[0].data.find(
          (element) => element.x === date
        );

        // either create the new date obj in the Total object or add to it.
        if (!(founddate === undefined)) {
          founddate.y += 1;
        } else {
          let newitem = {
            x: date,
            y: 1,
          };
          workingobjects[0].data.push(newitem);
        }

        // Capture the matching serviceline obj in workingobjects or return null
        currentmarket = "Not Specified";

        if (item.market.length > 0) {
          let findcurrentmarket = item.market;
          currentmarket = findcurrentmarket[0];
        }

        let foundmarket = workingobjects.find(
          (element) => element.name === currentmarket.marketname
        );

        // Either add to the existing object or create a new serviceline object within workingobjects
        if (!(foundmarket === undefined)) {
          let index = workingobjects.findIndex(
            (element) => element.name === currentmarket.marketname
          );
          let marketfounddate = workingobjects[index].data.find(
            (element) => element.x === date
          );

          if (!(marketfounddate === undefined)) {
            marketfounddate.y += 1;
          } else {
            let newmarketdateitem = {
              x: date,
              y: 1,
            };
            foundmarket.data.push(newmarketdateitem);
          }
        } else {
          let newitem = {
            name: currentmarket.marketname,
            data: [
              {
                x: date,
                y: 1,
              },
            ],
          };
          workingobjects.push(newitem);
        }
      });

      workingobjects.forEach((marketobject) => {
        let thename = marketobject.name;
        let tabledata = marketobject.data.map(({ date, data }) => [date, data]);
        // object dates and data are being converted to arrays
        let ydata = marketobject.data.map(({ data }) => [data]);
        let newchartobject;

        let sorteddata = marketobject.data.sort((a, b) => a.x - b.x);

        if (thename === "Total") {
          let newchartobject = {
            name: thename,
            data: sorteddata,
            color: "rgba(26,134,208,.2)",
            type: "areaspline",
            fillOpacity: 0.2,
            lineWidth: 0,
          };
          series.push(newchartobject);
        } else {
          let newchartobject = {
            type: "spline",
            name: thename,
            data: sorteddata,
          };
          series.push(newchartobject);
        }
      });

      ticks = workingobjects[0].data.map(({ date }) => [new Date(date)]);

      return {
        ...this.ticks,
        ...this.chartOptions,
        series,
      };
    }
  }

  ticks = ["Oct 2019", "Nov 2019"];

  chartOptions = {
    credits: {
      enabled: false,
    },
    chart: {
      height: 350,
      backgroundColor: "rgba(0,0,0,0)",
    },
    title: false,
    exporting: {
      enabled: true,
      chartOptions: {
        title: {
          text: null,
        },
      },
    },
    legend: {
      verticalAlign: "top",
      itemStyle: {
        color: "#000000",
      },
      itemHoverStyle: {
        color: "#020202",
      },
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000",
        },
      },
    },
    xAxis: {
      type: "datetime",
      labels: {
        overflow: "justify",
        style: {
          color: "#000000",
        },
      },
    },
    annotations: {
      visible: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: "circle",
        },
        // pointInterval: 3600000 * 25, // every day
        pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${
              this.series.name
            } at ${this.y.toFixed(2)}</span>`;
          },
        },
      },
    },
  };

  componentDidMount() {
    if (this.props.data.length > 0) {
      let options = this.chartData();
      this.setState({
        options: options,
        optionsReady: true,
      });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.data !== this.props.data ||
      this.props.dateRange !== prevProps.dateRange
    ) {
      let options = this.chartData();
      this.setState({
        options: options,
        optionsReady: true,
      });
    }
  };

  render() {
    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Opportunity <span className="fw-semi-bold">Market</span> by
                Month Entered
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend" />
            </Col>
          </Row>
        }
      >
        {this.state.optionsReady ? (
          <HighchartsReact highcharts={Highcharts} options={this.chartData()} />
        ) : (
          <div className="loader animated fadeIn handle initialLoader">
            <span className="spinner">
              <i className="fa fa-spinner fa-spin" />
            </span>
            <Col xs={12} md={12} lg={12} xl={12}>
              Loading Data...
            </Col>
          </div>
        )}
      </Widget>
    );
  }
}
