import React from "react";
import cx from "classnames";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Button, Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import Widget from "../../components/Widget/Widget";
import DonutChart from "./components/Donut";
import Dynamic from "./components/dynamic/Dynamic";
import BrokerPerformance from "./components/BrokerPerformance";
import BrokerLineChart from "./components/MainChart";
import IndustryChart from "./components/Industrychart";
import s from "./Profile.module.scss";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Report from "./components/oppreport";
import axios from "axios";
import ErrorPage from "../error/ErrorPage";
import Loading from "./components/loading";

var moment = require("moment");

class Scorecard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdf: false,
      relevantProjects: [],
      subjectName: "",
      clientlist: [],
      teammates: [],
      opps: [],
      brokername: "",
      activityChartPng: "",
      industryChartPng: "",
      statusChartPng: "",
      exportBox: false,
      subjectInfo: {
        serviceline: "",
        phonenumber: "",
        email: "",
        market: "",
        title: "",
      },
    };
    this.lineChartRef = React.createRef();
    this.statusRef = React.createRef();
    this.industryRef = React.createRef();
    this.CanvasRef = React.createRef();
  }

  getBrokerInfo() {
    let opps = this.state.opps;
    let brokername = this.state.brokername;
    if (opps.length > 0 && !(brokername === "")) {
      let keyproject = opps.find((item) =>
        item.sourcingbroker.find(
          (item) => item.fullname.toLowerCase() === brokername.toLowerCase()
        )
      );
      let clientlist = [];

      let teammates = [];
      let serviceline = "";
      let phonenumber = "";
      let email = "";
      let market = "";
      let title = "";

      if (keyproject === undefined) {
        const matching = (element) =>
          element.fullname.toLowerCase() === brokername.toLowerCase();

        keyproject = opps.find((item) => item.broker.some(matching));

        let index = keyproject.broker.findIndex(
          (i) => i.fullname.toLowerCase() === brokername.toLowerCase()
        );

        if (keyproject.broker[index].primarybl.length > 0) {
          serviceline = this.cleanServiceList(
            keyproject.broker[index].primarybl
          );
        }

        phonenumber = keyproject.broker[index].cellphone;
        email = keyproject.broker[index].email;
        market = keyproject.market;
        title = keyproject.broker[index].title;
      } else {
        let index = keyproject.sourcingbroker.findIndex(
          (i) => i.fullname.toLowerCase() === brokername.toLowerCase()
        );
        market = keyproject.market;
        if (keyproject.sourcingbroker[index].primarybl.length > 0) {
          serviceline = this.cleanServiceList(
            keyproject.sourcingbroker[index].primarybl
          );
        }
        phonenumber = keyproject.sourcingbroker[index].cellphone;
        email = keyproject.sourcingbroker[index].email;
        title = keyproject.sourcingbroker[index].title;
      }

      let results = opps.filter(function (item) {
        if (
          item.sourcingbroker.find(
            (item) => item.fullname.toLowerCase() === brokername.toLowerCase()
          ) ||
          item.broker.find(
            (item) => item.fullname.toLowerCase() === brokername.toLowerCase()
          )
        ) {
          return true;
        } else {
          return false;
        }
      });

      results.forEach((item) => {
        if (item.clientcompany.length > 0) {
          clientlist.push(item.clientcompany[0]);
        }

        for (let key in item.sourcingbroker) {
          let obj = item.sourcingbroker[key];

          if (!(obj.fullname.toLowerCase() === brokername.toLowerCase())) {
            let foundperson = teammates.find(
              (element) => element.name === obj.fullname
            );
            if (!(foundperson === undefined)) {
              foundperson.number += 1;
            } else {
              let newperson = {
                name: obj.fullname,
                number: 1,
              };
              teammates.push(newperson);
            }
          }
        }

        for (let key in item.broker) {
          let obj = item.broker[key];
          if (!(obj.fullname.toLowerCase() === brokername.toLowerCase())) {
            let foundperson = teammates.find(
              (element) => element.name === obj.fullname
            );
            if (!(foundperson === undefined)) {
              foundperson.number += 1;
            } else {
              let newperson = {
                name: obj.fullname,
                number: 1,
              };
              teammates.push(newperson);
            }
          }
        }
      });

      teammates.sort((a, b) => parseFloat(b.number) - parseFloat(a.number));

      this.setState({
        subjectName: brokername,
        relevantProjects: results,
        clientlist: clientlist,
        fetchingBrokerData: false,
        teammates: teammates,
        subjectInfo: {
          serviceline: serviceline,
          phonenumber: phonenumber,
          market: market,
          email: email,
          title: title,
        },
      });
    }
  }

  cleanPersonList(person) {
    let list = [];
    person.forEach((item) => list.push(item.fullname));
    let theperson = list.join(", ");
    return theperson;
  }

  cleanServiceList(serviceline) {
    let list = [];
    serviceline.forEach((item) => list.push(item.servicelinename));
    let theservice = list.join(", ");
    return theservice;
  }

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  handleMemberClick(member) {
    this.props.history.push("/app/reporting/brokerscorecard/" + member.name);
  }

  redirectToDashboard() {
    this.props.history.push("/app/dashboard");
  }

  getMemberInfo() {
    const params = this.props.match.params;
    if (!(params.subjectName === undefined)) {
      let subjectName = params.subjectName;
      this.setState(
        {
          subjectName: subjectName
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" "),
        },
        this.retreiveMemberInfo(subjectName)
      );
    }
  }

  componentDidMount() {
    this.setState(
      {
        opps: this.props.opps,
        brokername: this.props.brokername,
      },
      this.getBrokerInfo()
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.brokername !== prevState.brokername) {
      if (!(this.state.brokername === "")) {
        this.getBrokerInfo();
      }
    }
  };

  componentWillReceiveProps({ opps, brokername }) {
    this.setState(
      {
        brokername: brokername,
        opps: opps,
      },
      this.getBrokerInfo()
    );
  }

  exportBox() {
    this.setState({
      exportBox: true,
    });
    if (!this.state.pdf) {
      this.generateDownload();
    }
  }
  generateDownload() {
    let linechartoptions =
      this.lineChartRef.current.BrokerChart.current.chart.userOptions;
    linechartoptions = JSON.stringify(linechartoptions);

    let statuschartoptions =
      this.statusRef.current.Performance.current.chart.userOptions;
    statuschartoptions = JSON.stringify(statuschartoptions);

    let industrychartoptions =
      this.industryRef.current.Industry.current.chart.userOptions;
    industrychartoptions = JSON.stringify(industrychartoptions);

    let linedata = {
      options: linechartoptions,
      filename: "LineChart",
      async: true,
    };
    let statusdata = {
      options: statuschartoptions,
      filename: "StatusChart",
      async: true,
    };
    let industrydata = {
      options: industrychartoptions,
      filename: "IndustryChart",
      async: true,
    };

    let url = "https://export.highcharts.com/";
    let returnedlineurl = "";
    let returnedstatuseurl = "";
    let returnedindustryurl = "";
    let self = this;

    axios({
      method: "post",
      url: url,
      data: linedata,
    })
      .then(function (response) {
        returnedlineurl = url + response.data;
        console.log(returnedlineurl);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    axios({
      method: "post",
      url: url,
      data: statusdata,
    })
      .then(function (response) {
        returnedstatuseurl = url + response.data;
        console.log(returnedstatuseurl);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });

    axios({
      method: "post",
      url: url,
      data: industrydata,
    })
      .then(function (response) {
        returnedindustryurl = url + response.data;
        console.log(returnedindustryurl);

        self.setState(
          {
            activityChartPng: returnedlineurl,
            statusChartPng: returnedstatuseurl,
            industryChartPng: returnedindustryurl,
          },
          self.allowDownload()
        );
      })
      .catch(function (response) {
        //handle error
      });
  }

  allowDownload() {
    this.setState({
      pdf: true,
    });
  }

  render() {
    const { projects, cleandata, blist, marketdata, user } = this.props;
    const {
      subjectName,
      teammates,
      relevantProjects,
      subjectInfo,
      clientlist,
      activityChartPng,
      industryChartPng,
      statusChartPng,
      exportBox,
      alerts,
    } = this.state;

    let brokerDropdownOptions = [];

    let winrate = (
      (relevantProjects.filter((object) => object.winloss === "Won").length /
        relevantProjects.filter(
          (object) => object.winloss === "Won" || object.winloss === "Lost"
        ).length) *
      100
    ).toFixed(1);

    let wonProjects = relevantProjects.filter(
      (object) => object.winloss === "Won"
    );

    return (
      <div className={s.root}>
        <ErrorPage>
          <Modal
            size="lg"
            isOpen={exportBox}
            toggle={() => this.toggle("exportBox")}
          >
            <ModalHeader toggle={() => this.toggle("exportBox")}>
              Export Scorecard
            </ModalHeader>
            <ModalBody className="bg-white">
              {!this.state.pdf ? (
                <div className="loader animated fadeIn handle">
                  <span className="spinner">
                    <i className="fa fa-spinner fa-spin" />
                  </span>
                </div>
              ) : null}

              {this.state.pdf && this.state.activityChartPng !== "" && (
                <div>
                  <PDFDownloadLink
                    document={
                      <Report
                        brokername={subjectName}
                        relevantProjects={relevantProjects}
                        subjectInfo={subjectInfo}
                        clientlist={clientlist}
                        activityChart={activityChartPng}
                        teammates={teammates}
                        winrate={winrate}
                        statusChart={statusChartPng}
                        industryChart={industryChartPng}
                      />
                    }
                    fileName={
                      subjectName +
                      " " +
                      moment().format("MMM-D-YYYY") +
                      "_scorecard.pdf"
                    }
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download PDF"
                    }
                  </PDFDownloadLink>
                </div>
              )}
            </ModalBody>
          </Modal>

          {this.state.relevantProjects.length > 0 ? (
            <>
              <Row>
                <Col xs={12} md={8} lg={8} xl={8}>
                  <h1 className="page-title">
                    Broker <span className="fw-semi-bold">Scorecard</span>
                  </h1>
                </Col>

                <Col xs={12} md={4} lg={4} xl={4}>
                  <div className="pb-xlg h-100 mb-0 exportAndBack">
                    <ul>
                      <li>
                        <Button
                          className="mb-xs mr-xs btn-block btn-primary "
                          onClick={this.redirectToDashboard.bind(this)}
                          color="primary"
                        >
                          Back to Dashboard
                        </Button>
                      </li>

                      <li>
                        <Button
                          className="mb-xs mr-xs btn-block btn-primary "
                          onClick={this.exportBox.bind(this)}
                          color="primary"
                        >
                          Export
                        </Button>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
              <div>
                <Row>
                  <Col lg={6} xs={12}>
                    <Widget>
                      <div className="widget-top-overflow text-white profileBorderBottom">
                        <div className="height-150 overflow-hidden bg-white">
                          <BrokerLineChart
                            ref={this.lineChartRef}
                            data={relevantProjects}
                          />
                        </div>
                      </div>
                      <Row>
                        <Col
                          md={6}
                          xs={12}
                          className="text-center profileBorderRight"
                        >
                          <div className="mt-5">
                            <h5 className="fw-normal">
                              <span className="fw-semi-bold">
                                {subjectName}
                              </span>
                            </h5>
                            {subjectInfo.title ? (
                              <p>{subjectInfo.title}</p>
                            ) : null}
                            <div className="mt-lg">
                              <ul className={cx(s.profileContacts, "mt-sm")}>
                                {subjectInfo.phonenumber ? (
                                  <li>
                                    <i className="fa fa-lg fa-phone fa-fw mr-2" />
                                    <button className="btn-link">
                                      {" "}
                                      {subjectInfo.phonenumber}{" "}
                                    </button>
                                  </li>
                                ) : null}
                                {subjectInfo.email ? (
                                  <li>
                                    <i className="fa fa-lg fa-envelope fa-fw mr-2" />
                                    <button className="btn-link">
                                      {" "}
                                      {subjectInfo.email}{" "}
                                    </button>
                                  </li>
                                ) : null}
                                {subjectInfo.serviceline ? (
                                  <li>
                                    <span className="fw-semi-bold">
                                      Service Line:{" "}
                                    </span>{" "}
                                    {subjectInfo.serviceline}{" "}
                                  </li>
                                ) : null}
                                {subjectInfo.market ? (
                                  <li>
                                    <span className="fw-semi-bold">
                                      Market:{" "}
                                    </span>{" "}
                                    {subjectInfo.market[0].marketname}{" "}
                                  </li>
                                ) : null}
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col md={6} xs={12}>
                          <div>
                            <p className="lead mt-lg">Frequent team members</p>
                            <ul>
                              {teammates.map((member, index) => (
                                <li key={index}>
                                  <button
                                    className="btn-link d-inline-block"
                                    onClick={() =>
                                      this.handleMemberClick(member)
                                    }
                                  >
                                    {member.name}
                                  </button>
                                  <div className="float-right d-inline-block text-right">
                                    {member.number} Opp(s).
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Col>
                      </Row>
                    </Widget>
                    <BrokerPerformance
                      ref={this.statusRef}
                      data={relevantProjects}
                      brokerName={subjectName}
                    />
                  </Col>
                  <Col lg={6} xs={12}>
                    <Row>
                      <Col xs={12} xl={4} md={6}>
                        <div className="pb-xlg h-100">
                          <Widget
                            className="mb-0 h-100"
                            close
                            bodyClass="mt-lg"
                            title={
                              <h5>
                                <span className="fw-semi-bold">Total</span>{" "}
                                Pursuits
                              </h5>
                            }
                          >
                            <div className="d-flex justify-content-between align-items-center mb h3">
                              <h2 style={{ fontSize: "1.5rem" }}>
                                {" "}
                                {relevantProjects.length}{" "}
                              </h2>
                            </div>
                          </Widget>
                        </div>
                      </Col>
                      <Col xs={12} xl={4} md={6}>
                        <div className="pb-xlg h-100">
                          <Widget
                            bodyClass="mt"
                            close
                            className="mb-0 h-100"
                            title={
                              <h5>
                                Current{" "}
                                <span className="fw-semi-bold">Win Rate</span>
                              </h5>
                            }
                          >
                            <div className="d-flex justify-content-between align-items-center mb h3">
                              <h2 style={{ fontSize: "1.5rem" }}>
                                {" "}
                                {winrate}%
                              </h2>
                              <i className="la la-arrow-right text-success rotate-315" />
                            </div>
                          </Widget>
                        </div>
                      </Col>

                      <Col xs={12} xl={4} md={6}>
                        <div className="pb-xlg h-100">
                          <Widget
                            bodyClass="mt"
                            close
                            className="mb-0 h-100"
                            title={
                              <h5>
                                Total{" "}
                                <span className="fw-semi-bold">SF Won</span>
                              </h5>
                            }
                          >
                            <div className="d-flex justify-content-between align-items-center mb h3">
                              <h2 style={{ fontSize: "1.5rem" }}>
                                {" "}
                                {wonProjects
                                  .reduce(
                                    (a, { pursuitsize }) => a + pursuitsize,
                                    0
                                  )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                sf
                              </h2>
                            </div>
                          </Widget>
                        </div>
                      </Col>
                    </Row>
                    <IndustryChart
                      ref={this.industryRef}
                      data={relevantProjects}
                      companydata={clientlist}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Widget className="mb-0 h-100" bodyClass="mt-lg">
                      <Dynamic data={relevantProjects} />
                    </Widget>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <Loading />
          )}
        </ErrorPage>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    marketdata: state.projects.marketdata,
  };
}

export default withRouter(connect(mapStateToProps)(Scorecard));
