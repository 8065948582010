import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import axios from "axios";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
var moment = require('moment');


class RevenueChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      opps: []
    }
  }

  static defaultProps = {
    data: [],
    isReceiving: false
  };


  chartData = () => {
      const { nationallinechartdata } = this.props;
      // Grab the data from props
      let workingdata = nationallinechartdata

      // Create initial values
      let workingobjects = [
        {
          name: 'Total',
          data: []
        }
      ]
      let currentmarket = ""
      let ticks = []
      let series = []

    // DATA LOOP through each item- currently structured as {marketname:foo, data:[{date:foo, count:foo},{date:foo, count:foo}]}
    workingdata.forEach(item => {
      // Intitial values
      let marketname = item.marketname
      let array = item.data

      //If the object is not the "Total" data object
      if(!(marketname.toLowerCase() == "total")){
        let subjectdata = []
        // Create new market object
        let newitem =
          {
            name: item.marketname,
            data:[]
          }
        // Clean data for date
        item.data.forEach(thedata => {
          // Convert to UTC Date
          let datecode = moment(thedata.date, 'MMM DD, YYYY');
          datecode = moment(datecode).format('MMM DD, YYYY');
          let date = moment.utc(datecode, 'MMM DD, YYYY').valueOf();
          let count = Number(thedata.count)
          // Create plot object
          let finaldata = {
            x: date,
            y: count
          }
          subjectdata.push(finaldata)
        })
        newitem.data = subjectdata

        workingobjects.push(newitem)
      }
      //If the object is not the "Total" data object
      else{
        let subjectdata = []
        // Clean the Total Data object
        item.data.forEach(thedata => {
          let datecode = moment(thedata.date, 'MMM DD, YYYY');
          datecode = moment(datecode).format('MMM DD, YYYY');
          let date = moment.utc(datecode, 'MMM DD, YYYY').valueOf();
          let count = Number(thedata.count)
          let finaldata = {
            x: date,
            y: count
          }
          subjectdata.push(finaldata)
        })
        workingobjects[0].data = subjectdata
      }
    }); // END DATA LOOP

      // CLEAN OBJECT PARSE
      workingobjects.forEach(marketobject => {

        let thename = marketobject.name;
        let tabledata = marketobject.data.map(({date, data}) => [date, data]);
        // object dates and data are being converted to arrays
        let ydata = marketobject.data.map(({data}) => [data]);
        let newchartobject;

        if(thename === 'Total'){
          let newchartobject = {
            name: thename,
            data: marketobject.data,
            color: 'rgba(26,134,208,.2)',
            type: 'areaspline',
            fillOpacity: 0.2,
            lineWidth: 0
          };
          series.push(newchartobject)
        } else{
          let newchartobject = {
            type: 'spline',
            name: thename,
            data: marketobject.data
          };
          series.push(newchartobject)
        }
      })

     ticks = workingobjects[0].data.map(({date}) => [new Date(date)]);


    return {
      ...this.ticks,
      ...this.chartOptions,
      series
    }
  }

  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      zoomType: 'x',
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      enabled: true,
    
        chartOptions: {
          title: {
            text: null
          }
        }
  
    },
    legend: {
      enabled: true,
      verticalAlign: 'bottom',
      layout: 'horizontal',
      // floating: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    rangeSelector: {
     selected: 2,
     buttons: [{
       type: 'month',
       count: 1,
       text: '1m'
     }, {
       type: 'month',
       count: 3,
       text: '3m'
     }, {
       type: 'ytd',
       text: 'YTD'
     }, {
       type: 'year',
       count: 1,
       text: '1y'
     }, {
       type: 'all',
       text: 'All'
     }]
   },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        turboThreshold: 10000000,
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        // pointInterval: 3600000 * 25, // every day
        pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${this.series.name} at ${this.y.toFixed(2)}</span>`;
          }
        }
      },
    }
  };


  render() {

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Market Opportunities <span className="fw-semi-bold">By Month</span>
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact constructorType={"stockChart"} highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}


export default withRouter(RevenueChart);
