let OKTA_CLIENT_ID = "0oa1jktzrofqZEru90h8";

let OKTA_ISSUER = "https://applogin-uat.jll.com";
const OKTA_TESTING_DISABLEHTTPSCHECK =
  process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false;
const OKTA_REDIRECT_URI = `${window.location.origin}/login/callback`;

export default {
  oidc: {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    consumerclaim: "oktaauthserver=apim;jllsystemuser=apimteamtest;",
    redirectUri: OKTA_REDIRECT_URI,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
