import React from "react";
import { connect } from "react-redux";
import { Button, Row, Col, Label } from "reactstrap";
import Select from "react-select";

import s from "./Profile.module.scss";

var moment = require("moment");

class BrokerScorecardV2 extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={s.root}>
        <Row>
          <Label md="6" className="pb-md h-100" for="default-select">
            Select a broker to see their scorecard
          </Label>
          <Col md="8" className="pb-md h-100">
            <Select
              ref="brokerSelector"
              className="selectCustomization"
              options={this.props?.brokeroptions}
              onChange={(e) => this.props.selectBrokerforCard(e)}
            />
          </Col>
          <Col md="4" className="pb-md h-100 scorecard-button">
            <Button
              color={"primary"}
              className="btn btn-block btn-primary "
              onClick={this.props.chooseBrokerforCard.bind(this)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    user: state.authentication.userdata,
    cleandata: state.projects.cleandata,
    projects: state.projects.projects,
    blist: state.projects.blist,
    marketdata: state.projects.marketdata,
  };
}

export default connect(mapStateToProps)(BrokerScorecardV2);
