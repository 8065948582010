import React from "react";
import { Row, Col } from "reactstrap";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

const columns = [
  {
    dataField: "member",
    text: "Name",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "team",
    text: "Team",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "total_member_score",
    text: "Total Score",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
  {
    dataField: "opportunities",
    text: "# Opportunities",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
  {
    dataField: "wins",
    text: "# Wins",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
];

const adoptionColumns = [
  {
    dataField: "member",
    text: "Name",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "team",
    text: "Team",
    headerClasses: "gamificationTableHeader",
    classes: "gamificationTableCell",
  },
  {
    dataField: "opportunities",
    text: "# Opportunities",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
  {
    dataField: "wins",
    text: "# Wins",
    headerClasses: "gamificationTableHeader",
    sort: true,
    classes: "gamificationTableCell",
  },
];

const defaultSortedBy = [
  {
    dataField: "total_member_score",
    order: "desc",
  },
];

const defaultAdoptionSortedBy = [
  {
    dataField: "opportunities",
    order: "desc",
  },
];

const TeamScorecard = (props) => {
  let scores = props.scores;
  let formattedData = scores.map((score) => {
    return {
      opportunities: score.opportunities,
      member: score.member?.fullname,
      team: score.team?.name,
      total_member_score: score.total_member_score,
      wins: score.wins,
    };
  });

  formattedData = formattedData.filter(
    (item) => item.team === props.team?.name
  );
  let totalScore = 0;
  if (formattedData.length > 0) {
    totalScore = formattedData.reduce(
      (accum, item) => accum + item.total_member_score,
      0
    );
  }

  return (
    <>
      <Row>
        <Col lg={7}>
          <h2 className="scorecardHeader">{props.team.name}</h2>
        </Col>

        <Col lg={5}>
          {props.eventDetails?.type === "laterAddition" ? (
            <h3
              className="text-right"
              style={{
                marginTop: "10px",
                marginBottom: "20px",
              }}
            >
              {props.team?.team_score} <small>Total Opportunities</small>
            </h3>
          ) : (
            <img className="teamScorecardLogo" src={props.team?.logo} />
          )}
        </Col>
      </Row>
      <Row className="scorecardTableTeamRow">
        <Col lg={12} className="pl-0 pr-0">
          {props.scores ? (
            <BootstrapTable
              wrapperClasses="table-responsive"
              bordered={false}
              defaultSorted={
                props.eventDetails?.type !== "adoption"
                  ? defaultSortedBy
                  : defaultAdoptionSortedBy
              }
              keyField="id"
              data={formattedData}
              columns={
                props.eventDetails?.type !== "adoption"
                  ? columns
                  : adoptionColumns
              }
            />
          ) : null}
        </Col>
      </Row>
      <Row className="scorecardBottomRow"></Row>
    </>
  );
};

export default TeamScorecard;
