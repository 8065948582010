import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { DateRangePicker } from "react-date-range";
import { Button } from "reactstrap";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "30%",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

function DateRangeModal({
  dateModalOpen,
  dateRange,
  setDateRange,
  dateRangeFilter,
  setDateModalOpen,
}) {
  const classes = useStyles();

  return (
    <>
      <Modal
        open={dateModalOpen}
        className={classes.paper}
        onClose={(e) => setDateModalOpen(false)}
      >
        <div
          style={{
            backgroundColor: "white",
            borderRadius: "4px",
            padding: "5%",
            width: "100%",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              fontSize: "1.4em",
            }}
          >
            Time range filter:
          </span>
          <div
            className="dateRangeButton mt-md mb-lg"
            onClick={(e) => dateRangeFilter("All Time")}
          >
            All Time
          </div>
          <div
            className="dateRangeButton mt-sm mb-lg"
            onClick={(e) => dateRangeFilter("Last Year")}
          >
            Last Year
          </div>
          <div
            className="dateRangeButton mt-sm mb-lg"
            onClick={(e) => dateRangeFilter("This Year")}
          >
            This Year
          </div>
        </div>
        {/* <DateRangePicker
          onChange={(item) => setDateRange([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={dateRange}
          direction="horizontal"
        />
        <Button>All Time</Button>
        <Button>Last Year</Button>
        <Button>This Year</Button> */}
      </Modal>
    </>
  );
}

export default DateRangeModal;
