import React from "react";
// import Highcharts from "highcharts";
import { render } from "react-dom";
import Highcharts from "highcharts/highmaps";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import proj4 from "proj4";
import Widget from "../../../../components/Widget/Widget";
import { Col, Row } from "reactstrap";

import mapDataIE from "@highcharts/map-collection/custom/usa-and-canada.geo.json";
import s from "./Vector.module.scss";

// pk.eyJ1Ijoiamxsc2FsZXNlbmFibGVtZW50IiwiYSI6ImNrY251MjlrczBlbzQyc3Fyemp3ajc4eTkifQ.j2GWGP2FPQNd3TyMM8SHFQ

(function (H) {
  H.wrap(H.Chart.prototype, "pan", function (proceed) {
    H.each(this.yAxis, function (axis) {
      axis.fixTo = null;
    });
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  });
})(Highcharts);

highchartsMap(Highcharts); // Initialize the map module

if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

class OpportunityMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      optionsReady: false,
    };
  }

  createMap() {
    let mapOptions = {
      chart: {
        map: "custom/usa-and-canada",
      },
      title: {
        text: " ",
      },
      credits: {
        enabled: false,
      },
      mapNavigation: {
        enabled: true,
        enableButtons: true,
        enableTouchZoom: true,
        buttonOptions: {
          verticalAlign: "bottom",
        },
      },
      tooltip: {
        headerFormat: "",
        pointFormat: "Market: {point.keyword}, Opps: {point.z}",
      },
      series: [
        {
          // Use the gb-all map with no data as a basemap
          name: "Basemap",
          mapData: mapDataIE,
          borderColor: "#bdbdbd",
          nullColor: "#edf0f2",
          showInLegend: false,
        },
        {
          // Specify points using lat/lon
          type: "mapbubble",
          name: "Locations",
          dataLabels: {
            enabled: true,
            format: "{point.keyword}",
          },
          color: "#1a86d0",
          data: this.props.locations,
          cursor: "pointer",
          point: {
            events: {
              click: function () {
                console.log(this.keyword);
              },
            },
          },
        },
      ],
    };

    this.setState({
      locations: this.props.locations,
      mapOptions: mapOptions,
      optionsReady: true,
    });
  }

  componentDidMount() {
    this.createMap();
  }

  componentDidUpdate(prevProps) {
    if (this.props.locations !== prevProps.locations) {
      this.createMap();
    }
  }
  componentWillUnmount() {
    if (this.map) {
      this.map.dispose();
    }
  }

  render() {
    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xlg={12}>
              <h5>
                Opportunities <span className="fw-semi-bold">By Location</span>
              </h5>
            </Col>
          </Row>
        }
      >
        {this.state.optionsReady ? (
          <HighchartsReact
            constructorType={"mapChart"}
            highcharts={Highcharts}
            options={this.state.mapOptions}
          />
        ) : null}
      </Widget>
    );
  }
}

export default OpportunityMap;
