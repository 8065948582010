import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";

class TimerangeStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunities: [],
      wonopps: [],
      lostopps: [],
      newopps: [],
    };
  }

  setOppsandUpdates() {
    let startDate = this.props.dateRange[0];
    let endDate = this.props.dateRange[1];

    startDate = startDate?.set({ hour: 0, minute: 0, second: 0 });
    endDate = endDate?.set({ hour: 23, minute: 59, second: 59 });

    let opps = this.props.opps.filter((item) =>
      moment(item.date_closed, "MM-DD-YYYY hh:mm:ss").isBetween(
        startDate,
        endDate
      )
    );

    let wonopps = opps.filter((item) => item.winloss === "Won");
    let lostopps = opps.filter((item) => item.winloss === "Lost");
    let winrate = (
      (wonopps.length / (wonopps.length + lostopps.length)) *
      100
    ).toFixed(1);
    let tempWon = wonopps.map((item) => ({
      created: item.datecreated,
      closed: item.date_closed,
    }));
    this.setState({
      newopps: this.props.newopps,
      wonopps: wonopps,
      lostopps: lostopps,
      opportunities: this.props.opps,
      winrate: winrate,
    });
  }

  componentDidMount() {
    this.setOppsandUpdates();
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.opps != prevProps.opps ||
      prevProps.dateRange !== this.props.dateRange
    ) {
      this.setOppsandUpdates();
    }
  };

  render() {
    return (
      <>
        <Row>
          {this.state.opportunities.length > 0 ? (
            <>
              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    className="mb-0 h-100"
                    bodyClass="mt-lg"
                    title={
                      <h5>
                        <span className="fw-semi-bold">New</span> Pursuits
                        <Tooltip
                          title={
                            <div style={{ fontSize: "12px" }}>
                              New opps entered within time period.
                            </div>
                          }
                          placement="top"
                        >
                          <span className="float-right">
                            <i class="las la-info-circle"></i>
                          </span>
                        </Tooltip>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.newopps.length.toLocaleString()}
                      </h2>
                    </div>
                  </Widget>
                </div>
              </Col>
              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    className="mb-0 h-100"
                    title={
                      <h5>
                        <span className="fw-semi-bold">Wins </span>Reported
                        <Tooltip
                          title={
                            <div style={{ fontSize: "12px" }}>
                              Wins reported within time period.
                            </div>
                          }
                          placement="top"
                        >
                          <span className="float-right">
                            <i class="las la-info-circle"></i>
                          </span>
                        </Tooltip>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.wonopps.length.toLocaleString()}
                      </h2>

                      <i className="la la-arrow-right text-success rotate-315" />
                    </div>
                  </Widget>
                </div>
              </Col>

              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    className="mb-0 h-100"
                    title={
                      <h5>
                        <span className="fw-semi-bold">Losses</span> Reported
                        <Tooltip
                          title={
                            <div style={{ fontSize: "12px" }}>
                              Losses reported within time period.
                            </div>
                          }
                          placement="top"
                        >
                          <span className="float-right">
                            <i class="las la-info-circle"></i>
                          </span>
                        </Tooltip>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {this.state.lostopps.length.toLocaleString()}
                      </h2>
                      <i className="la la-arrow-right text-danger rotate-45" />
                    </div>
                  </Widget>
                </div>
              </Col>

              <Col xs={12} xl={3} md={6}>
                <div className="pb-xlg h-100">
                  <Widget
                    bodyClass="mt"
                    className="mb-0 h-100"
                    title={
                      <h5>
                        Date Range{" "}
                        <span className="fw-semi-bold">Win Rate</span>
                        <Tooltip
                          title={
                            <div style={{ fontSize: "12px" }}>
                              Wins reported / Concluded opps in given time
                              period.
                            </div>
                          }
                          placement="top"
                        >
                          <span className="float-right">
                            <i class="las la-info-circle"></i>
                          </span>
                        </Tooltip>
                      </h5>
                    }
                  >
                    <div className="d-flex justify-content-between align-items-center mb h3">
                      <h2 style={{ fontSize: "2.1rem" }}>
                        {" "}
                        {this.state.winrate} %
                      </h2>
                      {/* <h2 style={{fontSize: '2.1rem'}}> {(((this.props.rawopps.filter(object => object.winloss === "Won").length) / (this.props.rawopps.filter(object => object.winloss === "Won" || object.winloss === "Lost"  ).length)) * 100).toFixed(1)} %</h2> */}
                      <i className="la la-arrow-right text-success rotate-315" />
                    </div>
                  </Widget>
                </div>
              </Col>
            </>
          ) : null}
        </Row>
      </>
    );
  }
}

export default TimerangeStats;
