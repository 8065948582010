import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class SizeThreshold extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
      simpleSelectDropdownValue: 'Choose number displayed',

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  getThreshold(opps){
    let thresholds = []
    
    opps.forEach(item =>{
      let currentthreshold = "Unknown"
      let id = 0
   
      if(item.pursuitsize <= 9999){
        id = 1
        currentthreshold = "0-10,000 SF"
      }else if(item.pursuitsize >= 0 && item.pursuitsize <= 10000){
        id = 2
        currentthreshold = "10,000-25,000 SF"
      }else if(item.pursuitsize >= 10000 && item.pursuitsize <= 24999){
        id = 3
        currentthreshold = "25,000-50,000 SF"
      }else if(item.pursuitsize >= 25000 && item.pursuitsize <= 49000){
        id = 4
        currentthreshold = "50,000-100,000 SF"
      }else if(item.pursuitsize >= 100000){
        id = 5
        currentthreshold = "100,000+ SF"
      }

      if(!(currentthreshold === "Unknown")){
        let foundthreshold = thresholds.find(element => element.threshold === currentthreshold)

        if(!(foundthreshold === undefined)){
          foundthreshold.count += 1
        }else{
            
            let newobj = {
              id: id,
              threshold: currentthreshold,
              count: 1
            }
            thresholds.push(newobj)
        }
   
      }
    })

    return thresholds
  }
    

  chartData = () => {

  let data = this.getThreshold(this.props.data)
  data = data.sort((a,b) => a.id - b.id)
  data = data.map(({threshold, count}) => [threshold, count])


  var config = {
    chart: {
        type: 'column',
        height: 250,
    },
    exporting: {
      chartOptions: {
        title: {
          text: null
        }
      }
    },
    title: {
      text: ''
  },
    xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                fontSize: '10px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
    },
    credits: {
      enabled: false
    },
    yAxis: {
        min: 0,

        title: {
            text: 'Opportunities'
        },
        labels: {
          enabled: true,
          style: {
            color: "#000000"
          }
        }
    },
    legend: {
        enabled: false
    },
    tooltip: {
        pointFormat: 'Size threshold for: <b>{point.y:.1f}</b> opportunities'
    },
    series: [{
        name: 'Opportunities',
        data: data,
        dataLabels: {
          enabled: true
      }
    }],
   
};


    // this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
    return config
  };



  changeSelectDropdownSimple = (e) => {
    var chart = this.refs.chartref.chart;
    this.setState({ simpleSelectDropdownValue: parseFloat(e.currentTarget.textContent) });

    chart.update(this.chartData())
  
  }

  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
              Opportunities<span className="fw-semi-bold"> By Size</span> 
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
        
      </Widget>
    );
  }
}

export default withRouter(SizeThreshold)
