import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Switch, Route, withRouter, Redirect } from "react-router";
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { TransitionGroup, CSSTransition } from "react-transition-group";
import FourohFour from "../../pages/fourohfour";
import Hammer from "rc-hammerjs";
import SellerAnaylsis from "../../pages/BrokerScorecardAnalysis/BrokerScorecardAnalysis";
import EmailReports from "../../pages/emailreports/EmailReports";
import EmailInfo from "../../pages/emailreports/emaillogs/EmailInfo";
import MarketSelector from "../../pages/marketselector/marketselector";
import Dashboard from "../../pages/dashboardvtwo/Dashboard";
import BrokerScorecardv2 from "../../pages/scorecardv2/BrokerScorecardv2";
import OpportunityCardHolder from "../../pages/opportunitycardv2/OpportunityCardHolder";
import CalendarHolder from "../../pages/extra/calendarv2/Calendarholder";
import { SidebarTypes } from "../../reducers/layout";
import TimelineWorkflow from "../../pages/workflow/TimelineWorkflow";
import RefreshPortal from "../../pages/refreshemailportal/RefreshPortal";
import Header from "../Header";
import Sidebar from "../Sidebar";
import {
  openSidebar,
  closeSidebar,
  changeActiveSidebarItem,
  toggleSidebar,
} from "../../actions/navigation";
import s from "./Layout.module.scss";

import { DashboardThemes } from "../../reducers/layout";

class Layout extends React.Component {
  static propTypes = {
    sidebarStatic: PropTypes.bool,
    sidebarOpened: PropTypes.bool,
    dashboardTheme: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    sidebarStatic: false,
    sidebarOpened: false,
    dashboardTheme: DashboardThemes.DARK,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentlocation: "",
      urlchecked: false,
    };
    this.handleSwipe = this.handleSwipe.bind(this);
  }

  componentDidMount() {
    const staticSidebar = JSON.parse(localStorage.getItem("staticSidebar"));
    if (staticSidebar && window.innerWidth > 768) {
      this.props.dispatch(toggleSidebar());
    } else if (this.props.sidebarOpened) {
      setTimeout(() => {
        this.props.dispatch(closeSidebar());
        this.props.dispatch(changeActiveSidebarItem(null));
      }, 2500);
    }
    this.handleResize();
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  handleResize() {
    if (window.innerWidth <= 768 && this.props.sidebarStatic) {
      this.props.dispatch(toggleSidebar());
    }
  }

  handleSwipe(e) {
    if ("ontouchstart" in window) {
      if (e.direction === 4) {
        this.props.dispatch(openSidebar());
        return;
      }

      if (e.direction === 2 && this.props.sidebarOpened) {
        this.props.dispatch(closeSidebar());
        return;
      }
    }
  }

  render() {
    const {
      projects,
      cleandataready,
      marketdata,
      receivedUserMarketOpps,
      userdataready,
      marketdataready,
    } = this.props;

    return (
      <div
        className={[
          s.root,
          this.props.sidebarStatic ? `${s.sidebarStatic}` : "",
          !this.props.sidebarOpened ? s.sidebarClose : "",
          "se-dashboard",
          `dashboard-${
            this.props.sidebarType === SidebarTypes.TRANSPARENT
              ? "light"
              : this.props.dashboardTheme
          }`,
        ].join(" ")}
      >
        <Sidebar />
        <div className={s.wrap}>
          {userdataready ? <Header /> : null}

          <Hammer onSwipe={this.handleSwipe}>
            {userdataready && marketdataready ? (
              <>
                <main className={s.content}>
                  <TransitionGroup>
                    <CSSTransition
                      key={this.props.location}
                      classNames="fade"
                      timeout={200}
                    >
                      <Switch>
                        <Route
                          path="/app/reporting/opportunity/:oppid?"
                          component={OpportunityCardHolder}
                        />
                        <Route
                          path="/app"
                          exact
                          render={() => <Redirect to="/app/markets" />}
                        />
                        <Route
                          path="/app/email/emailreports"
                          component={EmailReports}
                        />
                        <Route
                          path="/app/email/refreshportal"
                          component={RefreshPortal}
                        />
                        <Route
                          path="/app/email/emailsubscribed/:emailid?"
                          component={EmailInfo}
                        />
                        <Route
                          path="/app/reporting/opportunity/:oppid?"
                          component={OpportunityCardHolder}
                        />
                        <Route path="/app/404" exact component={FourohFour} />
                        <Route
                          path="/app/profile/emailreports"
                          component={EmailReports}
                        />
                        <Route
                          path="/app/dashboard/:market"
                          exact
                          component={Dashboard}
                        />
                        <Route
                          path="/app/dashboard/userdashboard/:customdashboardid"
                          exact
                          component={Dashboard}
                        />
                        <Route
                          path="/app/dashboard/report/:reporttype/:marketreportid"
                          exact
                          component={Dashboard}
                        />
                        <Redirect from="/app/dashboard" to="/app/markets" />
                        {/* <Route path="/app/updates" exact component={SearchHolder} /> */}
                        <Route
                          path="/app/markets"
                          exact
                          component={MarketSelector}
                        />
                        <Route
                          path="/app/reporting/calendar"
                          exact
                          component={CalendarHolder}
                        />
                        <Route
                          path="/app/timeline"
                          component={TimelineWorkflow}
                        />
                        <Route
                          path="/app/sellerdeepdive"
                          component={SellerAnaylsis}
                        />
                        <Route
                          path="/app/reporting/brokerscorecard/:brokername?"
                          component={BrokerScorecardv2}
                        />
                        <Redirect from="/*" to="/app/404" />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                  <footer className={s.contentFooter}>
                    For support, please contact Michael Gugliuzza -{" "}
                    <a
                      href="mailto:michael.gugliuzza@am.jll.com"
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      michael.gugliuzza@am.jll.com
                    </a>
                  </footer>
                </main>
                {marketdata.length == 0 ? (
                  <Modal size="lg" isOpen={true}>
                    <ModalHeader>Access required</ModalHeader>
                    <ModalBody className="bg-white">
                      Your account has not been granted access to view market
                      opportunity data. Please contact{" "}
                      <a href="mailto:michael.gugliuzza@am.jll.com?subject=Access to Sales Enablement Dashboard&cc=megan.cronkhite@am.jll.com &body=I would like to request acess to the Sales Enablement Dashboard.">
                        Michael Gugliuzza
                      </a>{" "}
                      to receive access. In order to receive approval, please CC
                      your market's Sales Enablement lead.
                    </ModalBody>
                    <ModalFooter>
                      <a href="mailto:michael.gugliuzza@am.jll.com?subject=Access to Sales Enablement Dashboard&cc=megan.cronkhite@am.jll.com &body=I would like to request acess to the Sales Enablement Dashboard.">
                        <Button color="primary">Email</Button>
                      </a>
                    </ModalFooter>
                  </Modal>
                ) : null}
              </>
            ) : (
              <div className="loader animated fadeIn handle initialLoader">
                <span className="spinner">
                  <i className="fa fa-spinner fa-spin" />
                </span>
                <Col xs={12} md={12} lg={12} xl={12}>
                  Loading Data...
                </Col>
              </div>
            )}
          </Hammer>
        </div>

        {this.props.pageLoading ? (
          <div className="loadingScreen">
            <Modal size="lg" className="loadingScreenModal" isOpen={true}>
              <ModalBody>
                <div className="loader animated fadeIn handle initialLoader">
                  <span className="spinner">
                    <i className="fa fa-spinner fa-spin" />
                  </span>
                  <Col xs={12} md={12} lg={12} xl={12}>
                    Loading Page...
                  </Col>
                </div>
              </ModalBody>
            </Modal>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    pageLoading: store.layout.pageLoading,
    token: store.authentication.token,
    receivedUserMarketOpps: store.projects.receivedUserMarketOpps,
    marketdataready: store.projects.marketdataready,
    cleandataready: store.projects.cleandataready,
    marketdata: store.projects.marketdata,
    userdataready: store.authentication.userdataready,
    sidebarOpened: store.navigation.sidebarOpened,
    sidebarStatic: store.navigation.sidebarStatic,
    dashboardTheme: store.layout.dashboardTheme,
    sidebarType: store.layout.sidebarType,
  };
}

export default withRouter(connect(mapStateToProps)(Layout));
