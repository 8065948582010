import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";

import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';

var moment = require('moment');
exporting(Highcharts);
exportData(Highcharts);

export default class WeekOverWeek extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  chartData = () => {

      const { data, updates } = this.props;

      // Workingobject will contain all servicelines and dates in addition to the total
      let workingobjects = [
        {
          name: 'Won',
          data:[],
          color: '#21AE8C',
          },
          {
          name: 'Lost',
          data: [],
          color: '#FD5F00',
          },
          {
          name: 'Open',
          data:[],
          color: '#1A86D0',
          }
      ]
      let currentmarket = ""
      let ticks = []
      let series = [
        
    ]

    data.forEach(item => {
      let foundupdate = updates.find(update => update.opportunityid === item.opportunityid)
      let date = ""

      if(!(foundupdate == undefined)){
        date = moment(foundupdate.timestamp, 'MM-DD-YYYY hh:mm:ss');
        date = date.startOf('week')
      }else{
        date =  moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss');
        date = date.startOf('week')
      }


      // let date = new Date(item.datecreated);

      let shortdate = moment(date).format('MMM DD, YYYY');
      // let shortdate =
      //
      date = moment.utc(shortdate, 'MMM DD, YYYY').valueOf();

      // date = Date(date);
      //Eventual settings for objects
      let chartobjects = []

      // Capture the matching serviceline obj in workingobjects or return null
      let currentstatus = "Not Specified"

      if(item.winloss){
        let findcurrentstatus = item.winloss
        if (findcurrentstatus === "Pending"){
          findcurrentstatus = "Open"
        }
        currentstatus = findcurrentstatus
      }

      let foundstatus = workingobjects.find(element => element.name === currentstatus)

      // Either add to the existing object or create a new serviceline object within workingobjects
      if(!(foundstatus === undefined)){

        let index = workingobjects.findIndex(element => element.name === currentstatus)
        let marketfounddate = workingobjects[index].data.find(element => element.x === date)

        if(!(marketfounddate === undefined)){
          marketfounddate.y += 1
        } else {
          let newmarketdateitem = {
            x: date,
            y: 1
          }
          foundstatus.data.push(newmarketdateitem)
        }
      } else {

        let newitem =
          {
            name: currentstatus,
            data: [{
              x: date,
              y: 1
            }]
          }
        workingobjects.push(newitem)
      }
      })


      workingobjects.forEach(marketobject => {

        let thename = marketobject.name;
        let tabledata = marketobject.data.map(({date, data}) => [date, data]);
        // object dates and data are being converted to arrays
        let ydata = marketobject.data.map(({data}) => [data]);
        let newchartobject;

        let sorteddata = marketobject.data.sort((a,b) => a.x - b.x)
        if(marketobject.color){
          newchartobject = {
            type: 'column',
            name: thename,
            color: marketobject.color,
            data: sorteddata
          };
        }else{
          newchartobject = {
            type: 'column',
            name: thename,
            data: sorteddata
          };
        }
         
          series.push(newchartobject)
        
      })
    
    //  ticks = workingobjects[0].data.map(({date}) => [new Date(date)]);
      
    
    return {
      ...this.ticks,
      ...this.chartOptions,
      series
    }
  }




  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    
    credits: {
      enabled: false
    },
    chart: {
      height: 350,
      type: 'column',
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      chartOptions: {
        title: {
          text: null
        }
      }
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      stackLabels: {
        enabled: true,
        style: {
            fontWeight: 'bold',
            color: (Highcharts.theme && Highcharts.theme.textColor) || 'gray'
        }
    },
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      tickInterval: 24 * 3600 * 1000 * 7,
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
        }
      }
    },
    annotations: {
      visible: false
    },
    stackLabels: {
      enabled: true,
      style: {
          fontWeight: 'bold',
          color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
          ) || 'gray'
      }
  },
  
    plotOptions: {
      series: {
        stacking:'normal',
        dataLabels: {
          enabled: true
      },
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        // pointInterval: 3600000 * 25, // every day
        pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        pointInterval: 24 * 3600 * 1000 * 7,
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${this.series.name} at ${this.y.toFixed(2)}</span>`;
          }
        }
      },
    }
  };


  render() {

    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Opportunity Status <span className="fw-semi-bold">By Week</span>
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact highcharts={Highcharts}  options={this.chartData()} />
      </Widget>
    );
  }
}
