import React, { Component } from 'react';
import {
 
    Col,

  } from 'reactstrap';

class Loading extends Component {
    render() {
        return (
           
                 <div className="loader animated fadeIn handle initialLoader">
              <span className="spinner">
                <i className="fa fa-spinner fa-spin" />
              </span>
              <Col xs={12} md={12} lg={12} xl={12} >
              Retrieving broker info...
              </Col>
            </div>
          
        );
    }
}

export default Loading;