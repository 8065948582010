import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class TechUsedStacked extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  cleanPersonList(person){
    let list =[]
    person.forEach(item => list.push(item.fullname))
    let theperson = list.join(", ")
    return theperson
  }

  cleanmarket(market){
    let list= []
    market.forEach(market=> list.push(market.marketname))
    let themarket = list.join(", ")
    return themarket
  }

  cleantech(tech){
    let list= []
    tech.forEach(techused => {
      if(!(techused.techname === "")){
      list.push(techused.techname)}}
      )
    let thetech = list.join(", ")
    return thetech
  }

  chartData = () => {

    let techlist = []

    const { data } = this.props;

    let cleandata = data.map(project => {
      return {
        id: project.opportunityid,
        techused: `${this.cleantech(project.techused)}`,
        pursuitsize: project.pursuitsize,
        servicelines: project.servicelines
      };
    })

    cleandata.forEach(item => {
      // Convert the string to list
      let currenttech = item.techused.split(', ')
      // Remove blanks
      currenttech = currenttech.filter(te => te); 
      // Create category object for x
      let count = currenttech.length
      // count = count.toString()

      // Search in existing list for an element wth the same name as count
      let foundtech = techlist.find(element => element.name === count)
      
      // If an item matching name is found
      if(!(foundtech === undefined)){
        //For each item in the current list-
        currenttech.forEach(item1 =>{
          // Look for a key with the same name in the found object
          if(foundtech.data[item1]){
            // If found, add one to the value
            foundtech.data[item1] += 1
          }else{
            // Else add the item to the object with value of 1
            foundtech.data[item1] = 1
          }
        })     
      // If there isn't an iteem wiith a matching numeric name
      } else{
        // Create a new object with the numeric count as name
        let newtechaverage = {
          name: count,
          data: {}
        }
        //Add the tools used too that new object
        currenttech.forEach(item2 =>{
          newtechaverage.data["name"] =  count
          newtechaverage.data[item2] =  1
        })
        //Add that new object to the main list
        techlist.push(newtechaverage)
      }
    })

    // techlist.sort((a,b) => parseFloat(b.data.total) - parseFloat(a.data.total))
    //Begin creation of chart data
    let categories = []
    let series = []

// For every item in the techlist {name: 1, data: [{'JTE': 4}]}

// Sort the list
techlist.sort(function(a, b) { 
  return a.name - b.name;
})

let listofobjects = []
techlist.forEach(item => {
  listofobjects.push(item.data)
})


let result = []

// Create an object with all the keys in it
// This will return one object containing all keys the items
if(listofobjects.length > 0){
  let obj = listofobjects.reduce((res, item) => ({...res, ...item}));

  // Get those keys as an listofobjectsay
  let keys = Object.keys(obj);

  // Create an object with all keys set to the default value (0)
  let def = keys.reduce((result, key) => {
    result[key] = 0
    return result;
  }, {});

  // Use object destrucuring to replace all default values with the ones we have
  result = listofobjects.map((item) => ({...def, ...item}));
}


result.every(item => {
  categories.push(item.name)

  for(const thekey in item){
    let foundseries = series.find(element => element.name === thekey)
    // let newseries = {
    //   name: thekey,
    //   data: item.data[]
    // }

    if(!(foundseries === undefined)){
      foundseries.data.push(item[thekey])
    }else{
        if(thekey !== "name"){
            let newseries = {
          name: thekey,
          data:[item[thekey]]
        }
        series.push(newseries)
      }
    }
  }
  // }else{
  //   let newseries = {
  //     // {
  //     //   name: 'Open',
  //     //   data:[],
  //     //   color: '#1A86D0',
  //     //     }
  //   }
  // }


  // series.find(item => (item.name).toLowerCase() === 'wins').data.push(item.data.wins)
  // series.find(item => (item.name).toLowerCase() === 'losses').data.push(item.data.losses)
  // series.find(item => (item.name).toLowerCase() === 'open').data.push(item.data.open)
  return true
})
// this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
return {
  ...this.chartOptions,
  series: series,
  xAxis: {
    title: {
      enabled: true,
      text: '# of Tools Per Opportunity', 
  },
    // min: 0,
    // max: 7,
    // scrollbar: {
    //     enabled: true
    // },
    tickLength: 0,
    categories: categories
  },

}
};

  redirectToScorecard = (e) => {
    console.log(this)
    this.props.history.push("/app/reporting/brokerscorecard/"+e.target.point.category)
  }

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 250,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'column'
    },
    title: false,
    exporting: {
      enabled: true,
        chartOptions: {
          title: {
            text: null
          }
        }
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: {
        enabled: true,
        text: '# Times Tool Used', 
    },
      labels: {
        style: {
          color: "#000000"
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
      stackLabels: {
        enabled: true,
        style: {
            fontWeight: 'bold',
            color: ( // theme
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || 'gray'
        }
    }
    },
    
    annotations: {
      visible: false
    },
    plotOptions: {
    series: {
    	stacking:'normal',
      dataLabels: {
                       enabled: true
                 }
    }
  },
  };




  // click: function(e) {
  //   let name = e.target.point.category
  //   console.log(e.target.point.category)
  //   this.redirectToScorecard(e.target.point.category)
  // }

  // click() {
  //   // (this.props.history.push("/app/brokerscorecard/"+this.series.name)).bind(this)
  //   this.redirectToScorecard(this.series.name)
  //
  //   console.log(this.series.name)
  //   console.log(this.category)
  // }



  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                <span className="fw-semi-bold">Average Number</span> Of Tools Used
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
      </Widget>
    );
  }
}

export default withRouter(TechUsedStacked)
