import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
var moment = require('moment');


export default class TechUsedIncreasePercent extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  chartData = () => {
      const { data } = this.props;
      
      let sorteddata = data.sort((a,b) => moment(a.datecreated, 'MM-DD-YYYY hh:mm:ss') - moment(b.datecreated, 'MM-DD-YYYY hh:mm:ss'))

      // Workingobject will contain all servicelines and dates in addition to the total
  
      let ticks =[]
      let categories = []
      let currentsl = ""
      let series = [{
        name: 'Tech Adoption %',
        data: []
      },]
    
      // Quarterly sectioning
      if(this.props.timerange === "Quarter"){
        let dataToQuarters = sorteddata.map(item => ({quarter: moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss').format('[Q]Q-Y'), tools: item.techused}))
        
        let finalQuarterValues = dataToQuarters.reduce(function(pV, cV, cI){
          let foundob = pV.find(item=> item.quarter === cV['quarter'])
          if(foundob){
            foundob.total += 1
            if(cV['tools'].length > 0){
              foundob.techadopted += 1
            }
          }else{
            let newob = {
              quarter: cV['quarter'],
              techadopted: 0,
              total: 1
            }
            if(cV['tools'].length > 0){
              newob.techadopted = 1
            }
            pV.push(newob)
          }
          return pV;
        }, []);

  
        let finalQuarterData = finalQuarterValues.map((item,index, array) => {
          if(index > 0){
            return {'quarter':item.quarter, 'percentChange':parseFloat(((item.techadopted / item.total) * 100).toFixed(2))}
          }
        })
     
        finalQuarterData = finalQuarterData.filter(Boolean)
      
      finalQuarterData.forEach(slobject => {
            if(slobject.percentChange < 1000){
              categories.push(slobject.quarter)
              series[0].data.push(slobject.percentChange)
            }else{
              categories.push(slobject.quarter)
              series[0].data.push(0)
            }
        })
      }else if(this.props.timerange === "Year"){
        // Yearly sectioning
          let dataToYears = sorteddata.map(item => ({year: moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss').format('Y'), tools: item.techused}))   
          let finalYearValues = dataToYears.reduce(function(pV, cV, cI){
            let foundob = pV.find(item=> item.year === cV['year'])
            if(foundob){
              foundob.total += 1
            if(cV['tools'].length > 0){
              foundob.techadopted += 1
            }
            }else{
              let newob = {
                year: cV['year'],
                techadopted: 0,
              total: 1
            }
            if(cV['tools'].length > 0){
              newob.techadopted = 1
            }
              pV.push(newob)
            }
            return pV;
          }, []);
          let finalYearData = finalYearValues.map((item,index, array) => {
            if(index > 0){
              return {'year':item.year, 'percentChange':parseFloat(((item.techadopted / item.total) * 100).toFixed(2))}
        } else{
        return {'year':item.year, 'percentChange':0}
        }
          })
          finalYearData = finalYearData.filter(Boolean)

        finalYearData.forEach(slobject => {
              if(slobject.percentChange < 1000){
                categories.push(slobject.year)
                series[0].data.push(slobject.percentChange)
              }else{
                categories.push(slobject.year)
                series[0].data.push(0)
              }
          })
      }else if(this.props.timerange === "Month"){
        // Monthly sectioning
          let dataToMonths = sorteddata.map(item => ({month: moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss').format('MMM-Y'), tools: item.techused}))
              
          let finalMonthValues = dataToMonths.reduce(function(pV, cV, cI){
            let foundob = pV.find(item=> item.month === cV['month'])
            if(foundob){
              foundob.total += 1
            if(cV['tools'].length > 0){
              foundob.techadopted += 1
            }
            }else{
              let newob = {
                month: cV['month'],
                techadopted: 0,
                total: 1
              }
              if(cV['tools'].length > 0){
                newob.techadopted = 1
              }
              pV.push(newob)
            }
            return pV;
          }, []);

          let finalMonthData = finalMonthValues.map((item,index, array) => {
            if(index > 0){
              return {'month':item.month, 'percentChange':parseFloat(((item.techadopted / item.total) * 100).toFixed(2))}
        } else{
        return {'month':item.month, 'percentChange': 0}
        }
          })
          finalMonthData = finalMonthData.filter(Boolean)

          finalMonthData.forEach(slobject => {
              if(slobject.percentChange < 1000){
                categories.push(slobject.month)
                series[0].data.push(slobject.percentChange)
              }else{
                categories.push(slobject.month)
                series[0].data.push(0)
              }
          })
      }
      

   let yAxis =  {

      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      },
     
    }

    if(this.props.timerange === "Year"){
      yAxis.plotLines = [{
        color: '#FF0000',
        width: 2,
        value: 20,
        label: {text: 'Goal: 20% Tech Adoption',
        align: 'left',}
    }]
    }

    let xAxis = {
    
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
      }},
      categories: categories
    }


    return {
      ...this.ticks,
      ...this.chartOptions,
      xAxis,
      yAxis,
      series
    }
  }

  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'column',
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      enabled: true,
        chartOptions: {
          title: {
            text: null
          }
        }
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      min:30,
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      },
      
    },
    xAxis: {
      // type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        dataLabels:{
          enabled:true,
          allowOverlap: true,
          format: '{y}%',
        },
        // pointInterval: 3600000 * 25, // every day
        // pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${this.series.name} at ${this.y.toFixed(2)}%</span>`;
          }
        }
      },
    }
  };


  render() {

    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={12}>
              <h5>
                <span className="fw-semi-bold">Tech Adoption Percentage</span>&nbsp;By {this.props.timerange}
              </h5>
            </Col>
            <Col xs={12} sm={12}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}
