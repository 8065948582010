import React from "react";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Nav,
  NavLink,
  NavItem,
  Progress,
} from "reactstrap";
import axios from "axios";
import Select from "react-select";
import { Multiselect } from "multiselect-react-dropdown";
import MaskedInput from "react-maskedinput";
import Widget from "../../../../../../components/Widget/Widget";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import s from "./Wizard.module.scss";
// import { borderTopRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';

const count = 4;
const StepsComponents = {
  Step4: function Step4() {
    return (
      <fieldset>
        <h2>Thank you!</h2>
        <p>Your submission has been received.</p>
      </fieldset>
    );
  },
};

class Step4Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    function ShowHtml(props) {
      return (
        <div
          className="emailPreviewBox"
          dangerouslySetInnerHTML={{ __html: props }}
        />
      );
    }
    return (
      <>
        <Row>
          <Col lg={12}>
            <h3>Email Preview:</h3>
            <p className="help-block">
              Please verify that the design of the email preview generated looks
              accurate. <br></br> <br></br>{" "}
              <span className="fw-semi-bold">NOTE: </span>
              The query presented within the email is based on the "frequency"
              selected. If no opportunities were found in the "frequency"
              selected, the email shown in the preview may not display any
              opportunities.
            </p>
          </Col>
          <Col lg={12}>
            {this.props.emailTestCodeReady ? (
              ShowHtml(this.props.emailTestCode)
            ) : this.props.emailTestError ? (
              <div className="animated fadeIn handle initialLoader emailLoader">
                <p className="text-danger">Some error occurred...</p>
              </div>
            ) : (
              <div className="animated fadeIn handle initialLoader emailLoader">
                <span className="spinner">
                  <i className="fa fa-spinner fa-spin" />
                </span>
                <Col xs={12} md={12} lg={12} xl={12}>
                  Loading Preview...
                </Col>
              </div>
            )}
          </Col>
        </Row>
      </>
    );
  }
}

class Step3Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  modules = {
    toolbar: [
      [
        "bold",
        "italic",
        "underline",
        "strike",
        {
          color: [
            "#E30613",
            "#000",
            "#626468",
            "#B1B2B4",
            "#DBD6C7",
            "#fff",
            "#7874B5",
            "#602376",
            "#9A054A",
            "#ED700A",
          ],
        },
        {
          background: [
            "#E30613",
            "#000",
            "#626468",
            "#B1B2B4",
            "#DBD6C7",
            "#fff",
            "#7874B5",
            "#602376",
            "#9A054A",
            "#ED700A",
          ],
        },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  render() {
    return (
      <>
        <fieldset>
          <FormGroup>
            <Label for="selectedFromAddress">From Address*</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedFromAddress"
              name="selectedFromAddress"
              className="selectedFromAddress"
              value={this.props.selectedFromAddress}
              options={
                this.props.fromBoxRecipients &&
                this.props.fromBoxRecipients.constructor === Array
                  ? this.props.fromBoxRecipients
                  : []
              }
            />
            <p className="help-block">
              Who should this email report be sent "From"?
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="brokerinput">CC Address(es)</Label>
            {this.props.recipients ? (
              <Multiselect
                options={this.props.recipients} // Options to display in the dropdown
                onSelect={this.props.onSelectCCAddress.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectCCAddress.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectCCAddressRef}
                selectedValues={this.props.selectedCCAddress}
                id="recipientInput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Please select those who should be cc'ed to the campaign.
              IMPORTANT: If you wish to receive the emails as they go out for
              confirmation, please add your name to the CC field above.
            </p>
          </FormGroup>

          <FormGroup>
            <Label for="customtextinput">
              Custom message in campaign email
            </Label>
            <ReactQuill
              id="customtextinput"
              theme="snow"
              style={{ backgroundColor: "white" }}
              onChange={this.props.handleCustomTextChange}
              modules={this.modules}
              value={this.props.selectedCustomMessage}
              placeholder="Add your custom message here. If left blank, a default message will be used."
            />
          </FormGroup>
        </fieldset>
      </>
    );
  }
}

class Step2Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    return (
      <>
        <fieldset>
          <FormGroup>
            <Label for="selectedTimerange">Time Range Filter</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedTimerange"
              name="selectedTimerange"
              className="selectedTimerange"
              value={this.props.selectedTimerange}
              options={this.props.timerangeChoices}
            />
            <p className="help-block">
              By default, the report will base the query of opportunities sent
              by the "Frequency". However- if a specific time range is desired,
              please select above. (i.e. A monthly report of Year to Date
              opportunities is desired. YTD would be selected above, Monthly
              would be selected within Frequency.)
            </p>
          </FormGroup>
          {this.props.customTimeframeSelected ? (
            <FormGroup>
              <Label for="mask-date">
                Custom start date
                <span className="help-block">MM-DD-YYYY</span>
                {this.props.invalidStartDate ? (
                  <span className="help-block text-danger">
                    Please enter a valid date.
                  </span>
                ) : null}
              </Label>
              <MaskedInput
                onChange={this.props.handleMaskedDateInput}
                name="selectedTimerangeCustom"
                value={this.props.rawSelectedTimeRangeCustom}
                className="form-control"
                id="mask-date"
                mask="11-11-1111"
              />
              <p className="help-block">
                With a custom start date time range, you have the ability to
                choose the start date of the opportunity query. (i.e. I want to
                send reminders for opportunities after 12 1, 2020)
              </p>
            </FormGroup>
          ) : null}

          <FormGroup>
            <Label for="marketinput">Only Include</Label>
            {this.props.brokers ? (
              <Multiselect
                options={this.props.brokers} // Options to display in the dropdown
                onSelect={this.props.onSelectOnlyIncludeBroker.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectOnlyIncludeBroker.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectOnlyIncludeBrokerRef}
                selectedValues={this.props.selectedOnlyIncludeBrokers}
                id="marketinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Refresh emails in this campaign will only send to the above
              selected brokers.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="marketinput">Exclude</Label>
            {this.props.brokers ? (
              <Multiselect
                options={this.props.brokers} // Options to display in the dropdown
                onSelect={this.props.onSelectExcludedBroker.bind(this)} // Function will trigger on select event
                onRemove={this.props.onSelectExcludedBroker.bind(this)} // Function will trigger on remove event
                ref={this.props.onSelectExcludedBrokerRef}
                selectedValues={this.props.selectedExcludedBrokers}
                id="marketinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">
              Refresh emails in this campaign will send to all brokers in your
              selected markets except those selected in this list.
            </p>
          </FormGroup>
        </fieldset>
      </>
    );
  }
}

class Step1Component extends React.Component {
  constructor(prop) {
    super(prop);
  }

  render() {
    return (
      <>
        <fieldset>
          <FormGroup>
            <Label for="campaignName">Campaign Name*</Label>
            <input
              onChange={(e) => this.props.handleNormalInputChange(e)}
              className={`selectCustomization form-control ${
                this.props.checkedInputs &&
                (this.props.campaignName.length === 0 ||
                  typeof this.props.campaignName === "object")
                  ? "ng-invalid"
                  : ""
              }`}
              type="text"
              id="campaignName"
              name="campaignName"
              value={`${
                typeof this.props.campaignName === "object"
                  ? ""
                  : this.props.campaignName
              }`}
            />
            <p className="help-block">Name your campaign for easy reference.</p>
          </FormGroup>
          <FormGroup>
            <Label for="email">Frequency*</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedFrequency"
              name="selectedFrequency"
              className={`selectCustomization ${
                this.props.checkedInputs &&
                (this.props.selectedFrequency.length === 0 ||
                  typeof this.props.selectedFrequency != "object")
                  ? "ng-invalid"
                  : ""
              }`}
              value={this.props.selectedFrequency}
              options={this.props.frequency}
            />
            <p className="help-block">How often should the campaign send?</p>
          </FormGroup>
          <FormGroup>
            <Label for="email">Active Status*</Label>
            <Select
              onChange={(e) => this.props.handleInputChange(e)}
              id="selectedActive"
              name="selectedActive"
              className={`selectCustomization ${
                this.props.checkedInputs &&
                (this.props.selectedActive.length === 0 ||
                  typeof this.props.selectedActive != "object")
                  ? "ng-invalid"
                  : ""
              }`}
              value={this.props.selectedActive}
              options={this.props.reportActiveChoices}
            />
            <p className="help-block">
              Please select if this campaign should be sent at the selected
              frequency. Default is active.
            </p>
          </FormGroup>
          <FormGroup>
            <Label for="address">Market(s)*</Label>
            {this.props.markets ? (
              <Multiselect
                options={this.props.markets} // Options to display in the dropdown
                style={
                  this.props.checkedInputs &&
                  (this.props.selectedMarkets.length === 0 ||
                    !Object.prototype.toString.call(
                      this.props.selectedMarkets
                    ) == "[object Array]")
                    ? {
                        searchBox: {
                          border: "none",
                          borderBottom: "1px solid #eb516d",
                        },
                      }
                    : ""
                }
                onSelect={this.props.onSelectMarket.bind(this)} // Function will trigger on select event
                onRemove={this.props.onRemoveMarket.bind(this)} // Function will trigger on remove event
                ref={this.props.marketSelectRef}
                selectedValues={this.props.selectedMarkets}
                id="marketinput"
                displayValue="name"
              />
            ) : null}
            <p className="help-block">Select market or markets.</p>
          </FormGroup>
        </fieldset>
      </>
    );
  }
}

class Wizard extends React.Component {
  constructor(prop) {
    super(prop);
    this.state = {
      brokers: [],
      secondFieldMissingValue: false,
      firstFieldMissingValue: false,
      inputCheck: false,
      checkedInputs: false,
      recipients: [],
      fromBoxRecipients: [],
      reportActiveChoices: [],
      inputsready: false,
      markets: [],
      frequency: [],
      timerangeChoices: [],
      customTimeframeSelected: false,
      campaignName: [],
      selectedFrequency: [],
      selectedOnlyIncludeBrokers: [],
      selectedExcludedBrokers: [],
      selectedTimerange: [],
      selectedActive: { value: true, label: "Active", name: "selectedActive" },
      selectedFromAddress: [],
      selectedCCAddress: [],
      selectedMarkets: [],
      selectedCustomMessage: "",
      rawSelectedTimeRangeCustom: "",
      invalidStartDate: false,
      emailTestCode: "",
      emailTestError: false,
      emailTestCodeReady: false,
      fetching: false,
      currentStep: 1,
      progress: 25,
      isDatePickerOpen: false,
    };
    this.handleCustomTextChange = this.handleCustomTextChange.bind(this);
    this.saveReport = this.saveReport.bind(this);
    this.saveReportChanges = this.saveReportChanges.bind(this);
    this.sendTest = this.sendTest.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMaskedDateInput = this.handleMaskedDateInput.bind(this);
    this.handleNormalInputChange = this.handleNormalInputChange.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
    this.onSelectMarket = this.onSelectMarket.bind(this);
    this.onRemoveMarket = this.onRemoveMarket.bind(this);
    this.onSelectCCAddress = this.onSelectCCAddress.bind(this);
    this.onSelectExcludedBroker = this.onSelectExcludedBroker.bind(this);
    this.onSelectOnlyIncludeBroker = this.onSelectOnlyIncludeBroker.bind(this);
    this.onSelectCCAddressRef = React.createRef();
    this.marketSelectRef = React.createRef();
    this.onSelectOnlyIncludeBrokerRef = React.createRef();
    this.onSelectExcludedBrokerRef = React.createRef();
    this.onSelectServiceLineRef = React.createRef();
  }

  onSelectOnlyIncludeBroker(selectedList, selectedItem) {
    this.setState({
      selectedOnlyIncludeBrokers: selectedList,
    });
  }

  onSelectExcludedBroker(selectedList, selectedItem) {
    this.setState({
      selectedExcludedBrokers: selectedList,
    });
  }

  onSelectCCAddress(selectedList, selectedItem) {
    this.setState({
      selectedCCAddress: selectedList,
    });
  }

  onSelectMarket(selectedList, selectedItem) {
    this.setState({
      selectedMarkets: selectedList,
    });
  }
  onRemoveMarket(selectedList, selectedItem) {
    this.setState({
      selectedMarkets: selectedList,
    });
  }

  handleMaskedDateInput(e) {
    if (e.target.name === "selectedTimerangeCustom") {
      if (e.target.value.includes("_")) {
        this.setState({
          rawSelectedTimeRangeCustom: e.target.value,
          invalidStartDate: true,
        });
      } else {
        this.setState({
          rawSelectedTimeRangeCustom: e.target.value,
          invalidStartDate: false,
        });
      }
    }
  }

  handleInputChange(e) {
    const value = e.value;
    const name = e.name;
    if (e.name === "selectedTimerange") {
      if (e.value === "Custom") {
        this.setState({
          [name]: e,
          customTimeframeSelected: true,
        });
      } else {
        this.setState({
          [name]: e,
          customTimeframeSelected: false,
        });
      }
    } else {
      this.setState({
        [name]: e,
      });
    }
  }

  handleNormalInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
  handleCustomTextChange(e) {
    this.setState({
      selectedCustomMessage: e,
    });
  }

  checkSecondRequired() {
    let objectFieldList = [this.state.selectedFromAddress];
    let fieldIsNotMissingValue = true;

    objectFieldList.forEach((field) => {
      if (
        field.length === 0 ||
        !Object.prototype.toString.call(field) == "[object Array]"
      ) {
        fieldIsNotMissingValue = false;
      }
    });

    return fieldIsNotMissingValue;
  }

  saveReport() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let timerange = this.state.selectedTimerange;

    if (
      this.state.selectedTimerange?.value?.includes("Custom") &&
      !this.state.invalidStartDate
    ) {
      let newDate = this.state.rawSelectedTimeRangeCustom.replaceAll("-", " ");
      timerange = { value: `Start Date: ${newDate}` };
    }

    let data = {
      campaignName: this.state.campaignName,
      selectedCustomMessage: this.state.selectedCustomMessage,
      selectedActive: this.state.selectedActive,
      selectedTimerange: timerange,
      selectedMarkets: this.state.selectedMarkets,
      selectedFromAddress: this.state.selectedFromAddress,
      selectedCCAddress: this.state.selectedCCAddress,
      selectedFrequency: this.state.selectedFrequency,
      selectedExcludedBrokers: this.state.selectedExcludedBrokers,
      selectedOnlyIncludeBrokers: this.state.selectedOnlyIncludeBrokers,
    };

    axios.post(`/api/v1/refreshcampaignsave/`, data).then(
      (res) => {
        this.props.receiveNewlyCreatedCampaign(res.data);
        this.props.toggleCreateorEdit("createReportModal");
      },
      (error) => {
        console.log(error);
        this.setState({
          emailTestError: true,
        });
      }
    );
    this.nextStep();
  }

  saveReportChanges() {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let reportID = this.props.currentReportDetails.id;
    let timerange = this.state.selectedTimerange;

    if (
      this.state.selectedTimerange?.value?.includes("Custom") &&
      !this.state.invalidStartDate
    ) {
      let newDate = this.state.rawSelectedTimeRangeCustom.replaceAll("-", " ");
      timerange = { value: `Start Date: ${newDate}` };
    }

    let data = {
      reportID: reportID,
      campaignName: this.state.campaignName,
      selectedCustomMessage: this.state.selectedCustomMessage,
      selectedActive: this.state.selectedActive,
      selectedTimerange: timerange,
      selectedMarkets: this.state.selectedMarkets,
      selectedFromAddress: this.state.selectedFromAddress,
      selectedCCAddress: this.state.selectedCCAddress,
      selectedFrequency: this.state.selectedFrequency,
      selectedExcludedBrokers: this.state.selectedExcludedBrokers,
      selectedOnlyIncludeBrokers: this.state.selectedOnlyIncludeBrokers,
    };

    axios.post(`/api/v1/refreshcampaignsavechanges/`, data).then(
      (res) => {
        this.props.receiveNewlyCreatedCampaign(res.data);
        this.props.toggleCreateorEdit("editReportModal");
      },
      (error) => {
        console.log(error);
        this.setState({
          emailTestError: true,
        });
      }
    );
    this.nextStep();
  }

  sendTest() {
    this.setState({
      emailTestCode: "",
      emailTestCodeReady: false,
      emailTestError: false,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    let timerange = this.state.selectedTimerange;

    if (
      this.state.selectedTimerange?.value?.includes("Custom") &&
      !this.state.invalidStartDate
    ) {
      let newDate = this.state.rawSelectedTimeRangeCustom.replaceAll("-", " ");
      timerange = { value: `Start Date: ${newDate}` };
    }

    let data = {
      campaignName: this.state.campaignName,
      selectedCustomMessage: this.state.selectedCustomMessage,
      selectedActive: this.state.selectedActive,
      selectedTimerange: timerange,
      selectedMarkets: this.state.selectedMarkets,
      selectedFromAddress: this.state.selectedFromAddress,
      selectedCCAddress: this.state.selectedCCAddress,
      selectedFrequency: this.state.selectedFrequency,
      selectedExcludedBrokers: this.state.selectedExcludedBrokers,
      selectedOnlyIncludeBrokers: this.state.selectedOnlyIncludeBrokers,
    };

    axios.post(`/api/v1/refreshemailtest/`, data).then(
      (res) => {
        this.setState({
          emailTestCode: res.data,
          emailTestCodeReady: true,
        });
      },
      (error) => {
        console.log(error);
        this.setState({
          emailTestError: true,
        });
      }
    );
    this.nextStep();
  }

  checkFirstRequired() {
    let normalFieldList = [this.state.campaignName];
    let objectFieldList = [
      this.state.selectedFrequency,
      this.state.selectedMarkets,
    ];
    let fieldIsNotMissingValue = true;
    normalFieldList.forEach((field) => {
      if (field.length === 0) {
        fieldIsNotMissingValue = false;
      }
    });
    objectFieldList.forEach((field) => {
      if (
        field.length === 0 ||
        !Object.prototype.toString.call(field) == "[object Array]"
      ) {
        fieldIsNotMissingValue = false;
      }
    });
    return fieldIsNotMissingValue;
  }

  checkIfIntialValues(initialState) {
    if (this.props.currentReportDetails) {
      let initialData = this.props.currentReportDetails;

      let selectedMarkets = initialData.markets.map((item) => ({
        name: item.marketname,
        index: item.market_id,
      }));
      let selectedOnlyIncludeBrokers = initialData.only_include_list.map(
        (item) => ({ name: item.fullname, index: item.id })
      );
      let selectedExcludedBrokers = initialData.exclude_list.map((item) => ({
        name: item.fullname,
        index: item.id,
      }));
      let selectedCCAddress = initialData.cc_members.map((item) => ({
        name: item.fullname,
        index: item.id,
      }));
      let selectedFromAddress = initialState.fromBoxRecipients.find(
        (item) => item.label === initialData.from_member.fullname
      );
      let selectedActive = initialState.active.find(
        (item) => item.value === initialData.active
      );
      let selectedFrequency = initialState.frequency.find(
        (item) => item.value === initialData.frequency
      );
      let selectedTimeRange = "";
      let rawSelectedTimeRangeCustom = "";
      let customTimeframeSelected = false;
      if (initialData.timerange.includes("Start Date:")) {
        selectedTimeRange = initialState.timerangeChoices.find(
          (item) => item.value === "Custom"
        );
        customTimeframeSelected = true;
        rawSelectedTimeRangeCustom = initialData.timerange.split(": ");
        rawSelectedTimeRangeCustom = rawSelectedTimeRangeCustom[1].replaceAll(
          " ",
          "-"
        );
      } else {
        selectedTimeRange = initialState.timerangeChoices.find(
          (item) => item.value === initialData.timerange
        );
      }

      this.setState({
        campaignName: initialData.name,
        rawSelectedTimeRangeCustom: rawSelectedTimeRangeCustom,
        customTimeframeSelected: customTimeframeSelected,
        selectedMarkets: selectedMarkets,
        selectedActive: selectedActive,
        selectedTimerange: selectedTimeRange,
        selectedFromAddress: selectedFromAddress,
        selectedFrequency: selectedFrequency,
        selectedCCAddress: selectedCCAddress,
        selectedOnlyIncludeBrokers: selectedOnlyIncludeBrokers,
        selectedExcludedBrokers: selectedExcludedBrokers,
        selectedCustomMessage: initialData.customtext,
      });
    }
  }

  nextStep() {
    let currentStep = this.state.currentStep;
    let firstFieldMissingValue = false;
    let secondFieldMissingValue = false;
    let checkedInputs = true;

    if (currentStep === 1) {
      firstFieldMissingValue = this.checkFirstRequired();
      if (firstFieldMissingValue) {
        currentStep += 1;
      }
    } else if (currentStep === 2) {
      currentStep += 1;
    } else if (currentStep === 3) {
      secondFieldMissingValue = this.checkSecondRequired();
      if (secondFieldMissingValue) {
        currentStep += 1;
      }
    }
    this.setState({
      checkedInputs,
      firstFieldMissingValue,
      currentStep,
      secondFieldMissingValue,
      progress: (100 / count) * currentStep,
    });
  }

  sortList(a, b) {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  }
  sortNormalList(a, b) {
    return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
  }

  getInputValues() {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/refreshcampaigninputvalues/`).then(
      (res) => {
        let data = res.data;

        // let brokerlist = data.brokerlist.map((item, index) => {'name': item.fullname, 'index': index})
        let brokerlist = data.brokerlist.map(function (item, index) {
          return { name: item.fullname, index: index };
        });

        let recipients = data.teamMembers.map(function (item, index) {
          return { name: item.fullname, index: index };
        });
        let fromBoxRecipients = data.teamMembers.map((item) => ({
          value: item.fullname,
          label: item.fullname,
          name: "selectedFromAddress",
        }));

        let markets = data.markets.map(function (item, index) {
          return { name: item.marketname, index: index };
        });

        let activechoices = data.activechoices;
        let opportunityTimerange = data.opportunityTimerange;
        markets = markets.sort((a, b) => this.sortList(a, b));

        recipients = recipients.sort((a, b) => this.sortList(a, b));
        fromBoxRecipients = fromBoxRecipients.sort((a, b) =>
          this.sortNormalList(a, b)
        );
        brokerlist = brokerlist.sort((a, b) => this.sortList(a, b));

        let frequency = data.frequency;
        let objForInitialState = {
          timerangeChoices: opportunityTimerange,
          active: activechoices,
          brokerlist: brokerlist,
          recipients: recipients,
          fromBoxRecipients: fromBoxRecipients,
          markets: markets,
          frequency: frequency,
        };
        this.checkIfIntialValues(objForInitialState);
        this.setState({
          reportActiveChoices: activechoices,
          timerangeChoices: opportunityTimerange,
          brokers: brokerlist,
          recipients: recipients,
          fromBoxRecipients: fromBoxRecipients,
          markets: markets,
          frequency: frequency,
          fetching: false,
          inputsready: true,
        });
      },
      (error) => {
        this.setState({
          errorData: error,
          fetching: false,
          fetchingError: false,
        });
      }
    );
  }
  previousStep() {
    let currentStep = this.state.currentStep;
    if (currentStep <= 1) {
      currentStep = 1;
    } else {
      currentStep -= 1;
    }

    this.setState({
      currentStep,
      progress: (100 / count) * currentStep,
    });
  }

  componentDidMount() {
    this.getInputValues();
  }

  render() {
    const currentStep = this.state.currentStep;
    return (
      <div className={s.root}>
        <>
          <Row>
            <Col lg={12}>
              {this.state.inputsready ? (
                <Widget
                  className={s.formWizard}
                  title={
                    <div>
                      <h4>Refresh Campaign Form&nbsp;</h4>
                      <p className="text-muted">
                        Please fill out the following form to create or edit a
                        custom Refresh Campaign.
                      </p>
                    </div>
                  }
                >
                  <Nav pills justified className={s.wizardNavigation}>
                    <NavItem>
                      <NavLink active={currentStep === 1}>
                        <small>1.</small>
                        &nbsp; Basic Information
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentStep === 2}>
                        <small>2.</small>
                        &nbsp; Filters
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentStep === 3}>
                        <small>3.</small>
                        &nbsp; Customize
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink active={currentStep === 4}>
                        <small>4.</small>
                        &nbsp; Confirmation
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <Progress
                    value={this.state.progress}
                    color="info"
                    className="progress-xs"
                  />
                  <div className="tab-content">
                    <div className={s.stepBody}>
                      {currentStep === 1 && (
                        <Step1Component
                          selectedActive={this.state.selectedActive}
                          reportActiveChoices={this.state.reportActiveChoices}
                          checkedInputs={this.state.checkedInputs}
                          selectedMarkets={this.state.selectedMarkets}
                          campaignName={this.state.campaignName}
                          marketSelectRef={this.marketSelectRef}
                          handleNormalInputChange={this.handleNormalInputChange}
                          selectedFrequency={this.state.selectedFrequency}
                          onRemoveMarket={this.onRemoveMarket}
                          onSelectMarket={this.onSelectMarket}
                          handleInputChange={this.handleInputChange}
                          markets={this.state.markets}
                          frequency={this.state.frequency}
                        />
                      )}

                      {currentStep === 2 && (
                        <Step2Component
                          rawSelectedTimeRangeCustom={
                            this.state.rawSelectedTimeRangeCustom
                          }
                          invalidStartDate={this.state.invalidStartDate}
                          handleMaskedDateInput={this.handleMaskedDateInput}
                          customTimeframeSelected={
                            this.state.customTimeframeSelected
                          }
                          selectedExcludedBrokers={
                            this.state.selectedExcludedBrokers
                          }
                          onSelectExcludedBrokerRef={
                            this.onSelectExcludedBrokerRef
                          }
                          onSelectExcludedBroker={this.onSelectExcludedBroker}
                          timerangeChoices={this.state.timerangeChoices}
                          selectedTimerange={this.state.selectedTimerange}
                          handleInputChange={this.handleInputChange}
                          onSelectOnlyIncludeBrokerRef={
                            this.onSelectOnlyIncludeBrokerRef
                          }
                          onSelectServiceLineRef={this.onSelectServiceLineRef}
                          brokers={this.state.brokers}
                          businesslines={this.state.businesslines}
                          onSelectServiceLine={this.onSelectServiceLine}
                          handleNormalInputChange={this.handleNormalInputChange}
                          onSelectOnlyIncludeBroker={
                            this.onSelectOnlyIncludeBroker
                          }
                          selectedOnlyIncludeBrokers={
                            this.state.selectedOnlyIncludeBrokers
                          }
                        />
                      )}

                      {currentStep === 3 && (
                        <Step3Component
                          selectedCustomMessage={
                            this.state.selectedCustomMessage
                          }
                          selectedCCAddress={this.state.selectedCCAddress}
                          handleCustomTextChange={this.handleCustomTextChange}
                          handleInputChange={this.handleInputChange}
                          fromBoxRecipients={this.state.fromBoxRecipients}
                          checkedInputs={this.state.checkedInputs}
                          selectedFromAddress={this.state.selectedFromAddress}
                          recipients={this.state.recipients}
                          onSelectCCAddress={this.onSelectCCAddress}
                          onSelectCCAddressRef={this.onSelectCCAddressRef}
                        />
                      )}
                      {currentStep === 4 && (
                        <Step4Component
                          emailTestError={this.state.emailTestError}
                          checkedInputs={this.state.checkedInputs}
                          emailTestCode={this.state.emailTestCode}
                          emailTestCodeReady={this.state.emailTestCodeReady}
                        />
                      )}
                    </div>

                    <div className="description">
                      <ul className="pager wizard">
                        <li className="previous">
                          <Button
                            disabled={currentStep === 1}
                            color="primary"
                            onClick={this.previousStep}
                          >
                            <i className="fa fa-caret-left" />
                            &nbsp;Previous
                          </Button>
                        </li>
                        {currentStep < count && currentStep < 3 ? (
                          <li className="next">
                            <Button color="primary" onClick={this.nextStep}>
                              Next <i className="fa fa-caret-right" />
                            </Button>
                          </li>
                        ) : currentStep < count &&
                          currentStep == 3 &&
                          !this.state.firstFieldMissingValue &&
                          !this.state.secondFieldMissingValue ? (
                          <li className="next">
                            <Button color="primary" onClick={this.sendTest}>
                              View Preview <i className="fa fa-caret-right" />
                            </Button>
                          </li>
                        ) : (currentStep < count &&
                            this.state.firstFieldMissingValue &&
                            currentStep == 3) ||
                          (currentStep < count &&
                            this.state.secondFieldMissingValue &&
                            currentStep == 3) ? (
                          <li className="next">
                            <Button color="primary" disabled>
                              Preview Report <i className="fa fa-caret-right" />
                            </Button>
                          </li>
                        ) : null}
                        {currentStep === count &&
                        !this.state.saveError &&
                        !this.props.currentReportDetails ? (
                          <li className="finish">
                            <Button color="primary" onClick={this.saveReport}>
                              Save Report <i className="fa fa-check" />
                            </Button>
                          </li>
                        ) : currentStep === count &&
                          !this.state.saveError &&
                          this.props.currentReportDetails ? (
                          <li className="finish">
                            <Button
                              color="primary"
                              onClick={this.saveReportChanges}
                            >
                              Save Changes <i className="fa fa-check" />
                            </Button>
                          </li>
                        ) : currentStep === count && this.state.saveError ? (
                          <li className="finish">
                            <Button color="danger" disabled>
                              Error <i className="fa fa-check" />
                            </Button>
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </div>
                </Widget>
              ) : null}
            </Col>
          </Row>
        </>
      </div>
    );
  }
}

export default Wizard;
