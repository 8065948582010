import React, { Component } from "react";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { Col, Row } from "reactstrap";
import axios from "axios";
import ToolkitProvider, {
  CSVExport,
  Search,
} from "react-bootstrap-table2-toolkit";
import { reactTableData, reactBootstrapTableData } from "./data";
import s from "./Dynamic.modules.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Tooltip from "@material-ui/core/Tooltip";

var moment = require("moment");

const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;

const states = {
  won: "success",
  lost: "danger",
  open: "info",
  pending: "warning",
};

class Dynamic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      reactTable: reactTableData(),
      reactBootstrapTable: reactBootstrapTableData(),
    };
  }

  cleanServicesList(services) {
    let list = [];
    services.forEach((item) => list.push(item.servicename));
    return list;
  }

  cleanMarketList(market) {
    let list = [];
    market.forEach((item) => list.push(item.marketname));
    let themarket = list.join(", ");
    return themarket;
  }

  cleanTechList(tech) {
    let list = [];
    tech.forEach((item) => list.push(item.techname));
    let thetool = list.join(", ");
    return thetool;
  }

  redirectToScorecard = (e) => {
    console.log(this);
    // this.props.history.push("/app/brokerscorecard/"+e.target.point.category)
  };

  sendTest(cell, row, index) {
    return (
      <div style={{ width: 100 }}>
        <SendButton
          cell={cell}
          row={row}
          index={index}
          token={this.formatExtraData}
        >
          Receive Test
        </SendButton>
      </div>
    );
  }

  renderSizePerPageDropDown = (props) => {
    const limits = [];
    props.sizePerPageList.forEach((limit) => {
      limits.push(
        <DropdownItem
          key={limit}
          onClick={() => props.changeSizePerPage(limit)}
        >
          {limit}
        </DropdownItem>
      );
    });

    return (
      <Dropdown isOpen={props.open} toggle={props.toggleDropDown}>
        <DropdownToggle color="default" caret>
          {props.currSizePerPage}
        </DropdownToggle>
        <DropdownMenu>{limits}</DropdownMenu>
      </Dropdown>
    );
  };

  render() {
    const { data } = this.props;
    const defaultSorted = [
      {
        dataField: "name", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc", // desc or asc
      },
    ];
    const pagination = paginationFactory({
      sizePerPage: 50,
    });
    const options = {
      sizePerPage: 50,
      scrollX: true,
      paginationSize: 3,
      sizePerPageDropDown: this.renderSizePerPageDropDown,
      ExportCSVButton,
    };

    data.sort(function (a, b) {
      return a.name - b.name;
    });

    function cleanPersonArray(person) {
      let list = [];
      person.forEach((item) => list.push(item.fullname));
      return list;
    }

    function formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    function numberFormatter(cell) {
      return (
        <div style={{ width: 150 }}>
          <small style={{ width: 100 }}>{formatNumber(cell)}</small>
        </div>
      );
    }

    function dateFormatter(cell) {
      return (
        <div style={{ width: 100 }}>
          {moment(cell, "MM-DD-YYYY hh:mm:ss").format("MM/DD/YYYY")}
        </div>
      );
    }

    function cleanPersonList(person) {
      let list = [];
      person.forEach((item) => list.push(`${item.firstname} ${item.lastname}`));
      // let theperson = list.join(", ")
      return list;
    }

    function generalText(cell) {
      return (
        <div style={{ width: 150 }}>
          <small>{cell.toString()}</small>
        </div>
      );
    }

    function brokerListFormatter(cell, row) {
      return (
        <div style={{ width: 200 }}>
          <small>{cell}</small>
          <br />
        </div>
      );
    }

    function cleanMarketList(market) {
      let list = [];
      market.forEach((item) => list.push(item.marketname));
      let themarket = list.join(", ");
      if (list.length >= 20) {
        return "All markets";
      } else {
        return themarket;
      }
    }
    function cleanCreatedBy(person) {
      if (person?.fullname) {
        return person?.fullname;
      } else {
        return "";
      }
    }

    function sourcingmethodformat(cell) {
      return (
        <div style={{ width: 100 }}>
          <small>
            {cell.map(function (item, index) {
              if (!(item == " ") && !(item == "")) {
                if (cell.length == index + 1) {
                  return <span>{item}</span>;
                } else {
                  return <span>{item + ", "}</span>;
                }
              }
            })}
          </small>
          <br />
        </div>
      );
    }

    function dateFormatter(cell) {
      return (
        <div style={{ width: 100 }}>
          {moment(cell, "YYYY-MM-DD hh:mm:ss").format("MM/DD/YYYY")}
        </div>
      );
    }

    let cleandata = data.map((report) => {
      return {
        id: report.id,
        name: report.name,
        altname: report.altname,
        recipients: cleanPersonList(report.recipients),
        createdby: cleanCreatedBy(report.createdby),
        markets: cleanMarketList(report.markets),
        serviceline: report.serviceline,
        brokerlist: report.brokerlist,
        active: report.active,
        frequency: report.frequency,
        datecreated: report.datecreated,
        propertytype: report.propertytype,
        sfthreshold: report.sfthreshold,
      };
    });

    let columns = [
      {
        dataField: "name",
        text: "Report Name",
        sort: true,
        formatter: brokerListFormatter,
        classes: " align-middle",
        width: "200px",
      },
      {
        dataField: "active",
        text: "Report Active",
        sort: true,
        // formatter: generalText,
        classes: "align-middle",
      },
      {
        dataField: "frequency",
        text: "Report Frequency",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "markets",
        text: "Markets Included",
        formatter: generalText,
        sort: true,
        classes: "align-middle",
      },
      {
        dataField: "altname",
        text: "Alternate Market Name",
        formatter: generalText,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "recipients",
        text: "Subscribed Users",
        formatter: sourcingmethodformat,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "datecreated",
        text: "Date Created",
        formatter: dateFormatter,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "createdby",
        text: "Created by",
        // formatter: sourcingmethodformat,
        sort: true,
        classes: " align-middle",
      },
      {
        dataField: "id",
        text: "Send Test",
        formatter: this.sendTest,
        classes: "align-middle",
        formatExtraData: this.props.token,
      },
    ];

    let filename =
      "Email-Reports" + "-" + moment().format("MM-DD-YYYY-HH:MM") + ".csv";
    return (
      <ToolkitProvider
        keyField="opportunityid"
        data={cleandata}
        columns={columns}
        exportCSV={{
          fileName: filename,
          onlyExportFiltered: true,
        }}
        search
      >
        {(props) => (
          <div>
            <Row className="mb-2">
              <Col xs={6} md={6} lg={6} xl={8}>
                <ExportCSVButton {...props.csvProps}>
                  Export CSV
                </ExportCSVButton>
              </Col>
              <Col xs={6} md={6} lg={6} xl={4}>
                <SearchBar {...props.searchProps} />
              </Col>
            </Row>
            <div>
              <BootstrapTable
                {...props.baseProps}
                pagination={pagination}
                options={options}
                defaultSorted={defaultSorted}
                striped={true}
                tableContainerClass={`table-striped jll-opp-table table-hover ${s.bootstrapTable}`}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    );
  }
}

class SendButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      complete: false,
      error: false,
      success: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(cell, row, rowIndex) {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/sendtestemail/${cell}/`).then(
      (res) => {
        this.setState({
          complete: true,
          success: true,
          fetching: false,
        });
      },
      (error) => {
        this.setState({
          error: true,
          fetching: false,
          complete: true,
        });
      }
    );
  }

  render() {
    const { cell, row, rowIndex } = this.props;
    return (
      <>
        {!this.state.fetching && !this.state.complete ? (
          <React.Fragment>
            <Tooltip
              title={
                <div style={{ fontSize: "12px" }}>
                  A test will be sent to your email only.
                </div>
              }
              placement="top"
            >
              <Button
                color="primary"
                size="xs"
                className="px-2"
                onClick={() => this.handleClick(cell, row, rowIndex)}
              >
                Send Test
              </Button>
            </Tooltip>
            {/* <Dialog ref={(el) => { this.dialog = el }} /> */}
          </React.Fragment>
        ) : this.state.fetching ? (
          <div>
            <i className="la la-spinner la-spin" />
          </div>
        ) : this.state.success ? (
          <div>Test Sent!</div>
        ) : this.state.error ? (
          <div>Error.</div>
        ) : null}
      </>
    );
  }
}

export default withRouter(Dynamic);
