import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class MarketPerformance extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  cleanPersonList(person){
    let list =[]
    person.forEach(item => list.push(item.fullname))
    let theperson = list.join(", ")
    return theperson
  }

  cleanmarket(market){
    let list= []
    market.forEach(market=> list.push(market.marketname))
    let themarket = list.join(", ")
    return themarket
  }


//   Highcharts.chart('container', {
//     chart: {
//         type: 'column'
//     },
//     title: {
//         text: 'Stacked column chart'
//     },
//     xAxis: {
//         categories: ['Apples', 'Oranges', 'Pears', 'Grapes', 'Bananas']
//     },
//     yAxis: {
//         min: 0,
//         title: {
//             text: 'Total fruit consumption'
//         },
//         stackLabels: {
//             enabled: true,
//             style: {
//                 fontWeight: 'bold',
//                 color: ( // theme
//                     Highcharts.defaultOptions.title.style &&
//                     Highcharts.defaultOptions.title.style.color
//                 ) || 'gray'
//             }
//         }
//     },

 
//     plotOptions: {
//         column: {
//             stacking: 'normal',
//             dataLabels: {
//                 enabled: true
//             }
//         }
//     },
//     series: [{
//         name: 'John',
//         data: [5, 3, 4, 7, 2]
//     }, {
//         name: 'Jane',
//         data: [2, 2, 3, 2, 1]
//     }, {
//         name: 'Joe',
//         data: [3, 4, 4, 2, 5]
//     }]
// });





  chartData = () => {

    let marketchartlist = []
    let marketlist = []

    const { data } = this.props;

    let cleandata = data.map(project => {
      return {
        id: project.opportunityid,
        pursuitname: project.pursuitname,
        datecreated: project.datecreated,
        type: project.type,
        sourcingbroker: `${this.cleanPersonList(project.sourcingbroker)}`,
        bdmember: `${this.cleanPersonList(project.bdmember)}`,
        meetingtype: project.meetingtype,
        broker: `${this.cleanPersonList(project.broker)}`,
        market: `${this.cleanmarket(project.market)}`,
        meetingdate: project.meetingdate,
        opportunityid: project.opportunityid,
        winloss: project.winloss,
        pursuitsize: project.pursuitsize,
        servicelines: project.servicelines
      };
    })

    cleandata.forEach(item => {
      let foundmarket = marketlist.find(element => element.name === item.market)
      //check if exists in array
      if(!(foundmarket === undefined)){

        if((item.winloss).toLowerCase() === "won"){
          foundmarket.data.wins += 1
          foundmarket.data.total += 1
        }else if((item.winloss).toLowerCase() === "lost"){
          foundmarket.data.losses += 1
          foundmarket.data.total += 1
        }else{
          foundmarket.data.open += 1
          foundmarket.data.total += 1
        }
      } else{
        let name = item.market
        if (name === ""){
          name = "Not Specified"
        }
        let newmarket = {
          name: name,
          data: {
            wins: (item.winloss).toLowerCase() === "won" ? 1 : 0,
            losses: (item.winloss).toLowerCase() === "lost" ? 1 : 0,
            open: (item.winloss).toLowerCase() === "open" ? 1 : 0,
            total: 1
          }
        }
        marketlist.push(newmarket)
      }
    })

    marketlist.sort((a,b) => parseFloat(b.data.total) - parseFloat(a.data.total))
    //Begin creation of chart data
    let categories = []
    let series = [
      {
      name: 'Wins',
      data:[],
      color: '#21AE8C',

      },
      {
      name: 'Losses',
      data: [],
      color: '#FD5F00',

      },
      {
      name: 'Open',
      data:[],
      color: '#1A86D0',
        }
      ]



//function(event){console.log("Open Event"); console.log(event)}

marketlist.every(item => {

  categories.push(item.name)
  series.find(item => (item.name).toLowerCase() === 'wins').data.push(item.data.wins)
  series.find(item => (item.name).toLowerCase() === 'losses').data.push(item.data.losses)
  series.find(item => (item.name).toLowerCase() === 'open').data.push(item.data.open)
  return true
})
// this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
return {
  ...this.chartOptions,
  series: series,
  xAxis: {
    // min: 0,
    // max: 7,
    // scrollbar: {
    //     enabled: true
    // },
    tickLength: 0,
    categories: categories
  },

}
};

  redirectToScorecard = (e) => {
    console.log(this)
    this.props.history.push("/app/reporting/brokerscorecard/"+e.target.point.category)
  }

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 250,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'column'
    },
    title: false,
    exporting: {
      enabled: true,
        chartOptions: {
          title: {
            text: null
          }
        }
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      reversedStacks: false,
      labels: {
        enabled: true,
        style: {
          color: "#000000"
        }
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
    },
      stackLabels: {
        enabled: true,
    }
    },
    
    annotations: {
      visible: false
    },
    plotOptions: {
    series: {
    	stacking:'normal',
    },
    dataLabels: {
      enabled: false
  }
  },
  };




  // click: function(e) {
  //   let name = e.target.point.category
  //   console.log(e.target.point.category)
  //   this.redirectToScorecard(e.target.point.category)
  // }

  // click() {
  //   // (this.props.history.push("/app/brokerscorecard/"+this.series.name)).bind(this)
  //   this.redirectToScorecard(this.series.name)
  //
  //   console.log(this.series.name)
  //   console.log(this.category)
  // }



  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                <span className="fw-semi-bold">Top Markets</span> By Opportunities Entered
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
      </Widget>
    );
  }
}

export default withRouter(MarketPerformance)
