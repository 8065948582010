import React, { Component } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import { connect } from "react-redux";
import s from "../../../scorecardv2/Profile.module.scss";
import { updateUserProfile } from "../../../../actions/authentication";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Table,
  Badge,
} from "reactstrap";
import { Multiselect } from "multiselect-react-dropdown";
import { withRouter } from "react-router";
import Loader from "../../../../components/Loader/Loader";
import axios from "axios";
import { Link } from "react-router-dom";

var moment = require("moment");

class SaveCustomDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      finalmarketlist: [],
      charts: {},
      tableRowsCharts: [],
      selectedMarket: [],
      tableRowsFilters: [],
      toggledCharts: [],
      presetFilters: [],
      saving: false,
      dashboardnameerror: false,
      dashboardmarketerror: false,
      dashboarddescription: "",
      filtersFriendly: [],
      friendlyCharts: [],
      dashboardnameinput: "",
      savesuccess: false,
      newdashboardid: "",
    };
    this.marketMultiselectRef = React.createRef();
    this.submitCustomDashboard = this.submitCustomDashboard.bind(this);
    this.redirectToNewDashboard = this.redirectToNewDashboard.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.value;
    const name = target.name;
    const id = target.id;
    if (name == "dashboardnameinput" && value.length == 0) {
      this.setState({
        [name]: value,
        dashboardnameerror: true,
      });
    } else if (name === "dashboardnameinput" && value.length > 0) {
      this.setState({
        [name]: value,
        dashboardnameerror: false,
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  onSelectMarket(selectedList, selectedItem) {
    if (selectedList.length === 0) {
      this.setState({
        finalmarketlist: selectedList,
        dashboardmarketerror: true,
      });
    } else {
      this.setState({
        finalmarketlist: selectedList,
        dashboardmarketerror: false,
      });
    }
  }

  onRemoveMarket(selectedList, removedItem) {
    if (selectedList.length === 0) {
      this.setState({
        finalmarketlist: selectedList,
        dashboardmarketerror: true,
      });
    } else {
      this.setState({
        finalmarketlist: selectedList,
        dashboardmarketerror: false,
      });
    }
  }

  submitCustomDashboard() {
    // Check inputs
    this.setState({
      saving: true,
    });

    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    let data = {
      dashboardName: this.state.dashboardnameinput,
      dashboardDesc: this.state.dashboarddescription,
      dashboardMarkets: this.state.finalmarketlist,
      dashboardFilters: this.state.presetFilters,
      dashboardCharts: this.state.toggledCharts,
      friendlyFilters: this.state.activefilters,
      friendlyCharts: this.state.friendlyCharts,
    };
    axios.post(`/api/v1/userdashboard/1/`, data).then((res) => {
      this.props.dispatch(updateUserProfile(res.data.userprofile));
      this.setState({
        saving: false,
        savesuccess: true,
        newdashboardid: res.data.dashboardid,
      });
    });
  }
  redirectToNewDashboard() {
    this.props.history.push(
      "/app/dashboard/userdashboard/" + this.state.newdashboardid
    );
  }

  componentDidMount() {
    let markets = this.props.markets;
    let currentmarkets = this.props.currentmarkets;
    let finalmarketlist = [];
    let RenderDonut = this.props.dashboardState.RenderDonut;
    let RenderDefaultStats = this.props.dashboardState.RenderDefaultStats;
    let RenderDayChartSL = this.props.dashboardState.RenderDayChartSL;
    let RenderLocationsMap = this.props.dashboardState.RenderLocationsMap;
    let RenderMonthOverMonthMeeting =
      this.props.dashboardState.RenderMonthOverMonthMeeting;
    let RenderWeekOverWeekMeeting =
      this.props.dashboardState.RenderWeekOverWeekMeeting;
    let RenderMonthOverMonth = this.props.dashboardState.RenderMonthOverMonth;
    let RenderMarketPerformance =
      this.props.dashboardState.RenderMarketPerformance;
    let RenderWeekOverWeek = this.props.dashboardState.RenderWeekOverWeek;
    let RenderSourcingMethodChart =
      this.props.dashboardState.RenderSourcingMethodChart;
    let RenderIndustryChart = this.props.dashboardState.RenderIndustryChart;
    let RenderLineChart = this.props.dashboardState.RenderLineChart;
    let RenderServiceLineChart =
      this.props.dashboardState.RenderServiceLineChart;
    let RenderBrokerPerf = this.props.dashboardState.RenderBrokerPerf;
    let RenderTableData = this.props.dashboardState.RenderTableData;
    let RenderTechUsedAverage = this.props.dashboardState.RenderTechUsedAverage;

    let charts = {
      RenderDonut: {
        name: "Status Chart",
        value: RenderDonut,
      },
      RenderDefaultStats: {
        name: "Overall Stats",
        value: RenderDefaultStats,
      },
      RenderDayChartSL: {
        name: "Service Line Opportunities Entered Daily",
        value: RenderDayChartSL,
      },
      RenderLocationsMap: {
        name: "Opportunities by Location Map",
        value: RenderLocationsMap,
      },
      RenderMonthOverMonthMeeting: {
        name: "Meetings # Month Over Month",
        value: RenderMonthOverMonthMeeting,
      },
      RenderWeekOverWeekMeeting: {
        name: "Meetings # Week Over Week",
        value: RenderWeekOverWeekMeeting,
      },
      RenderMonthOverMonth: {
        name: "Opps. Entered Month Over Month",
        value: RenderMonthOverMonth,
      },
      RenderWeekOverWeek: {
        name: "Opps. Entered Week Over Week",
        value: RenderWeekOverWeek,
      },
      RenderMarketPerformance: {
        name: "Opportunities By Location Bar Chart",
        value: RenderMarketPerformance,
      },
      RenderSourcingMethodChart: {
        name: "Sourcing Methodology Chart",
        value: RenderSourcingMethodChart,
      },
      RenderIndustryChart: {
        name: "Industry Chart",
        value: RenderIndustryChart,
      },
      RenderLineChart: {
        name: "Opportunities Entered By Market Timeline",
        value: RenderLineChart,
      },
      RenderServiceLineChart: {
        name: "Opportunities Entered By Service Line Timeline",
        value: RenderServiceLineChart,
      },
      RenderBrokerPerf: {
        name: "Broker Performance Chart",
        value: RenderBrokerPerf,
      },
      RenderTableData: {
        name: "Opportunity Table",
        value: RenderTableData,
      },
      RenderTechUsedAverage: {
        name: "Average Tech. Used",
        value: RenderTechUsedAverage,
      },
    };

    charts = Object.keys(charts)
      .filter((key) => charts[key].value)
      .reduce(
        (newObj, currKey) => ((newObj[currKey] = charts[currKey]), newObj),
        {}
      );

    const chartRows = Object.keys(charts).map((key) => {
      return (
        <tr key={key}>
          <td>{charts[key].name}</td>
        </tr>
      );
    });

    let toggledCharts = this.props.dashboardState;
    toggledCharts = Object.keys(toggledCharts)
      .filter((key) => key.toLowerCase().includes("render"))
      .reduce(
        (newObj, currKey) => (
          (newObj[currKey] = toggledCharts[currKey]), newObj
        ),
        {}
      );

    let friendlyCharts = Object.keys(charts).map((key) => {
      return charts[key].name;
    });

    let activefilters = this.props.filters.activefilters;
    const filterRows = activefilters.map((key) => {
      return (
        <tr key={key.type}>
          <td>{key.value}</td>
        </tr>
      );
    });

    markets.forEach((item, index) => {
      if (
        item.marketname !== "JTE" &&
        item.marketname !== "National" &&
        item.marketname !== "All Markets"
      ) {
        let newitem = {
          name: item.marketname,
          id: item.market_id,
        };
        finalmarketlist.push(newitem);
      }
    });

    let selectedMarket = finalmarketlist.filter((item) =>
      currentmarkets.some((current) => item.id === current.market_id)
    );

    this.setState({
      markets: finalmarketlist,
      finalmarketlist: selectedMarket,
      selectedMarket: selectedMarket,
      activefilters: activefilters,
      tableRowsCharts: chartRows,
      tableRowsFilters: filterRows,
      toggledCharts: toggledCharts,
      presetFilters: this.props.filterSnapshot,
      friendlyCharts: friendlyCharts,
    });
  }

  render() {
    return (
      <>
        {!this.state.savesuccess && !this.state.saving ? (
          <>
            <Row className="mb-3 mx-auto">
              <Col xs={12} xl={12} lg={12} md={12}>
                <div className="mt-lg">
                  Below are the settings used by this custom dashboard. To
                  change settings, navigate to a Market dashboard and create a
                  new custom dashboard.
                </div>
              </Col>
            </Row>

            <Row className="mx-auto">
              <Col xs={12} xl={12} lg={12} md={12}>
                <div>
                  <div>
                    <ul
                      className={cx(
                        s.profileContacts,
                        "mt-sm",
                        "settings-left"
                      )}
                    >
                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Basic Information:{" "}
                        </span>
                      </li>
                      <li>
                        <FormGroup row>
                          <Label
                            for="normal-field"
                            md={4}
                            className="text-md-left"
                          >
                            Dashboard name:
                          </Label>
                          <Col md={8}>
                            <Input
                              ref="dashboardnameinput"
                              onChange={this.handleInputChange.bind(this)}
                              name="dashboardnameinput"
                              type="text"
                              id="dashboardnameinput"
                              placeholder="Give your dashboard a custom name"
                            />
                            {this.state.dashboardnameerror ? (
                              <span class="help-block text-danger">
                                Dashboard name is required. Please enter a
                                custom name for your dashboard.
                              </span>
                            ) : null}
                          </Col>
                        </FormGroup>

                        <FormGroup row>
                          <Label
                            for="normal-field"
                            md={4}
                            className="text-md-left"
                          >
                            Dashboard description:
                          </Label>
                          <Col md={8}>
                            <Input
                              rows="4"
                              ref="dashboarddescription"
                              onChange={this.handleInputChange.bind(this)}
                              name="dashboarddescription"
                              id="dashboarddescription"
                              type="textarea"
                              maxlength="250"
                              placeholder="Brief description of your dashboard"
                              id="default-textarea"
                            />
                          </Col>
                        </FormGroup>
                      </li>

                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Choose Markets:{" "}
                        </span>
                      </li>
                      <li>
                        <Multiselect
                          options={this.state.markets} // Options to display in the dropdown
                          onSelect={this.onSelectMarket.bind(this)} // Function will trigger on select event
                          selectedValues={this.state.selectedMarket}
                          onRemove={this.onRemoveMarket.bind(this)} // Function will trigger on remove event
                          id="techUsedFilter"
                          ref={this.marketMultiselectRef}
                          displayValue="name" // Property name to display in the dropdown options
                        />
                        {this.state.dashboardmarketerror ? (
                          <span class="help-block text-danger">
                            At least one market must be selected to save this
                            dashboard.
                          </span>
                        ) : null}
                      </li>
                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Default Charts Shown:{" "}
                        </span>
                      </li>
                      <li>
                        <div className="mt-sm mb-sm">
                          To modify, close this window and toggle the desired
                          charts within the "Toggle Charts" panel.
                        </div>
                        <Table className="table-striped settingstable">
                          <thead>
                            <tr>
                              <th>Chart Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tableRowsCharts.length > 0 ? (
                              <>{this.state.tableRowsCharts}</>
                            ) : (
                              <tr>
                                <td>No changes specified.</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </li>
                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Default Filters:{" "}
                        </span>
                      </li>
                      <li>
                        <div className="mt-sm mb-sm">
                          To modify, close this window and filter the dashboard
                          to display your desired report.
                        </div>
                        <Table className="table-striped settingstable">
                          <thead>
                            <tr>
                              <th>Filter Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.tableRowsFilters.length > 0 ? (
                              <>{this.state.tableRowsFilters}</>
                            ) : (
                              <tr>
                                <td>No changes specified.</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mx-auto">
              <Col xs={12} xl={12} lg={12} md={12}>
                <Button
                  color="primary"
                  disabled={
                    this.state.saving ||
                    this.state.dashboardnameerror ||
                    this.state.dashboardmarketerror ||
                    this.state.dashboardnameinput.length === 0 ||
                    this.state.finalmarketlist.length === 0
                  }
                  className="float-right btn-md"
                  onClick={this.submitCustomDashboard}
                >
                  Save Dashboard
                </Button>
              </Col>
            </Row>
          </>
        ) : this.state.saving ? (
          <Row>
            <Col className="text-center" xs={12} xl={12} lg={12} md={12}>
              <span className="fw-semi-bold settings-title">
                Your dashboard is saving
              </span>
              <Loader />
              <div className="mb-5"></div>
            </Col>
          </Row>
        ) : this.state.savesuccess ? (
          <Row>
            <Col className="text-center" xs={12} xl={12} lg={12} md={12}>
              <span className="fw-semi-bold settings-title">
                Your dashboard has been saved
              </span>
              <div className="mt-sm mb-sm">
                To view your custom dashboard, refresh your browser and navigate
                to the Market Selector page.
              </div>
              <div className="mt-sm mb-sm">or</div>
              <Link
                to={`/app/dashboard/userdashboard/${this.state.newdashboardid}`}
              >
                <Button onClick={this.props.togglePanel} color="primary">
                  Click Here
                </Button>
              </Link>

              {/* <Button onClick={this.redirectToNewDashboard} color="primary">Click Here</Button> */}
            </Col>
          </Row>
        ) : null}
      </>
    );
  }
}

export default withRouter(connect(null)(SaveCustomDashboard));
