import React, { Component } from 'react';
import cx from 'classnames';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { connect } from 'react-redux';
import Widget from '../../../../components/Widget';

import s from './BigStat.module.scss';

var moment = require('moment');

class BigStat extends Component {

  state = { simpleSelectDropdownValue: 'This Month' }

  changeSelectDropdownSimple = (e) => {
    this.setState({ simpleSelectDropdownValue: e.currentTarget.textContent });
  }

  range(timeframe) {
    if(timeframe === "This Month"){
      return "month"
    }
    else if(timeframe === "This Year"){
      return "year"
    }
    else if(timeframe === "Total"){
      return "total"
    }
  }

  render() {
    const { opps, sl, updates } = this.props;

    let filteredprojects = opps.filter(object => object.servicelines.length > 0 && object.servicelines[0].servicelinename.toLowerCase().includes(sl.toLowerCase()))
    let chosenrange = this.state.simpleSelectDropdownValue
    let filteredwinsobj = filteredprojects.filter(object => object.winloss === "Won")

    let filteredwonfromupdates = filteredprojects.filter(x =>updates.OppStatusUpdates.some(y=>y.opportunityid==x.opportunityid) && updates.OppStatusUpdates.some(z => moment(z.updateTime, "MM-DD-YYYY hh:mm:ss").isSame(moment(), `${this.range(this.state.simpleSelectDropdownValue)}`) && x.winloss === "Won")).length   
    let newopps = filteredprojects.filter(x => moment(x.datecreated, "MM-DD-YYYY hh:mm:ss").isSame(moment(), `${this.range(this.state.simpleSelectDropdownValue)}`)).length
   
    let wins = filteredwinsobj.length

    let total = opps.filter(object => object.servicelines.length > 0 && object.servicelines[0].servicelinename.toLowerCase().includes(sl.toLowerCase()) && (object.winloss === "Won" || object.winloss === "Lost")).length

    let color;

    let winrate= (wins/total * 100).toFixed(2)

    let increasednumber = () => {
      let newwins = filteredwonfromupdates
      if(this.range(this.state.simpleSelectDropdownValue) === 'total'){
        return filteredprojects.length
      }else{
        return newwins
      }
    }


    let increasedopps = () => {
      if(this.range(this.state.simpleSelectDropdownValue) === 'total'){
        return wins
      }else{
   
        //Subtract the current win rate by the projects that won either last month or year divided by
        //  let newwins = filteredupdates.filter(object => object.winloss === "Won" && moment(object.meetingdate).isSame(moment().startOf(range(chosenrange)).subtract(1, `${range(chosenrange)}s`), range(chosenrange))).length))
        let newoppsrange = newopps
        return newoppsrange
      }
    }

    // let increasednumber = () => {
    //   if(range(chosenrange) === 'total'){
    //     return filteredprojects.length
    //   }else{
    //     return Math.max(0, (filteredprojects.filter(object => moment(object.meetingdate).isSame(new Date(), range(chosenrange))).length) - (filteredprojects.filter(object =>  moment(object.meetingdate, "MM-DD-YYYY hh:mm:ss").isSame(moment().subtract(1, `${range(chosenrange)}s`), range(chosenrange))).length))
    //   }
    // }

    // let increasedpercent = () => {
    //   if(range(chosenrange) === 'total'){
    //     return winrate
    //   }else{
    //     //Subtract the current win rate by the projects that won either last month or year divided by
    //      let winchangerate = (winrate - (filteredprojects.filter(object => object.winloss === "Won" && moment(object.meetingdate).isSame(moment().startOf(range(chosenrange)).subtract(1, `${range(chosenrange)}s`), range(chosenrange))).length) / (filteredprojects.filter(object =>  object.winloss === "Won" || object.winloss === "Lost" && moment(object.meetingdate).isSame(moment().startOf(range(chosenrange)).subtract(1, `${range(chosenrange)}s`), range(chosenrange))).length) * 100).toFixed(2)

    //      if(Number.isNaN(winchangerate)){
    //        return 0.00
    //      }else{
    //        return winchangerate
    //      }
    //   }
    // }


    let increase = (timeframe, numberfunction) => {
      if(timeframe === "total"){
        return true
      }
      else{
        if(numberfunction > 0){
          return true
        }else if(numberfunction < 0){
          return false
        }else{
          return undefined
        }
      }
    }
    return (
      <>
      {
      filteredprojects.length>0
      ?
      <div className="pb-xlg h-100">
        <Widget
          className="mb-0 h-100"
          bodyClass={`mt p-0`}
          title={
            <div className="d-flex justify-content-between flex-wrap">
              <h4 style={{ width: '100px !important' }} className={cx('d-flex align-items-center pb-1', )}>
                <span className={`circle bg-${"primary"} mr-sm`} style={{ fontSize: '6px' }} />
                   <span  className="fw-semi-bold">{sl}</span>&nbsp;
              </h4>
              <UncontrolledButtonDropdown className={s.dropdown}>
                <DropdownToggle
                  caret color="default"
                  className="dropdown-toggle-split mr-xs"
                >
                  {this.state.simpleSelectDropdownValue}&nbsp;&nbsp;
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.changeSelectDropdownSimple}>
                    This Month
                  </DropdownItem>
                  <DropdownItem onClick={this.changeSelectDropdownSimple}>
                    This Year
                  </DropdownItem>
                  <DropdownItem onClick={this.changeSelectDropdownSimple}>
                    Total
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          }
        >
          <h4 className="fw-semi-bold mb-2 px-4">{ winrate }%</h4>
          <p className="text-muted mb-2 px-4 mr"><small>Current Win Rate</small></p>
          <div className="d-flex border-top">
            <div className="w-50 border-right py-3 pr-2 pl-4">
              <div className="d-flex align-items-start h3">
                <h6>+ {increasednumber()}</h6>
                <i
                  className={`la la-arrow-right ml-sm text-${(increase(this.state.simpleSelectDropdownValue, increasednumber())) ? ('success') : ((increase(this.state.simpleSelectDropdownValue, increasednumber()) === undefined)?('info'):('danger'))}
                  rotate-${(increase(this.range(this.state.simpleSelectDropdownValue),increasednumber())) ? ('315') : ((increase(chosenrange, increasednumber()) === undefined)?('25'):('45'))}`}
                />
              </div>
              <p className="text-muted mb-0 mr"><small># Wins { this.state.simpleSelectDropdownValue }</small></p>
            </div>
            <div className="w-50 py-3 pl-2">
              <div className="d-flex align-items-start h3">
                <h6>+ {increasedopps()}</h6>
                  <i
                    className={`la la-arrow-right ml-sm text-${(increase(this.state.simpleSelectDropdownValue, increasedopps())) ? ('success') : ((increase(this.state.simpleSelectDropdownValue, increasedopps()) === undefined)?('info'):('danger'))}
                    rotate-${(increase(this.range(this.state.simpleSelectDropdownValue),increasedopps())) ? ('315') : ((increase(this.state.simpleSelectDropdownValue, increasedopps()) === undefined)?('25'):('45'))}`}
                  />
              </div>
              <p className="text-muted mb-0 mr"><small>New Opps { this.state.simpleSelectDropdownValue }</small></p>
            </div>
          </div>
        </Widget>
      </div>
      :
      null
    }
    </>
    );
  }
}


function mapStateToProps(state) {
  return {
      updates: state.projects.alloppupdates,
  }
}

export default connect(mapStateToProps)(BigStat);
