import React, { useState } from "react";
import { render } from "react-dom";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

const getData = (dataIn, volLine, wrLine) => {
  return {
    yAxis: {
      labels: {
        format: "{value}%",
      },
      min: 0,
      max: 100,
      plotLines: [
        {
          value: wrLine,
          width: 2,
        },
      ],
    },
    xAxis: {
      gridLineWidth: 1,
      label: "Volume",
      startOnTick: true,
      endOnTick: true,
      showLastLabel: true,
      plotLines: [
        {
          value: volLine,
          width: 2,
        },
      ],
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    chart: {
      type: "scatter",
      zoomType: "xy",
    },
    tooltip: {
      formatter: function () {
        return (
          "<b>" +
          this.key +
          "</b> </br><b>Win Rate: </b>" +
          this.y.toFixed(2) +
          "%</br>" +
          "<b>Volume: </b>" +
          this.x
        );
      },
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          format: "{point.name}",
        },
        // type: "scatter",
        name: "Seller",
        data: dataIn.map((e) => ({
          x: e.opp_count,
          y: e.win_rate * 100,
          name: e.name,
        })),
      },
    ],
  };
};

const SAChart = ({ wrLine, volLine, brokerData }) => {
  const [chartOptions, setChartOptions] = useState();

  React.useEffect(() => {
    setChartOptions(getData(brokerData, volLine, wrLine));
  }, [brokerData, volLine, wrLine]);

  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default SAChart;
