import React from 'react';
import {
    Row,
    Col
  } from 'reactstrap';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, useTheme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
      padding: "6%"
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 151,
    },
  }));

const TopStats = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    return(
        <Row>
            {props?.teams?.map((item, index) => (
                <Col key={index} lg={3}>
                <div style={{backgroundColor: "white"}} className={props?.winningTeam.name === item.name ? "winningShadowTop" : "scorecardShadowTop"}>
                    <Row className='align-items-center'>
                        <Col lg={4}>
                            <CardMedia
                            className="topScoreImage"
                            image={item.logo}
                        />
                        </Col>
                        <Col lg={8} className="text-center">
                        <h2>{props.topScores.find(score => score.team?.name === item.name)?.totalScore}</h2>
                        </Col>
                    </Row>
                    
                </div>
                </Col>
            ))}
        </Row>
    );
}

export default TopStats;