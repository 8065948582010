import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Moment from 'react-moment'
var moment = require('moment');

export default class BrokerLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.BrokerChart = React.createRef()
  }


  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };



  chartData = () => {
      const { data } = this.props;
      // Workingobject will contain all servicelines and dates in addition to the total
      let workingobjects = [
        {
          name: 'Total Activity',
          data: []
        }
      ]

      let ticks =[]
      let series =[]
      // formatteddate = moment(date).format('MMMM Do YYYY, h:mm:ss a')
      function containsObject(a, b) {
            for (var i in b) {
              if (b[i].date === a) {
                b[i].qty += 1;
                return true
                break; //Stop this loop, we found it!
              }
            }
            return false
              }


    data.forEach(item => {
      let date =  moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss');
      let shortdate = moment(date).format('MMM YYYY')
      date = moment.utc(shortdate, 'MMM YYYY').valueOf();


      //Eventual settings for objects
      let chartobjects = [{
        name: 'Total Activity',
        data: [],
        color: 'rgba(26,134,208,.2)',
        type: 'areaspline',
        fillOpacity: 0.2,
        lineWidth: 0
      }]

      // Check to see if date is in workingobject
      let founddate = workingobjects[0].data.find(element => element.x === date)

      // either create the new date obj in the Total object or add to it.
      if(!(founddate === undefined)){
        founddate.y += 1
      } else {
        let newitem = {
          x: date,
          y: 1
        }
        workingobjects[0].data.push(newitem)
      }

    })



      workingobjects.forEach(slobject => {

        let thename = slobject.name;
        let tabledata = slobject.data.map(({date, data}) => [date, data]);
        // object dates and data are being converted to arrays
        let ydata = slobject.data.map(({data}) => [data]);
        let newchartobject;

        if(thename === 'Total Activity'){
          let newchartobject = {
            name: thename,
            data: slobject.data,
            color: 'rgba(26,134,208,.2)',
            type: 'areaspline',
            fillOpacity: 0.2,
            lineWidth: 0
          };
          series.push(newchartobject)
        } else{
          let newchartobject = {
            type: 'spline',
            name: thename,
            data: slobject.data
          };
          series.push(newchartobject)
        }
      })


     ticks = workingobjects[0].data.map(({date}) => [new Date(date)]);


    return {
      ...this.ticks,
      ...this.chartOptions,
      series
    }
  }



  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      height: 150,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: {
      text: null
    },
    exporting: {
        enabled: false,
        chartOptions:{
                 title: {
                     text: null
                 }
             },
      scale: 3,
      fallbackToExportServer: false
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
        }
      }
    },
    annotations: null,
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        // pointInterval: 3600000 * 25, // every day
        pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${this.series.name} at ${this.y.toFixed(2)}</span>`;
          }
        }
      },
    }
  };


  render() {

    const { isReceiving, data } = this.props;

    return (

      <HighchartsReact ref={this.BrokerChart} options={this.chartData()} />

    );
  }
}
