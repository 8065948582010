import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';

// const styles = StyleSheet.create({
//   page: { backgroundColor: '#edf0f2', fontFamily: "Montserrat"},
//
//   section: { color: 'black', textAlign: 'center', margin: 30 },
//
//   statWidget: {display: 'block', position: 'relative', marginBottom: 40, backgroundColor:'#fff', fontSize: "10pt", width: '25%', height: '25%', borderRadius: 0.35, boxShadow: '0 23px 20px -20px' },
//
//   widgetHeader: {padding:"padding: 15px 20px 0", fontSize: "15pt" },
//
//   widgetHeaderBold: {fontWeight:"600"}
//
// });

Font.register({ family: 'KadwaRegular', src:'https://fonts.gstatic.com/s/kadwa/v2/rnCm-x5V0g7ipiTAT8M.ttf' } );
Font.register({ family: 'KadwaBold', src: 'https://fonts.gstatic.com/s/kadwa/v2/rnCr-x5V0g7ipix7atM5kn0.ttf' } );
Font.register( { family: 'MontserratRegular', src: 'https://fonts.gstatic.com/s/montserrat/v12/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf' } );

const Invoice = () => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Text>John Doe Inc</Text>
        </View>
        <View style={styles.headerRight}>
          <Text>John Doe</Text>
          <Text></Text>
          <Text>123 Fake Street</Text>
          <Text>Fakeville</Text>
          <Text>A12 3BC</Text>
        </View>
      </View>

      <View style={styles.ribbon}>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Date</Text>
          <Text style={styles.ribbonValue}>06/01/2019</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Invoice #</Text>
          <Text style={styles.ribbonValue}>123</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Amount</Text>
          <Text style={styles.ribbonValue}>£4500.00</Text>
        </View>
      </View>

      <View style={styles.table}>
        <View style={styles.tableRowB}>
          <Text style={styles.tableHeadingA}>
            Service
          </Text>
          <Text style={styles.tableHeadingB}>
            Description
          </Text>
          <Text style={styles.tableHeadingC}>
            Amount
          </Text>
        </View>

        <View style={styles.tableRowA}>
          <Text style={styles.serviceName}>
            Design
          </Text>
          <Text style={styles.serviceDescription}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          </Text>
          <Text style={styles.serviceAmount}>
            £1000.00
          </Text>
        </View>

        <View style={styles.tableRowB}>
          <Text style={styles.serviceName}>
            Development
          </Text>
          <Text style={styles.serviceDescription}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          </Text>
          <Text style={styles.serviceAmount}>
            £2000.00
          </Text>
        </View>

        <View style={styles.tableRowA}>
          <Text style={styles.serviceName}>
            Consultation
          </Text>
          <Text style={styles.serviceDescription}>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          </Text>
          <Text style={styles.serviceAmount}>
            £1500.00
          </Text>
        </View>

        <View style={styles.summary}>
          <Text style={styles.summaryMeta}>
            Due Date: 19/01/2019
          </Text>
          <Text style={styles.summaryAmount}>
            £4500.00
          </Text>
        </View>
      </View>

      <Text style={styles.howToPay}>Paying your invoice</Text>

      <View style={styles.ribbonGrey}>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Bank Name</Text>
          <Text style={styles.ribbonValue}>Lloyds</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Sort Code</Text>
          <Text style={styles.ribbonValue}>00-00-00</Text>
        </View>
        <View style={styles.ribbonBox}>
          <Text style={styles.ribbonLabel}>Account Number</Text>
          <Text style={styles.ribbonValue}>1234 5678</Text>
        </View>
      </View>

      <View style={styles.footer}>
        <Text>
          example.com &bull;
          johndoe@example.com &bull;
          0123 456 7890
        </Text>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  header: {
    borderTopWidth: 4,
    borderTopColor: '#0084B4',
    color: '#898989',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  headerLeft: {
    textAlign: 'left',
    flex: 1,
    alignSelf: 'center',
    fontSize: 32,
    fontWeight: 900,
    lineHeight: 1,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  headerRight: {
    textAlign: 'right',
    fontSize: 12,
    flex: 1,
    fontFamily: 'MontserratRegular',
  },

  ribbon: {
    backgroundColor: '#0084B4',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: '#FFF',
  },
  ribbonGrey: {
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
    marginBottom: 20,
    textAlign: 'center',
    color: '#0084B4',
  },
  ribbonBox: {
    width: '33.333333%',
  },
  ribbonLabel: {
    fontSize: 14,
    fontFamily: 'KadwaBold',
  },
  ribbonValue: {
    fontSize: 28,
    fontFamily: 'KadwaBold',
  },

  table: {
    paddingHorizontal: 20,
    display: 'flex',
    marginBottom: 20,
  },
  tableRowA: {
    backgroundColor: '#EDEDED',
    display: 'flex',
    flexDirection: 'row',
    padding: 10,
  },
  tableRowB: {
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  serviceName: {
    fontSize: 10,
    width: '25%',
    fontFamily: 'KadwaBold',
  },
  serviceDescription: {
    fontSize: 10,
    width: '50%',
    fontFamily: 'MontserratRegular',
  },
  serviceAmount: {
    fontSize: 20,
    width: '25%',
    textAlign: 'right',
    fontFamily: 'KadwaBold',
  },
  tableHeadingA: {
    width: '25%',
    fontSize: 14,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  tableHeadingB: {
    width: '50%',
    fontSize: 14,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  tableHeadingC: {
    width: '25%',
    textAlign: 'right',
    fontSize: 14,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },
  summary: {
    backgroundColor: '#0084B4',
    color: '#FFF',
    padding: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  summaryMeta: {
    width: '75%',
    fontFamily: 'KadwaBold',
  },
  summaryAmount: {
    fontSize: 20,
    width: '25%',
    textAlign: 'right',
    fontFamily: 'KadwaBold',
  },

  howToPay: {
    paddingHorizontal: 20,
    textAlign: 'center',
    fontSize: 20,
    color: '#0084B4',
    fontFamily: 'KadwaBold',
  },

  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,
    borderTopColor: '#EDEDED',
    borderTopWidth: 1,
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'MontserratRegular',
  },
});


const Report = (props) => {
  return <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>{props.object.pursuitname}</Text>
        <Text style={styles.statWidget}>
          <Text style={styles.widgetHeader}>Pursuit <Text style={styles.widgetHeaderBold}> Status</Text></Text>


        </Text>
        <Text>Section 2asdfasdfasdfad
        2asdfasdfasdfadasdfasdfa

        2asdfasdfasdfad
        2asdfasdfasdfadasdfasdfa</Text>
      </View>
    </Page>

  </Document>
};


export default Invoice
