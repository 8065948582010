/* eslint class-methods-use-this: ["error", { "exceptMethods": ["printInvoice"] }] */
import React from "react";
import {
  Row,
  Col,
  Table,
  ButtonToolbar,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DateRangePickerWrapper from "../DateRange/DateRange";
import { connect } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import axios from "axios";
import s from "../Invoice.module.scss";
import DataTable from "../logstable/DataTable";
import NoAccess from "./components/NoAccess";
import Widget from "../../../components/Widget/Widget";
import RefreshLogDetails from "./components/RefreshLogDetails/RefreshLogDetails";
var moment = require("moment");

class RefreshEmailDetails extends React.Component {
  constructor(props) {
    super(props);
    this.updateLocalStartDate = this.updateLocalStartDate.bind(this);
    this.updateLocalEndDate = this.updateLocalEndDate.bind(this);
    this.state = {
      success: false,
      fetching: false,
      error: false,
      complete: false,
      successCustomDate: false,
      fetchingCustomDate: false,
      errorCustomDate: false,
      completeCustomDate: false,
      errortextCustomDate: "",
      missingDateError: false,
      startdate: null,
      enddate: null,
      errortext: "",
      editReportModal: false,
      createReportModal: false,
      customRangeModal: false,
      fetchingError: false,
      errorData: "",
      currentReportDetails: null,
      emailreports: [],
      emaillogs: [],
    };
    this.toggle = this.toggle.bind(this);
  }

  updateLocalStartDate(val) {
    this.setState({
      startdate: val,
    });
  }

  updateLocalEndDate(val) {
    this.setState({
      enddate: val,
    });
  }

  handleClick(cell) {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios
      .get(`/api/v1/sendcampaignbrokerlist/${cell}/`, { responseType: "blob" })
      .then(
        (res) => {
          let blob = new Blob([res.data], { type: "text/csv" });
          blob.name = `${
            this.state.currentReportDetails.name
          }-${moment().format("MM-DD-YYYY")}.csv`;
          let url = window.URL.createObjectURL(blob);
          window.open(url);
          this.setState({
            complete: true,
            success: true,
            fetching: false,
          });
        },
        (error) => {
          let erro = JSON.stringify(error.response.data);
          erro = JSON.parse(erro);
          let errormessage = "Some Error Occured";
          if (erro.Error) {
            errormessage = erro.Error;
          }

          this.setState({
            error: true,
            errortext: errormessage,
            fetching: false,
            complete: true,
          });
        }
      );
  }

  handleCustomTest(cell) {
    if (this.state.startdate && this.state.enddate) {
      this.setState({
        fetchingCustomDate: true,
        customRangeModal: false,
      });
      let startdate = moment(this.state.startdate).format("MM DD YYYY");
      let enddate = moment(this.state.enddate).format("MM DD YYYY");

      axios.defaults.headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${this.props.token}`,
      };
      axios
        .get(
          `/api/v1/sendcustomdatetest/${cell}/?startDate=${startdate}&endDate=${enddate}`
        )
        .then(
          (res) => {
            this.setState({
              completeCustomDate: true,
              successCustomDate: true,
              fetchingCustomDate: false,
            });
          },
          (error) => {
            let erro = JSON.stringify(error.response.data);
            erro = JSON.parse(erro);
            let errormessage = "Some Error Occured";
            if (erro.Error) {
              errormessage = erro.Error;
            }

            this.setState({
              errorCustomDate: true,
              errortextCustomDate: errormessage,
              fetchingCustomDate: false,
              completeCustomDate: true,
            });
          }
        );
    } else {
      this.setState({
        missingDateError: true,
      });
    }
  }

  toggle(id) {
    this.setState((prevState) => ({
      [id]: !prevState[id],
    }));
  }

  getCurrentReportDetails() {
    let foundReport = this.props.refreshemails.find(
      (item) => this.props.currentRefreshEmailID === item.id
    );
    this.setState({
      currentReportDetails: foundReport,
    });
  }

  componentDidMount() {
    if (!this.props.noReports) {
      this.getCurrentReportDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentRefreshEmailID !== this.props.currentRefreshEmailID) {
      this.getCurrentReportDetails();
      this.setState({
        success: false,
        fetching: false,
        error: false,
        complete: false,
        errortext: "",
        successCustomDate: false,
        fetchingCustomDate: false,
        errorCustomDate: false,
        completeCustomDate: false,
        errortextCustomDate: "",
        missingDateError: false,
        startdate: null,
        enddate: null,
      });
    }
  }

  render() {
    const { currentReportDetails, emaillogs, customRangeModal } = this.state;

    let marketsincluded = [];
    let settingsIncluded = [];

    if (currentReportDetails) {
      if (currentReportDetails.markets.length >= 15) {
        marketsincluded.push("All Markets");
      } else {
        currentReportDetails.markets.forEach((item) => {
          marketsincluded.push(item.marketname);
        });
      }

      if (currentReportDetails.timerange.length > 0) {
        settingsIncluded.push({
          key: "Time range:",
          value: `${currentReportDetails.timerange}`,
        });
      }
      if (currentReportDetails.only_include_list.length > 0) {
        let includeBrokers = currentReportDetails.only_include_list.map(
          (item) => {
            return item.fullname;
          }
        );
        includeBrokers = includeBrokers.join(", ");
        settingsIncluded.push({
          key: "Only include:",
          value: `${includeBrokers}`,
        });
      }

      if (currentReportDetails.exclude_list.length > 0) {
        let includeBrokers = currentReportDetails.exclude_list.map((item) => {
          return item.fullname;
        });
        includeBrokers = includeBrokers.join(", ");
        settingsIncluded.push({ key: "Exclude:", value: `${includeBrokers}` });
      }
      if (currentReportDetails.customtext.length > 0) {
        settingsIncluded.push({ key: "Custom text:", value: "Yes" });
      }
    }

    return (
      <>
        <Row className="mb-2">
          <Col lg={8}>
            <h2 className="text-muted no-margin">Refresh campaign details:</h2>
          </Col>
          <Col lg={3}>
            <Button
              color="primary"
              className="float-right"
              onClick={() => this.props.toggleCreateorEdit("createReportModal")}
            >
              Create Campaign
            </Button>
          </Col>
        </Row>

        {currentReportDetails ? (
          <Row>
            <Col lg={11}>
              <Row className={s.root}>
                <Col xs={12}>
                  <Widget bodyClass={"p-0"}>
                    <div className="widget">
                      <header>
                        <Row className="mb-lg">
                          <Col sm={6} lg={8} className="col-print-6">
                            <h2 className="text-muted no-margin">
                              {currentReportDetails.name}
                            </h2>
                            {currentReportDetails.createdby ? (
                              <>
                                {currentReportDetails.createdby.fullname ===
                                  this.props.userprofile.fullname ||
                                currentReportDetails.from_member?.fullname ===
                                  this.props.userprofile.fullname ||
                                this.props.userprofile.fullname === "Admin" ? (
                                  <div>
                                    <Button
                                      onClick={() =>
                                        this.props.toggleCreateorEdit(
                                          "editReportModal"
                                        )
                                      }
                                      color="primary"
                                      className="float-left btn-link"
                                    >
                                      Edit Campaign
                                    </Button>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </Col>
                          <Col lg={4}>
                            <span
                              className={`ml-auto ${s.timestamp}createdByText float-right`}
                            >
                              <Tooltip
                                title={
                                  <div style={{ fontSize: "12px" }}>
                                    {currentReportDetails.active
                                      ? "Active"
                                      : "Not Active"}
                                  </div>
                                }
                                placement="top"
                              >
                                <span
                                  className={`emailStatus ${
                                    currentReportDetails.active
                                      ? "bg-success"
                                      : "bg-warning"
                                  }`}
                                ></span>
                              </Tooltip>
                            </span>
                            <br></br>
                            <p className="float-right">
                              Created on:{" "}
                              {moment(
                                currentReportDetails.datecreated,
                                "YYYY-MM-DD hh:mm:ss"
                              ).format("MMM DD, YYYY")}
                            </p>
                            <br></br>
                            {currentReportDetails.createdby ? (
                              <div className="createdByText">
                                <p className="float-right">
                                  Created by:{" "}
                                  {currentReportDetails.createdby.fullname}
                                </p>
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                      </header>
                      <section>
                        <Row className="">
                          <Col lg={6} className="border-right border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                Markets included:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {marketsincluded.map((item) => {
                                    return <li>{item}</li>;
                                  })}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6} className="border-bottom">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={4} className="fw-semi-bold">
                                Filter settings:
                              </Col>
                              <Col lg={8}>
                                <ul className="mb-0">
                                  {settingsIncluded.length > 0
                                    ? settingsIncluded.map((item) => {
                                        return (
                                          <li>
                                            <u>{item.key}</u>&nbsp;{item.value}
                                          </li>
                                        );
                                      })
                                    : "None"}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6} className="border-right ">
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                From:
                              </Col>
                              <Col lg={6}>
                                {currentReportDetails.from_member
                                  ? `${currentReportDetails.from_member.fullname}`
                                  : "None"}
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={6}>
                            <Row className="pb-2 pt-2 email-desc-cell">
                              <Col lg={6} className="fw-semi-bold">
                                CC:
                              </Col>
                              <Col lg={6}>
                                <ul className="mb-0">
                                  {currentReportDetails.cc_members.map(
                                    (item) => {
                                      return <li>{item.fullname}</li>;
                                    }
                                  )}
                                </ul>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </section>
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <NoAccess
            header={"Email Report"}
            message={"No report details to display."}
          />
        )}
        <h2 className="text-muted no-margin">Campaign log details:</h2>
        {this.props.currentRefreshLog ? (
          <RefreshLogDetails
            setRefreshLogs={this.props.setRefreshLogs}
            currentID={this.props.currentRefreshLog}
            getRefreshEmailLogs={this.props.getRefreshEmailLogs}
            currentRefreshEmailID={this.props.currentRefreshEmailID}
            refreshlogs={this.props.refreshlogs}
          />
        ) : this.props.currentRefreshLog &&
          this.props.refreshlogs.length > 0 ? (
          <NoAccess message={"Select a log from the middle column."} />
        ) : (
          <NoAccess message={"No logs exist for this campaign."} />
        )}
        <h2 className="text-muted no-margin">Tools:</h2>

        {currentReportDetails ? (
          <Row>
            <Col lg={11}>
              <Row className={s.root}>
                <Col xs={12}>
                  <Widget bodyClass={"p-0"}>
                    <div className="widget">
                      <Row>
                        <Col lg={12}>
                          {!this.state.complete && !this.state.fetching ? (
                            <Tooltip
                              title={
                                <div style={{ fontSize: "12px" }}>
                                  A list of brokers who will be downloaded.
                                </div>
                              }
                              placement="top"
                            >
                              <Button
                                block
                                color="primary"
                                className="px-2"
                                onClick={() =>
                                  this.handleClick(currentReportDetails.id)
                                }
                              >
                                Download Broker List
                              </Button>
                            </Tooltip>
                          ) : this.state.complete && this.state.success ? (
                            <Button
                              block
                              color="success"
                              className="px-2"
                              disabled
                            >
                              List was sent!
                            </Button>
                          ) : this.state.fetching && !this.state.complete ? (
                            <Button
                              block
                              color="primary"
                              className="px-2"
                              disabled
                            >
                              <i
                                className="la la-spinner la-spin"
                                style={{ fontSize: this.props.size }}
                              />
                            </Button>
                          ) : (
                            <Button
                              block
                              color="danger"
                              className="px-2"
                              disabled
                            >
                              {this.state.errortext}
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </Widget>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
        <Modal
          size="lg"
          isOpen={customRangeModal}
          toggle={() => this.toggle("customRangeModal")}
        >
          <ModalHeader toggle={() => this.toggle("customRangeModal")}>
            Send custom{" "}
          </ModalHeader>
          <ModalBody className="bg-white">
            <p>Select a custom date range to test the email report:</p>
            <div className="mb-4">
              <DateRangePickerWrapper
                startdate={this.state.startdate}
                enddate={this.state.enddate}
                updateLocalEndDate={this.updateLocalEndDate}
                updateLocalStartDate={this.updateLocalStartDate}
              />
            </div>
            {this.state.missingDateError ? (
              <p className="text-danger">Please choose a date range.</p>
            ) : null}
            <Button
              block
              color="primary"
              className="px-2"
              onClick={() => this.handleCustomTest(currentReportDetails.id)}
            >
              Send Test
            </Button>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

class SendButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: false,
      complete: false,
      error: false,
      success: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(cell, row, rowIndex) {
    this.setState({
      fetching: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.get(`/api/v1/sendtestemail/${cell}/`).then(
      (res) => {
        this.setState({
          complete: true,
          success: true,
          fetching: false,
        });
      },
      (error) => {
        this.setState({
          error: true,
          fetching: false,
          complete: true,
        });
      }
    );
  }

  render() {
    const { cell, row, rowIndex } = this.props;
    return (
      <>
        {!this.state.fetching && !this.state.complete ? (
          <React.Fragment>
            <Tooltip
              title={
                <div style={{ fontSize: "12px" }}>
                  A test will be sent to your email only.
                </div>
              }
              placement="top"
            >
              <Button
                color="primary"
                size="xs"
                className="px-2"
                onClick={() => this.handleClick(cell, row, rowIndex)}
              >
                Send Test
              </Button>
            </Tooltip>
            {/* <Dialog ref={(el) => { this.dialog = el }} /> */}
          </React.Fragment>
        ) : this.state.fetching ? (
          <div>
            <i className="la la-spinner la-spin" />
          </div>
        ) : this.state.success ? (
          <div>Test Sent!</div>
        ) : this.state.error ? (
          <div>Error.</div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    token: state.authentication.token,
    userprofile: state.authentication.userprofile,
  };
}

export default connect(mapStateToProps)(RefreshEmailDetails);
