import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../components/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";

export default class IndustryChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
    };
      this.Industry = React.createRef()
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

  handleclick(event){
    this.setState({
      test: event
    })
  }


  chartData = () => {
    const { data, companydata } = this.props;

    //Begin creation of chart data
    let industrylist = []
    let categories = []
    //Look in each of the client objects and match numbers to types
    companydata.forEach(item => {
      if(!(item.clientindustry === "")){
        let industryfound = industrylist.find(element => element.name === item.clientindustry)

        if(!(industryfound === undefined)){
            industryfound.data.total += 1
          } else{
          let newindustry = {
            name: item.clientindustry,
            data: {
              total: 1
            }
          }
          industrylist.push(newindustry)
        }
      }

      })


    //create series
    let series = [
      {
      name: 'Total',
      data:[]
      }
      ]

      industrylist.sort((a,b) => parseFloat(b.data.total) - parseFloat(a.data.total))

      //for every client, add to the data and series
      industrylist.every(item => {
        categories.push(item.name)
        series.find(item => (item.name).toLowerCase() === 'total').data.push(item.data.total)
        return true
      })

    return {
      ...this.chartOptions,
      series: series,
      xAxis: {
        min: 0,
        max: 5,
        scrollbar: {
            enabled: true
        },
        tickLength: 0,
        categories: categories
      },

    }
  };

  chartOptions = {
    credits: {
      enabled: false
    },
    // width: 700,
    chart: {
      height: 200,
      // width: 700,
      backgroundColor: 'rgba(0,0,0,0)',
      type: 'bar'
    },
    title: false,
    exporting: {
      enabled: true
    },
    legend: {
      reversed: true,
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },

    annotations: {
      visible: false
    },
    plotOptions: {
    series: {
    	stacking:'normal',
      point: {
        events: {
          click() {
            console.log(this.series.name)
            console.log(this.category)
          }
        }
      }
    }
  },
  };

  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={5}>
              <h5>
                Industries<span className="fw-semi-bold"> Served</span>
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts} ref={this.Industry} options={this.chartData()} />

      </Widget>
    );
  }
}
