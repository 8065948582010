import React, { Component } from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import s from "../../../scorecardv2/Profile.module.scss";
import axios from "axios";
import { connect } from "react-redux";

import { updateUserProfile } from "../../../../actions/authentication";
import PropTypes from "prop-types";
import {
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Table,
  Badge,
} from "reactstrap";
import Widget from "../../../../components/Widget/Widget";

var moment = require("moment");

class CurrentDashboardSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: null,
      dashboardName: null,
      dashobarddesc: "",
      createdby: null,
      createddate: null,
      usersubscribed: false,
      successadded: false,
      markets: [],
      filters: [],
      charts: [],
    };
    this.addDashboard = this.addDashboard.bind(this);
  }

  addDashboard() {
    // Check inputs
    this.setState({
      saving: true,
    });
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${this.props.token}`,
    };
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios
      .post(`/api/v1/userdashboardadd/${this.props.settings.dashboardid}/`)
      .then((res) => {
        this.props.dispatch(updateUserProfile(res.data));
        this.setState({
          successadded: true,
        });
      });
  }

  componentDidMount() {
    let passedsettings = this.props.settings;
    let markets = passedsettings.market;
    let dashboardsettings = JSON.parse(passedsettings.settings);
    let finalmarkets = [];
    let finalfilters = [];
    let finalcharts = [];
    let userdata = this.props.userdata;
    let dashboardid = passedsettings.dashboardid;
    let foundinusersettings = userdata.dashboards.some(
      (item) => item.dashboardid === dashboardid
    );

    finalmarkets = markets.map((item) => {
      return (
        <tr>
          {" "}
          <td>{item.marketname}</td>{" "}
        </tr>
      );
    });
    finalfilters = dashboardsettings.friendlyFilters.map((item) => {
      return (
        <tr>
          {" "}
          <td>{item.value}</td>{" "}
        </tr>
      );
    });
    finalcharts = dashboardsettings.friendlyCharts.map((item) => {
      return (
        <tr>
          {" "}
          <td>{item}</td>{" "}
        </tr>
      );
    });

    // if(dashboardsettings.friendlyFilters){
    //   dashboardsettings.friendlyFilters.forEach(item => finalfilters.push(item.value))
    // }

    this.setState({
      settings: passedsettings,
      dashboardName: dashboardsettings.dashboardname,
      createdby: dashboardsettings.createdby,
      createddate: dashboardsettings.createdDate,
      dashboarddesc: dashboardsettings.dashboarddesc,
      usersubscribed: foundinusersettings,
      markets: finalmarkets,
      filters: finalfilters,
      charts: finalcharts,
    });
  }

  render() {
    return (
      <>
        <Row className="mb-3 mx-auto">
          <Col xs={12} xl={12} lg={12} md={12}>
            <div className="mt-lg">
              Below are the settings used by this custom dashboard. To change
              settings, navigate to a Market dashboard and create a new custom
              dashboard.
            </div>
          </Col>
        </Row>

        <Row className="mx-auto">
          {this.state.settings ? (
            <>
              <Col xs={12} xl={12} lg={12} md={12}>
                <div>
                  {/* <h5 className="fw-normal"><span className="fw-semi-bold">NAME</span></h5>
  
                  <p>TITLE</p> */}

                  <div>
                    <ul
                      className={cx(
                        s.profileContacts,
                        "mt-sm",
                        "settings-left"
                      )}
                    >
                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Basic Information:{" "}
                        </span>
                      </li>
                      <li>
                        <Table className="table-striped settingstable">
                          <tbody>
                            <tr>
                              <td>
                                <span className="settings-key">
                                  Dashboard Name:
                                </span>
                              </td>
                              <td>{this.state.dashboardName}</td>
                            </tr>
                            {this.state.dashboarddesc.length > 0 ? (
                              <tr>
                                <td>
                                  <span className="settings-key">
                                    Dashboard Description:
                                  </span>
                                </td>
                                <td>{this.state.dashboarddesc}</td>
                              </tr>
                            ) : null}
                            <tr>
                              <td>
                                <span className="settings-key">
                                  Created By:
                                </span>
                              </td>
                              <td>{this.state.createdby}</td>
                            </tr>
                            <tr>
                              <td>
                                <span className="settings-key">
                                  Created Date:
                                </span>
                              </td>
                              <td>{this.state.createddate}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </li>

                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Markets Included:{" "}
                        </span>
                      </li>
                      <li>
                        <Table className="table-striped settingstable">
                          <thead>
                            <tr>
                              <th>Market Name(s)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.markets.length > 0 ? (
                              <>{this.state.markets}</>
                            ) : (
                              <tr>
                                <th>No markets specified</th>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </li>
                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Default Charts Shown:{" "}
                        </span>
                      </li>
                      <li>
                        <Table className="table-striped settingstable">
                          <thead>
                            <tr>
                              <th>Chart Name(s)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.charts.length > 0 ? (
                              <>{this.state.charts}</>
                            ) : (
                              <tr>
                                <th>No charts specified</th>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </li>
                      <li className="settings-section">
                        <span className="fw-semi-bold settings-title">
                          Default Filters:{" "}
                        </span>
                      </li>
                      <li>
                        <Table className="table-striped settingstable">
                          <thead>
                            <tr>
                              <th>Default Filter(s)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.filters.length > 0 ? (
                              <>{this.state.filters}</>
                            ) : (
                              <tr>
                                <th>No filters specified</th>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              {!this.state.usersubscribed && !this.state.successadded ? (
                <Col xs={12} xl={12} lg={12} md={12}>
                  <span className="float-right">
                    <Button onClick={this.addDashboard} color="primary">
                      Save Dashboard To Home Page
                    </Button>
                  </span>
                </Col>
              ) : null}
              {this.state.successadded ? (
                <Col xs={12} xl={12} lg={12} md={12}>
                  <span className="float-right">
                    <Button color="success">Dashboard Added</Button>
                  </span>
                </Col>
              ) : null}
            </>
          ) : (
            <Col xs={12} xl={12} lg={12} md={12}>
              "There are no current settings to be displayed."
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export default connect(null)(CurrentDashboardSettings);
