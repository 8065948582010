import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official'
var moment = require('moment');


// function sortByQuarterYear(lhs, rhs) {
//   var lhsQuarterYear = lhs.name.split("-");
//   var rhsQuarterYear = rhs.name.split("-");
//   var lhsDate = new Date(lhsQuarterYear[1], quarterToMonthMap[lhsQuarterYear[0]]);
//   var rhsDate = new Date(rhsQuarterYear[1], quarterToMonthMap[rhsQuarterYear[0]]);
//   return lhsDate.getTime() - rhsDate.getTime();
// }

export default class VolumeIncreaseChart extends PureComponent {
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };

 

  chartData = () => {
      const { data } = this.props;

      let sorteddata = data.sort((a,b) => moment(a.datecreated, 'MM-DD-YYYY hh:mm:ss') - moment(b.datecreated, 'MM-DD-YYYY hh:mm:ss'))

      // Workingobject will contain all servicelines and dates in addition to the total
  
      let ticks =[]
      let categories = []
      let currentsl = ""
      let series = [{
        name: 'Volume Change',
        data: []
      },]
     // Quarterly sectioning
     if(this.props.timerange === "Quarter"){
      let dataToQuarters = sorteddata.map(item => ({quarter: moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss').format('[Q]Q-Y') }))
      
      let finalQuarterValues = dataToQuarters.reduce(function(pV, cV, cI){
        let foundob = pV.find(item=> item.quarter === cV['quarter'])
        if(foundob){
          foundob.value += 1
        }else{
          let newob = {
            quarter: cV['quarter'],
            value: 1
          }
          pV.push(newob)
        }
        return pV;
      }, []);

      let finalQuarterData = finalQuarterValues.map((item,index, array) => {
        if(index > 0){
          return {'quarter':item.quarter, 'volume':item.value}
        }
      })
  
      finalQuarterData = finalQuarterData.filter(Boolean)
    
    finalQuarterData.forEach(slobject => {
            categories.push(slobject.quarter)
            series[0].data.push(slobject.volume)
      })
    }else if(this.props.timerange === "Year"){
      // Yearly sectioning
        let dataToYears = sorteddata.map(item => ({year: moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss').format('Y')}))   
        let finalYearValues = dataToYears.reduce(function(pV, cV, cI){
          let foundob = pV.find(item=> item.year === cV['year'])
          if(foundob){
            foundob.value += 1
          }else{
            let newob = {
              year: cV['year'],
              value: 1
            }
            pV.push(newob)
          }
          return pV;
        }, []);

        let finalYearData = finalYearValues.map((item,index, array) => {
          if(index > 0){
            return {'year':item.year, 'volume':item.value}
      } else{
      return {'year':item.year, 'volume':0}
      }
        })
        finalYearData = finalYearData.filter(Boolean)

      finalYearData.forEach(slobject => {
           
              categories.push(slobject.year)
              series[0].data.push(slobject.volume)
         
        })
    }else if(this.props.timerange === "Month"){
      // Monthly sectioning
        let dataToMonths = sorteddata.map(item => ({month: moment(item.datecreated, 'MM-DD-YYYY hh:mm:ss').format('MMM-Y')}))
            
        let finalMonthValues = dataToMonths.reduce(function(pV, cV, cI){
          let foundob = pV.find(item=> item.month === cV['month'])
          if(foundob){
            foundob.value += 1
          }else{
            let newob = {
              month: cV['month'],
              value: 1
            }
            pV.push(newob)
          }
          return pV;
        }, []);

        let finalMonthData = finalMonthValues.map((item,index, array) => {
          if(index > 0){
            return {'month':item.month, 'volume':item.value}
      } else{
      return {'month':item.month, 'volume':0}
      }
        })
        finalMonthData = finalMonthData.filter(Boolean)

        finalMonthData.forEach(slobject => {
              categories.push(slobject.month)
              series[0].data.push(slobject.volume)
        })
    }

    let goal = (series[0].data[series[0].data.length - 2] * 0.20) + series[0].data[series[0].data.length - 2]
    let yAxis =  {
  
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      },
    }

    if(this.props.timerange === "Year"){
      yAxis.plotLines = [{
        color: '#FF0000',
        width: 2,
        value: goal,
        label: {text: `Goal: 20% increase`,
        align: 'right',}
    }]
    }

    return {
      ...this.ticks,
      ...this.chartOptions,
      xAxis: {
        labels: {
          overflow: 'justify',
          style: {
            color: "#000000"
        }},
        categories: categories
      },
      yAxis,
      series
    }
  }

  ticks = ['Oct 2019', 'Nov 2019']

  chartOptions = {
    credits: {
      enabled: false
    },
    chart: {
      type: 'column',
      height: 350,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    title: false,
    exporting: {
      enabled: true,
        chartOptions: {
          title: {
            text: null
          }
        }
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        color: "#000000"
      },
      itemHoverStyle: {
        color: "#020202"
      }
    },
    yAxis: {
      title: false,
      labels: {
        style: {
          color: "#000000"
        }
      }
    },
    xAxis: {
      // type: 'datetime',
      labels: {
        overflow: 'justify',
        style: {
          color: "#000000"
        }
      }
    },
    annotations: {
      visible: false
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          symbol: 'circle'
        },
        dataLabels:{
          enabled:true,
          format: '{y}',
        },
        // pointInterval: 3600000 * 25, // every day
        // pointStart: Date.UTC(2019, 8, 1, 0, 0, 0),
        tooltip: {
          pointFormatter() {
            return `<span style="color: ${this.color}">${this.series.name} at ${this.y.toFixed(2)}</span>`;
          }
        }
      },
    }
  };


  render() {

    const { isReceiving, data } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={12}>
              <h5>
              <span className="fw-semi-bold">Volume Increase</span>&nbsp;By {this.props.timerange}
              </h5>
            </Col>
            <Col xs={12} sm={12}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >
       <HighchartsReact highcharts={Highcharts} options={this.chartData()} />
      </Widget>
    );
  }
}
