import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, UncontrolledButtonDropdown,DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap";
import Widget from "../../../../components/Widget/Widget";
import HighchartsReact from 'highcharts-react-official'
import Highcharts from "highcharts/highstock";
import { withRouter } from "react-router";

class TechToolUsed extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: true,
      pointName: "",
      simpleSelectDropdownValue: 'Choose number displayed',

    };
  }
  static propTypes = {
    data: PropTypes.any.isRequired,
    isReceiving: PropTypes.bool
  };

  static defaultProps = {
    data: [],
    isReceiving: false
  };



  getTechToolsUsed(opps){
    let techused = []
    
    opps.forEach(item =>{
      let currenttools = "Unknown"
      let foundmarket = undefined
      if (item.techused.length > 0) {
        currenttools = item.techused.map(({techname})=> techname)
      }
      if(!(currenttools === "Unknown")){
        currenttools.forEach(item => {
          let foundtool = techused.find(element => element.techtool === item)

          if(!(foundtool === undefined)){
            foundtool.count += 1
          }else{
              
              let newobj = {
                techtool: item,
                count: 1
              }
              techused.push(newobj)
          }
        })
      }
    })

    techused = techused.filter(x => !(x.techtool === ""))
    techused = techused.filter(x => !(x.techtool === "JLL Tenant Experience"))
    const elementsIndex = techused.findIndex(element => element.techtool == "Aero" )   
    let newtechused = [...techused]
    // newtechused[elementsIndex] = {...newtechused[elementsIndex], techtool:"Needs Assessment"}
    return newtechused
  }
    

  chartData = () => {

  let data = this.getTechToolsUsed(this.props.data)
  data = data.sort((a,b) => b.count - a.count)
  data = data.map(({techtool, count}) => [techtool, count])


  var config = {
    chart: {
        type: 'column',
        height: 250
    },
    exporting: {
      chartOptions: {
        title: {
          text: null
        }
      }
    },
    title: {
      text: ''
  },
    xAxis: {
        type: 'category',
        labels: {
            rotation: -45,
            style: {
                fontSize: '10px',
                fontFamily: 'Verdana, sans-serif'
            }
        }
    },
    credits: {
      enabled: false
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Opportunities Used'
        },
    },
    legend: {
        enabled: false
    },
    tooltip: {
        pointFormat: 'Tool used in: <b>{point.y:.1f}</b> opportunities'
    },
    series: [{
        name: 'Population',
        data: data,
        dataLabels: {
          enabled: true
      }
    }]
};


    // this.chartOptions.yAxis.categories= brokerchartlist.map(item => item.name)
    return config
  };



  changeSelectDropdownSimple = (e) => {
    var chart = this.refs.chartref.chart;
    this.setState({ simpleSelectDropdownValue: parseFloat(e.currentTarget.textContent) });

    chart.update(this.chartData())
  
  }

  render() {

    const { isReceiving } = this.props;

    return (
      <Widget
        bodyClass="mt"
        className="mb-xlg"
        fetchingData={isReceiving}
        collapse
        close
        title={
          <Row>
            <Col xs={12} sm={12}>
              <h5>
                <span className="fw-semi-bold">Top Tools</span> By Opportunities
              </h5>
            </Col>
            <Col xs={12} sm={7}>
              <div className="chart-legend"/>
            </Col>
          </Row>
        }
      >

       <HighchartsReact  highcharts={Highcharts}  ref="chartref" options={this.chartData()} />
        
      </Widget>
    );
  }
}

export default withRouter(TechToolUsed)
