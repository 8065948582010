import React from 'react';
import {
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import {
  Link
} from "react-router-dom";
import s from './ListGroup.module.scss';
var moment = require('moment');

class OppNotifications extends React.Component {

  render() {
    const { notifications } = this.props;

    const sortednotifications = [].concat(notifications).sort((a,b) => (a.timestamp < b.timestamp) ? 1 : ((b.timestamp < a.timestamp) ? -1 : 0));
    return (
      <ListGroup className={[s.listGroup, 'thin-scroll'].join(' ')}>
      {
        sortednotifications
        ?
        sortednotifications.map((item, index) => {
          if(item.updateDate != null){
            return  <ListGroupItem key={item.id} className={s.listGroupItem}>
                <span className={[s.notificationIcon, 'thumb-sm'].join(' ')}>
                <i className="glyphicon glyphicon-time fa-lg" />
                </span>
                <p className="text-ellipsis m-0">
                <strong><Link to={'/app/reporting/opportunity/'+item.updateDate.opportunityid}>Opportunity: {item.updateDate.opportunityid} </Link></strong>meeting date has been changed to <strong>{moment(item.updateDate.toDate).format("MMM. Do, YYYY")}</strong>
                  <time className="help-block m-0">
                    {moment(item.updateDate.updateTime, "MM-DD-YYYY hh:mm:ss").fromNow()}
                  </time>
                </p>
              </ListGroupItem>
          }else if(item.updateNewOpp != null){
            return <ListGroupItem  key={index} className={s.listGroupItem}>
                <span className={[s.notificationIcon, 'thumb-sm'].join(' ')}>
                  <i className="glyphicon glyphicon-upload fa-lg" />
                </span>
                <p className="text-ellipsis m-0">
              <strong><Link to={'/app/reporting/opportunity/'+item.updateNewOpp.opportunityid}>Opportunity: {item.updateNewOpp.opportunityid} </Link></strong>has been added.
                  <time className="help-block m-0">
                    {moment(item.updateNewOpp.updateTime, "MM-DD-YYYY hh:mm:ss").fromNow()}
                  </time>
                </p>
              </ListGroupItem>
          }else if(item.updateStatus != null){
            return <ListGroupItem  key={index} className={s.listGroupItem}>
                <span className={[s.notificationIcon, 'thumb-sm'].join(' ')}>
                  <i className="glyphicon glyphicon-ok-sign fa-lg" />
                </span>
                <p className="text-ellipsis m-0">
                  <strong><Link to={'/app/reporting/opportunity/'+item.updateStatus.opportunityid}>Opportunity: {item.updateStatus.opportunityid} </Link></strong>meeting date has been changed to <span className={
                    item.updateStatus.toStatus == "Open" ? 'text-open' :
                    item.updateStatus.toStatus == "Pending" ? 'text-pending' :
                    item.updateStatus.toStatus == "Won" ? 'text-won' :
                    item.updateStatus.toStatus == "Lost" ? 'text-lost' :
                    null
                  }> {item.updateStatus.toStatus} </span>
                  <time className="help-block m-0 align-right">
                    {moment(item.updateStatus.updateTime, "MM-DD-YYYY hh:mm:ss").fromNow()}
                  </time>
                </p>
              </ListGroupItem>
          }

        })
        :
        null
      }
      </ListGroup>
    );
  }
}

function mapStateToProps(state) {
  return{
    notifications: state.projects.notifications
  }
}

export default connect(mapStateToProps)(OppNotifications);
