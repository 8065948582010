import axios from "axios";
import { toast } from "react-toastify";
import config from "../config";
import LogRocket from "logrocket";

export const RECEIVED_PROJECTS = "RECEIVED_PROJECTS";
export const RECEIVED_PROJECT = "RECEIVED_PROJECT";
export const RECEIVING_PROJECTS = "RECEIVING_PROJECTS";
export const RECEIVING_PROJECT = "RECEIVING_PROJECT";
export const RECEIVED_DATA_SUCCESS = "RECEIVED_DATA_SUCCESS";
export const RECEIVING_DATA = "RECEIVING_DATA";
export const SET_FILTERED_PROJECTS = "SET_FILTERED_PROJECTS";
export const SET_CLEAN_DATA = "SET_CLEAN_DATA";
export const RECEIVING_PROFILE = "RECEIVING_PROFILE";
export const RECEIVING_NOTIFICATIONS = "RECEIVING_NOTIFICATIONS";
export const RECEIVED_NOTIFICATION_SUCCESS = "RECEIVED_NOTIFICATION_SUCCESS";
export const RECEIVING_MEETING_DATE_UPDATES = "RECEIVING_MEETING_DATE_UPDATES";
export const RECEIVED_MEETING_DATE_UPDATES_SUCCESS =
  "RECEIVED_MEETING_DATE_UPDATES_SUCCESS";
export const RECEIVING_NEW_OPP_UPDATES = "RECEIVING_NEW_OPP_UPDATES";
export const RECEIVED_NEW_OPP_UPDATES_SUCCESS =
  "RECEIVED_NEW_OPP_UPDATES_SUCCESS";
export const RECEIVING_OPP_STATUS_UPDATES = "RECEIVING_OPP_STATUS_UPDATES";
export const RECEIVED_OPP_STATUS_UPDATES_SUCCESS =
  "RECEIVED_OPP_STATUS_UPDATES_SUCCESS";
export const GET_MARKET_SPECIFIC_DATA = "GET_MARKET_SPECIFIC_DATA";
export const RECEIVED_MARKET_DATA_SUCCESS = "RECEIVED_MARKET_DATA_SUCCESS";
export const RECEIVING_MARKETS = "RECEIVING_MARKETS";
export const READ_NOTIFICATIONS = "READ_NOTIFICATIONS";
export const WARNING_OCCURED = "WARNING_OCCURED";
export const MARK_NOTICE_AS_READ = "MARK_NOTICE_AS_READ";
export const MARK_NOTICE_AS_READ_SIMPLE = "MARK_NOTICE_AS_READ_SIMPLE";
export const GET_NATIONAL_DASHBOARD_DATA = "GET_NATIONAL_DASHBOARD_DATA";
export const RECEIVING_NATIONAL_DASHBOARD_DATA =
  "RECEIVING_NATIONAL_DASHBOARD_DATA";
export const RECEIVED_NATIONAL_DASHBOARD_DATA_SUCCESS =
  "RECEIVED_NATIONAL_DASHBOARD_DATA_SUCCESS";
export const RECEIVED_USER_MARKET_OPPS = "RECEIVED_USER_MARKET_OPPS";
export const FAILED_USER_MARKET_OPPS = "FAILED_USER_MARKET_OPPS";
export const FAILED_USER_MARKET_OPPS_REVERT = "FAILED_USER_MARKET_OPPS_REVERT";
export const CREATE_FILTER_SNAPSHOT = "CREATE_FILTER_SNAPSHOT";

export const RECEIVED_MARKET_OPTIONS_SUCCESS =
  "RECEIVED_MARKET_OPTIONS_SUCCESS";

function cleanPersonList(person) {
  let list = [];
  person.forEach((item) => list.push(item.fullname));
  let theperson = list.join(", ");
  return theperson;
}
function cleanServiceList(serviceline) {
  let list = [];
  serviceline.forEach((item) => list.push(item.servicelinename));
  let theservice = list.join(", ");
  return theservice;
}

function cleanTechList(tech) {
  let list = [];
  tech.forEach((item) => list.push(item.techname));
  let thetool = list.join(", ");
  return thetool;
}

function cleanDealType(dealType) {
  let list = [];
  dealType.forEach((item) => list.push(item.name));
  let dealT = list.join(", ");
  return dealT;
}

export function getNationalDashboardData(token) {
  return (dispatch) => {
    dispatch(receivingNotifications());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios.get("/api/v1/nationalchartdata/").then(
      (res) => {
        dispatch(receiveNationalDashboardDataSuccess(res.data));
        saveToLocalStorage(res.data, "nationaldashboarddata");
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function createFilterSnapshot(payload) {
  return {
    type: CREATE_FILTER_SNAPSHOT,
    payload,
  };
}

export function receivingNationalDashboardData() {
  return {
    type: RECEIVING_NATIONAL_DASHBOARD_DATA,
  };
}

export function receiveNationalDashboardDataSuccess(payload) {
  return {
    type: RECEIVED_NATIONAL_DASHBOARD_DATA_SUCCESS,
    payload,
  };
}

export function readNotifications(notifications, token, notficationsRead) {
  return (dispatch) => {
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    // let count = 5;
    // let items = notifications.slice(0, count)

    if (!notficationsRead) {
      notifications.forEach((item) => {
        axios.put(`/api/v1/notifications/${item.id}/`);
      });
    }
    dispatch(markAsReadSimple());
  };
}

export function markAsReadSimple() {
  return {
    type: MARK_NOTICE_AS_READ_SIMPLE,
  };
}

export function markAsRead(payload) {
  return {
    type: MARK_NOTICE_AS_READ,
    payload,
  };
}

export function checkLSforOpps() {
  try {
    const serializedState = localStorage.getItem("projects");
    const lastUpdated = localStorage.getItem("lastUpdated") || new Date();
    if (serializedState === null || lastUpdated == null) {
      let payload = {
        warning: `No ${projects} found in Local Storage`,
      };
      return {
        type: WARNING_OCCURED,
        payload,
      };
    }
    let projects = JSON.parse(serializedState);
    return receiveDataSuccess(projects, lastUpdated);
  } catch (e) {
    let payload = {
      warning: e,
    };
    return {
      type: WARNING_OCCURED,
      payload,
    };
  }
}

export function receivingUserProfile() {
  return {
    type: RECEIVING_PROFILE,
  };
}

export function getNotificationsandUpdates() {
  return (dispatch) => {
    dispatch(receivingNotifications());
    axios.get("/api/v1/notifications/").then(
      (res) => {
        dispatch(receiveNotificationSuccess(res.data));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getMarketData() {
  return (dispatch) => {
    dispatch(receivingMarketData());

    axios.get("/api/v1/markets/").then(
      (res) => {
        let data = res.data.sort((a, b) => a.marketname < b.marketname);
        let finalmarketlist = [];
        data.forEach((item, index) => {
          if (
            item.marketname !== "JTE" &&
            item.marketname !== "National" &&
            item.marketname !== "All Markets"
          ) {
            let newitem = {
              name: item.marketname,
              id: item.market_id,
            };
            finalmarketlist.push(newitem);
          }
        });

        finalmarketlist = finalmarketlist.sort(function (a, b) {
          return a.marketname > b.marketname
            ? 1
            : b.marketname > a.marketname
            ? -1
            : 0;
        });

        dispatch(receiveMarketDataSuccess(data));
        dispatch(receiveMarketOptionsSuccess(finalmarketlist));
        dispatch(getUserMarketOpps(data));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function getOppList(opps) {
  let oppnamelist = opps.map(({ pursuitname }) => pursuitname).sort();

  return oppnamelist;
}

function getbrokerlist(opps) {
  let blist = [];

  let brokers = opps.map(({ broker }) => [broker]);

  brokers.forEach((item) => blist.push(item));
  let sbrokers = opps.map(({ sourcingbroker }) => [sourcingbroker]);
  sbrokers.forEach((item) => blist.push(item));
  let merged = [].concat.apply([], blist);
  let newarray = [];
  merged.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(", ");
      newarray.push(newitem);
    } else {
      newarray.push(item);
    }
  });
  merged = [].concat.apply([], newarray);
  blist = [...new Set(merged)];
  blist = blist.sort();
  return blist;
}

const getPMValues = (opps) => {
  let tempDealTypeList = [];
  let tempDealSubTypeList = [];
  let salesStageList = [];

  opps.forEach((item) => {
    if (item.deal_type?.length > 0) {
      tempDealTypeList.push(item.deal_type);
    }
    if (item.deal_sub_type?.length > 0) {
      tempDealSubTypeList.push(item.deal_sub_type);
    }
    if (item.sales_stage?.length > 0) {
      salesStageList.push(item.sales_stage);
    }
  });
  let dealTypeList = [];
  let dealSubTypeList = [];
  tempDealTypeList = [].concat.apply([], tempDealTypeList);

  tempDealTypeList.forEach((item) => {
    if (item?.length > 0) {
      if (item?.includes(",")) {
        let newitem = item.split(", ");
        dealTypeList.push(newitem);
      } else {
        dealTypeList.push(item);
      }
    }
  });

  tempDealSubTypeList = [].concat.apply([], tempDealSubTypeList);
  tempDealSubTypeList.forEach((item) => {
    if (item?.length > 0) {
      if (item?.includes(",")) {
        let newitem = item.split(", ");
        dealSubTypeList.push(newitem);
      } else {
        dealSubTypeList.push(item);
      }
    }
  });

  salesStageList = [].concat.apply([], salesStageList);

  dealTypeList = [...new Set(dealTypeList)];
  dealSubTypeList = [...new Set(dealSubTypeList)];
  salesStageList = [...new Set(salesStageList)];

  dealTypeList = dealTypeList.sort();
  dealSubTypeList = dealSubTypeList.sort();
  salesStageList = salesStageList.sort();

  return { dealTypeList, dealSubTypeList, salesStageList };
};

function getOppTypeList(opps) {
  let opptypes = opps.map(({ type }) => [type]);
  let newarray = [];
  opptypes = [].concat.apply([], opptypes);
  newarray = [...new Set(opptypes)];
  newarray = newarray.sort();
  return newarray;
}

function getMeetingTypeList(opps) {
  let opptypes = opps.map(({ meetingtype }) => [meetingtype]);
  let newarray = [];
  opptypes = [].concat.apply([], opptypes);
  newarray = [...new Set(opptypes)];
  newarray = newarray.sort();
  return newarray;
}

function getServiceLineList(opps) {
  let servicelines = opps.map(({ servicelines }) => servicelines);
  let newarray = [];
  servicelines = [].concat.apply([], servicelines);
  servicelines = servicelines.map(({ servicelinename }) => servicelinename);
  newarray = [...new Set(servicelines)];
  newarray = newarray.sort();
  newarray.unshift("");
  return newarray;
}

function getDAList(opps) {
  let advisors = opps.map((opp) => {
    if (
      opp.bdmember.some((bdmember) =>
        bdmember.role.some((role) => role.rolename == "Digital Advisor")
      )
    ) {
      let found = opp.bdmember.map((bdmember) => {
        if (bdmember.role.some((role) => role.rolename == "Digital Advisor")) {
          return bdmember.fullname;
        }
      });
      return found;
    }
  });
  let newarray = [];
  advisors = advisors.flat();
  newarray = [...new Set(advisors)];
  newarray = newarray.sort();
  newarray = newarray.filter((x) => x !== undefined);
  return newarray;
}

async function getMarketCodeList(opps, token) {
  axios.defaults.headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${token}`,
  };
  await axios.get("/api/v1/marketcodes/").then((res) => {
    let newarray = [];
    newarray = [...new Set(res.data)];
    newarray = newarray.sort();
    return newarray;
  });
}

function getSEList(opps) {
  let advisors = opps.map(function (opp) {
    if (opp.bdmember.length > 0) {
      if (
        opp.bdmember[0].role.length > 0 &&
        opp.bdmember[0].role[0].rolename == "Sales Enablement Member"
      ) {
        return opp.bdmember[0].fullname;
      } else {
        return "";
      }
    } else {
      return "";
    }
  });
  let newarray = [];
  newarray = [...new Set(advisors)];
  newarray = newarray.sort();
  return newarray;
}

function cleanIndustryList(clients) {
  let industries = clients.map(({ clientindustry }) => clientindustry);
  return industries;
}
function cleanSourcingMethodList(methods) {
  let sourcingmethods = methods.map(({ methodname }) => methodname);
  return sourcingmethods;
}

function cleanServicesList(services) {
  let otherservices = services.map(({ servicename }) => servicename);
  return otherservices;
}

function getPropertyTypeList(opps) {
  let opptypes = opps.map(({ propertytype }) => [propertytype]);
  let newarray = [];
  opptypes = [].concat.apply([], opptypes);
  newarray = [...new Set(opptypes)];
  newarray = newarray.sort();
  return newarray;
}
function getIndustryList(opps) {
  let industries = opps.map(({ clientindustry }) => clientindustry);
  let newarray = [...new Set(industries)];
  newarray = newarray.sort();
  return newarray;
}

function getPracticeGroupList(opps) {
  let practiceGroups = opps.map(function (opp) {
    if (opp.practicegroups.length > 0) {
      if (opp.practicegroups[0].name.length > 0) {
        return opp.practicegroups[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  });

  let newarray = [...new Set(practiceGroups)];
  newarray = newarray.sort();
  return newarray;
}

function getOtherServicesList(opps) {
  let methods = opps.map(({ otherJllServices }) => otherJllServices);
  methods = [].concat.apply([], methods);
  let newarray = [];
  methods.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(",");
      newarray.push(newitem);
    } else {
      newarray.push(item);
    }
  });
  newarray = [].concat.apply([], newarray);
  newarray = [...new Set(newarray)];
  newarray = newarray.sort();
  return newarray;
}

function getSourcingMethodList(opps) {
  let methods = opps.map(({ sourcingmethod }) => sourcingmethod);
  methods = [].concat.apply([], methods);
  let newarray = [];
  methods.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(",");
      newarray.push(newitem);
    } else {
      newarray.push(item);
    }
  });
  newarray = [].concat.apply([], newarray);
  newarray = [...new Set(newarray)];
  newarray = newarray.sort();
  return newarray;
}

function getTechList(opps) {
  let tools = opps.map(({ techused }) => [techused]);

  let newarray = [];
  let finalarray = [];
  tools = [].concat.apply([], tools);
  // tools = tools.flat(2)

  newarray = [...new Set(tools)];

  newarray.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(", ");
      finalarray.push(newitem);
    } else {
      finalarray.push(item);
    }
  });

  finalarray = [].concat.apply([], finalarray);
  finalarray = [...new Set(finalarray)];
  finalarray = finalarray.sort();
  return finalarray;
}

export function getUserMarketOpps(markets) {
  return (dispatch) => {
    let cleanmarkets = markets.map(({ marketname }) => marketname);
    if (
      cleanmarkets.some((e) => e === "All Markets") &&
      cleanmarkets.some((e) => e === "National")
    ) {
      let opplist = [];
      axios.get(`/api/v1/marketdata/1000/`).then(
        (res) => {
          let opps = res.data.opportunities;
          let marketcodelist = res.data.marketcodes;
          let cleandata = opps.map((project) => {
            return {
              ...project,
              pursuitname: project.pursuitname,
              techused: `${cleanTechList(project.techused)}`,
              deal_type: `${cleanDealType(project.deal_type)}`,
              deal_sub_type: `${cleanDealType(project.deal_sub_type)}`,
              sourcingbroker: `${cleanPersonList(project.sourcingbroker)}`,
              clientindustry: `${cleanIndustryList(project.clientcompany)}`,
              broker: `${cleanPersonList(project.broker)}`,
              otherJllServices: `${cleanServicesList(
                project.otherJllServices
              )}`,
              sourcingmethod: `${cleanSourcingMethodList(
                project.sourcingmethodology
              )}`,
            };
          });

          let dalist = getDAList(opps);
          let selist = getSEList(opps);
          let brokerlist = getbrokerlist(cleandata);
          let opplist = getOppList(cleandata);
          let techlist = getTechList(cleandata);
          let opptypelist = getOppTypeList(opps);
          let meetingtypelist = getMeetingTypeList(opps);
          let servicelinelist = getServiceLineList(opps);
          let industrylist = getIndustryList(cleandata);
          let sourcingmethodology = getSourcingMethodList(cleandata);
          let otherserviceslist = getOtherServicesList(cleandata);
          let propertytypelist = getPropertyTypeList(opps);
          let pmValues = getPMValues(cleandata);

          let practicegrouplist = getPracticeGroupList(opps);

          let finaldalist = [];
          let finalselist = [];
          let finalmarketcodelist = [];
          let finalindustries = [];
          let finalsourcingmethods = [];
          let finalservicelinelist = [];
          let finalotherservices = [];
          let finaltechlist = [];
          let finalbrokerlist = [];
          let finalpracticegrouplist = [];
          let statuses = ["Won", "Lost", "Open", "Pending", "Dormant"];
          let finalopptypelist = [];
          let finalpropertytypelist = [];
          let finalstatuslist = [];
          let finalDealTypeList = [];
          let finalDealSubtypeList = [];
          let finalSalesStageList = [];

          pmValues.dealTypeList.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalDealTypeList.push(newitem);
          });

          pmValues.dealSubTypeList.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalDealSubtypeList.push(newitem);
          });

          pmValues.salesStageList.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalSalesStageList.push(newitem);
          });

          marketcodelist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalmarketcodelist.push(newitem);
          });

          techlist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finaltechlist.push(newitem);
          });

          statuses.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalstatuslist.push(newitem);
          });

          propertytypelist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalpropertytypelist.push(newitem);
          });

          opptypelist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalopptypelist.push(newitem);
          });

          brokerlist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalbrokerlist.push(newitem);
          });

          dalist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finaldalist.push(newitem);
          });

          selist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalselist.push(newitem);
          });

          industrylist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalindustries.push(newitem);
          });

          practicegrouplist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalpracticegrouplist.push(newitem);
          });

          sourcingmethodology.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalsourcingmethods.push(newitem);
          });

          otherserviceslist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalotherservices.push(newitem);
          });

          servicelinelist.forEach((item, index) => {
            let newitem = {
              name: item,
              id: index,
            };
            finalservicelinelist.push(newitem);
          });

          let payload = {
            marketcodes: marketcodelist,
            opportunities: opps,
            opptypelist: opptypelist,
            meetingtypelist: meetingtypelist,
            industrylist: industrylist,
            propertytypelist: propertytypelist,
            oppnamelist: opplist,
            otherserviceslist: otherserviceslist,
            sourcingmethodology: sourcingmethodology,
            servicelinelist: servicelinelist,
            techlist: techlist,
            selist: selist,
            dalist: dalist,
            blist: brokerlist,
            practicegrouplist: practicegrouplist,
            finaldalist: finaldalist,
            finalselist: finalselist,
            finalmarketcodelist: finalmarketcodelist,
            finalindustries: finalindustries,
            finalsourcingmethods: finalsourcingmethods,
            finalservicelinelist: finalservicelinelist,
            finalotherservices: finalotherservices,
            finaltechlist: finaltechlist,
            finalbrokerlist: finalbrokerlist,
            finalpracticegrouplist: finalpracticegrouplist,
            finalstatuslist: finalstatuslist,
            finalopptypelist: finalopptypelist,
            finalpropertytypelist: finalpropertytypelist,
            finalDealTypeList: finalDealTypeList,
            finalDealSubtypeList: finalDealSubtypeList,
            finalSalesStageList: finalSalesStageList,
          };
          dispatch(receivedUserMarketOpps(payload));
        },
        (error) => {
          dispatch(failedUserMarketOpps);
          console.log(error);
        }
      );
    } else if (cleanmarkets.length > 0) {
      let finalopplist = [];
      let finalmarketlist = [];
      let opps = [];
      let promises = [];

      markets.forEach((item) => {
        promises.push(
          axios.get(`/api/v1/marketdata/${item.market_id}/`).then(
            (res) => {
              //Get opps and markets from each market.
              let opplist = res.data.opportunities;
              opps.push(opplist);
            },
            (error) => {
              console.log(error);
            }
          )
        );
      });
      //end foreach
      Promise.all(promises).then(() => {
        opps = [].concat.apply([], opps);
        let cleandata = opps.map((project) => {
          return {
            ...project,
            pursuitname: project.pursuitname,
            techused: `${cleanTechList(project.techused)}`,
            deal_type: `${cleanDealType(project.deal_type)}`,
            deal_sub_type: `${cleanDealType(project.deal_sub_type)}`,
            sourcingbroker: `${cleanPersonList(project.sourcingbroker)}`,
            clientindustry: `${cleanIndustryList(project.clientcompany)}`,
            broker: `${cleanPersonList(project.broker)}`,
            otherJllServices: `${cleanServicesList(project.otherJllServices)}`,
            sourcingmethod: `${cleanSourcingMethodList(
              project.sourcingmethodology
            )}`,
          };
        });

        let dalist = getDAList(opps);
        let finalmarketcodelist = [];

        let marketcodelist = async () => {
          await axios.get("/api/v1/marketcodes/").then((res) => {
            let newarray = [];
            newarray = [...new Set(res.data)];
            newarray = newarray.sort();
            newarray.forEach((item, index) => {
              let newitem = {
                name: item,
                id: index,
              };
              finalmarketcodelist.push(newitem);
            });

            return newarray;
          });
        };
        let selist = getSEList(opps);
        let brokerlist = getbrokerlist(cleandata);
        let opplist = getOppList(cleandata);
        let servicelinelist = getServiceLineList(opps);
        let sourcingmethodology = getSourcingMethodList(cleandata);
        let industrylist = getIndustryList(cleandata);
        let techlist = getTechList(cleandata);
        let opptypelist = getOppTypeList(opps);
        let otherserviceslist = getOtherServicesList(cleandata);
        let meetingtypelist = getMeetingTypeList(opps);
        let propertytypelist = getPropertyTypeList(opps);
        let practicegrouplist = getPracticeGroupList(opps);
        let pmValues = getPMValues(cleandata);

        let finaldalist = [];
        let finalselist = [];

        let finalindustries = [];
        let finalsourcingmethods = [];
        let finalservicelinelist = [];
        let finalotherservices = [];
        let finaltechlist = [];
        let finalbrokerlist = [];
        let finalpracticegrouplist = [];
        let statuses = ["Won", "Lost", "Open", "Pending", "Dormant"];
        let finalopptypelist = [];
        let finalpropertytypelist = [];
        let finalstatuslist = [];
        let finalDealTypeList = [];
        let finalDealSubtypeList = [];
        let finalSalesStageList = [];

        pmValues.dealTypeList.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalDealTypeList.push(newitem);
        });

        pmValues.dealSubTypeList.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalDealSubtypeList.push(newitem);
        });

        pmValues.salesStageList.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalSalesStageList.push(newitem);
        });

        techlist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finaltechlist.push(newitem);
        });

        statuses.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalstatuslist.push(newitem);
        });

        propertytypelist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalpropertytypelist.push(newitem);
        });

        opptypelist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalopptypelist.push(newitem);
        });

        brokerlist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalbrokerlist.push(newitem);
        });

        dalist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finaldalist.push(newitem);
        });

        selist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalselist.push(newitem);
        });

        industrylist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalindustries.push(newitem);
        });

        practicegrouplist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalpracticegrouplist.push(newitem);
        });

        sourcingmethodology.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalsourcingmethods.push(newitem);
        });

        otherserviceslist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalotherservices.push(newitem);
        });

        servicelinelist.forEach((item, index) => {
          let newitem = {
            name: item,
            id: index,
          };
          finalservicelinelist.push(newitem);
        });

        let payload = {
          marketcodes: marketcodelist,
          selist: finalselist,
          dalist: finaldalist,
          opportunities: opps,
          otherserviceslist: finalotherservices,
          industrylist: finalindustries,
          techlist: finaltechlist,
          finalstatuslist: finalstatuslist,
          sourcingmethodology: finalsourcingmethods,
          meetingtypelist: meetingtypelist,
          propertytypelist: finalpropertytypelist,
          blist: finalbrokerlist,
          practicegrouplist: finalpracticegrouplist,
          oppnamelist: opplist,
          finaldalist: finaldalist,
          finalselist: finalselist,
          finalmarketcodelist: finalmarketcodelist,
          finalindustries: finalindustries,
          finalsourcingmethods: finalsourcingmethods,
          finalservicelinelist: finalservicelinelist,
          finalotherservices: finalotherservices,
          finaltechlist: finaltechlist,
          finalbrokerlist: finalbrokerlist,
          finalpracticegrouplist: finalpracticegrouplist,
          finalstatuslist: finalstatuslist,
          finalopptypelist: finalopptypelist,
          finalpropertytypelist: finalpropertytypelist,
          finalDealTypeList: finalDealTypeList,
          finalDealSubtypeList: finalDealSubtypeList,
          finalSalesStageList: finalSalesStageList,
        };
        dispatch(receivedUserMarketOpps(payload));
      });
    } else {
      dispatch(failedUserMarketOpps);
      console.log("Error retrieving opportunities.");
    }
  };
}

export function getOppDateUpdates(token) {
  return (dispatch) => {
    dispatch(receivingOppDateUpdates());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios.get("/api/v1/opportunitydateupdates/").then(
      (res) => {
        dispatch(receiveOppDateUpdatesSuccess(res.data));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getNewOppUpdates(token) {
  return (dispatch) => {
    dispatch(receivingNewOppUpdates());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios.get("/api/v1/newoppupdates/").then(
      (res) => {
        dispatch(receiveNewOppUpdatesSuccess(res.data));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function getOppStatusUpdates(token) {
  return (dispatch) => {
    dispatch(receivingOppStatusUpdates());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios.get("/api/v1/oppstatusupdate/").then(
      (res) => {
        dispatch(receiveOppStatusUpdatesSuccess(res.data));
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

export function receivingNotifications() {
  return {
    type: RECEIVING_NOTIFICATIONS,
  };
}

export function receivingMarketData() {
  return {
    type: RECEIVING_MARKETS,
  };
}

export function receivingOppDateUpdates() {
  return {
    type: RECEIVING_MEETING_DATE_UPDATES,
  };
}

export function receivingNewOppUpdates() {
  return {
    type: RECEIVING_NEW_OPP_UPDATES,
  };
}

export function receivingOppStatusUpdates() {
  return {
    type: RECEIVING_OPP_STATUS_UPDATES,
  };
}

export function getMarketSpecificData(market, cleandata) {
  let marketdata = cleandata;

  if (!(market == "National")) {
    marketdata = cleandata.filter(
      (object) =>
        object.market[0].marketname.toLowerCase() == market.toLowerCase()
    );
  }

  let payload = {
    marketdata: marketdata,
  };
  return {
    type: GET_MARKET_SPECIFIC_DATA,
    payload,
  };
}

export function receivedUserMarketOpps(payload) {
  return {
    type: RECEIVED_USER_MARKET_OPPS,
    payload,
  };
}

export function failedUserMarketOpps() {
  return {
    type: FAILED_USER_MARKET_OPPS,
  };
}

export function failedUserMarketOppsRevert() {
  return {
    type: FAILED_USER_MARKET_OPPS_REVERT,
  };
}

export function receiveMarketDataSuccess(payload) {
  return {
    type: RECEIVED_MARKET_DATA_SUCCESS,
    payload,
  };
}

export function receiveMarketOptionsSuccess(payload) {
  return {
    type: RECEIVED_MARKET_OPTIONS_SUCCESS,
    payload,
  };
}

export function receiveNotificationSuccess(payload) {
  return {
    type: RECEIVED_NOTIFICATION_SUCCESS,
    payload,
  };
}

export function receiveOppDateUpdatesSuccess(payload) {
  return {
    type: RECEIVED_MEETING_DATE_UPDATES_SUCCESS,
    payload,
  };
}

export function receiveNewOppUpdatesSuccess(payload) {
  return {
    type: RECEIVED_NEW_OPP_UPDATES_SUCCESS,
    payload,
  };
}

export function receiveOppStatusUpdatesSuccess(payload) {
  return {
    type: RECEIVED_OPP_STATUS_UPDATES_SUCCESS,
    payload,
  };
}

export function receiveDataSuccess(payload, dateupdated = new Date()) {
  let cleandata = payload.map((project) => {
    return {
      id: project.opportunityid,
      pursuitname: project.pursuitname,
      datecreated: project.datecreated,
      type: project.type,
      sourcingbroker: `${cleanPersonList(project.sourcingbroker)}`,
      bdmember: `${cleanPersonList(project.bdmember)}`,
      meetingtype: project.meetingtype,
      broker: `${cleanPersonList(project.broker)}`,
      meetingdate: project.meetingdate,
      opportunityid: project.opportunityid,
      winloss: project.winloss,
      pursuitsize: project.pursuitsize,
      market: project.market,
      servicelines: `${cleanServiceList(project.servicelines)}`,
    };
  });

  let blist = [];

  let brokers = cleandata.map(({ broker }) => [broker]);
  brokers.forEach((item) => blist.push(item));
  let sbrokers = cleandata.map(({ sourcingbroker }) => [sourcingbroker]);
  sbrokers.forEach((item) => blist.push(item));
  let merged = [].concat.apply([], blist);
  let newarray = [];
  merged.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(", ");
      newarray.push(newitem);
    } else {
      newarray.push(item);
    }
  });
  merged = [].concat.apply([], newarray);
  blist = [...new Set(merged)];
  blist = blist.sort();

  let servicelinelist = [];
  let slines = cleandata.map(({ servicelines }) => [servicelines]);
  slines.forEach((item) => servicelinelist.push(item));
  let smerged = [].concat.apply([], servicelinelist);

  smerged.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(", ");
      servicelinelist.push(newitem);
    } else {
      servicelinelist.push(item);
    }
  });
  smerged = [].concat.apply([], servicelinelist);
  servicelinelist = [...new Set(smerged)];
  servicelinelist = servicelinelist.sort();

  let oppnamelist = cleandata.map(({ pursuitname }) => pursuitname).sort();

  let oppobjectlist = [];

  cleandata.forEach((item) => {
    let newobj = {
      value: item.id,
      label: item.pursuitname,
    };
    oppobjectlist.push(newobj);
  });

  payload = {
    projects: payload,
    cleandata: cleandata,
    oppnamelist: oppnamelist,
    oppobjectlist: oppobjectlist,
    blist: blist,
    servicelinelist: servicelinelist,
    dataLastupdated: dateupdated,
  };
  return {
    type: RECEIVED_DATA_SUCCESS,
    payload,
  };
}

export function setCleanData(payload, currentmarketname) {
  let cleandata = payload.map((project) => {
    return {
      id: project.opportunityid,
      pursuitname: project.pursuitname,
      datecreated: project.datecreated,
      type: project.type,
      sourcingbroker: `${cleanPersonList(project.sourcingbroker)}`,
      bdmember: `${cleanPersonList(project.bdmember)}`,
      meetingtype: project.meetingtype,
      broker: `${cleanPersonList(project.broker)}`,
      meetingdate: project.meetingdate,
      opportunityid: project.opportunityid,
      winloss: project.winloss,
      pursuitsize: project.pursuitsize,
      market: project.market,
      servicelines: `${cleanServiceList(project.servicelines)}`,
    };
  });

  let blist = [];

  let brokers = cleandata.map(({ broker }) => [broker]);
  brokers.forEach((item) => blist.push(item));
  let sbrokers = cleandata.map(({ sourcingbroker }) => [sourcingbroker]);
  sbrokers.forEach((item) => blist.push(item));
  let merged = [].concat.apply([], blist);
  let newarray = [];
  merged.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(", ");
      newarray.push(newitem);
    } else {
      newarray.push(item);
    }
  });
  merged = [].concat.apply([], newarray);
  blist = [...new Set(merged)];
  blist = blist.sort();

  let servicelinelist = [];
  let slines = cleandata.map(({ servicelines }) => [servicelines]);
  slines.forEach((item) => servicelinelist.push(item));
  let smerged = [].concat.apply([], servicelinelist);

  smerged.forEach((item) => {
    if (item.includes(",")) {
      let newitem = item.split(", ");
      servicelinelist.push(newitem);
    } else {
      servicelinelist.push(item);
    }
  });
  smerged = [].concat.apply([], servicelinelist);
  servicelinelist = [...new Set(smerged)];
  servicelinelist = servicelinelist.sort();

  let oppnamelist = cleandata.map(({ pursuitname }) => pursuitname).sort();

  let oppobjectlist = [];

  cleandata.forEach((item) => {
    let newobj = {
      value: item.id,
      label: item.pursuitname,
    };
    oppobjectlist.push(newobj);
  });
  let marketdata = cleandata;
  if (currentmarketname != "National") {
    marketdata = cleandata.filter(
      (object) =>
        object.market[0].marketname.toLowerCase() ==
        currentmarketname.toLowerCase()
    );
  }

  payload = {
    marketdata: marketdata,
    cleandata: cleandata,
    oppnamelist: oppnamelist,
    oppobjectlist: oppobjectlist,
    blist: blist,
    servicelinelist: servicelinelist,
  };
  return {
    type: SET_CLEAN_DATA,
    payload,
  };
}

export function receivingData() {
  return {
    type: RECEIVING_DATA,
  };
}

export function getProjectsRequest(token) {
  return (dispatch) => {
    dispatch(receivingProjects());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios.get("/api/v1/plist/").then((res) => {
      dispatch(receiveDataSuccess(res.data, new Date()));
      saveToLocalStorage(res.data, "projects");
    });
  };
}

export function getHistoricalData(token) {
  return (dispatch) => {
    dispatch(receivingProjects());
    axios.defaults.headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    axios.get("/api/v1/plist/?&timeframe=all").then((res) => {
      dispatch(receiveDataSuccess(res.data, new Date()));
      saveToLocalStorage(res.data, "projects");
    });
  };
}

function saveToLocalStorage(projects, type) {
  try {
    const serializedState = JSON.stringify(projects);
    if (type == "projects") {
      localStorage.setItem("projects", serializedState);
      localStorage.setItem("dataLastupdated", new Date());
    } else if (type == "nationaldashboarddata") {
      localStorage.setItem("nationaldashboarddata", serializedState);
    }
  } catch (e) {
    console.log(e);
  }
}

export function setFilteredProjects(payload) {
  return {
    type: SET_FILTERED_PROJECTS,
    payload,
  };
}

export function receiveProjects(payload) {
  return {
    type: RECEIVED_PROJECTS,
    payload,
  };
}

export function receivingProjects(payload) {
  return {
    type: RECEIVING_PROJECTS,
    payload,
  };
}

export function receiveProject(payload) {
  return {
    type: RECEIVED_PROJECT,
    payload,
  };
}

export function receivingProject() {
  return {
    type: RECEIVING_PROJECT,
  };
}
